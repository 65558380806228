import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Route, Switch } from "react-router-dom";
// core components
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";

import EditColumna from "./components/EditColumna";
import ModalDelete from "./components/ModalDelete";
import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import { StateListColumnas, ColumnsListado } from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formControl: {
    marginRight: "30px",
    marginTop: "20px",
    minWidth: 200
  },
  selectEmpty: {
    marginTop: "20px"
  }
};

class Columnas extends Component {
  state = { ...StateListColumnas };
  listado = [];
  calles = [];

  componentDidMount() {
    this.getListado();
    this.getCalles();
  }

  getListado = () => {
    this.setState({
      isLoading: true
    });

    Database.get(
      "/list-columnas/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];
        this.listado = resultado;
        if (this.state.id_calle) this.handleChangeSelect(this.state.id_calle);

        this.setState({
          isLoading: false
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getCalles = () => {
    Database.get("/list-calles/" + process.env.REACT_APP_ID_ALMACEN, this).then(
      res => {
        let resultado = [...res.result];
        this.setState({
          calles: resultado[0]
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleChangeSelect = value => {
    let listado = this.listado.filter(elem => {
      return parseInt(elem.id_calle) === parseInt(value);
    });

    let calle = this.state.calles.find(elem => {
      return parseInt(elem.id) === parseInt(value);
    });

    this.setState({ id_calle: value, rowCalle: calle, listado: listado });
  };

  editItem = value => {
    this.props.history.push(this.props.match.url + "/editar/" + value);
  };

  handleDelete = rowData => {
    Database.post(
      "/delete-columna",
      { id: rowData.id, id_almacen: process.env.REACT_APP_ID_ALMACEN },
      this
    ).then(
      res => {
        let listado = [...this.state.listado];
        listado = listado.filter(elem => {
          if (elem.id === rowData.id) return false;

          return true;
        });

        this.setState(
          {
            listado: listado,
            openDeleteDialog: false,
            deleteRowData: null
          },
          () => {
            toast.success("La columna se ha eliminado con exito!");
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    });
  }

  render() {
    let style = {};
    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: "none" };
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Columnas</h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Columnas
              </p>
            </CardHeader>
            <CardBody>
              <div>
                <FormControl className={this.props.classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Pasillo</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.id_calle}
                    onChange={event =>
                      this.handleChangeSelect(event.target.value)
                    }
                  >
                    {this.state.calles.map(elem => {
                      return (
                        <MenuItem value={elem.id}>
                          {"Pasillo Nº" +
                            elem.numero +
                            " (segmento " +
                            elem.segmento +
                            ")"}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Button
                  disabled={!this.state.id_calle}
                  style={{ marginTop: "25px" }}
                  onClick={() =>
                    this.props.history.push(this.props.match.url + "/nuevo")
                  }
                  color="primary"
                >
                  <AddIcon /> Generar Columnas
                </Button>
              </div>
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListado}
                data={this.state.listado}
                title=""
                localization={localization}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar Columna",
                    onClick: (event, rowData) =>
                      this.props.history.push(
                        this.props.match.url + "/editar/" + rowData.id
                      )
                    //onClick: (event, rowData) => {console.log(this.state.id_calle); console.log(this.state.rowCalle); console.log(rowData.id)}
                  },

                  {
                    tooltip: "Eliminar una columna",
                    icon: "delete",
                    onClick: (evt, rowData) => this.handleDeleteButton(rowData)
                  }
                ]}
                components={{
                  Container: props => <Paper elevation={0} {...props} />
                }}
                options={{
                  //filtering: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: "Columnas " + moment().format("DD-MM-YYYY"),
                  exportDelimiter: ";",
                  headerStyle: {
                    backgroundColor: lightGreen[700],
                    color: "#FFF"
                  }
                }}
              />
            </CardBody>
          </Card>

          <Switch>
            <Route
              path={this.props.match.url + "/nuevo"}
              render={() => (
                <EditColumna
                  idCalle={this.state.id_calle}
                  rowCalle={this.state.rowCalle}
                  getListado={() => this.getListado()}
                />
              )}
            />

            <Route
              path={this.props.match.url + "/editar/:idColumna"}
              render={() => (
                /*
           <h1>{this.state.id_calle} - {this.state.rowCalle}</h1>
           */

                <EditColumna
                  idCalle={this.state.id_calle}
                  //rowCalle={this.state.rowCalle}
                  getListado={() => this.getListado()}
                />
              )}
            />
          </Switch>
        </GridItem>
        <ModalDelete
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={rowData => this.handleDelete(rowData)}
        />
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Columnas);
