import React, { useEffect } from "react";
// style
import lightGreen from "@material-ui/core/colors/lightGreen";
// material table
import MaterialTable from "material-table";
import { localization } from "variables/general.js";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import Database from "variables/Database.js";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

const addToLote = (rowData, props) => {
  props.addContenedorALoteDeSalida(rowData);
};

export const removeFromLote = (rowData, props) => {
  props.removeContenedorDeLoteDeSalida(rowData);
};

const isInLote = (rowData, props) => {
  let test = props.contenedoresEnLoteDeSalida.filter(c => {
    return c.id === rowData.id;
  });
  return test.length == 0;
};

export let localization_options = { header: { actions: "" } };
localization_options = { ...localization, ...localization_options };

export const columnasTablaContenedoresSalidaAlmacen = [
  { title: "Pallet", field: "id" },
  { title: "Referencia", field: "ProductosUnidad.nombre_prod_unidad" },
  { title: "EAN13", field: "ProductosUnidad.ean13_prod_unidad" },
  { title: "Calle de Salida", field: "CallesSalida.calles_salida_descripcion" },
  { title: "Cliente", field: "cliente.cliente" },
  { title: "Lote", field: "lote" },
  { title: "Pedido", field: "referencia" }
];

function TablaSalidaDeAlmacen(props) {
  useEffect(() => {
    Database.get("/list-contenedores-en-salida-listar/" + props.pedido_id).then(
      result => {
        let contenedores = [...result.result];
        props.setContenedores(contenedores);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, [props.reload, props.pedido_id]);

  return (
    <MaterialTable
      id="tabla-salidas"
      isLoading={props.isLoading}
      columns={columnasTablaContenedoresSalidaAlmacen}
      data={props.contenedores}
      title=""
      localization={localization_options}
      actions={[
        rowData => ({
          icon: "send",
          tooltip: "Enviar a lote de Salida",
          onClick: (event, rowData) => addToLote(rowData, props),
          disabled: !isInLote(rowData, props)
        }),
        rowData => ({
          icon: "undo",
          tooltip: "Devolver a su ubicación",
          onClick: (event, rowData) => removeFromLote(rowData, props),
          disabled: isInLote(rowData, props)
        })
      ]}
      options={{
        filtering: true,
        exportDelimiter: ";",
        headerStyle: {
          backgroundColor: lightGreen[700],
          color: "#FFF"
        },
        selection: false,
        actionsColumnIndex: -1
      }}
    ></MaterialTable>
  );
}

const mapStateToProps = state => {
  return {
    contenedores: state.salidas.contenedores,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    isLoading: state.salidas.isLoading,
    reload: state.salidas.reload,
    selectedTab: state.salidas.selectedTab,
    pedido_id: state.pedidossalidas.pedido_id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setContenedores: contenedores =>
      dispatch(actionCreators.setContenedores(contenedores)),
    removeContenedorDeLoteDeSalida: contenedor =>
      dispatch(actionCreators.removeContenedorDeLoteDeSalida(contenedor)),
    addContenedorALoteDeSalida: contenedor =>
      dispatch(actionCreators.addContenedorALoteDeSalida(contenedor)),
    setSelectedTab: selectedTab =>
      dispatch(actionCreators.setSelectedTab(selectedTab))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TablaSalidaDeAlmacen)
);
