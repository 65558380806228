import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

class SelectorUbicacionNueva extends Component {
  state = {
    ubicaciones: [],
    selected_ubicacion: null,
    inputUbicacion: ""
  };

  componentDidMount() {
    Database.get(
      "/list-pickingubicacioneslibres/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        console.log(res.result);
        let resultado = [...res.result];
        this.setState({
          ubicaciones: resultado
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = "0"; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return "-" + numberOutput.toString();
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return "-" + zero.repeat(width - length) + numberOutput.toString();
      } else {
        return zero.repeat(width - length) + numberOutput.toString();
      }
    }
  }

  render() {
    return (
      <Autocomplete
        id="combo-box-productos"
        options={this.state.ubicaciones}
        getOptionLabel={ubicacion =>
          `${ubicacion["Calle.calle"]}${this.zfill(
            ubicacion["Columna.columna"],
            3
          )}${this.zfill(ubicacion["nivel"], 2)} - ${
            ubicacion["Ocupacion.descripcion"]
          }`
        }
        value={this.state.selected_ubicacion}
        onChange={(event, newValue) => {
          this.props.getDataSelectedUbicacionNueva(newValue);
          console.log(newValue);
          this.setState({ selected_ubicacion: newValue });
        }}
        inputValue={this.state.inputUbicacion}
        onInputChange={(event, newInputValue) => {
          console.log(newInputValue);
          this.setState({ inputUbicacion: newInputValue });
        }}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Seleccionar una ubicación"
            variant="outlined"
          />
        )}
      ></Autocomplete>
    );
  }
}

export default SelectorUbicacionNueva;
