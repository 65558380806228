import * as actionTypes from "./actionTypes";

export const setContenedores = contenedores => {
  return {
    type: actionTypes.SET_CONTENEDORES,
    contenedores: contenedores
  };
};

export const setUbicacionesPicking = ubicacionesPicking => {
  return {
    type: actionTypes.SET_UBICACIONES_PICKING,
    ubicacionesPicking: ubicacionesPicking
  };
};

export const removeContenedorDeLoteDeSalida = contenedor => {
  return {
    type: actionTypes.REMOVE_CONTENEDOR_DE_LOTE_DE_SALIDA,
    contenedor: contenedor
  };
};

export const addContenedorALoteDeSalida = contenedor => {
  return {
    type: actionTypes.ADD_CONTENEDOR_A_LOTE_DE_SALIDA,
    contenedor: contenedor
  };
};

export const addBultosALoteDeSalida = bultos => {
  return {
    type: actionTypes.ADD_BULTOS_A_LOTE_DE_SALIDA,
    bultos: bultos
  };
};

export const addDisplaysALoteDeSalida = displays => {
  return {
    type: actionTypes.ADD_DISPLAYS_A_LOTE_DE_SALIDA,
    displays: displays
  };
};

export const addUnidadesALoteDeSalida = unidades => {
  return {
    type: actionTypes.ADD_UNIDADES_A_LOTE_DE_SALIDA,
    unidades: unidades
  };
};

export const restarBultosDePicking = (id_ubicacion, n_bultos) => {
  return {
    type: actionTypes.RESTAR_BULTOS_DE_PICKING,
    id_ubicacion: id_ubicacion,
    n_bultos: n_bultos
  };
};

export const restarDisplaysDePicking = (id_ubicacion, n_displays) => {
  return {
    type: actionTypes.RESTAR_DISPLAYS_DE_PICKING,
    id_ubicacion: id_ubicacion,
    n_displays: n_displays
  };
};

export const restarUnidadesDePicking = (id_ubicacion, n_unidades) => {
  return {
    type: actionTypes.RESTAR_UNIDADES_DE_PICKING,
    id_ubicacion: id_ubicacion,
    n_unidades: n_unidades
  };
};

export const setContenedoresEnCalleDeSalida = contenedoresEnCalleDeSalida => {
  return {
    type: actionTypes.SET_CONTENEDORES_EN_CALLES_DE_SALIDA,
    contenedoresEnCalleDeSalida: contenedoresEnCalleDeSalida
  };
};

export const setModalVisibility = (status, modal) => {
  return {
    type: actionTypes.SET_MODAL_VISIBILITY,
    status: status,
    modal: modal
  };
};

export const setCalleDeSalida = calle => {
  return {
    type: actionTypes.SET_CALLE_DE_SALIDA,
    calle: calle
  };
};

export const reloadTable = () => {
  return {
    type: actionTypes.RELOAD_TABLE
  };
};

export const reloadTablePicking = () => {
  return {
    type: actionTypes.RELOAD_TABLE_PICKING
  };
};

export const resetForm = tab_number => {
  return {
    type: actionTypes.RESET_FORM,
    tab_number: tab_number
  };
};

export const setSelectedTab = value_selected_tab => {
  return {
    type: actionTypes.SET_SELECTED_TAB,
    value_selected_tab: value_selected_tab
  };
};

export const setSePerderanContenedoresSeleccionados = bool_value => {
  return {
    type: actionTypes.SET_SE_PERDERAN_CONTENEDORES_SELECCIONADOS,
    bool_value: bool_value
  };
};

export const setMaximosCantidadPicking = (
  total_bultos,
  total_displays,
  total_unidades
) => {
  return {
    type: actionTypes.SET_MAXIMOS_CANTIDAD_PICKING,
    total_bultos: total_bultos,
    total_displays: total_displays,
    total_unidades: total_unidades
  };
};

export const setBultosSeleccionados = bultos_seleccionados => {
  return {
    type: actionTypes.SET_BULTOS_SELECCIONADOS,
    bultos_seleccionados: bultos_seleccionados
  };
};

export const setDisplaysSeleccionados = displays_seleccionados => {
  return {
    type: actionTypes.SET_DISPLAYS_SELECCIONADOS,
    displays_seleccionados: displays_seleccionados
  };
};

export const setUnidadesSeleccionadas = unidades_seleccionadas => {
  return {
    type: actionTypes.SET_UNIDADES_SELECCIONADAS,
    unidades_seleccionadas: unidades_seleccionadas
  };
};

export const setSelectedRadioButtonPicking = selected_radio_picking => {
  return {
    type: actionTypes.SET_SELECTED_RADIO_BUTTON_PICKING,
    selected_radio_picking: selected_radio_picking
  };
};

export const resetSelectorCantidadPicking = () => {
  return {
    type: actionTypes.RESET_SELECTED_CANTIDAD_PICKING
  };
};

export const disable_total_picking = radio_group_picking => {
  return {
    type: actionTypes.DISABLE_TOTAL_PICKING,
    radio_group_picking: radio_group_picking
  };
};

export const setContenedoresUbicacionesPicking = contenedores_ubicacion_picking => {
  return {
    type: actionTypes.SET_CONTENEDORES_UBICACIONES_PICKING,
    contenedores_ubicacion_picking: contenedores_ubicacion_picking
  };
};

export const setTipoPickingUbicacion = tipo_picking => {
  return {
    type: actionTypes.SET_TIPO_PICKING_UBICACION,
    tipo_picking: tipo_picking
  };
};

export const addPickingALoteDeSalida = lote_picking => {
  return {
    type: actionTypes.ADD_PICKING_A_LOTE_DE_SALIDA,
    lote_picking: lote_picking
  };
};

export const setDatosUbicacionPicking = datos_completos_ubicacion_picking => {
  return {
    type: actionTypes.SET_DATOS_UBICACION_PICKING,
    datos_completos_ubicacion_picking: datos_completos_ubicacion_picking
  };
};

export const removeUbicacionLoteSalida = ubicacion_id => {
  return {
    type: actionTypes.REMOVE_UBICACION_LOTE_SALIDA,
    ubicacion_id: ubicacion_id
  };
};

export const onChangeHandlerInputNumber = event => {
  return {
    type: actionTypes.ON_CHANGE_HANDLER_INPUT_NUMBER,
    event: event
  };
};
