export const StateAlmacen = {
  orderForm: {
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    direccion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Dirección",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    telefono: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Telefono",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo Total (mts)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    ancho: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho Total (mts)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false,
  openMapaDialog: false
};

export const StateListAlmacenes = {
  almacenes: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const ColumnsListado = [
  { title: "Nombre", field: "nombre" },
  { title: "Dirección", field: "direccion" },
  { title: "Telefono", field: "telefono" }
];
