export const StateListClientes = {
  clientes: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditCliente = {
  editClienteForm: {
    razon_social: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Razon Social",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    nombre_fantasia: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre Fantasia",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    // username: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Nombre Usuario',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
    // password: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'password',
    //         label: 'constraseña',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         minLength: 5,
    //         required: true,

    //     },
    //     valid: false,
    //     touched: false
    // },
    ruc: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "RUC",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    numero_cliente: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Numero Cliente",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    telefono: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Telefono",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  clienteEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewCliente = {
  newClienteForm: {
    razon_social: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Razon Social",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    nombre_fantasia: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre Fantasia",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    // username: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Nombre Usuario',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
    // password: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'password',
    //         label: 'constraseña',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         minLength: 5,
    //         required: true,

    //     },
    //     valid: false,
    //     touched: false
    // },
    ruc: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "RUC",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    numero_cliente: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Numero Cliente",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    tocken: {
      elementType: "input",
      elementConfig: {
        type: "password",
        label: "Token",
        fullWidth: true
      },
      value: "",
      validation: {
        minLength: 5,
        required: true
      },
      valid: false,
      touched: false
    },
    telefono: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Telefono",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },

  formIsValid: false,
  successSubmit: null,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Numero Cliente", field: "numero_cliente" },
  { title: "Razon Social", field: "razon_social" },
  { title: "Nombre Fantasia", field: "nombre_fantasia" },
  { title: "RUC", field: "ruc" },
  // { title: "usuario", field: "username" },

  { title: "Telefono", field: "telefono" }
];
