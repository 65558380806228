import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
  style_selectores_picking: {
    marginLeft: "auto",
    marginRight: "auto",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  }
}));

const postCantidadSeleccionada = props => {
  let data_seleccionada = {};
  data_seleccionada.bultos_seleccionados = props.bultos_seleccionados;
  // props.restarBultosDePicking(props.datos_completos_ubicacion_picking.id, props.bultos_seleccionados);
  data_seleccionada.displays_seleccionados = props.displays_seleccionados;
  data_seleccionada.unidades_seleccionadas = props.unidades_seleccionadas;
  // props.reloadTablePicking();

  // Database.post(`/cantidad-picking-seleccionada`, {data_seleccionada}, this).then(res => {
  //     let ubicacionesPicking = [...res.result];
  //     props.setUbicacionesPicking(ubicacionesPicking)
  // }, err => {
  //     toast.error(err.message);
  // })
};

const SelectorCantidadPickingASalida = props => {
  const classes = useStyles();

  if (props.total_bultos === "No corresponde" || props.total_bultos < 0) {
    props.setBultosSeleccionados(0);
  } else if (props.total_bultos < props.bultos_seleccionados) {
    props.setBultosSeleccionados(props.total_bultos);
  }

  if (props.total_displays === "No corresponde" || props.total_displays < 0) {
    props.setDisplaysSeleccionados(0);
  } else if (props.total_displays < props.displays_seleccionados) {
    props.setDisplaysSeleccionados(props.total_displays);
  }

  if (props.total_unidades === "No corresponde" || props.total_unidades < 0) {
    props.setUnidadesSeleccionadas(0);
  } else if (props.total_unidades < props.unidades_seleccionadas) {
    props.setUnidadesSeleccionadas(props.total_unidades);
  }

  return (
    <form
      className={classes.style_selectores_picking}
      noValidate
      autoComplete="off"
    >
      <div>
        <FormControl>
          <TextField
            id="cantidad-bultos"
            label="Cantidad de bultos"
            type="number"
            value={props.bultos_seleccionados}
            defaultValue={0}
            disabled={props.disable_total_bultos}
            onChange={event => props.onChangeHandlerInputNumber(event)}
            onClick={() => postCantidadSeleccionada(props)}
            InputProps={{
              inputProps: { min: 0, max: props.total_bultos || 0 }
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <TextField
          id="cantidad-display"
          label="Cantidad de displays"
          type="number"
          value={props.displays_seleccionados}
          defaultValue={0}
          disabled={props.disable_total_displays}
          onChange={event => props.onChangeHandlerInputNumber(event)}
          onClick={() => postCantidadSeleccionada(props)}
          InputProps={{
            inputProps: { min: 0, max: props.total_displays || 0 }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="cantidad-unidades"
          label="Cantidad de unidades"
          type="number"
          value={props.unidades_seleccionadas}
          defaultValue={0}
          disabled={props.disable_total_unidades}
          onChange={event => props.onChangeHandlerInputNumber(event)}
          onClick={() => postCantidadSeleccionada(props)}
          InputProps={{
            inputProps: { min: 0, max: props.total_unidades || 0 }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    bultos_seleccionados: state.salidas.bultos_seleccionados,
    displays_seleccionados: state.salidas.displays_seleccionados,
    unidades_seleccionadas: state.salidas.unidades_seleccionadas,
    total_bultos: state.salidas.total_bultos,
    total_displays: state.salidas.total_displays,
    total_unidades: state.salidas.total_unidades,
    disable_total_bultos: state.salidas.disable_total_bultos,
    disable_total_displays: state.salidas.disable_total_displays,
    disable_total_unidades: state.salidas.disable_total_unidades,
    datos_completos_ubicacion_picking:
      state.salidas.datos_completos_ubicacion_picking,
    ubicacionesPicking: state.salidas.ubicacionesPicking
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeHandlerInputNumber: event =>
      dispatch(actionCreators.onChangeHandlerInputNumber(event)),
    reloadTablePicking: () => dispatch(actionCreators.reloadTablePicking()),
    setUbicacionesPicking: ubicacionesPicking =>
      dispatch(actionCreators.setUbicacionesPicking(ubicacionesPicking)),
    setBultosSeleccionados: bultos_seleccionados =>
      dispatch(actionCreators.setBultosSeleccionados(bultos_seleccionados)),
    setDisplaysSeleccionados: displays_seleccionados =>
      dispatch(actionCreators.setDisplaysSeleccionados(displays_seleccionados)),
    setUnidadesSeleccionadas: unidades_seleccionadas =>
      dispatch(actionCreators.setUnidadesSeleccionadas(unidades_seleccionadas))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectorCantidadPickingASalida);
