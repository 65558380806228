import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
  StateProductosPresentacion,
  StateEditProductosPresentacion
} from "../variablesState/variablesStatePresentacion";

import Database from "variables/Database.js";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import Alert from "@material-ui/lab/Alert";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import ModalBuscarProductos from "./ModalBuscarProducto";
import ModalBuscarCliente from "./ModalBuscarCliente";

import * as accs from "../FuncionesAccesorias";
import FormEdicionPresentacion from "./FormEdicionPresentacion/FormEdicionPresentacion";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewProductoPresentacion extends Component {
  state = this.props.match.params.idProductoPresentacion
    ? JSON.parse(JSON.stringify(StateEditProductosPresentacion))
    : JSON.parse(JSON.stringify(StateProductosPresentacion));

  _copyState = () => {
    return JSON.parse(JSON.stringify(this.state));
  };

  handleSubmit = event => {
    event.preventDefault();

    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }

    if (this.state.orderForm.display.value) {
      for (let key in this.state.orderForm2) {
        objeto[key] = this.state.orderForm2[key].value;
      }
    }

    if (this.state.orderForm.bulto.value) {
      for (let key in this.state.orderForm3) {
        objeto[key] = this.state.orderForm3[key].value;
      }
    }
    objeto["id_producto"] = this.state.selectedProduct.id;
    // Chequeando si es display por bulto o unidades por bulto
    if (this.state.orderForm.display.value == 1) {
      objeto["cantidad_displays_bulto"] = objeto["elementos_por_bulto"];
      objeto["cantidad_unidades_bulto"] =
        objeto["elementos_por_bulto"] * objeto["cantidad_display"];
    } else {
      objeto["cantidad_unidades_bulto"] = objeto["elementos_por_bulto"];
    }
    this.setState({
      disableAllButtons: true
    });

    Database.post(`/insert-producto-presentacion`, { ...objeto }).then(
      res => {
        this.setState(
          {
            disableAllButtons: false
          },
          () => {
            toast.success("El Dato Logísitico se ha creado con exito!");
            this.resetForm();

            if (this.props.onSave) this.props.onSave(res.result.id);
            else this.props.getListado();
            this.getItem();
            this.getPickingTipo();
          }
        );
      },
      err => {
        this.setState({
          disableAllButtons: false
        });

        toast.error(err.message);
      }
    );
  };

  handleSubmitEdit = event => {
    event.preventDefault();
    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }

    this.setState({
      disableAllButtons: true
    });

    Database.post(`/update-producto-presentacion`, {
      id: this.props.match.params.idProductoPresentacion,
      id_almacen: process.env.REACT_APP_ID_ALMACEN,
      ...objeto
    }).then(
      res => {
        this.setState(
          {
            disableAllButtons: false
          },
          () => {
            toast.success("El Dato Logísitico se ha modificado con exito!");

            this.props.getListado();
          }
        );
      },
      err => {
        this.setState({
          disableAllButtons: false
        });

        toast.error(err.message);
      }
    );
  };

  getItem = idProductoPresentacion => {
    Database.get(
      `/list-producto-presentacion-join/` + idProductoPresentacion
    ).then(
      res => {
        let resultado = res.result;

        let orderForm = { ...this.state.orderForm };

        for (let key in orderForm) {
          if (resultado[key]) orderForm[key].value = resultado[key];
        }

        let objeto = inputAllChangedHandler(orderForm);

        this.setState({
          orderForm: objeto.orderForm,
          formIsValid: objeto.formIsValid
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getPickingTipo = () => {
    Database.get(`/list-picking-tipo/`).then(
      res => {
        let resultado = res.result;

        let orderForm = { ...this.state.orderForm };

        resultado.forEach(elem => {
          orderForm.id_picking_tipo.elementConfig.options.push({
            displayValue: elem.descripcion,
            value: elem.id
          });
        });

        this.setState({
          orderForm: orderForm
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  // componentDidMount() {
  //   if (this.props.match.params.idProductoPresentacion) {
  //     console.log('mua');

  //     this.getItem( this.props.match.params.idProductoPresentacion);
  //   }
  //   // this.getItemsSelect();
  // }

  componentDidMount() {
    Database.get("/list-productos-presentacion-productos-join/", this).then(
      result => {
        let resultado = [...result.result];
        let selectedProduct = null;
        let hasSelectedProducto = false;
        if (this.props.rowProductoUnidad) {
          selectedProduct = this.props.rowProductoUnidad;
          hasSelectedProducto = true;
        }
        this.setState({
          presentaciones: resultado,
          selectedProduct: selectedProduct,
          hasSelectedProducto: hasSelectedProducto
        });
      },
      err => {
        toast.error(err.message);
      }
    );

    if (this.props.match.params.idProductoPresentacion) {
      // console.log('entra a edit');
      this.getItem(this.props.match.params.idProductoPresentacion);
    }

    this.getPickingTipo();
    /*
    if (this.props.rowCliente) {
      this.setState({
        selectedCliente: this.props.rowCliente,
        hasSelectedCliente: true
      })
    }
    */
  }

  resetForm = () => {
    let state_copy = JSON.parse(JSON.stringify(StateProductosPresentacion));
    state_copy = {
      ...state_copy,
      presentaciones: [...this.state.presentaciones]
    };
    this.setState(state_copy);
  };

  calculoAutomaticoDisplay = e => {
    const msj_error =
      "Para calcular el peso debe ingresar cantidad de unidades \n por display";
    let orderForm2 = { ...this.state.orderForm2 };
    if (orderForm2.cantidad_display.value) {
      accs.getPesoParaUnidadesEnDisplay(this.state.selectedProduct, orderForm2);
      let { orderForm, formIsValid } = inputChangedHandler(
        { target: { value: orderForm2.peso_display.value } },
        "peso_display",
        orderForm2
      );
      this.setState({
        orderForm2: orderForm,
        formIsValid2: formIsValid
      });
      accs.unsetPesoErrorMessageDisplay();
    } else {
      accs.setPesoErrorMessageDisplay(msj_error);
      this.setState({ orderForm2: orderForm2 });
    }
  };

  calculoAutomaticoBulto = e => {
    let msj_base = "Para calcular el peso debe ingresar cantidad de ";
    let bulto = { ...this.state.orderForm3 };
    let display = { ...this.state.orderForm2 };
    let peso_display = display.peso_display.value;
    let cantidad_bultos = null;
    let msj_error = null;
    if (this.state.orderForm.bulto.value == 1) {
      // si tiene bulto
      cantidad_bultos = bulto.elementos_por_bulto.value;
      if (this.state.orderForm.display.value == 1) {
        // si tiene display
        if (cantidad_bultos) {
          // tiene cantidad de bultos
          if (peso_display) {
            // tiene peso de display
            accs.getPesoParaDisplayEnBulto(
              bulto,
              cantidad_bultos,
              peso_display
            );
            accs.unsetPesoErrorMessageBulto();
          } else {
            // No fue ingresado peso display
            msj_error = "Debe ingresar el peso del display";
            accs.setPesoErrorMessageBulto(msj_error);
          }
        } else {
          // no fue ingresado cantidad de bultos
          msj_error = msj_base + " displays por bulto";
          accs.setPesoErrorMessageBulto(msj_error);
        }
      }
      if (this.state.orderForm.display.value == 0) {
        // si no tiene display
        if (cantidad_bultos) {
          // tiene cantidad de bultos
          accs.getPesoParaUnidadesEnBulto(this.state.selectedProduct, bulto);
          accs.unsetPesoErrorMessageBulto();
        } else {
          // no fue ingresado cantidad de bultos
          accs.setPesoErrorMessageBulto(msj_base + " unidades por bulto");
        }
      }
    }
    let { orderForm, formIsValid } = inputChangedHandler(
      { target: { value: bulto.peso_bulto.value } },
      "peso_bulto",
      bulto
    );
    this.setState({
      bulto: orderForm,
      formIsValid3: formIsValid
    });
  };

  //  Modal Buscar productos
  handleBuscarProductoDialog = () => {
    this.setState({ openBuscarProductoDialog: true });
  };

  handleBuscarClienteDialog = () => {
    this.setState({ openBuscarClienteDialog: true });
  };

  handleModalClose(modal = "producto") {
    if (modal === "producto") {
      this.setState({ openBuscarProductoDialog: false });
    } else if (modal === "cliente") {
      this.setState({ openBuscarClienteDialog: false });
    }
  }

  handleAccept(p, modal = "producto") {
    let state_copy = this._copyState();
    if (modal === "producto") {
      state_copy.openBuscarProductoDialog = false;
      state_copy.selectedProduct = p;
      state_copy.hasSelectedProducto = true;
    } else if (modal === "cliente") {
      state_copy.openBuscarClienteDialog = false;
      state_copy.selectedCliente = p;
      state_copy.hasSelectedCliente = true;
    }
    this.setState(state_copy);
  }

  // Interaccion paso a paso

  _changeLabelInformacionBulto(new_label) {
    let copy_order_form = { ...this.state.orderForm3 };
    copy_order_form.elementos_por_bulto.elementConfig.label = new_label;
    this.setState({ orderForm3: copy_order_form });
  }

  _product_is_selected = () => {
    return this.state.hasSelectedProducto;
  };

  _toggleCheckbox = (isEnabled, which_element) => {
    let copy_state = this._copyState();
    copy_state.orderForm[which_element].elementConfig.disabled = isEnabled;
    this.setState({ copy_state });
  };

  _toggleCheckboxDisplay = radio_seleccionado => {
    // Muestra y Esconde la informacion sobre el display y cambia el label de unidades/displays por bulto
    let copy_state = { ...this.state.orderForm };

    if (radio_seleccionado === "Si") {
      copy_state.display.elementConfig.disabled = false;
      copy_state.bulto.elementConfig.disabled = false;
      copy_state.display.value = 1;
      this._changeLabelInformacionBulto("Cantidad de displays por bulto");
    } else {
      copy_state.display.elementConfig.disabled = true;
      copy_state.display.value = 0;
      copy_state.bulto.elementConfig.disabled = false;
      this._changeLabelInformacionBulto("Cantidad de unidades por bulto");
    }
    this.setState({ orderForm: copy_state });
  };

  getPresentacionesParaIdProducto = id_producto_x => {
    let presentaciones_para_prod_x = [];
    let presentaciones_copia = [...this.state.presentaciones];
    presentaciones_copia.map(x => {
      if (x.id_producto_unidad === id_producto_x) {
        presentaciones_para_prod_x.push(x);
      }
    });
    return presentaciones_para_prod_x;
  };

  msjPresentacionesGuardadasExistentes = cant_presentaciones_prod_x => {
    let msj_presentaciones_guardadas = "";
    if (cant_presentaciones_prod_x === 0) {
      msj_presentaciones_guardadas =
        "No hay Datos Logísiticos guardados para esta referencia";
    } else if (cant_presentaciones_prod_x === 1) {
      msj_presentaciones_guardadas =
        "Existe 1 Dato Logísitico asociado a esta referencia";
    } else {
      msj_presentaciones_guardadas =
        "Existen " +
        cant_presentaciones_prod_x +
        " Datos Logísiticos asociados a esta referencia";
    }
    return msj_presentaciones_guardadas;
  };

  // Validation
  _isValidDisplay = () => {
    let test = false;
    if (this.state.orderForm.display.value == true) {
      // Value significa si tiene o no display
      test = this.state.formIsValid2; // formisvalid2 es el objeto que testea display
    } else {
      test = true; // Si el display no es mostrado el test es verdadero (se puede ingresar sin display)
    }
    return test;
  };

  _isValidBulto = () => {
    let test = false;
    if (this.state.orderForm.bulto.value == true) {
      // Value significa si tiene o no display
      test = this.state.formIsValid3; // FormIsValid3 corresponde al objeto testeado cuando se inicializa un bulto
    } else {
      test = false; // Si bulto no ha sido clickeado y es visible el test es falso (debe tener bulto)
    }
    return test;
  };

  isValidForm = () => {
    return (
      this.state.orderForm3.cantidad_bultos_capa.value.trim() !== "" &&
      this.state.orderForm3.cantidad_capas.value.trim() !== "" &&
      this._isValidDisplay() &&
      this._isValidBulto() &&
      this.state.formIsValid &&
      this.state.hasSelectedProducto
    );
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    if (this.props.match.params.idProductoPresentacion) {
      return (
        <form
          onSubmit={event => {
            this.handleSubmitEdit(event);
          }}
        >
          <FormEdicionPresentacion
            clases={this.props.classes}
            estado={this.state}
            historia={this.props.history}
            valido={this.state.formIsValid}
            inputDescripcion={formElementsArray.map(elem => {
              return (
                <InputNew
                  objeto={elem.config}
                  changed={event => {
                    let { orderForm, formIsValid } = inputChangedHandler(
                      event,
                      elem.id,
                      this.state.orderForm
                    );
                    this.setState({
                      orderForm: orderForm,
                      formIsValid: formIsValid
                    });
                  }}
                ></InputNew>
              );
            })}
            parametrosRoute={this.props}
          ></FormEdicionPresentacion>
        </form>
      );
    }

    const titulo = !this.state.selectedProduct ? (
      <Alert severity="warning">
        Primero, debe seleccionar una referencia!
      </Alert>
    ) : (
      `Datos para la el Dato Logistico - ${this.state.selectedProduct.descripcion}`
    );
    //const cliente = (!this.state.selectedCliente) ? <Alert severity="warning">Primero, debe seleccionar un cliente!</Alert> : `Cliente seleccionado: ${this.state.selectedCliente.razon_social} RUC: ${this.state.selectedCliente.ruc}`;

    let presentaciones_guardadas = null;
    let cantidad_presentaciones_guardadas = null;
    let msj_presentaciones_guardadas = "";
    if (this.state.selectedProduct) {
      presentaciones_guardadas = this.getPresentacionesParaIdProducto(
        this.state.selectedProduct.id
      );
      cantidad_presentaciones_guardadas = presentaciones_guardadas.length;
      msj_presentaciones_guardadas = this.msjPresentacionesGuardadasExistentes(
        cantidad_presentaciones_guardadas
      );
    }
    const titulo_header =
      this.state.selectedProduct == null
        ? "Nuevo Dato Logísitico"
        : `${this.state.selectedProduct.descripcion}`;

    return (
      <form
        onSubmit={event => {
          this.handleSubmit(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              {titulo_header}
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario de un nuevo Dato Logísitico
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <span style={{ fontSize: "100%", fontWeight: "600" }}>
                    {" "}
                    Seleccione una referencia y un cliente
                  </span>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    disabled={this.props.rowProductoUnidad}
                    color="primary"
                    onClick={event => this.handleBuscarProductoDialog()}
                  >
                    <SearchIcon></SearchIcon> Buscar Referencia
                  </Button>
                </Grid>
                {/*
                  <Grid item>
                    <Button disabled={this.props.rowCliente} variant="contained" color="primary" onClick={(event) => this.handleBuscarClienteDialog()}>
                      <SearchIcon></SearchIcon>Buscar Cliente
                </Button>
                  </Grid>
                  */}
                <Grid item md={12}>
                  <span style={{ fontSize: "100%", fontWeight: "600" }}>
                    {titulo}
                  </span>
                </Grid>
                {/*
                  <Grid item md={12}>
                    <span style={{ fontSize: '100%', fontWeight: '600' }}>{cliente}
                    </span>
                  </Grid>
                  */}
                <Grid item md={12}>
                  <span style={{ fontSize: "90%", fontWeight: "600" }}>
                    {msj_presentaciones_guardadas}
                  </span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <InputNew
                    objeto={this.state.orderForm.descripcion}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "descripcion",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  ></InputNew>
                </Grid>
                <Grid item md={12}>
                  <InputNew
                    objeto={this.state.orderForm.id_picking_tipo}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "id_picking_tipo",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  ></InputNew>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "35px" }}>
                <Box
                  fontSize="default"
                  fontWeight="fontWeightMedium"
                  m={3}
                  alignItems="center"
                >
                  {" "}
                  TIENE DISPLAY?
                </Box>
                <InputNew
                  objeto={this.state.orderForm.display_opcional}
                  getRadioSeleccionado={x => this._toggleCheckboxDisplay(x)}
                ></InputNew>
              </Grid>

              <Grid container spacing={3}>
                {this.state.orderForm.display.value == 1 && [
                  <Grid item xs={12} sm={12}>
                    {" "}
                    <span style={{ fontSize: "130%", fontWeight: "600" }}>
                      Información Display
                    </span>
                  </Grid>,

                  <Grid style={accs.style_alert_peso_display} item md={12}>
                    <Alert severity="warning">
                      {accs.mensaje_error_peso_display}
                    </Alert>
                  </Grid>,

                  <Grid item xs={12} sm={6}>
                    <InputNew
                      objeto={this.state.orderForm2.cantidad_display}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "cantidad_display",
                          this.state.orderForm2
                        );
                        this.setState({
                          orderForm2: orderForm,
                          formIsValid2: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={8} sm={2}>
                    <Button
                      onClick={this.calculoAutomaticoDisplay}
                      style={{ top: "50%" }}
                    >
                      {" "}
                      Calcular Peso{" "}
                    </Button>
                  </Grid>,
                  <Grid item xs={4} sm={4} style={{ color: "red" }}>
                    {this.state.orderForm2.msj_error_peso_display}
                  </Grid>,
                  <Grid item xs={9} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm2.peso_display}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "peso_display",
                          this.state.orderForm2
                        );
                        this.setState({
                          orderForm2: orderForm,
                          formIsValid2: formIsValid
                        });
                      }}
                    />
                  </Grid>,

                  <Grid item xs={12} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm2.alto_display}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "alto_display",
                          this.state.orderForm2
                        );
                        this.setState({
                          orderForm2: orderForm,
                          formIsValid2: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm2.largo_display}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "largo_display",
                          this.state.orderForm2
                        );
                        this.setState({
                          orderForm2: orderForm,
                          formIsValid2: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm2.ancho_display}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "ancho_display",
                          this.state.orderForm2
                        );
                        this.setState({
                          orderForm2: orderForm,
                          formIsValid2: formIsValid
                        });
                      }}
                    />
                  </Grid>
                ]}
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <InputNew
                    objeto={this.state.orderForm.bulto}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "bulto",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>

                {this.state.orderForm.bulto.value == 1 && [
                  <Grid item xs={12} sm={12}>
                    {" "}
                    <span style={{ fontSize: "130%", fontWeight: "600" }}>
                      Información Bulto
                    </span>
                  </Grid>,
                  <Grid style={accs.style_alert_peso_bulto} item md={12}>
                    <Alert severity="warning">
                      {accs.mensaje_error_peso_bulto}
                    </Alert>
                  </Grid>,
                  <Grid item xs={12} sm={6}>
                    <InputNew
                      objeto={this.state.orderForm3.elementos_por_bulto}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "elementos_por_bulto",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={6}>
                    <Button
                      onClick={this.calculoAutomaticoBulto}
                      style={{ top: "50%" }}
                    >
                      {" "}
                      Calcular Peso{" "}
                    </Button>
                  </Grid>,
                  <Grid item xs={12} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm3.peso_bulto}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "peso_bulto",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>,

                  <Grid item xs={12} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm3.alto_bulto}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "alto_bulto",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm3.largo_bulto}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "largo_bulto",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={3}>
                    <InputNew
                      objeto={this.state.orderForm3.ancho_bulto}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "ancho_bulto",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={6}>
                    <InputNew
                      objeto={this.state.orderForm3.dun14}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "dun14",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={6}>
                    <InputNew
                      objeto={this.state.orderForm3.cantidad_bultos_capa}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "cantidad_bultos_capa",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>,
                  <Grid item xs={12} sm={6}>
                    <InputNew
                      objeto={this.state.orderForm3.cantidad_capas}
                      changed={event => {
                        let { orderForm, formIsValid } = inputChangedHandler(
                          event,
                          "cantidad_capas",
                          this.state.orderForm3
                        );
                        this.setState({
                          orderForm3: orderForm,
                          formIsValid3: formIsValid
                        });
                      }}
                    />
                  </Grid>
                ]}
              </Grid>
            </div>
            {!this.props.modal && (
              <Button
                style={{ marginTop: "25px" }}
                color="info"
                onClick={() => this.props.history.goBack()}
              >
                <ArrowBack />
                Volver
              </Button>
            )}
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={!this.isValidForm() || this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>

        <ModalBuscarProductos
          openBuscarProductoDialog={this.state.openBuscarProductoDialog}
          handleClose={() => this.handleModalClose("producto")}
          handleAccept={productData =>
            this.handleAccept(productData, "producto")
          }
        />
        <ModalBuscarCliente
          openBuscarClienteDialog={this.state.openBuscarClienteDialog}
          handleClose={() => {
            this.handleModalClose("cliente");
          }}
          handleAccept={clientData => this.handleAccept(clientData, "cliente")}
        />
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(NewProductoPresentacion));
