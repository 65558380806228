import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

function SelectorPedidosSinCliente(props) {
  const [inputValue, setInputValue] = React.useState("");
  const [pedidos, setPedidos] = React.useState([]);
  const [selected_pedido, setPedido] = React.useState(null);

  useEffect(() => {
    Database.get("/listar-pedidos", this).then(
      res => {
        let pedidos = res.result;
        setPedidos(pedidos);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, []);

  return (
    <Autocomplete
      id="combo-box-pedidos"
      value={selected_pedido ? selected_pedido : null}
      onChange={(event, newValue) => {
        setPedido(newValue);
        props.setPedidoSelected(newValue);
      }}
      options={pedidos}
      getOptionLabel={pedido => `${pedido.referencia}`}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      style={{ width: 500 }}
      renderInput={params => (
        <TextField
          {...params}
          label="Seleccionar un pedido"
          variant="outlined"
        />
      )}
    ></Autocomplete>
  );
}

const mapStateToProps = state => {
  return {
    pedido_seleccionado: state.pedidossalidas.pedido_seleccionado
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPedidoSelected: pedido =>
      dispatch(actionCreators.setPedidoSelected(pedido))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectorPedidosSinCliente);
