import { toast } from "react-toastify";
import Database from "variables/Database.js";

const assert = (condition, message) => {
  if (!condition) {
    toast.error(message);
  }
};

const SubmitDataEspacial = data => {
  assert(
    ["calle", "muelle", "calle_de_salida"].includes(data.tipo),
    "Error: El tipo de elemento es invalido"
  );
  assert(
    typeof parseInt(data.id) === "number",
    "Error: El id debe ser numerico"
  );
  let elem_id = data.tipo + "_" + data.id;
  data.id = elem_id;
  Database.post(`/insert-data-espacial`, {
    id: process.env.REACT_APP_ID_ALMACEN,
    ...data
  }).then(
    res => {
      toast.success("Ubicacion guardada!");
    },
    err => {
      toast.error(err.message);
    }
  );
};

export { SubmitDataEspacial };
