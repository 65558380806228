import React from "react";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
// Card
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import EncabezadoCard from "components/EncabezadoCard/EncabezadoCard";
// Componentes
import TablaDetalleMovimientos from "../ModalTablaMovimientos/ModalTablaMovimientos.js";
import DetalleEntrada from "../DetalleEntrada/DetalleEntrada.js";
import DetalleSalida from "../DetalleSalida/DetalleSalida.js";

const ModalDetalleAuditoria = props => {
  const [open, setOpen] = React.useState(props.openModalTabla);

  // console.log('entra');
  // console.log(props.openModalTabla);

  const handleClose = () => {
    setOpen(props.openModalTabla);
  };

  return (
    <div>
      <Dialog
        open={props.openModalTabla}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <Card>
            <EncabezadoCard
              titulo_card="Detalle Auditoría"
              subtitulo_card=""
            ></EncabezadoCard>
            <CardBody>
              {props.infoContenedor && props.infoContenedor.result.entrada ? (
                <DetalleEntrada
                  data={props.infoContenedor.result}
                ></DetalleEntrada>
              ) : null}
              {props.infoContenedor &&
              props.infoContenedor.result.movimiento_contenedor.length > 0 ? (
                <TablaDetalleMovimientos
                  data={props.infoContenedor.result.movimiento_contenedor}
                ></TablaDetalleMovimientos>
              ) : null}
              {props.infoContenedor && props.infoContenedor.result.salida ? (
                <DetalleSalida
                  data={props.infoContenedor.result}
                ></DetalleSalida>
              ) : null}
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setClose()} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    infoContenedor: state.auditoria.infoContenedor,
    openModalTabla: state.auditoria.openModalTabla
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setClose: () => dispatch(actionCreators.setClose())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDetalleAuditoria);
