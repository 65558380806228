import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 1000,
    margin: 20
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 24
  },
  pos: {
    marginBottom: 12
  }
});

export default function SimpleCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          variant="h4"
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.titulo}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.click_remover}
        >
          <DeleteIcon />
          Remover
        </Button>
        <Button variant="contained" color="primary" onClick={props.click_rotar}>
          <RotateRightIcon />
          Rotar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={props.click_guardar}
        >
          <SaveIcon />
          Guardar
        </Button>
      </CardActions>
    </Card>
  );
}
