import React, { Component } from "react";
import "./Calle.css";

import interact from "interactjs";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { diccionario1000Col } from "../CalculosAccesorios/CalculosAccesoriosSectores";

// var _ = require('lodash/core');

// let calle_nombre = null;

class Calle extends Component {
  render() {
    let sector_id = this.props.sector;
    let sector_id_str = String(sector_id);
    // console.log(sector_id_str);
    let color = diccionario1000Col[sector_id_str];

    let style = {
      transform:
        "translate(" + this.props.pos_x + "px, " + this.props.pos_y + "px)",
      position: "absolute",
      width: this.props.width,
      height: this.props.height,
      backgroundColor: color
    };

    // calle_nombre = this.props.id;

    let esMovible = null;
    if (this.props.es_movible === true) {
      esMovible = "Calle";
    } else {
      esMovible = "CalleFixed";
    }

    let arrowStyle = {
      position: "absolute",
      top: "45%",
      left: "30%",
      width: "24px",
      color: "black"
    };

    let arrow = null;
    if (this.props.sentido === "A") {
      arrow = (
        <div className="flecha" style={arrowStyle}>
          <ArrowDownwardIcon />
          <ArrowDownwardIcon />
          <ArrowDownwardIcon />
          <ArrowDownwardIcon />
          <ArrowDownwardIcon />
        </div>
      );
    }
    if (this.props.sentido === "D") {
      arrow = (
        <div className="flecha" style={arrowStyle}>
          <ArrowUpwardIcon />
          <ArrowUpwardIcon />
          <ArrowUpwardIcon />
          <ArrowUpwardIcon />
          <ArrowUpwardIcon />
        </div>
      );
    }

    return (
      <div>
        {arrow}
        <div
          className={esMovible}
          pos_x={this.props.pos_x}
          data-x={this.props.pos_x}
          pos_y={this.props.pos_y}
          data-y={this.props.pos_y}
          data-name={this.props.id}
          style={style}
        >
          {this.props.id}
        </div>
      </div>
    );
  }
}

interact(".Calle")
  .resizable({
    // resize from all edges and corners
    edges: { left: true, right: true, bottom: true, top: true },

    listeners: {
      move(event) {
        var target = event.target;
        var x = parseFloat(target.getAttribute("data-x")) || 0;
        var y = parseFloat(target.getAttribute("data-y")) || 0;

        // update the element's style
        target.style.width = event.rect.width + "px";
        target.style.height = event.rect.height + "px";

        // translate when resizing from top or left edges
        x += event.deltaRect.left;
        y += event.deltaRect.top;

        target.style.webkitTransform = target.style.transform =
          "translate(" + x + "px," + y + "px)";

        target.setAttribute("data-x", x);
        target.setAttribute("data-y", y);
        target.textContent =
          target.getAttribute("data-name") +
          " " +
          Math.round(event.rect.width) +
          "\u00D7" +
          Math.round(event.rect.height);
      }
    },
    modifiers: [
      // keep the edges inside the parent
      interact.modifiers.restrictEdges({
        outer: ".canvas"
      }),

      // minimum size
      interact.modifiers.restrictSize({
        min: { width: 10, height: 10 }
      })
    ],

    inertia: true
  })
  .draggable({
    listeners: { move: window.dragMoveListenerCalleNew },
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: ".canvas",
        endOnly: true
      })
    ]
  });

export default Calle;
