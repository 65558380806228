import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

const _getSelectedOptionIndex = el => {
  let attribute = null;
  if (el.tagName === "LI" && el.hasAttribute("data-option-index")) {
    attribute = el.getAttribute("data-option-index");
  }
  return attribute;
};

class SelectorUbicacion extends Component {
  state = {
    data: [],
    selected_data: null,
    inputValue: ""
  };

  getData = el => {
    let state_index = null;
    let data = null;
    state_index = _getSelectedOptionIndex(el);
    if (state_index != null) {
      data = this.state.data[state_index];
    }
    this.setState({ selected_data: data });
    return data;
  };

  componentDidUpdate(prevProps) {
    if (this.props.columna !== prevProps.columna && this.props.columna) {
      this.props.resetvalue();
      Database.get(
        "/list-ubicaciones_por_calle_columna/" +
          this.props.calle.id +
          "/" +
          this.props.columna.id,
        this
      ).then(
        res => {
          let resultado = [...res.result];
          let data = resultado;
          this.setState({ data: data });
        },
        err => {
          toast.error(err.message);
        }
      );
    }
  }

  render() {
    return (
      <Autocomplete
        id="combo-box-ubicacion"
        disabled={this.props.disabled}
        options={this.state.data}
        getOptionLabel={data => {
          /*     if (data.es_picking == 1) {
                     //   console.log(data);
                        return (`${data.nivel} (a: ${data.altura}m - p: ${data.peso_maximo}kg) - PK`);
                   }
                    else*/
          // console.log("NO PK");
          return `${data.nivel} (a: ${data.altura}m - p: ${data.peso_maximo}kg)`;
        }}
        // calle={this.props.calle}
        // columna={this.props.columna}

        value={this.props.ubicacion}
        inputValue={this.state.inputValue}
        onInputChange={(event, newInputValue) => {
          this.setState({
            inputValue: newInputValue
          });
        }}
        onChange={(event, newInputValue) => {
          this.props.getDataSelectedData(newInputValue);
        }}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Seleccionar una ubicación"
            variant="outlined"
          />
        )}
      ></Autocomplete>
    );
  }
}

export default SelectorUbicacion;
