import React from 'react'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import blueGrey from '@material-ui/core/colors/blueGrey'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'

const DetalleItems = ({ rowData, onDeleteRow, showDelete }) => {
  return (
    <Box
      sx={{ margin: 2 }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
      }}
    >
      <div style={{ maxWidth: '100%' }}>
        <Table>
          <TableHead
            style={{
              backgroundColor: blueGrey[50],
            }}
            size='small'
          >
            <TableRow>
              <TableCell align='left' style={{ minWidth: '200px' }}>
                Tipo
              </TableCell>
              <TableCell align='left' style={{ minWidth: '250px' }}>
                Valor
              </TableCell>
              {showDelete && (
                <TableCell align='center' style={{ minWidth: '20px' }}>
                  Acción
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((item, index) =>
              item.items.map((detailItem) => (
                <TableRow key={detailItem.id}>
                  <TableCell>{detailItem.type}</TableCell>
                  <TableCell>{detailItem.value}</TableCell>
                  {showDelete && (
                    <TableCell>
                      <Tooltip title='Borrar item' placement='right'>
                        <DeleteIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => onDeleteRow(item, detailItem.id)}
                        />
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </Box>
  )
}

export default DetalleItems
