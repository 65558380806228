/* eslint-disable */
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SelectorCalles from "./SelectorCalles";
import SelectorMuelles from "./SelectorMuelles";
import SelectorCallesSalida from "./SelectorCallesSalida";
import SelectorColumnas from "./SelectorColumnas";
import SelectorUbicacion from "./SelectorUbicacion";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "35%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

export default function ModalNuevaUbicacion(props) {
  const [selectedDataMuelle, setSelectedDataMuelle] = React.useState([null]);
  const [selectedDataCalleSalida, setSelectedDataCalleSalida] = React.useState([
    null
  ]);
  const [selectedDataColumna, setSelectedDataColumna] = React.useState([null]);
  const [selectedDataCalle, setSelectedDataCalle] = React.useState([null]);
  const [enabledButton, setButtonStatus] = React.useState(false);

  const [enabledUbicacion, setUbicacionStatus] = React.useState(false);

  const [enabledColumna, setColumnaStatus] = React.useState(false);
  const [calle, setCalle] = React.useState(null);
  const [columna, setColumna] = React.useState(null);
  const [ubicacion, setUbicacion] = React.useState(null);

  const [muelle, setMuelle] = React.useState(null);
  const [calleSalida, setCalleSalida] = React.useState(null);

  const getSelectedDataMuelle = data => {
    setMuelle(data);
    if (data != null) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  const getSelectedDataCalleSalida = data => {
    setCalleSalida(data);
    if (data !== null) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  const getSelectedDataCalle = data => {
    //setSelectedDataCalle(data)
    setCalle(data);
    setColumnaStatus(true);
    if (data !== null) {
      //setButtonStatus(true);
    } else {
      resetubicacion();
      setUbicacionStatus(false);
      resetcolumna();
      setColumnaStatus(false);
      setButtonStatus(false);
    }
  };

  const getSelectedDataColumna = data => {
    setColumna(data);
    if (data !== null) {
      setUbicacionStatus(true);
    } else {
      resetubicacion();
      setUbicacionStatus(false);
      setButtonStatus(false);
    }
  };

  const getSelectedDataUbicacion = data => {
    setUbicacion(data);
    if (data !== null) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  const resetcolumna = () => {
    setColumna(null);
  };

  const resetubicacion = () => {
    setUbicacion(null);
  };

  const clickAceptar = () => {
    props.handleAccept(muelle, calleSalida, calle, columna, ubicacion);

    setCalle(null);
    setColumna(null);
    setUbicacion(null);
    setMuelle(null);
    setCalleSalida(null);
  };

  return (
    <Dialog
      open={props.openNuevaUbicacionDialog}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Seleccionar la nueva ubicación {enabledButton}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardHeader color="primary">
            <h4>Ubicacion</h4>
          </CardHeader>
          <CardBody>
            <SelectorCalles getDataSelectedData={getSelectedDataCalle} />
            <SelectorColumnas
              columna={columna}
              calle={calle}
              disabled={!enabledColumna}
              getDataSelectedData={getSelectedDataColumna}
              resetvalue={resetcolumna}
            />
            <SelectorUbicacion
              ubicacion={ubicacion}
              columna={columna}
              calle={calle}
              disabled={!enabledUbicacion}
              getDataSelectedData={getSelectedDataUbicacion}
              resetvalue={resetubicacion}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="primary">
            <h4>Muelle Entrada</h4>
          </CardHeader>
          <CardBody>
            <SelectorMuelles getDataSelectedData={getSelectedDataMuelle} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="primary">
            <h4>Calle de Salida</h4>
          </CardHeader>
          <CardBody>
            <SelectorCallesSalida
              getDataSelectedData={getSelectedDataCalleSalida}
            />
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={clickAceptar}
          color="primary"
          autoFocus
          disabled={!enabledButton}
        >
          Seleccionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
