import React from "react";
import Grid from "@material-ui/core/Grid";
import CSVReader from "react-csv-reader";

// Redux
import { connect } from "react-redux";
import * as actionCreators from "store/actions/index";

const CargarCsv = function(props) {
  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        style={{ marginTop: "10px" }}
      >
        <CSVReader
          cssClass="csv-reader-input"
          label="Buscar CSV "
          onFileLoaded={(data, fileInfo) => {
            window.lat = data;
            props.cargaCsv(data);
          }}
          onError={() => console.log("las")}
          parserOptions={parseOptions}
          inputId="cargar-csv"
          inputStyle={{ color: "red" }}
        />
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    campos_habilitados: state.importacionmasiva.campos_habilitados,
    csv: state.importacionmasiva.csv
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cargaCsv: csv => dispatch(actionCreators.cargaCsv(csv))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CargarCsv);
