import React, { Component } from "react";
import "./SelectorTablas.css";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

const bloques = [];
const muelles = [];
const callesDeSalida = [];

const deselectRows = () => {
  let filas = document.getElementsByClassName("fila-tabla");
  for (let i = 0; i < filas.length; i++) {
    if (filas[i].getAttribute("aria-checked") == "true") {
      filas[i].click();
    }
  }
};

class SelectorTablas extends Component {
  state = {
    bloques: [...bloques],
    muelles: [...muelles],
    callesDeSalida: [...callesDeSalida]
  };

  handleCalles = () => {
    deselectRows();
    this.props.fillCalles(this.state.bloques);
  };

  handleMuelles = () => {
    deselectRows();
    this.props.fillMuelles(this.state.muelles);
    // this.props.handleClick();
  };

  handleCallesDeSalida = () => {
    deselectRows();
    this.props.fillCallesDeSalida(this.state.callesDeSalida);
  };

  moverCalleAMapa = (calle_id, data_pos) => {
    let copia_bloques = [...this.state.bloques];
    let bloque = copia_bloques.filter(elem => {
      return elem.calle.id === Number(calle_id);
    });
    bloque = bloque[0];
    bloque.is_selected = false;
    bloque["data_pos"] = data_pos;
    this.props.addBloquesEnMapa(bloque, data_pos);
  };

  moverMuelleAMapa = muelle_id => {
    console.log("movermuelleamapa");
    let copia = [...this.state.muelles];
    console.log(copia);
    let muelle_index = copia.map((val, index) => {
      if (val.muelle.id === parseInt(muelle_id)) {
        return index;
      }
    });
    let muelleAMapa = copia.splice(muelle_index, 1);
    let muellesEnMapa = this.state.muellesEnMapa || [];
    let data_pos = { pos_x: 100, pos_y: 200, rotacion: 65 };
    muelleAMapa[0]["data_pos"] = data_pos;
    muellesEnMapa.push(muelleAMapa);
    // console.log('bloque a mapa es a');
    // console.log(bloqueAMapa);

    // console.log(data_pos)
    this.props.addMuellesEnMapa(muelleAMapa[0], data_pos);
    // console.log('bloque a mapa es b');
    // this.setState({bloques: copia_bloques});
    // this.setState({bloquesEnMapa: bloqueAMapa[0]});
    // this.props.bloquesEnMapa = bloqueAMapa;
  };

  componentDidMount() {
    Database.get("/list-mapa/" + process.env.REACT_APP_ID_ALMACEN, this).then(
      res => {
        let calles = res.calles;

        // let column = res.columnas;

        calles = calles.map(elem => {
          return {
            calle: {
              id: elem.id,
              nombre_calle: "Calle " + elem.numero + " (" + elem.segmento + ")",
              pos_x: 50,
              pos_y: 0,
              width: 2,
              height: 50,
              cantcol: elem.columnas.length,
              nombres: [...elem.columnas].map(col => col.numero)
            },
            columnas: [
              {
                id: "432432",
                columna_nombre: "columnas1",
                pos_x: 0,
                pos_y: 0,
                width: 1,
                height: 50
              },
              {
                id: "fekm34",
                columna_nombre: "columnas2",
                pos_x: 100,
                pos_y: 0,
                width: 1,
                height: 50
              }
            ],
            is_selected: false,
            rotacion: 0,
            tipo: "PI",
            sentido: elem.sentido,
            sector: elem.id_sector,
            spatialObjectType: "bloque"
          };
        });
        this.setState({
          bloques: calles
        });
      },
      err => {
        toast.error(err.message);
      }
    );

    Database.get(
      "/list-muelles/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let muelles = [...res.result];

        muelles = muelles.map(elem => {
          return {
            id: elem.id,
            nombre_muelle: "muelle " + elem.id,
            ancho: elem.ancho,
            largo: elem.largo,
            capacidad: elem.capacidad,
            descripcion: elem.descripcion,
            spatialObjectType: "muelle",
            is_selected: false,
            rotacion: 0
          };
        });
        this.setState({
          muelles: muelles
        });
        // console.log('********')
        // console.log('muelles')
        // console.log(this.state.muelles);
        // console.log('********')
      },
      err => {
        toast.error(err.message);
      }
    );

    Database.get(
      "/list-callessalida/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let callesDeSalida = [...res.result];
        this.setState({
          callesDeSalida: callesDeSalida
        });
      },
      err => {
        toast.error(err.message);
      }
    );

    Database.get("/list-mapa-ubicaciones/", this).then(
      res => {
        // let callesDeSalida = [...res.calles_de_salida];
        let calles = [...res.calles];
        // let muelles = [...res.muelles];
        for (let c of calles) {
          let calle_id = c.id.split("_")[1];
          let data_pos = { pos_x: c.x, pos_y: c.y, rotacion: c.rotacion };
          this.moverCalleAMapa(calle_id, data_pos);
        }
        this.props.setLoadedStatus(true);
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  render() {
    // console.log(muelles);
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Button onClick={this.handleCalles}>Calles</Button>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={this.handleMuelles}>Muelles</Button>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={this.handleCallesDeSalida}>Calles de Salida</Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    bloquesEnMapa: state.mapa.bloquesEnMapa,
    isLoaded: state.mapa.isLoaded,
    muellesEnMapa: state.mapa.muellesEnMapa
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBloquesEnMapa: (b, data_pos) =>
      dispatch(actionCreators.addBloquesEnMapa(b, data_pos)),
    setLoadedStatus: status => dispatch(actionCreators.setLoadedStatus(status)),
    setPositionBloque: (pos_x, pos_y, rotacion) =>
      dispatch(actionCreators.setPositionBloque(pos_x, pos_y, rotacion)),
    addMuellesEnMapa: (b, data_pos) =>
      dispatch(actionCreators.addMuellesEnMapa(b, data_pos))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectorTablas);
// export default SelectorTablas;

// setPositionMuelle: (pos_x, pos_y, rotacion) => dispatch(actionCreators.setPositionMuelle(pos_x, pos_y, rotacion)),
