import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Switch } from "react-router-dom";
// core components
import MaterialTable from "material-table";
// import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import ButtonSmall from "@material-ui/core/Button";

//import NewOrdenTrabajo from "./components/NewOrdenTrabajo";
import ModalDelete from "./ModalDelete";

import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import {
  StateListOrdenesTrabajo,
  ColumnsListadoPedidos
} from "../VariablesState.js";

import lightGreen from "@material-ui/core/colors/lightGreen";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewOrdenTrabajo extends Component {
  state = { ...StateListOrdenesTrabajo };

  componentDidMount() {
    this.getPedidosAdmin();
  }

  //enviar a la api el trabajador, y los pedidos seleccionados
  handleSubmitForm = event => {
    event.preventDefault();

    let objeto = {};
    //la propiedad checked va dentro de cada pedido
    objeto["pedidos"] = this.state.pedidos;

    objeto["id_almacen"] = process.env.REACT_APP_ID_ALMACEN;

    console.log(objeto);

    Database.post("/crear-ordentrabajo", { ...objeto }, this).then(
      res => {
        this.resetForm();
        //actualiza las infos
        this.props.getOrdenesTrabajoAdmin();
        this.getPedidosAdmin();

        toast.success("Orden procesada correctamente");
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  resetForm = () => {
    // let state = { ...StateListOrdenesTrabajo };

    let pedidos = [...this.state.pedidos];
    pedidos = pedidos.map(elem => {
      return {
        ...elem,
        checked: false
      };
    });
    this.setState({
      pedidos: pedidos
    });
  };

  handleToggle = value => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    let deleteEnabled = false;
    let editEnabled = false;
    const botonesAcc = { ...this.state.botonesAcciones };
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) {
      deleteEnabled = true;
      if (newChecked.length === 1) editEnabled = true;
    }
    botonesAcc.editar.enabled = editEnabled;
    botonesAcc.delete.enabled = deleteEnabled;
    this.setState({
      botonesAcciones: botonesAcc,
      checked: newChecked
    });
  };

  menuHandleClose = value => {
    this.setState({
      menuContext: null
    });
  };

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    });
  };
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE NUEVOS TRABAJOS
  ////////////////////////
  ////////////////////////

  getPedidosAdmin = () => {
    this.setState({
      isLoading: true
    });

    Database.get("/list-pedidos-notrabajados", this, null, true).then(
      res => {
        let resultado = [...res.result[0]];
        console.log(resultado);

        resultado = resultado.map(elem => {
          return {
            ...elem,
            // vencimiento_mostrar: moment(elem.vencimiento).isValid() ? moment(elem.vencimiento).format("DD/MM/YYYY hh:mm:ss") : "",
            fecha_mostrar: moment(elem.fecha).isValid()
              ? moment(elem.fecha).format("DD/MM/YYYY hh:mm:ss")
              : "",
            checked: false
          };
        });

        this.setState({
          isLoading: false,
          pedidos: resultado,
          // checked: [],
          menuContext: null,
          botonesAcciones: {
            nuevo: {
              enabled: true,
              texto: "Nuevo"
            },
            editar: {
              enabled: false,
              texto: "Editar"
            },
            delete: {
              enabled: false,
              texto: "Eliminar"
            }
          }
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  // getNewOrdenTrabajoAdmin = () => {
  //   this.setState({
  //     isLoading: true
  //   })

  //   Database.get('/list-newordentrabajo',this,null,true)
  //     .then(res => {
  //       let resultado = [...res.result];
  //       console.log(resultado);

  //       resultado = resultado.map(elem => {
  //         return {
  //           ...elem,
  //          // vencimiento_mostrar: moment(elem.vencimiento).isValid() ? moment(elem.vencimiento).format("DD/MM/YYYY hh:mm:ss") : "",
  //           fecha_mostrar: moment(elem.fecha).isValid() ? moment(elem.fecha).format("DD/MM/YYYY hh:mm:ss") : ""
  //         };
  //       });

  //       this.setState({
  //         isLoading:false,
  //         newordentrabajo: resultado,
  //         checked: [],
  //         menuContext: null,
  //         botonesAcciones: {
  //           nuevo: {
  //             enabled: true,
  //             texto: 'Nuevo'
  //           },
  //           editar: {
  //             enabled: false,
  //             texto: 'Editar'
  //           },
  //           delete: {
  //             enabled: false,
  //             texto: 'Eliminar'
  //           }
  //         }
  //       })

  //     },err =>{
  //       toast.error(err.message);
  //     })
  // }

  editSingleUser = value => {
    this.props.history.push(
      this.props.match.url + "/editarordentrabajo/" + value
    );
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  handleDeleteUser = rowData => {
    Database.post("/delete-ordentrabajo", { id: rowData.id }, this).then(
      res => {
        let newordentrabajo = [...this.state.newordentrabajo];
        newordentrabajo = newordentrabajo.filter(elem => {
          if (elem.id === rowData.id) return false;

          return true;
        });

        this.setState(
          {
            newordentrabajo: newordentrabajo,
            openDeleteDialog: false
          },
          () => {
            toast.success(
              "La orden de procesamiento se ha eliminada con exito!"
            );
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    });
  }

  onClickItemTrabajador = rowData => {
    this.closeDialog();
    //   if (this.state.infoTrabajador && this.state.infoTrabajador.id == rowData.id) { }
    //   else {
    this.setState({
      infoTrabajador: rowData
      //     detallepedidos: []
    });
    //  }
  };

  closeDialog() {
    this.setState({ openDialogTrabajador: false });
  }

  handleSelectionChange = arrayRow => {
    let resultado = [...this.state.pedidos];

    //desactivo todo
    resultado.map(elem => {
      elem.checked = false;
    });

    //Paso tableData {checked = true} a checked=true x comodidad
    resultado.map(elem => {
      if (elem.tableData.checked === true) {
        elem.checked = true;
      }
    });

    // for (let i = 0; i <= arrayRow.length - 1; i++) {
    //   resultado[i].checked = true
    // }

    this.setState({
      pedidos: resultado
    });

    console.log(...this.state.pedidos);
  };

  render() {
    let style = {};
    console.log(this.props);
    // if (this.props.match.url !== this.props.location.pathname) {
    //   style = { display: 'none' }
    // }
    return (
      <form
        onSubmit={event => {
          this.handleSubmitForm(event);
        }}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={style}>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Nuevo Procesamiento de Pedido
                </h4>
                <p className={this.props.classes.cardCategoryWhite}>
                  Listado de Pedidos
                </p>
              </CardHeader>
              <CardBody>
                {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevoordentrabajo')} color="primary"><AddIcon /> Nuevo OrdenPicking</Button> */}

                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListadoPedidos}
                  data={this.state.pedidos}
                  title=""
                  localization={localization}
                  // actions={[
                  //   {
                  //   icon: 'check',
                  //    tooltip: 'Crear Orden de Picking',
                  // onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/ordentrabajo_productos/' + rowData.id)
                  //   onClick: (event, rowData) => this.handleCrearOdenPicking(rowData.id)
                  //  },
                  // {
                  //   icon: 'delete',
                  //   tooltip: 'Borrar OrdenTrabajo',
                  //   onClick: (event, rowData) => this.handleDeleteButton(rowData)

                  // }
                  // ]}

                  components={{
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  options={{
                    selection: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName:
                      "NewOrdenTrabajo " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: lightGreen[700],
                      color: "#FFF"
                    }
                  }}
                  onSelectionChange={rows => this.handleSelectionChange(rows)}
                />

                <ButtonSmall
                  style={{ marginTop: "25px" }}
                  color="info"
                  onClick={() =>
                    this.props.history.push("/admin/ordenestrabajo")
                  }
                >
                  <ArrowBack />
                  Volver
                </ButtonSmall>
                <ButtonSmall
                  style={{ marginTop: "25px" }}
                  color="primary"
                  variant="contained"
                  disabled={this.state.disableAllButtons}
                  type="submit"
                >
                  <Save /> Procesar
                </ButtonSmall>
              </CardBody>
            </Card>

            <Switch>
              {/* 
            
            <Route path={this.props.match.url + "/nuevoordentrabajo"} render={() =>

              <NewOrdenTrabajo
                getNewOrdenTrabajoAdmin={() => this.getNewOrdenTrabajoAdmin()}
                handleListNewUser={(rowData) => this.handleListNewUser(rowData)}
                />}
              />

            <Route path={this.props.match.url + "/ordentrabajo_productos/:id"} render={() =>
            
              <OrdenTrabajoProductos
                orderForm={this.state.EditOrdenTrabajoForm}
                editFormIsValid={this.state.editFormIsValid}
                successSubmitEdit={this.state.successSubmitEdit}           

             //   handleSubmitEditOrdenPicking={(event) => { this.handleSubmitEditOrdenPicking(event) } }
              //  inputEditChangedHandler={(event, inputIdentifier) => this.inputEditChangedHandler(event, inputIdentifier)}
             //   getUserEdit={(id) => { this.getUserEdit(id) } }
             //   resetEditForm={this.resetEditForm}
            //    reloadNewOrdenTrabajo={this.reloadNewOrdenTrabajo}
            //    getNewOrdenTrabajoAdmin={() => this.getNewOrdenTrabajoAdmin()}
                />}
              />
 */}
            </Switch>
          </GridItem>
          <ModalDelete
            openDeleteDialog={this.state.openDeleteDialog}
            deleteRowData={this.state.deleteRowData}
            handleClose={() => this.handleModalClose()}
            handleDelete={rowData => this.handleDeleteUser(rowData)}
          />
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(NewOrdenTrabajo);
