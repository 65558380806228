import React from "react";
import interact from "interactjs";
import * as calc from "../CalculosAccesorios/CalculosAccesorios";
import * as calcBloques from "../CalculosAccesorios/CalculosAccesoriosBloques";
import "./Muelle.css";

class Muelle extends React.Component {
  toggleSelected = () => {
    let clases = ["Muelle"];
    const is_selected = this.props.is_selected;
    if (is_selected) {
      clases.push("muelle-seleccionado");
    }
    return clases.join(" ");
  };

  // reattach = (target) => {
  //   target.on('click', this.props.click)
  // }

  render() {
    // Calculamos el alto y ancho para setear el estilo
    let clases = this.toggleSelected();

    let rotacion = this.props.rotacion;
    let ancho_muelle_metros = this.props.ancho;
    let largo_muelle_metros = this.props.largo;

    // si el largo o ancho de muelle no son ingresados, el default es 10 metros
    if (ancho_muelle_metros == null) {
      ancho_muelle_metros = 10;
    }
    if (largo_muelle_metros == null) {
      largo_muelle_metros = 10;
    }

    let ancho_muelle_px = calc.deMetrosAPixeles(Number(ancho_muelle_metros));
    let largo_muelle_px = calc.deMetrosAPixeles(Number(largo_muelle_metros));

    let muelle_id = this.props.id;
    let dataxMuelle = 0;
    let datayMuelle = 0;

    if (this.props.data_pos != null) {
      dataxMuelle = this.props.data_pos.pos_x;
      datayMuelle = this.props.data_pos.pos_y;
      rotacion = this.props.data_pos.rotacion;
    } else {
      dataxMuelle = calc.getPosicionX(muelle_id);
      datayMuelle = calc.getPosicionY(muelle_id);
    }

    let style = {};
    style = calcBloques.setStyleTransformBloque(
      dataxMuelle,
      datayMuelle,
      rotacion,
      ancho_muelle_px,
      largo_muelle_px
    );

    return (
      <div
        id={this.props.id}
        className={clases}
        degrees={rotacion}
        style={style}
        onClick={this.props.click}
      >
        {this.props.descripcion}
      </div>
    );
  }
}

function dragMoveListenerMuelle(event) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
  var degrees = parseFloat(target.getAttribute("degrees")) || 0;
  // translate the element
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + y + "px) rotate(" + degrees + "deg)";

  // update the posiion attributes
  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);
  target.setAttribute("degrees", degrees);
}

window.dragMoveListenerMuelle = dragMoveListenerMuelle;

interact(".Muelle").draggable({
  listeners: { move: window.dragMoveListenerMuelle }
});

// const mapStateToProps = state => {
//   return {
//     pos_x: state.pos_x,
//     pos_y: state.pos_y,
//     rotacion: state.rotacion
//   }
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     setPositionBloque: (pos_x, pos_y, rotacion) => dispatch(actionCreators.setPositionBloque(pos_x, pos_y, rotacion))
//   }
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Muelle);

export default Muelle;
