import * as actionTypes from "../actions/actionTypes";

const initialState = {
  selectedCliente: null,
  hasSelectedClient: false,
  idSelectedClient: null,
  referenciaPedido: null,
  openBuscarClienteDialog: false,
  selectedRadioButton: "pedido",
  pedido_seleccionado: null,
  pedido_id: 0,
  nuevaReferencia: 0,
  disableInputText: false,
  buscarReferencia: false
};

// modal selecciona cliente

const handleOpenBuscarClienteDialog = (state, action) => {
  let state_copy = { ...state };
  state_copy.openBuscarClienteDialog = true;
  return { ...state_copy };
};

const handleCloseClienteDialog = (state, action) => {
  let state_copy = { ...state };
  state_copy.openBuscarClienteDialog = false;
  return { ...state_copy };
};

const handleAcceptModalCliente = (state, action) => {
  let state_copy = { ...state };
  state_copy.openBuscarClienteDialog = false;
  state_copy.selectedCliente = action.cliente;
  state_copy.hasSelectedClient = true;
  state_copy.idSelectedClient = action.cliente.id;
  return { ...state_copy };
};

// Radio Button Option
const setSelectedRadioButton = (state, action) => {
  let state_copy = { ...state };
  state_copy.selectedRadioButton = action.selected_radio;
  return { ...state_copy };
};

const setSelectedClientToCero = (state, action) => {
  let state_copy = { ...state };
  state_copy.idSelectedClient = 0;
  state_copy.referenciaPedido = null;
  state_copy.nuevaReferencia = 0;
  state_copy.hasSelectedClient = true;
  state_copy.selectedCliente = null;
  state_copy.openBuscarClienteDialog = false;
  state_copy.disableInputText = false;
  return { ...state_copy };
};

const resetFormPedido = (state, action) => {
  let state_copy = { ...initialState };
  return { ...state_copy };
};

// referencia
const onChangeHandlerInputText = (state, action) => {
  let state_copy = { ...state };
  state_copy.referenciaPedido = action.event.target.value;
  return { ...state_copy };
};

// Setea pedido
const setPedidoSelected = (state, action) => {
  let state_copy = { ...state };
  if (action.pedido) {
    state_copy.pedido_seleccionado = action.pedido;
    state_copy.pedido_id = action.pedido.id_pedido;
    state_copy.referenciaPedido = action.pedido.referencia;
  }
  return { ...state_copy };
};

const changeRadioButtonPedido = (state, action) => {
  let state_copy = { ...state };
  if (state_copy.selectedRadioButton === "pedido") {
    state_copy.selectedRadioButton = "nopedido";
  } else {
    state_copy.selectedRadioButton = "pedido";
  }
  return { ...state_copy };
};

const setTrueNuevaReferencia = state => {
  // la referencia nunca fue usada
  let state_copy = { ...state };
  state_copy.nuevaReferencia = 1;
  return { ...state_copy };
};

const setFalseNuevaReferencia = state => {
  // la referencia que se quiere crear ya está usada
  let state_copy = { ...state };
  state_copy.nuevaReferencia = 2;
  return { ...state_copy };
};

const resetNuevaReferencia = state => {
  // resetea el valor de referencia a cero
  let state_copy = { ...state };
  state_copy.nuevaReferencia = 0;
  return { ...state_copy };
};

const setDisableInputText = (state, action) => {
  let state_copy = { ...state };
  state_copy.disableInputText = action.is_disabled;
  return { ...state_copy };
};

const setBuscarReferencia = (state, action) => {
  let state_copy = { ...state };
  state_copy.buscarReferencia = action.bool_buscar;
  return { ...state_copy };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BUSCAR_REFERENCIA:
      return setBuscarReferencia(state, action);

    case actionTypes.SET_DISABLE_INPUT_TEXT:
      return setDisableInputText(state, action);

    case actionTypes.RESET_NUEVA_REFERENCIA:
      return resetNuevaReferencia(state);

    case actionTypes.SET_FALSE_NUEVA_REFERENCIA:
      return setFalseNuevaReferencia(state);

    case actionTypes.SET_TRUE_NUEVA_REFERENCIA:
      return setTrueNuevaReferencia(state);

    case actionTypes.HANDLE_OPEN_CLIENTE_DIALOG:
      return handleOpenBuscarClienteDialog(state, action);

    case actionTypes.HANDLE_CLOSE_CLIENTE_DIALOG:
      return handleCloseClienteDialog(state, action);

    case actionTypes.HANDLE_ACCEPT_MODAL_CLIENTE:
      return handleAcceptModalCliente(state, action);

    case actionTypes.ONCHANGE_HANDLER_INPUT_TEXT:
      return onChangeHandlerInputText(state, action);

    case actionTypes.SET_SELECTED_RADIO_BUTTON:
      return setSelectedRadioButton(state, action);

    case actionTypes.SET_SELECTED_CLIENT_TO_CERO:
      return setSelectedClientToCero(state, action);

    case actionTypes.RESET_FORM_PEDIDO:
      return resetFormPedido(state, action);

    case actionTypes.SET_PEDIDO_SELECTED:
      return setPedidoSelected(state, action);

    case actionTypes.CHANGE_RADIO_BUTTON_PEDIDO:
      return changeRadioButtonPedido(state, action);

    default:
      return state;
  }
};

export default reducer;
