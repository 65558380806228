export const StateListBancos = {
  bancos: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditBanco = {
  editBancoForm: {
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    sucursal: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Sucursal",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    ejecutivo_asignado: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Ejecutivo Asignado",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    ejecutivo_mail: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Ejecutivo Mail",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    ejecutivo_telefono: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Ejecutivo Telefono",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  bancoEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewBanco = {
  newBancoForm: {
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    sucursal: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Sucursal",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    ejecutivo_asignado: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Ejecutivo Asignado",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    ejecutivo_mail: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Ejecutivo Mail",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    ejecutivo_telefono: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Ejecutivo Telefono",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },

  formIsValid: false,
  successSubmit: null,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Nombre", field: "nombre" },
  { title: "Sucursal", field: "sucursal" },
  { title: "Ejecutivo", field: "ejecutivo_asignado" },
  { title: "Telefono", field: "ejecutivo_telefono" },
  { title: "Mail", field: "ejecutivo_mail" }
];
