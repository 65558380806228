import React from "react";
import "./Columna.css";
import interact from "interactjs";

import Ubicacion from "../../Ubicacion/Ubicacion";
import * as calc from "../../CalculosAccesorios/CalculosAccesorios";

const Columna = props => {
  // window.rotacion_columna = props.rotacion_col;
  // console.log(props.rotacion_col);

  let alturaColPx = calc.deMetrosAPixeles(Number(props.height));
  let anchoColPx = calc.deMetrosAPixeles(Number(props.width));

  let style = {
    transform: "translate(" + props.pos_x + "px, " + props.pos_y + "px)",
    position: "absolute",
    width: String(anchoColPx) + "px",
    height: String(alturaColPx) + "px"
  };

  let esMovible = null;
  if (props.es_movible) {
    esMovible = "Columna";
  } else {
    esMovible = "ColumnaFixed";
  }

  // let cantCol = 0;

  // col

  // cantCol = props.cantCol;

  // // nombres de las columnas en los bloques de columnas
  let nombresUbicaciones = [];
  nombresUbicaciones = props.nombrescol;

  return (
    <div
      className={esMovible}
      pos_x={props.pos_x}
      data-x={props.pos_x}
      pos_y={props.pos_y}
      data-y={props.pos_y}
      data-name={props.id}
      style={style}
      degrees={props.rotacion_col}
    >
      {nombresUbicaciones.map((x, indexX) => {
        return <Ubicacion key={x} name={x} id={x}></Ubicacion>;
      })}
    </div>
  );
};

function dragMoveListener(event) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
  var degrees = parseFloat(target.getAttribute("degrees")) || 0;
  // translate the element
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + y + "px) rotate(" + degrees + "deg)";

  // update the posiion attributes
  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);
  target.setAttribute("degrees", degrees);
}

// this function is used later in the resizing and gesture demos
window.dragMoveListener = dragMoveListener;

interact(".Columna").draggable({
  listeners: { move: window.dragMoveListener },
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: ".canvas",
      endOnly: true
    })
  ]
});

export default Columna;
