export const StateListOperaciones = {
  operaciones: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditOperacion = {
  idEmpleado: null,
  nombreEmpleado: null,
  editOperacionForm: {
    id_cliente: {
      elementType: "select",
      elementConfig: {
        label: "Cliente",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    id_suboperacion: {
      elementType: "select",
      elementConfig: {
        label: "Sub Operación",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    id_centro_distribuidor: {
      elementType: "select",
      elementConfig: {
        label: "Centro Distribuidor",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    }
  },
  fechaDesde: null,
  fechaHasta: null,
  operacionEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewOperacion = {
  idEmpleado: null,
  nombreEmpleado: null,
  newOperacionForm: {
    id_cliente: {
      elementType: "select",
      elementConfig: {
        label: "Cliente",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    id_suboperacion: {
      elementType: "select",
      elementConfig: {
        label: "Sub Operación",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    id_centro_distribuidor: {
      elementType: "select",
      elementConfig: {
        label: "Centro Distribuidor",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    }
  },
  fechaDesde: null,
  fechaHasta: null,
  formIsValid: false,
  successSubmit: null,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Apellido", field: "apellido" },
  { title: "Nombre", field: "nombre" },
  { title: "Cliente", field: "cliente" },
  { title: "Sub Operación", field: "suboperacion" },
  { title: "Centro Distribuidor", field: "centro_distribuidor" },
  { title: "Fecha desde", field: "fecha_desde" },
  { title: "Fecha hasta", field: "fecha_hasta" }
];
