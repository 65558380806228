import React from "react";
// Material
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// DB
import Database from "variables/Database.js";
import { toast } from "react-toastify";
// UUID
var UUID = require("uuid");

const ordenarContenedores = async data_contenedores_picking => {
  let data = Database.post(
    `/ordenar-contenedores`,
    { data: data_contenedores_picking },
    this
  ).then(
    res => {
      return res;
    },
    err => {
      toast.error(err.message);
    }
  );
  return data;
};

const restarBultos = async (props, data_contenedores_picking) => {
  Database.post(
    `/picking-restar-bultos`,
    { data_contenedores_picking },
    this
  ).then(
    res => {
      toast.success("Se ha movido el lote a calle de salida!");
      props.reloadTablePicking();
      return res;
    },
    err => {
      toast.error(err.message);
    }
  );
};

const restarDisplays = async (props, data_contenedores_picking) => {
  Database.post(
    `/picking-restar-displays`,
    { data_contenedores_picking },
    this
  ).then(
    res => {
      toast.success("Se ha movido el lote a calle de salida!");
      props.reloadTablePicking();
      return res;
    },
    err => {
      toast.error(err.message);
    }
  );
};

const restarUnidades = async (props, data_contenedores_picking) => {
  Database.post(
    `/picking-restar-unidades`,
    { data_contenedores_picking },
    this
  ).then(
    res => {
      toast.success("Se ha movido el lote a calle de salida!");
      props.reloadTablePicking();
      return res;
    },
    err => {
      toast.error(err.message);
    }
  );
};

const handleAccept = async props => {
  props.setModalVisibility(false, "confirm_picking");
  var id_pedido = UUID.v4();
  for (let el of props.lote_salida_picking) {
    if (el.referencia_seleccionada === "bulto") {
      let contenedores_ordenados = await ordenarContenedores(el);
      el.contenedores = contenedores_ordenados.result;
      let data = {
        data_contenedores_picking: el,
        calle_de_salida: props.calle_de_salida,
        referencia: props.referenciaPedido,
        id_pedido: id_pedido
      };
      restarBultos(props, data);
    } else if (el.referencia_seleccionada === "display") {
      let contenedores_ordenados = await ordenarContenedores(el);
      el.contenedores = contenedores_ordenados.result;
      let data = {
        data_contenedores_picking: el,
        calle_de_salida: props.calle_de_salida,
        referencia: props.referenciaPedido,
        id_pedido: id_pedido
      };
      restarDisplays(props, data);
    } else if (el.referencia_seleccionada === "unidad") {
      let contenedores_ordenados = await ordenarContenedores(el);
      el.contenedores = contenedores_ordenados.result;
      let data = {
        data_contenedores_picking: el,
        calle_de_salida: props.calle_de_salida,
        referencia: props.referenciaPedido,
        id_pedido: id_pedido
      };
      restarUnidades(props, data);
    }
  }
  //   moverLoteACalleDeSalida(props);
  //   descartarContenedoresSeleccionados(props)
};

// const getDatosPedido = (props) => {
//     if (props.selectedRadioButton === 'pedido') {
//       let pedido = {};
//       pedido['referencia'] = props.referenciaPedido;
//       pedido['id_cliente'] = props.idSelectedClient;
//       return pedido
//     } else {
//       return null
//     }
//   }

function ConfirmPickingDialog(props) {
  return (
    <div>
      <Dialog
        open={props.confirmPicking}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Desea enviar el lote creado a la calle de salida?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={event =>
              props.setModalVisibility(false, "confirm_picking")
            }
            color="primary"
          >
            Cancelar
          </Button>
          <Button onClick={() => handleAccept(props)} color="primary" autoFocus>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    calle_de_salida: state.salidas.calle_de_salida,
    confirmPicking: state.salidas.confirmPicking,
    lote_salida_picking: state.salidas.lote_salida_picking,
    referenciaPedido: state.pedidossalidas.referenciaPedido
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal)),
    reloadTablePicking: () => dispatch(actionCreators.reloadTablePicking())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPickingDialog);
