import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import EncabezadoCard from "components/EncabezadoCard/EncabezadoCard";
import moment from "moment";

const detallesSalida = props => {
  let styleDisplay = { display: "block" };
  let styleTitleDetail = {
    fontSize: "115%",
    color: "green"
  };

  return (
    <Card>
      <EncabezadoCard
        titulo_card=""
        subtitulo_card="Detalle Salida"
      ></EncabezadoCard>
      <CardBody>
        <div style={{ marginLeft: "4em" }}>
          <div>
            <p style={styleTitleDetail}>Cantidad de Unidades</p>
            <p style={styleDisplay}>
              {props.data.salida["AudSalidaDetalles.cantidad_unidades"] ||
                "No posee"}{" "}
              <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Fecha</p>
            <p style={styleDisplay}>
              {moment(props.data.salida.createdTimestamp).format(
                "DD/MMM/YYYY LT"
              ) || "No posee"}{" "}
              <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Usuario</p>
            <p style={styleDisplay}>
              {props.data.usuario_salida.nombre || "No posee"} <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Pedido</p>
            <p style={styleDisplay}>
              {props.data.salida["AudSalidaDetalles.referenciaPedido"] ||
                "No posee"}{" "}
              <br />
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default detallesSalida;
