import * as actionTypes from "../actions/actionTypes";

const initialState = {
  datosPedido: null,
  openModalPedido: false,
  eliminarPallet: false
};

const setDatosPedido = (state, action) => {
  let state_copy = { ...state };
  state_copy.datosPedido = action.datos_pedido;
  return { ...state_copy };
};

const setModalPedidoVisibility = (state, action) => {
  let state_copy = { ...state };
  state_copy.openModalPedido = action.status;
  return { ...state_copy };
};

const setConfirmEliminarPalletPedido = (state, action) => {
  let state_copy = { ...state };
  state_copy.eliminarPallet = action.status;
  return { ...state_copy };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DATOS_PEDIDO:
      return setDatosPedido(state, action);

    case actionTypes.SET_MODAL_PEDIDO_VISIBILITY:
      return setModalPedidoVisibility(state, action);

    case actionTypes.SET_CONFIRM_ELIMINAR_PALLET_PEDIDO:
      return setConfirmEliminarPalletPedido(state, action);

    default:
      return state;
  }
};

export default reducer;
