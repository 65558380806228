export const StateListUbicaciones = {
  ubicaciones: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false,
  tabSelect: 0
};

export const StateUbicaciones = {
  orderForm: {
    id: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo Total (mts)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    sentido: {
      elementType: "select",
      elementConfig: {
        label: "Sentido",
        options: [
          { value: "A", displayValue: "Ascendente" },
          { value: "D", displayValue: "Descendente" }
        ],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    }
    // orden: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Orden',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
  },

  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListado = [
  //{ title: "Numero", field: "id" },
  { title: "Pasillo", field: "calle" },
  { title: "Columna", field: "columna" },
  { title: "Tipo", field: "tipocolumna" },
  { title: "Nivel", field: "nivel" },
  { title: "Altura (mts)", field: "altura" },
  { title: "Peso Máximo (kg)", field: "peso_maximo" },
  { title: "Ocupacion", field: "descripcion" }
];
