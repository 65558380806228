import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
  StateEntradas,
  ColumnsListado,
  ColumnsListadoProductos
} from "../VariablesState";

import moment from "moment";

import MaterialTable from "material-table";
import { localization } from "variables/general";
import Paper from "@material-ui/core/Paper";

import Database from "variables/Database.js";
import { inputChangedHandler } from "variables/input.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import ModalNuevaUnidad from "./ModalNuevaUnidad.js";
import ModalNuevaPresentacion from "./ModalNuevaPresentacion.js";
import ModalSeleccionarCliente from "./ModalSeleccionarCliente.js";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import StepAgregarProducto from "./StepAgregarProducto";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import AddIcon from "@material-ui/icons/Add";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewEntrada extends Component {
  state = JSON.parse(JSON.stringify(StateEntradas));
  muelles = [];

  handleSubmitNewEntrada(event) {
    event.preventDefault();
    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }

    objeto.cliente = this.state.infoCliente;
    objeto.detalleentradas = this.state.detalleentradas;

    console.log(objeto);

    Database.post("/insert-entrada/", objeto, this).then(
      res => {
        toast.success("La entrada se registro con exito");
        this.setState(JSON.parse(JSON.stringify(StateEntradas)));
        this.getMuelles();
        this.props.getItems();
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  getMuellesDevolucion = () => {
    Database.get(
      "/list-muelles-devolucion/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.orderForm };
        let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle));
        id_muelle.elementConfig.options = a;
        formulario.id_muelle = id_muelle;

        this.setState({
          orderForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getMuelles = () => {
    Database.get(
      "/list-muelles/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.orderForm };
        let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle));
        id_muelle.elementConfig.options = a;
        formulario.id_muelle = id_muelle;

        this.setState({
          orderForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  afterProductoPresentacionSelect = id => {
    Database.get("/list-producto-presentacion/" + id, this).then(
      res => {
        let resultado = res.result;
        this.setState({
          rowProductoPresentacion: resultado,
          openDialogProducto: true
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  afterProductoUnidadSelect = id => {
    Database.get("/list-producto-unidad/" + id, this).then(
      res => {
        let resultado = res.result;
        console.log(resultado);
        this.setState({
          infoProductoUnidad: resultado,
          openDialogPresentacion: true
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    this.getMuelles();
  }

  cargarMuelles(e) {
    // alert(this.state.orderForm.devolucion.value);
    if (this.state.orderForm.devolucion.value == 0)
      //quiere decir que activo la casilla y busco los muelles que sean de devolución
      this.getMuellesDevolucion();
    else this.getMuelles();
  }

  closeDialog() {
    this.setState({
      openDialogProducto: false,
      openDialogCliente: false,
      openDialogUnidad: false,
      openDialogPresentacion: false
    });
  }

  closeDialogPresentacion() {
    this.setState({ openDialogPresentacion: false });
  }

  closeDialogUnidad() {
    this.setState({ openDialogUnidad: false });
  }

  onClickProducto = (rowData, objeto) => {
    let detalleentradas = [...this.state.detalleentradas];

    let item = {};
    for (let key in objeto) {
      item[key] = objeto[key];
    }
    item.id_producto_unidad = rowData.id_producto_unidad;
    item.id_producto_presentacion = rowData.id;
    if (item.cantidad_bultos && rowData.cantidad_unidades_bulto)
      item.cantidad_unidades =
        parseInt(item.cantidad_bultos) *
        parseInt(rowData.cantidad_unidades_bulto);

    if (item.carga_unidad && rowData.cantidad_unidades_bulto)
      item.cantidad_unidades =
        parseInt(item.carga_unidad)

    if (item.vencimiento)
      item.vencimiento_mostrar = moment(item.vencimiento).format("DD/MM/YYYY");
    item.presentacion = { ...rowData };

    detalleentradas.push(item);

    this.setState({
      openDialogProducto: false,
      rowEditProducto: null,
      detalleentradas: detalleentradas
    });
  };

  onClickItemCliente = rowData => {
    this.closeDialog();
    if (this.state.infoCliente && this.state.infoCliente.id == rowData.id) {
    } else {
      this.setState({
        infoCliente: rowData,
        detalleentradas: []
      });
    }
  };

  deleteProducto = rowData => {
    let detalleentradasant = [...this.state.detalleentradas];
    detalleentradasant.splice(detalleentradasant.indexOf(rowData), 1);
    this.setState({
      detalleentradas: detalleentradasant
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }
    return [
      <form
        onSubmit={event => {
          this.handleSubmitNewEntrada(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Nueva Entrada</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario de alta de Entrada
            </p>
          </CardHeader>
          <CardBody>
            <InputNew
              key={"newdevolucion"}
              objeto={this.state.orderForm.devolucion}
              changed={event => {
                let { orderForm, formIsValid } = inputChangedHandler(
                  event,
                  "devolucion",
                  this.state.orderForm
                );
                this.setState({
                  orderForm: orderForm,
                  formIsValid: formIsValid
                });
                this.cargarMuelles(event);
              }}
            />

            <InputNew
              key={"newentradareferencia"}
              objeto={this.state.orderForm.referencia}
              changed={event => {
                let { orderForm, formIsValid } = inputChangedHandler(
                  event,
                  "referencia",
                  this.state.orderForm
                );
                this.setState({
                  orderForm: orderForm,
                  formIsValid: formIsValid
                });
              }}
            />

            <InputNew
              key={"muelleentrada-"}
              objeto={this.state.orderForm.id_muelle}
              changed={event => {
                let { orderForm, formIsValid } = inputChangedHandler(
                  event,
                  "id_muelle",
                  this.state.orderForm
                );
                this.setState({
                  orderForm: orderForm,
                  formIsValid: formIsValid
                });
              }}
            />

            <InputNew
              key={"comentario"}
              objeto={this.state.orderForm.comentario}
              changed={event => {
                let { orderForm, formIsValid } = inputChangedHandler(
                  event,
                  "comentario",
                  this.state.orderForm
                );
                this.setState({
                  orderForm: orderForm,
                  formIsValid: formIsValid
                });
              }}
            />

            <div>
              <Button
                style={{ marginTop: "25px" }}
                color="primary"
                onClick={() => this.setState({ openDialogCliente: true })}
              >
                Seleccionar Cliente
              </Button>

              {this.state.infoCliente && (
                <p>
                  Nombre:{" "}
                  <strong>{this.state.infoCliente.nombre_fantasia}</strong>{" "}
                  <br />
                  Razón Social:{" "}
                  <strong>{this.state.infoCliente.razon_social}</strong> <br />
                  Número de Ciente: {this.state.infoCliente.numero_cliente}{" "}
                  <br />
                  RUC: {this.state.infoCliente.ruc}
                </p>
              )}
            </div>

            {/* <div className="mt-3 mb-3">
                    {formElementsArray.map(formElement => (
                        <InputNew
                        key={"newentrada-" + formElement.id}
                        objeto={formElement.config}
                        changed={(event) => {
                            let { orderForm, formIsValid } = inputChangedHandler(event, formElement.id, this.state.orderForm);
                            this.setState({ orderForm: orderForm, formIsValid: formIsValid })
                        }}
                        />
                    ))}
                    </div> */}

            <div>
              <Button
                style={{ marginTop: "25px" }}
                color="primary"
                disabled={!this.state.infoCliente}
                onClick={() =>
                  this.setState({
                    openDialogProducto: true,
                    rowProductoPresentacion: null
                  })
                }
              >
                Seleccionar Dato Logístico
              </Button>
              <Button
                style={{ marginTop: "25px" }}
                color="primary"
                disabled={!this.state.infoCliente}
                onClick={() =>
                  this.setState({
                    openDialogUnidad: true,
                    rowProductoPresentacion: null
                  })
                }
              >
                <AddIcon />
                Nueva Referencia
              </Button>
              <Button
                style={{ marginTop: "25px" }}
                color="primary"
                disabled={!this.state.infoCliente}
                onClick={() =>
                  this.setState({
                    openDialogPresentacion: true,
                    rowProductoPresentacion: null
                  })
                }
              >
                <AddIcon />
                Nuevo Dato Logístico
              </Button>
            </div>
            <MaterialTable
              columns={ColumnsListadoProductos}
              data={this.state.detalleentradas}
              title="Productos Presentacion"
              actions={[
                {
                  icon: "delete",
                  tooltip: "Eliminar Producto",
                  onClick: (event, rowData) => this.deleteProducto(rowData)
                }
              ]}
              localization={localization}
              components={{
                Container: props => <Paper elevation={0} {...props} />
              }}
              options={{
                //filtering: true,
                actionsColumnIndex: -1
              }}
              detailPanel={rowData => {
                return (
                  <div>
                    <p style={{ fontSize: "115%" }}>
                      Información adicional Dato Logístico
                    </p>
                    <p>
                      Unidades x bulto:{" "}
                      {rowData.presentacion.cantidad_unidades_bulto} <br />
                      Unidades x display:{" "}
                      {rowData.presentacion.cantidad_unidades_display} <br />
                      displays x bulto:{" "}
                      {rowData.presentacion.cantidad_dispplays_bulto} <br />
                      <br />
                      Alto: {rowData.presentacion.alto_bulto} (cm), Largo:{" "}
                      {rowData.presentacion.largo_bulto} (cm), Ancho:{" "}
                      {rowData.presentacion.ancho_bulto} (cm)
                    </p>
                  </div>
                );
              }}
            />

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.goBack()}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={
                !this.state.formIsValid ||
                this.state.disableAllButtons ||
                this.state.detalleentradas.length == 0 ||
                !this.state.infoCliente
              }
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,
      <Dialog
        open={this.state.openDialogProducto}
        onClose={this.closeDialog.bind(this)}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          Seleccionar Dato Logístico
          <IconButton
            aria-label="close"
            className={this.props.classes.closeButton}
            onClick={this.closeDialog.bind(this)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {
          <DialogContent>
            {this.state.openDialogProducto && (
              <StepAgregarProducto
                infoCliente={this.state.infoCliente}
                rowEditProducto={this.state.rowEditProducto}
                ColumnsListado={ColumnsListado}
                onClickProducto={(id, cantidad, bultos, lote, vencimiento) =>
                  this.onClickProducto(id, cantidad, bultos, lote, vencimiento)
                }
                rowProductoPresentacion={this.state.rowProductoPresentacion}
              />
            )}
          </DialogContent>
        }
      </Dialog>,

      // <div>
      //   {this.state.openDialogProducto &&
      //     <ModalSeleccionarProducto
      //       // rowItem={this.state.rowItem}
      //       // onClick={this.onClickItem}
      //       openDialogProducto={this.state.openDialogProducto}
      //       closeDialogProducto={this.closeDialogProducto}
      //     />
      //   }
      // </div>
      // ,

      <div>
        {this.state.openDialogCliente && (
          <ModalSeleccionarCliente
            onClick={this.onClickItemCliente}
            openDialog={this.state.openDialogCliente}
            closeDialog={this.closeDialog.bind(this)}
          />
        )}

        {this.state.openDialogUnidad && (
          <ModalNuevaUnidad
            rowCliente={this.state.infoCliente}
            onClick={this.onClickItemUnidad}
            openDialog={this.state.openDialogUnidad}
            closeDialog={this.closeDialog.bind(this)}
            onSave={id => {
              console.log(id);
              this.closeDialogUnidad();
              this.afterProductoUnidadSelect(id);
            }}
          />
        )}

        {this.state.openDialogPresentacion && (
          <ModalNuevaPresentacion
            rowProductoUnidad={this.state.infoProductoUnidad}
            onClick={this.onClickItemPresentacion}
            openDialog={this.state.openDialogPresentacion}
            closeDialog={this.closeDialog.bind(this)}
            onSave={id => {
              this.closeDialogPresentacion();
              this.afterProductoPresentacionSelect(id);
            }}
          />
        )}
      </div>
    ];
  }
}

export default withRouter(withStyles(styles)(NewEntrada));
