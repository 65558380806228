import * as actionTypes from "./actionTypes";

export const setAlertasVencimiento = data => {
  return {
    type: actionTypes.ALERTAS_SET_VENCIMIENTO,
    data: data
  };
};

export const setAlertasUmbral = data => {
  return {
    type: actionTypes.ALERTAS_SET_UMBRAL,
    data: data
  };
};
