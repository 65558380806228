import React, { Component } from 'react'
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CardContent,
  Typography,
} from '@material-ui/core'

import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Card from 'components/Card/Card.js'

// Tabla
import MaterialTable from 'material-table'
import lightGreen from '@material-ui/core/colors/lightGreen'
import { localization } from 'variables/general.js'
import moment from 'moment'

import { withStyles } from '@material-ui/styles'
import AddItems from './DialogAddItems'
import DetalleItems from './DetalleItems'
import DetallePedidoCheck from './DetallePedidoCheck'
import Database from 'variables/Database'
import { toast } from 'react-toastify'
import DialogFinishCheck from './DialogFinishCheck'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
}

const rowBackgroundColors = {
  '1': 'LightCoral',
  '2': 'yellow',
}

class SalidasCheckList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Referente al checkeo del pedido
      pedidoId: this.props.dataListado.id,
      datosPrevios: false,
      id_calle_salida: '',
      dataListado: '',
      referencia: '',
      destino: '',
      selectedWorker: '',
      recolector: '',
      id_cliente: '',
      razon_social: '',

      scanValue: '', // Para valores de EAN13 o DUN14
      codeName: '',
      code: '',

      detallePedido: '',
      cantidadProductos: 0,
      columnsCheck: [
        { title: 'Código', field: 'code', editable: 'never' },
        { title: 'EAN13', field: 'ean13', editable: 'never' },
        { title: 'DUN14', field: 'dun14', editable: 'never' },
        { title: 'Nombre', field: 'name', editable: 'never' },
        {
          title: 'Unidades',
          field: 'quantity',
          type: 'numeric',
          editable: 'onUpdate',
        },
        { title: 'Observaciones', field: 'comment', editable: 'onUpdate' },
      ],
      dataCheckList: [],

      // Variables de manejo de los componentes
      isLoading: false,
      isDialogOpen: false,
      selectedRow: null,
      checkboxChecked: false, // Estado para controlar el estado del checkbox
      showScanInputs: true,
      showModal: false,
      showFinishModal: false,
      openDialogSinProducto: false,
      message: '',
      timeMessage: 0,
      warningProducts: false,
    }
  }

  componentDidMount() {
    // Primero verifico si ya habia productos cargados
    this.recuperarPedido()
  }

  //----------------------------------------------------------------
  // Recupero los datos del control si existe
  //----------------------------------------------------------------
  recuperarPedido = () => {
    this.setState({
      isLoading: true,
    })
    Database.get('/recuperar-checklist/' + this.state.pedidoId, this).then(
      (res) => {
        let compPedido = null
        let dataCheckList = []
        let dataListado = {}
        if (res.compPedido) {
          compPedido = res.compPedido
          dataCheckList = compPedido.CompPedidoDetalles

          dataCheckList = dataCheckList.map((item) => {
            item.messages = JSON.parse(item.messages)
            return item
          })

          dataListado = {
            id_cliente: compPedido.id_cliente,
            razon_social: compPedido.razon_social,
            referencia: compPedido.referencia,
            destino: compPedido.destino,
            trabajador: compPedido.recolector,
          }
        }

        if (res.info === 'Pedido no encontrado') {
          // Si no hay recupero datos del cliente
          this.recuperarCliente()
        } else {
          this.setState({
            isLoading: false,
            dataListado: dataListado ? dataListado : '',
            selectedWorker: compPedido.selectedWorker
              ? compPedido.selectedWorker
              : '',
            id_calle_salida: compPedido.id_calle_salida
              ? compPedido.id_calle_salida
              : '',
            dataCheckList: dataCheckList ? dataCheckList : '',
            warningProducts: compPedido.warningProducts
              ? compPedido.warningProducts
              : '',
            showScanInputs: compPedido.showScanInputs
              ? compPedido.showScanInputs
              : '',
            id_cliente: compPedido.id_cliente ? compPedido.id_cliente : '',
            razon_social: compPedido.razon_social
              ? compPedido.razon_social
              : '',
            referencia: compPedido.referencia ? compPedido.referencia : '',
            destino: compPedido.destino ? compPedido.destino : '',
            recolector: compPedido.recolector ? compPedido.recolector : '',
          })

          // Una vez obtenido todos los datos, busco los productos
          this.recuperarProductos()
        }
      },
      (err) => {
        toast.error(err.message)
      }
    )
  }

  //----------------------------------------------------------------
  // Recupero los datos del cliente
  //----------------------------------------------------------------
  recuperarCliente = () => {
    this.setState({
      isLoading: true,
    })

    Database.get('/me', this).then(
      (res) => {
        let resultado = [res.result[0][0].nombre]
        this.setState({
          isLoading: false,
          selectedWorker: resultado[0],
          pedidoId: this.props.dataListado.id,
          id_calle_salida: this.props.dataListado.id_calle_salida,
          dataListado: this.props.dataListado,
          referencia: this.props.dataListado.referencia,
          destino: this.props.dataListado.destino,
          recolector: this.props.dataListado.trabajador,
          id_cliente: this.props.dataListado.id_cliente,
          razon_social: this.props.dataListado.razon_social,
        })

        // Una vez obtenido todos los datos, busco los productos
        this.recuperarProductos()
      },
      (err) => {
        toast.error(err.message)
      }
    )
  }

  //----------------------------------------------------------------
  // Recupero los productos que posee el cliente
  //----------------------------------------------------------------
  recuperarProductos = () => {
    this.setState({
      isLoading: true,
    })
    Database.get(
      '/list-pedidos-salidas-detalle/' +
        this.state.pedidoId +
        '/' +
        this.state.id_calle_salida,
      this
    ).then(
      (res) => {
        let resultado = [...res.result[0]]

        for (let i = 0; i < resultado.length; i++) {
          if (resultado[i].dun14 === null) {
            resultado[i].dun14 = '...'
          }
          if (resultado[i].ean3 === null) {
            resultado[i].ean3 = '...'
          }
        }
        this.setState({
          detallePedido: resultado,
          cantidadProductos: resultado.length,
          isLoading: false,
        })
      },
      (err) => {
        toast.error(err.message)
      }
    )
  }

  // Función para abrir el diálogo
  abrirDialog = (message, timeMessage) => {
    this.setState({
      message: message,
      timeMessage: timeMessage,
      openDialogSinProducto: true,
    })

    let timeForMessage = timeMessage * 1000

    // Cerrar el diálogo después de 2 segundos
    setTimeout(() => {
      this.cerrarDialog()
    }, timeForMessage)
  }

  // Función para cerrar el diálogo
  cerrarDialog = () => {
    this.setState({ openDialogSinProducto: false })
  }

  //----------------------------------------------------------------
  // Input del escaneo
  //----------------------------------------------------------------

  handleCheckboxChange = () => {
    // Cambiar el estado del checkbox
    this.setState({ checkboxChecked: !this.state.checkboxChecked })

    // Si el checkbox se marca, abrir el modal
    if (!this.state.checkboxChecked) {
      this.setState({ showModal: true })
    } else {
      this.setState({ showModal: false })
    }
  }

  handleOpenDialog = (event, rowData) => {
    this.setState({ isDialogOpen: true, selectedRow: rowData })
  }

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  handleCodeChange = (event) => {
    const { name, value } = event.target

    // Limpia los otros dos campos si este campo se modifica
    if (name === 'scanValue') {
      this.setState({ code: '', codeName: '' })
    } else if (name === 'code') {
      this.setState({ scanValue: '', codeName: '' })
    } else if (name === 'codeName') {
      this.setState({ scanValue: '', code: '' })
    }

    this.setState({ [name]: value })
  }

  //----------------------------------------------------------------
  // Modificacion de los elementos de la tabla
  //----------------------------------------------------------------

  actualizarDatos = (nuevoDato) => {
    // Copiar dataCheckList para evitar mutaciones directas
    const dataCheckListCopy = [...this.state.dataCheckList]

    // Buscar el índice del elemento con el mismo id que el nuevo dato
    const index = dataCheckListCopy.findIndex(
      (item) => item.id === nuevoDato.id
    )

    if (index !== -1) {
      // Actualizar el valor de la propiedad 'items' del elemento encontrado con el nuevo valor
      dataCheckListCopy[index] = {
        ...dataCheckListCopy[index],
        items: nuevoDato.items,
        quantity: nuevoDato.quantity,
        warning: nuevoDato.warning,
      }

      // Actualizar el estado con la nueva lista
      this.setState({ dataCheckList: dataCheckListCopy })
    } else {
      // Si el ID no existe, crea un nuevo objeto y agrégalo a dataCheckList con las propiedades del nuevoDato
      const newItem = {
        ...nuevoDato,
        items: nuevoDato.items,
        // Otras propiedades según sea necesario
      }

      // Agrega el nuevo elemento al arreglo
      dataCheckListCopy.push(newItem)

      // Actualiza el estado con el nuevo dataCheckList
      this.setState({ dataCheckList: dataCheckListCopy })
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Ejecuta la función que desees cuando se presione Enter.
      this.handleCodeAdd()
    }
  }

  handleCodeAdd = () => {
    const {
      code,
      codeName,
      scanValue,
      dataCheckList,
      detallePedido,
      dataListado,
    } = this.state
    let ean13 = ''
    let dun14 = ''

    const stringValue = scanValue.toString()
    if (stringValue.length === 13) {
      ean13 = stringValue
    } else if (stringValue.length === 14) {
      dun14 = stringValue
    }

    // Calcular el próximo ID disponible
    const maxId = Math.max(...dataCheckList.map((item) => item.id), 0)
    const nextId = maxId + 1

    if (
      code.trim() !== '' ||
      ean13.trim() !== '' ||
      codeName.trim() !== '' ||
      dun14.trim() !== ''
    ) {
      let regexPattern = null

      // Hay que usar la variable ig para coincidencias
      if (codeName !== '') regexPattern = new RegExp(`(${codeName})`, 'ig')

      const existingItemIndex = dataCheckList.findIndex(
        (item) =>
          item.code === `${dataListado.id_cliente}-${code}` || // Verifica code ||
          item.ean13 === ean13 ||
          item.dun14 === dun14 ||
          item.name.match(regexPattern)
      )

      if (existingItemIndex !== -1) {
        const updatedData = [...dataCheckList]
        if (updatedData[existingItemIndex].items.length === 0) {
          updatedData[existingItemIndex].quantity += 1
          if (
            updatedData[existingItemIndex].quantity >
            updatedData[existingItemIndex].cant_unidades_esperada
          ) {
            const fechaHora = new Date().toLocaleString()

            let messageIndex = updatedData[existingItemIndex].messages.length // Inicializa el índice de mensajes

            const unidadesSobran =
              updatedData[existingItemIndex].quantity -
              updatedData[existingItemIndex].cant_unidades_esperada

            const newMessage = {
              id: ++messageIndex, // Asigna un nuevo ID de mensaje
              date: fechaHora,
              message: `Sobran ${unidadesSobran} unidades`,
            }

            toast.warning(
              'Sobran ' +
                unidadesSobran +
                ' unidades de ' +
                updatedData[existingItemIndex].name
            )

            updatedData[existingItemIndex].messages.push(newMessage)
            updatedData[existingItemIndex].warning = 2
          }
        } else {
          this.setState({
            isDialogOpen: true,
            selectedRow: updatedData[existingItemIndex],
          })
        }

        this.setState({
          dataCheckList: updatedData,
          code: '',
          codeName: '',
          scanValue: '',
        })
      } else {
        // Si no encontro ningun campo EAN13, DUN14, codigo o nombre
        let valorEncontrado = null
        let valorABuscar = null
        let coincidencia = false // Variable de control

        if (ean13 !== '' || dun14 !== '') {
          // Busco alguno de los 2 (no se dan a la vez)
          valorABuscar = `${ean13}${dun14}`
        } else if (code !== '') {
          valorABuscar = `${dataListado.id_cliente}-${code}`
        } else if (codeName !== '') {
          valorABuscar = `${codeName}`
        }

        for (let i = 0; i < detallePedido.length; i++) {
          const objBusqueda = detallePedido[i]

          for (const clave in objBusqueda) {
            if (objBusqueda.hasOwnProperty(clave)) {
              // Con RegExp busco la palabra o una secuencia de números
              let regex = ''
              if (codeName === '') {
                regex = new RegExp(`^${valorABuscar}$`, 'i')
              } else {
                regex = new RegExp(valorABuscar, 'i')
              }
              // Me aseguro que objBusqueda sea un string porque sino tira error
              if (String(objBusqueda[clave]).match(regex)) {
                valorEncontrado = objBusqueda
                coincidencia = true
                break
              }
            }
          }
        }

        if (coincidencia === false) {
          this.abrirDialog('Producto no perteneciente al pedido', 2)
          this.setState({
            code: '',
            codeName: '',
            scanValue: '',
          })
        } else {
          // En esta parte traigo todas las unidadades esperadas a escanear

          let {
            bulto,
            display,
            cantidad_bultos,
            cantidad_displays,
            cantidad_displays_bulto,
            cantidad_unidades_display,
            cantidad_unidades_bulto,
            cantidad_unidades,
          } = valorEncontrado

          let totalUnidades = 0

          // Me aseguro que los valores sean numeros y si son null las trato como cero

          cantidad_unidades =
            cantidad_unidades !== null ? parseInt(cantidad_unidades) : 0
          cantidad_displays =
            cantidad_displays !== null ? parseInt(cantidad_displays) : 0
          cantidad_unidades_display =
            cantidad_unidades_display !== null
              ? parseInt(cantidad_unidades_display)
              : 0
          cantidad_bultos =
            cantidad_bultos !== null ? parseInt(cantidad_bultos) : 0
          cantidad_unidades_bulto =
            cantidad_unidades_bulto !== null
              ? parseInt(cantidad_unidades_bulto)
              : 0
          cantidad_displays_bulto =
            cantidad_displays_bulto !== null
              ? parseInt(cantidad_displays_bulto)
              : 0

          // console.log('cant unid por bulto', cantidad_unidades_bulto)
          // console.log('tiene_bulto', bulto)
          // console.log('tiene display', display)
          // console.log('cantidad unidades sueltas', cantidad_unidades)
          // console.log('cantidad bultos', cantidad_bultos)
          // console.log('cantidad unidades display', cantidad_unidades_display)
          // console.log('cantidad display bulto', cantidad_displays_bulto)
          // console.log('cantidad displays', cantidad_displays)

          let errorBultoDisplay = false

          if (bulto === null || display === null) {
            errorBultoDisplay = true
            toast.error(
              'Error: ' +
                valorEncontrado.producto +
                ' falta definir si tiene bulto y/o display'
            )
          } else if (
            !isNaN(cantidad_unidades) &&
            !isNaN(cantidad_displays) &&
            !isNaN(cantidad_unidades_display) &&
            !isNaN(cantidad_bultos) &&
            !isNaN(cantidad_unidades_bulto) &&
            !isNaN(cantidad_displays_bulto)
          ) {
            // Checkeo el total de las unidades esperadas
            if (bulto === 0 && display == 0) totalUnidades = cantidad_unidades
            else if (bulto === 0 && display == 1)
              totalUnidades =
                cantidad_displays * cantidad_unidades_display +
                cantidad_unidades
            else if (bulto === 1 && display == 0)
              totalUnidades =
                cantidad_bultos * cantidad_unidades_bulto + cantidad_unidades
            else if (bulto === 1 && display == 1)
              totalUnidades =
                cantidad_bultos *
                  cantidad_displays_bulto *
                  cantidad_displays *
                  cantidad_unidades_display +
                cantidad_unidades
          } else {
            // Si algun valor no fue un numero
            console.error('Una o más cantidades no son números enteros válidos')
          }

          // console.log('total de unidades esperadas', totalUnidades)

          const newItem = {
            id: nextId, // Usar el próximo ID disponible
            ean13: valorEncontrado.ean13 ? valorEncontrado.ean13 : '...', // Valores apropiados
            dun14: valorEncontrado.dun14 ? valorEncontrado.dun14 : '...',
            name: valorEncontrado.producto ? valorEncontrado.producto : '...',
            code: valorEncontrado.codigo ? valorEncontrado.codigo : '...',
            quantity: 1, // Inicializamos la cantidad en 1 para nuevos códigos
            cant_unidades_esperada: totalUnidades,
            comment: '...',
            items: [],
            warning: 0,
            messages: [],
          }
          if (this.state.showModal) {
            // Paso los valores para que agregue items
            this.setState({
              isDialogOpen: true,
              selectedRow: newItem,
              code: '',
              codeName: '',
              scanValue: '',
            })
          } else {
            this.setState((prevState) => ({
              dataCheckList: errorBultoDisplay
                ? [...prevState.dataCheckList]
                : [...prevState.dataCheckList, newItem],
              code: '',
              codeName: '',
              scanValue: '',
            }))
          }
        }
      }
    }
  }

  handleDeleteRow = (rowData, itemId) => {
    // Aca se elimina la fila con el ID proporcionado.
    // Se busca el elemento en dataCheckList con el ID coincidente

    const updatedDataCheckList = this.state.dataCheckList.map((item) => {
      if (item.id === rowData.id) {
        // Se busca el índice del elemento con el ID coincidente en rowData.items
        const index = item.items.findIndex(
          (detailItem) => detailItem.id === itemId
        )
        if (index !== -1) {
          // Si se encontró un elemento con el ID coincidente, se elimina
          item.items.splice(index, 1)
          item.quantity = item.items.length

          // Luego, vuelves a enumerar todos los elementos
          item.items.forEach((detailItem, newIndex) => {
            // Actualiza el índice de cada elemento en el arreglo
            detailItem.id = newIndex + 1
          })
        }
      }
      return item
    })

    // Actualiza el estado con la lista actualizada
    this.setState({ dataCheckList: updatedDataCheckList })
  }

  //----------------------------------------------------------------
  // Finalizar el control de la orden
  //----------------------------------------------------------------

  handleCheck = () => {
    this.setState({ showFinishModal: true })
  }

  handleAceptarFinalizar = () => {
    // Aca compruebo si el pedido es correcto
    const { dataCheckList, cantidadProductos } = this.state

    const updatedDataCheckList = dataCheckList.map((item) => {
      const { quantity, cant_unidades_esperada } = item
      const fechaHora = new Date().toLocaleString()

      let messageIndex = item.messages.length // Inicializa el índice de mensajes

      if (quantity === cant_unidades_esperada) {
        return {
          ...item,
          warning: 0,
          messages: [
            ...item.messages,
            {
              id: ++messageIndex, // Asigna un nuevo ID de mensaje
              date: fechaHora,
              message: 'Cantidad del pedido esperada',
            },
          ],
        }
      } else if (quantity > cant_unidades_esperada) {
        const unidadesSobran = quantity - cant_unidades_esperada
        return {
          ...item,
          warning: 2,
          messages: [
            ...item.messages,
            {
              id: ++messageIndex, // Asigna un nuevo ID de mensaje
              date: fechaHora,
              message: `Sobran ${unidadesSobran} unidades`,
            },
          ],
        }
      } else {
        const unidadesFaltan = cant_unidades_esperada - quantity
        return {
          ...item,
          warning: 1,
          messages: [
            ...item.messages,
            {
              id: ++messageIndex, // Asigna un nuevo ID de mensaje
              date: fechaHora,
              message: `Faltan ${unidadesFaltan} unidades`,
            },
          ],
        }
      }
    })

    // Verifica si todos los elementos tienen warning igual a 0, es decir que cumplen con las cantidades correctas
    const finishCheckList =
      updatedDataCheckList.every((item) => item.warning === 0) &&
      updatedDataCheckList.length === cantidadProductos

    // console.log('finishcheck', finishCheckList)

    // Hasta que no se finalice todo el pedido la comprobacion sigue
    this.setState({
      dataCheckList: updatedDataCheckList,
      warningProducts: true,
      showFinishModal: false,
      showScanInputs: !finishCheckList,
    })

    try {
      const {
        pedidoId,
        selectedWorker,
        referencia,
        destino,
        id_cliente,
        razon_social,
        recolector,
        id_calle_salida,
      } = this.state

      let showScanInputs = true
      if (updatedDataCheckList.length === 0) {
        // Si no se cargo nada, no guardo nada en la base de datos
        return
      } else if (
        updatedDataCheckList.length === cantidadProductos &&
        finishCheckList
      ) {
        showScanInputs = false
      } else if (updatedDataCheckList.length !== cantidadProductos) {
        const valueToFinish = cantidadProductos - updatedDataCheckList.length
        toast.warning(
          'Faltan cargar ' + valueToFinish + ' productos para finalizar'
        )
      }
      // console.log('showScan', showScanInputs)
      // console.log('cliente', id_cliente)
      // console.log('razon', razon_social)
      // console.log('destino', destino)
      // console.log('referencia', referencia)
      // console.log('recolector', recolector)

      Database.post(`/guardar-checklist`, {
        pedidoId,
        dataCheckList: updatedDataCheckList,
        selectedWorker,
        referencia,
        destino,
        id_cliente,
        razon_social,
        recolector,
        id_calle_salida,

        showScanInputs: showScanInputs,
        warningProducts: true,
      })
        .then((res) => {
          let resultado = res
          if (!finishCheckList) {
            toast.info(
              'Guardado provisorio: Verificar las cantidades de los productos'
            )
          } else {
            toast.success(resultado.message)
          }
        })
        .catch((err) => {
          toast.error('Error al guardar el dato: ' + err.message)
        })
    } catch (error) {
      console.error('Error al realizar la solicitud: ' + error.message)
    }
  }

  //----------------------------------------------------------------
  // Cerrar el control de la orden
  //----------------------------------------------------------------

  handleCancelarFinalizar = () => {
    this.setState({
      showFinishModal: false,
    })
  }

  handleCerrarCheckList = () => {
    const { handleCloseCheckList } = this.props
    handleCloseCheckList()
  }

  render() {
    // const { dataListado } = this.props
    // console.log('dataListado hijo', dataListado)
    // console.log('props padre', this.props)

    // const { code } = this.state
    const { showScanInputs, warningProducts, dataCheckList } = this.state

    return (
      <Card style={this.props.style}>
        <CardHeader color='primary'>
          <h4 className={this.props.classes.cardTitleWhite}>SALIDAS</h4>
          <p className={this.props.classes.cardCategoryWhite}>
            Checkeo del Pedido en Calle de Salida
          </p>
        </CardHeader>

        <DetallePedidoCheck
          dataListado={this.state.dataListado}
          pedidoId={this.state.pedidoId}
          selectedWorker={this.state.selectedWorker}
          handleCerrarCheckList={this.handleCerrarCheckList}
        />

        {showScanInputs && (
          <CardBody>
            <Grid container spacing={2} alignItems='center' justify='center'>
              <Grid item xs={12} alignItems='center' justify='center'>
                <h3>
                  <b>Escaneo de los productos</b>
                </h3>{' '}
                <label fontSize={25}>
                  <Checkbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
                    checked={this.state.checkboxChecked}
                    onChange={this.handleCheckboxChange}
                  />
                  Agregar IMEI o Numero de Serie
                </label>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='outlined-basic'
                  label='Ingresar EAN13 o DUN14'
                  name='scanValue'
                  variant='outlined'
                  value={this.state.scanValue}
                  onChange={this.handleCodeChange}
                  onKeyPress={this.handleKeyPress}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    width: '33%',
                  }}
                  onClick={this.handleCodeAdd}
                >
                  Agregar
                </Button>
              </Grid>
              <Grid item justify='flex-end' xs={1}>
                <h4 style={{ textAlign: 'right' }}>
                  <b>{this.state.dataListado.id_cliente} -</b>
                </h4>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id='outlined-basic'
                  label='Ingresar por codigo manualmente'
                  name='code'
                  variant='outlined'
                  value={this.state.code}
                  onChange={this.handleCodeChange}
                  onKeyPress={this.handleKeyPress}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    width: '33%',
                  }}
                  onClick={this.handleCodeAdd}
                >
                  Agregar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='outlined-basic'
                  label='Ingresar por nombre de producto'
                  name='codeName'
                  variant='outlined'
                  value={this.state.codeName}
                  onChange={this.handleCodeChange}
                  onKeyPress={this.handleKeyPress}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    width: '33%',
                  }}
                  onClick={this.handleCodeAdd}
                >
                  Agregar
                </Button>
              </Grid>
            </Grid>
          </CardBody>
        )}
        <MaterialTable
          style={{
            marginTop: '30px',
          }}
          isLoading={this.state.isLoading}
          columns={this.state.columnsCheck}
          data={this.state.dataCheckList}
          title='Productos'
          localization={localization}
          options={{
            filtering: false,
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Productos ' + moment().format('DD-MM-YYYY'),
            exportDelimiter: ';',
            headerStyle: {
              backgroundColor: lightGreen[700],
              color: '#FFF',
            },
            rowStyle: (rowData) => {
              return {
                backgroundColor: rowBackgroundColors[rowData.warning] ?? '#fff',
              }
            },
          }}
          editable={
            showScanInputs
              ? {
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...this.state.dataCheckList]
                        const index = oldData.tableData.id
                        if (newData.quantity < 0) {
                          toast.info('La cantidad mínima es 1')
                          newData.quantity = 1
                        }

                        if (newData.quantity > newData.cant_unidades_esperada) {
                          const fechaHora = new Date().toLocaleString()

                          let messageIndex = newData.messages.length // Inicializa el índice de mensajes

                          const unidadesSobran =
                            newData.quantity - newData.cant_unidades_esperada

                          const newMessage = {
                            id: ++messageIndex, // Asigna un nuevo ID de mensaje
                            date: fechaHora,
                            message: `Sobran ${unidadesSobran} unidades`,
                          }

                          toast.warning(
                            'Sobran ' +
                              unidadesSobran +
                              ' unidades de ' +
                              newData.name
                          )

                          newData.messages.push(newMessage)
                          newData.warning = 2
                        }
                        if (dataUpdate[index].items.length === 0)
                          dataUpdate[index] = newData
                        else
                          this.abrirDialog(
                            'Error: El articulo ' +
                              dataUpdate[index].name +
                              ' tiene items, edítelos en el detalle',
                            8
                          )
                        this.setState({ dataCheckList: dataUpdate }, () => {
                          resolve()
                        })
                      }, 1000)
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...this.state.dataCheckList]
                        const index = oldData.tableData.id
                        dataDelete.splice(index, 1)
                        this.setState(
                          { dataCheckList: dataDelete, code: '' },
                          () => {
                            resolve()
                          }
                        )
                      }, 1000)
                    }),
                }
              : {}
          }
          actions={
            showScanInputs
              ? [
                  {
                    icon: 'add',
                    tooltip: 'Agregar items',
                    onClick: this.handleOpenDialog,
                  },
                ]
              : []
          }
          detailPanel={(rowData) => {
            // Detalle items espera un array
            if (rowData.items.length > 0)
              return (
                <DetalleItems
                  rowData={[rowData]}
                  onDeleteRow={this.handleDeleteRow}
                  showDelete={showScanInputs}
                />
              )
            else
              return (
                <div
                  style={{
                    padding: '20px',
                    fontSize: '16px',
                  }}
                >
                  No posee detalle
                </div>
              )
          }}
        />

        {warningProducts && (
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant='h5' gutterBottom>
                <b>NOTAS:</b>
              </Typography>
              {dataCheckList.map((item) => (
                <div key={item.id}>
                  <Typography sx={{ fontSize: 18 }} gutterBottom>
                    <b>{item.name}:</b>
                  </Typography>
                  {item.messages.map((message) => (
                    <Typography
                      key={message.id}
                      sx={{ fontSize: 14 }}
                      gutterBottom
                    >
                      - <b>[{message.date}]</b> : {message.message}
                    </Typography>
                  ))}
                </div>
              ))}
            </CardContent>
          </Card>
        )}

        {showScanInputs && (
          <Grid container alignItems='center' justify='center'>
            <Grid
              item
              xs={4}
              style={{
                padding: '20px',
                // marginTop: '10px',
              }}
            >
              <Button
                style={{
                  backgroundColor: 'green',
                  color: 'white',
                  width: '100%',
                }}
                onClick={this.handleCheck}
              >
                Finalizar control
              </Button>
            </Grid>
          </Grid>
        )}

        <AddItems
          open={this.state.isDialogOpen}
          onClose={this.handleCloseDialog}
          selectedRow={this.state.selectedRow}
          actualizarDatos={this.actualizarDatos}
        />

        <DialogFinishCheck
          mostrar={this.state.showFinishModal}
          onAceptarFinalizar={this.handleAceptarFinalizar}
          onCancelarFinalizar={this.handleCancelarFinalizar}
        />

        <Dialog
          open={this.state.openDialogSinProducto}
          onClose={this.cerrarDialog}
        >
          <DialogTitle>{this.state.message}</DialogTitle>
          <DialogContent>
            {' '}
            <DialogContentText>
              Este mensaje se cerrará en {this.state.timeMessage} segundos.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Card>
    )
  }
}

export default withStyles(styles)(SalidasCheckList)
