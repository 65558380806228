import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

import {
  columnasTablaContenedores,
  localization_options,
  removeFromLote
} from "../Tablas/TablaLotesASalida.js";
import { columnasTablaContenedoresSalidaAlmacen } from "../Tablas/TablaSalidaDeAlmacen.js";
import MaterialTable from "material-table";
import lightGreen from "@material-ui/core/colors/lightGreen";

function ModalTablaLote(props) {
  let columnasTablaModal = columnasTablaContenedores;
  if (props.selectedTab === 2) {
    columnasTablaModal = columnasTablaContenedoresSalidaAlmacen;
  }
  localization_options["body"]["emptyDataSourceMessage"] =
    props.selectedTab == 0
      ? "No existen contenedores en el almacén"
      : "Ya no existen contenedores en el lote a calle de salida";

  return (
    <Dialog
      open={props.editLoteDialog}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">Editar el lote</DialogTitle>
      <DialogContent>
        <MaterialTable
          id="tabla-en-lote"
          columns={columnasTablaModal}
          data={props.contenedoresEnLoteDeSalida}
          title=""
          localization={localization_options}
          actions={[
            rowData => ({
              icon: "undo",
              tooltip: "Devolver a su ubicación",
              onClick: (event, rowData) => removeFromLote(rowData, props)
            })
          ]}
          options={{
            filtering: true,
            exportDelimiter: ";",
            headerStyle: {
              backgroundColor: lightGreen[700],
              color: "#FFF"
            },
            selection: false,
            actionsColumnIndex: -1
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setModalVisibility(false, "lote")}
          color="primary"
          autoFocus
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    editLoteDialog: state.salidas.editLoteDialog,
    selectedTab: state.salidas.selectedTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeContenedorDeLoteDeSalida: contenedor =>
      dispatch(actionCreators.removeContenedorDeLoteDeSalida(contenedor)),
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTablaLote);
