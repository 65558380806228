/* eslint-disable */
import React from "react";

export const StateListProductosUnidad = {
  listado: [],
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false,
  openBuscarClienteDialog: false,
  selectedCliente: null
};

export const StateListProductos = {
  listado: [],
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateListProductosCtaegorias = {
  listado: [],
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const ColumnsListadoCategorias = [
  { title: "Codigo", field: "codigo" },
  { title: "Descripcion", field: "descripcion" },
  { title: "Espera Máxima en Muelle", field: "espera_en_muelle" }
];

export const StateProductos = {
  orderForm: {
    id_producto_categoria: {
      elementType: "select",
      elementConfig: {
        label: "Categoria",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    ean13: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "EAN13",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false,
        exactLength: 13
      },
      valid: false,
      touched: false
    },
    codigo_interno: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Código interno",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },

    peso: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Peso (Kg)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    alto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Alto (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    ancho: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    estado: {
      elementType: "select",
      elementConfig: {
        label: "Estado",
        options: [
          { displayValue: "Habilitado", value: 1 },
          { displayValue: "Deshabilitado", value: 0 }
        ],
        fullWidth: true
      },
      value: 1,
      validation: {
        required: true
      },

      valid: true,
      touched: false
    },

    display: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Información sobre el Display",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      disabled: true
    },

    bulto: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Información sobre el Bulto",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false,

  orderForm2: {
    cantidad_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad de Productos",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    peso_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Peso (Kg)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    alto_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Alto (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    largo_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    ancho_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    }
  },
  formIsValid2: false,

  orderForm3: {
    cantidad_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad Display/Unidades",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    peso_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Peso (Kg)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    alto_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Alto (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    largo_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    ancho_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    cantidad_pallet: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad por Pallet",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    maximo_apilable: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Máximo apilable",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    dun14: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "DUN14",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false,
        exactLength: 14
      },
      valid: false,
      touched: false
    }
  },
  formIsValid3: false
};

export const StateNewCategoria = {
  insumos: [],
  sectores: [],
  orderForm: {
    codigo: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Codigo Interno",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripcion",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    espera_en_muelle: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Espera máxima en muelle (minutos)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    estado: {
      elementType: "select",
      elementConfig: {
        label: "Estado",
        options: [
          { displayValue: "Habilitado", value: 1 },
          { displayValue: "Deshabilitado", value: 2 }
        ],
        fullWidth: true
      },
      value: 1,
      validation: {
        required: true
      },

      valid: true,
      touched: false
    }
  },

  formIsValid: false
};

export const StateProductosUnidad = {
  orderForm: {
    id_producto_categoria: {
      elementType: "select",
      elementConfig: {
        label: "Categoria",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },

    codigo: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Código de Artículo"
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    ean13: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "EAN13",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false,
        exactLength: 13
      },
      valid: true,
      touched: false
    },

    peso: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Peso (Kg)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    alto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Alto (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    ancho: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    estado: {
      elementType: "select",
      elementConfig: {
        label: "Estado",
        options: [
          { displayValue: "Habilitado", value: 1 },
          { displayValue: "Deshabilitado", value: 2 }
        ],
        fullWidth: true
      },
      value: 1,
      validation: {
        required: true
      },

      valid: true,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Descripcion", field: "descripcion" },
  {
    title: "Cod. Interno",
    field: "codigo"
  },
  { title: "EAN13", field: "ean13" },
  { title: "Categoria", field: "ProductosCat.descripcion" },
  { title: "Cliente", field: "cliente.nombre_fantasia" },
  {
    title: "Estado",
    render: rowData => {
      let estado = null;
      if (rowData.estado == 1) estado = "Habilitado";
      if (rowData.estado == 2) estado = "Deshabilitado";

      return <span>{estado}</span>;
    }
  }

  //{ title: "Orden", field: "orden" }
];
