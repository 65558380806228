import * as actionTypes from "../actions/actionTypes";

const initialState = {
  contenedores: [],
  contenedoresEnLoteDeSalida: [],
  contenedoresSalidaAlmacen: [],
  pickingEnLoteDeSalida: { bultos: 0, displays: 0, unidades: 0 },
  ubicacionesPicking: [],
  isLoading: true,
  isLoadingPicking: true,
  calle_de_salida: null,
  editLoteDialog: false,
  editLotePickingDialog: false,
  buscarCalleDialog: false,
  confirmDialog: false,
  confirmPicking: false,
  pedidoDialog: false,
  reload: false,
  reloadPicking: false,
  selectedTab: 0,
  sePerderanContenedoresSeleccionados: false,
  total_bultos: 0,
  total_displays: 0,
  total_unidades: 0,
  disable_total_bultos: false,
  disable_total_displays: false,
  disable_total_unidades: false,
  bultos_seleccionados: 0,
  displays_seleccionados: 0,
  unidades_seleccionadas: 0,
  radio_group_picking: "bulto",
  contenedores_ubicacion_picking: [],
  tipo_picking: null,
  lote_salida_picking: [],
  datos_completos_ubicacion_picking: null
};

const setContenedores = (state, action) => {
  let state_copy = state;
  state_copy.contenedores = action.contenedores;
  state_copy.isLoading = false;
  return { ...state_copy };
};

const setUbicacionesPicking = (state, action) => {
  let state_copy = state;
  state_copy.ubicacionesPicking = action.ubicacionesPicking;
  state_copy.isLoadingPicking = false;
  return { ...state_copy };
};

const setContenedoresUbicacionesPicking = (state, action) => {
  let state_copy = { ...state };
  state_copy.contenedores_ubicacion_picking =
    action.contenedores_ubicacion_picking;
  return { ...state_copy };
};

const addContenedorALoteDeSalida = (state, action) => {
  let state_copy = { ...state };
  let copia_contenedores = [...state_copy.contenedoresEnLoteDeSalida];
  copia_contenedores.push(action.contenedor);
  state_copy.contenedoresEnLoteDeSalida = copia_contenedores;
  return { ...state_copy };
};

const addPickingALoteDeSalida = (state, action) => {
  let state_copy = { ...state };
  let copia_lote_picking = [...state_copy.lote_salida_picking];
  copia_lote_picking.push(action.lote_picking);
  state_copy.lote_salida_picking = copia_lote_picking;
  return { ...state_copy };
};

const addBultosALoteDeSalida = (state, action) => {
  let state_copy = { ...state };
  let lote_de_salida_copia = { ...state_copy.pickingEnLoteDeSalida };
  let lastValues = lote_de_salida_copia.bultos;
  let newValues = lastValues + action.bultos;
  lote_de_salida_copia.bultos = newValues;
  state_copy.pickingEnLoteDeSalida = lote_de_salida_copia;
  return { ...state_copy };
};

const addDisplaysALoteDeSalida = (state, action) => {
  let state_copy = { ...state };
  let lote_de_salida_copia = { ...state_copy.pickingEnLoteDeSalida };
  let lastValues = lote_de_salida_copia.displays;
  let newValues = lastValues + action.displays;
  lote_de_salida_copia.displays = newValues;
  state_copy.pickingEnLoteDeSalida = lote_de_salida_copia;
  return { ...state_copy };
};

const addUnidadesALoteDeSalida = (state, action) => {
  let state_copy = { ...state };
  let lote_de_salida_copia = { ...state_copy.pickingEnLoteDeSalida };
  let lastValues = lote_de_salida_copia.unidades;
  let newValues = lastValues + action.unidades;
  lote_de_salida_copia.unidades = newValues;
  state_copy.pickingEnLoteDeSalida = lote_de_salida_copia;
  return { ...state_copy };
};

const removeContenedorDeLoteDeSalida = (state, action) => {
  let state_copy = { ...state };
  let lote_de_salida_state = [...state.contenedoresEnLoteDeSalida];
  lote_de_salida_state = lote_de_salida_state.filter(el => {
    return el.id !== action.contenedor.id;
  });
  state_copy.contenedoresEnLoteDeSalida = lote_de_salida_state;
  return { ...state_copy };
};

const removeUbicacionLoteSalida = (state, action) => {
  let state_copy = { ...state };
  let lote_de_salida_state = [...state.lote_salida_picking];
  lote_de_salida_state = lote_de_salida_state.filter(el => {
    return el.tableData.id !== action.ubicacion_id;
  });
  state_copy.lote_salida_picking = lote_de_salida_state;
  return { ...state_copy };
};

const setModalVisibility = (state, action) => {
  let state_copy = { ...state };
  if (action.modal === "lote") {
    state_copy.editLoteDialog = action.status;
  } else if (action.modal === "lote_picking") {
    state_copy.editLotePickingDialog = action.status;
  } else if (action.modal === "calle") {
    state_copy.buscarCalleDialog = action.status;
  } else if (action.modal === "confirm") {
    state_copy.confirmDialog = action.status;
  } else if (action.modal === "pedido") {
    state_copy.pedidoDialog = action.status;
  } else if (action.modal === "confirm_picking") {
    state_copy.confirmPicking = action.status;
  }
  return { ...state_copy };
};

const setCalleDeSalida = (state, action) => {
  let state_copy = { ...state };
  state_copy.calle_de_salida = action.calle;
  return { ...state_copy };
};

const reloadTable = state => {
  let state_copy = { ...state };
  state_copy.reload = !state_copy.reload;
  return { ...state_copy };
};

const reloadTablePicking = state => {
  let state_copy = { ...state };
  state_copy.reloadPicking = !state_copy.reloadPicking;
  return { ...state_copy };
};

const resetForm = (state, action) => {
  let state_copy = { ...state };
  let reload = !state_copy.reload;
  let new_state = { ...initialState };
  new_state.reload = reload;
  new_state.selectedTab = action.tab_number;
  return { ...new_state };
};

const resetSelectorCantidadPicking = (state, action) => {
  let state_copy = { ...state };
  state_copy.bultos_seleccionados = 0;
  state_copy.displays_seleccionados = 0;
  state_copy.unidades_seleccionadas = 0;
  return { ...state_copy };
};

const restarBultosDePicking = (state, action) => {
  let state_copy = { ...state };
  let picking_copia = [...state_copy.ubicacionesPicking];
  picking_copia = picking_copia.map(el => {
    if (el.id === action.id_ubicacion) {
      el.bultos_totales =
        el.bultos_totales !== "No corresponde"
          ? el.bultos_totales - action.n_bultos
          : el.bultos_totales;
    }
    return el;
  });
  state_copy.ubicacionesPicking = picking_copia;
  return { ...state_copy };
};

const restarDisplaysDePicking = (state, action) => {
  let state_copy = { ...state };
  let picking_copia = [...state_copy.ubicacionesPicking];
  picking_copia = picking_copia.map(el => {
    if (el.id === action.id_ubicacion) {
      el.displays_totales =
        el.displays_totales !== "No corresponde"
          ? el.displays_totales - action.n_displays
          : el.displays_totales;
    }
    return el;
  });
  state_copy.ubicacionesPicking = picking_copia;
  return { ...state_copy };
};

const restarUnidadesDePicking = (state, action) => {
  let state_copy = { ...state };
  let picking_copia = [...state_copy.ubicacionesPicking];
  picking_copia = picking_copia.map(el => {
    if (el.id === action.id_ubicacion) {
      el.unidades_totales = el.unidades_totales - action.n_unidades;
    }
    return el;
  });
  state_copy.ubicacionesPicking = picking_copia;
  if (action.n_unidades < 0) {
    state_copy = updateRowUbicacionesPicking(state_copy, action.id_ubicacion);
  }
  return { ...state_copy };
};

const setSelectedTab = (state, action) => {
  let state_copy = { ...state };
  state_copy.selectedTab = action.value_selected_tab;
  return { ...state_copy };
};

const setSePerderanContenedoresSeleccionados = (state, action) => {
  let state_copy = state;
  let new_value_sePerderanContenedoresSeleccionados = action.bool_value;
  state_copy.sePerderanContenedoresSeleccionados = new_value_sePerderanContenedoresSeleccionados;
  return { ...state_copy };
};

const setMaximosCantidadPicking = (state, action) => {
  let state_copy = state;
  state_copy.total_bultos = action.total_bultos;
  state_copy.total_displays = action.total_displays;
  state_copy.total_unidades = action.total_unidades;
  return { ...state_copy };
};

const setBultosSeleccionados = (state, action) => {
  let state_copy = state;
  state_copy.bultos_seleccionados = action.bultos_seleccionados;
  return { ...state_copy };
};

const setDisplaysSeleccionados = (state, action) => {
  let state_copy = state;
  state_copy.displays_seleccionados = action.displays_seleccionados;
  return { ...state_copy };
};

const setUnidadesSeleccionadas = (state, action) => {
  let state_copy = state;
  state_copy.unidades_seleccionadas = action.unidades_seleccionadas;
  return { ...state_copy };
};

const setDatosUbicacionPicking = (state, action) => {
  let state_copy = { ...state };
  state_copy.datos_completos_ubicacion_picking =
    action.datos_completos_ubicacion_picking;
  return { ...state_copy };
};

const disable_total_picking = (state, action) => {
  let state_copy = { ...state };
  let radio_picking = action.radio_group_picking;
  if (radio_picking === "bulto") {
    state_copy.disable_total_bultos = false;
    state_copy.disable_total_displays = true;
    state_copy.disable_total_unidades = true;
  }
  if (radio_picking === "display") {
    state_copy.disable_total_bultos = true;
    state_copy.disable_total_displays = false;
    state_copy.disable_total_unidades = true;
  }
  if (radio_picking === "unidad") {
    state_copy.disable_total_bultos = true;
    state_copy.disable_total_displays = true;
    state_copy.disable_total_unidades = false;
  }
  return { ...state_copy };
};

const setSelectedRadioButtonPicking = (state, action) => {
  let state_copy = state;
  state_copy.radio_group_picking = action.selected_radio_picking;
  return { ...state_copy };
};

const setTipoPickingUbicacion = (state, action) => {
  let state_copy = { ...state };
  state_copy.tipo_picking = action.tipo_picking;
  return { ...state_copy };
};

// change input number
const onChangeHandlerInputNumber = (state, action) => {
  // funcion que maneja el selector de cantidades de bultos, displays y unidades
  // No deja que el numero en el selector sobrepase al máximo real
  // resetea a cero el selector target cuando se cambia de selector
  let state_copy = { ...state };
  let selector_id = action.event.target.id;
  let maximo_bultos = state_copy.total_bultos;
  let maximo_displays = state_copy.total_displays;
  let maximo_unidades = state_copy.total_unidades;
  let bultos_ingresados;
  let displays_ingresados;
  let unidades_ingresadas;
  if (selector_id === "cantidad-bultos") {
    bultos_ingresados = action.event.target.value;
    if (bultos_ingresados > maximo_bultos) {
      state_copy.bultos_seleccionados = maximo_bultos;
    } else {
      state_copy.bultos_seleccionados = bultos_ingresados;
    }
    state_copy.displays_seleccionados = 0;
    state_copy.unidades_seleccionadas = 0;
  }
  if (selector_id === "cantidad-display") {
    state_copy.bultos_seleccionados = 0;
    displays_ingresados = action.event.target.value;
    if (displays_ingresados > maximo_displays) {
      state_copy.displays_seleccionados = maximo_displays;
    } else {
      state_copy.displays_seleccionados = displays_ingresados;
    }
    state_copy.unidades_seleccionadas = 0;
  }
  if (selector_id === "cantidad-unidades") {
    state_copy.bultos_seleccionados = 0;
    state_copy.displays_seleccionados = 0;
    unidades_ingresadas = action.event.target.value;
    if (unidades_ingresadas > maximo_unidades) {
      state_copy.unidades_seleccionadas = maximo_unidades;
    } else {
      state_copy.unidades_seleccionadas = unidades_ingresadas;
    }
  }
  return { ...state_copy };
};

const updateRowUbicacionesPicking = (state, ubicacion_id) => {
  let copia_state = { ...state };
  let copia_ubicaciones = [...copia_state.ubicacionesPicking];
  copia_ubicaciones = copia_ubicaciones.map(u => {
    if (u.id === ubicacion_id) {
      let new_bultos = Math.floor(u.unidades_totales / u.unidades_por_bulto);
      u.bultos_totales =
        new_bultos && isFinite(new_bultos) ? new_bultos : "No corresponde";
      let new_displays = Math.floor(
        u.unidades_totales / u.unidades_por_display
      );
      u.displays_totales =
        new_displays && isFinite(new_displays)
          ? new_displays
          : "No corresponde";
    }
    return u;
  });
  copia_state.ubicacionesPicking = copia_ubicaciones;
  return { ...copia_state };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTENEDORES:
      return setContenedores(state, action);

    case actionTypes.SET_UBICACIONES_PICKING:
      return setUbicacionesPicking(state, action);

    case actionTypes.ADD_CONTENEDOR_A_LOTE_DE_SALIDA:
      return addContenedorALoteDeSalida(state, action);

    case actionTypes.ADD_BULTOS_A_LOTE_DE_SALIDA:
      return addBultosALoteDeSalida(state, action);

    case actionTypes.ADD_DISPLAYS_A_LOTE_DE_SALIDA:
      return addDisplaysALoteDeSalida(state, action);

    case actionTypes.ADD_UNIDADES_A_LOTE_DE_SALIDA:
      return addUnidadesALoteDeSalida(state, action);

    case actionTypes.REMOVE_CONTENEDOR_DE_LOTE_DE_SALIDA:
      return removeContenedorDeLoteDeSalida(state, action);

    case actionTypes.SET_MODAL_VISIBILITY:
      return setModalVisibility(state, action);

    case actionTypes.SET_CALLE_DE_SALIDA:
      return setCalleDeSalida(state, action);

    case actionTypes.RELOAD_TABLE:
      return reloadTable(state);

    case actionTypes.RELOAD_TABLE_PICKING:
      return reloadTablePicking(state);

    case actionTypes.RESET_FORM:
      return resetForm(state, action);

    case actionTypes.SET_SELECTED_TAB:
      return setSelectedTab(state, action);

    case actionTypes.SET_SE_PERDERAN_CONTENEDORES_SELECCIONADOS:
      return setSePerderanContenedoresSeleccionados(state, action);

    case actionTypes.SET_MAXIMOS_CANTIDAD_PICKING:
      return setMaximosCantidadPicking(state, action);

    case actionTypes.SET_BULTOS_SELECCIONADOS:
      return setBultosSeleccionados(state, action);

    case actionTypes.SET_DISPLAYS_SELECCIONADOS:
      return setDisplaysSeleccionados(state, action);

    case actionTypes.SET_UNIDADES_SELECCIONADAS:
      return setUnidadesSeleccionadas(state, action);

    case actionTypes.SET_SELECTED_RADIO_BUTTON_PICKING:
      return setSelectedRadioButtonPicking(state, action);

    case actionTypes.RESET_SELECTED_CANTIDAD_PICKING:
      return resetSelectorCantidadPicking(state, action);

    case actionTypes.DISABLE_TOTAL_PICKING:
      return disable_total_picking(state, action);

    case actionTypes.SET_CONTENEDORES_UBICACIONES_PICKING:
      return setContenedoresUbicacionesPicking(state, action);

    case actionTypes.SET_TIPO_PICKING_UBICACION:
      return setTipoPickingUbicacion(state, action);

    case actionTypes.ADD_PICKING_A_LOTE_DE_SALIDA:
      return addPickingALoteDeSalida(state, action);

    case actionTypes.SET_DATOS_UBICACION_PICKING:
      return setDatosUbicacionPicking(state, action);

    case actionTypes.REMOVE_UBICACION_LOTE_SALIDA:
      return removeUbicacionLoteSalida(state, action);

    case actionTypes.RESTAR_BULTOS_DE_PICKING:
      return restarBultosDePicking(state, action);

    case actionTypes.RESTAR_DISPLAYS_DE_PICKING:
      return restarDisplaysDePicking(state, action);

    case actionTypes.RESTAR_UNIDADES_DE_PICKING:
      return restarUnidadesDePicking(state, action);

    case actionTypes.ON_CHANGE_HANDLER_INPUT_NUMBER:
      return onChangeHandlerInputNumber(state, action);

    default:
      return state;
  }
};

export default reducer;
