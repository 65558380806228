import * as actionTypes from "./actionTypes";

export const setDatosPedido = datos_pedido => {
  return {
    type: actionTypes.SET_DATOS_PEDIDO,
    datos_pedido: datos_pedido
  };
};

export const setModalPedidoVisibility = status => {
  return {
    type: actionTypes.SET_MODAL_PEDIDO_VISIBILITY,
    status: status
  };
};

export const setConfirmEliminarPalletPedido = status => {
  return {
    type: actionTypes.SET_CONFIRM_ELIMINAR_PALLET_PEDIDO,
    status: status
  };
};
