import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { withStyles } from "@material-ui/styles";
import moment from "moment";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import { StateContenedores } from "./VariablesState";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditContenedor extends Component {
  state = JSON.parse(JSON.stringify(StateContenedores));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  getContenedorEdit = id => {
    Database.get("/list-contenedor/" + id, this).then(
      resultado => {
        let objeto = resultado.result[0][0] || null;
        // console.log(objeto);
        // console.log(objeto.id);
        if (objeto) {
          let orderFormCopy = { ...this.state.orderForm };

          orderFormCopy.id.value = objeto.id;
          orderFormCopy.codigo.value = objeto.codigo;
          orderFormCopy.descripcion.value = objeto.descripcion;
          orderFormCopy.bultos.value = objeto.cantidad_bultos || 0;
          orderFormCopy.lote.value = objeto.lote;
          orderFormCopy.unidades.value = objeto.cantidad_unidades;
          //  orderFormCopy.vencimiento.value = objeto.vencimiento;

          for (let key in orderFormCopy) {
            orderFormCopy[key].touched = true;
            orderFormCopy[key].valid = true;
          }
          //  this.getContenedoresType("edit", orderFormCopy);

          if (objeto.vencimiento) {
            let fechaString = moment
              .utc(objeto.vencimiento)
              .format("YYYY-MM-DD");
            let fecha_actual = moment(fechaString).format();
            objeto.vencimiento = fecha_actual;
          }

          let { orderForm, formIsValid } = inputAllChangedHandler(
            orderFormCopy
          );

          this.setState({
            vencimiento: objeto.vencimiento,
            orderForm: orderForm,
            formIsValid: formIsValid
          });
        }
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitForm = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }

      // console.log(objeto);

      this.setState({
        disableAllButtons: true
      });

      if (this.state.vencimiento && this.state.vencimiento != "Invalid date") {
        /* let fechaString = moment(this.state.vencimiento).format("YYYY-MM-DD");
        let fecha_utc = moment.utc(fechaString).format();
        objeto["vencimiento"] =  fecha_utc;*/
        objeto["vencimiento"] = moment(this.state.vencimiento).isValid()
          ? moment.parseZone(this.state.vencimiento).format("YYYY-MM-DD")
          : "";
      }

      //  console.log(objeto);

      Database.post(`/update-contenedor`, {
        id_contenedor: this.props.match.params.idcontenedor,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              /*console.log(res);
            if(res.result[0].resultado == -1)
            toast.error("Alguna de las calles ya fueron asignadas a otro contenedor. Verifique los valores ingresados.");
          else*/
              toast.success("El contenedor se ha modificado con exito!");
              this.props.getContenedoresAdmin();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
    } else {
      toast.error("verificar los campos en el formulario");
    }
  };

  handleVencimiento = date => {
    console.log(date);
    console.log(moment(date).format("d/MM/yyyy"));
    console.log(moment(date).format("dd/MM/yyyy"));
    console.log(moment(date).format("D/MM/yyyy"));
    console.log(moment(date).format("yyyy-MM-DD 00:00:00"));

    this.setState({
      vencimiento: date
    });
  };

  componentDidMount() {
    // this.getContenedoresType();
    this.getContenedorEdit(this.props.match.params.idcontenedor);

    console.log(this.state.vencimiento);
  }

  render() {
    const formElementsArray = [];

    //console.log(this.state.orderForm);
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitForm(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Editar Pallet</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del pallet
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <InputNew
                  key={"input-" + formElement.id}
                  objeto={formElement.config}
                  changed={event => {
                    let { orderForm, formIsValid } = inputChangedHandler(
                      event,
                      formElement.id,
                      this.state.orderForm
                    );
                    this.setState({
                      orderForm: orderForm,
                      formIsValid: formIsValid
                    });
                  }}
                />
              ))}
            </div>
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechanacimiento"
                  label="Fecha de vencimiento"
                  format="dd/MM/yyyy"
                  value={this.state.vencimiento}
                  onChange={this.handleVencimiento}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/ajustes")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    ];
  }
}

export default withRouter(withStyles(styles)(EditContenedor));
