// imports React
import React from "react";
// Card
import Card from "components/Card/Card.js";
// Grid, button & Icon
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FaWarehouse } from "react-icons/fa";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// componentes
import RadioGroupPicking from "../RadioGroup/RadioGroupPicking";
import SelectorCantidadPicking from "../SelectorCantidadPicking/SelectorCantidadPicking.js";
// Componentes material
import Typography from "@material-ui/core/Typography";
// import Card from '@material-ui/core/Card';
// Styles
import { withStyles } from "@material-ui/styles";
// Confirm Dialog
import ConfirmPickingDialog from "./PickingConfirm";
import { toast, Zoom } from "react-toastify";

const classes = {
  card: {
    color: "black",
    margin: "2em",
    marginBottom: "20px",
    paddingLeft: "2em",
    paddingRight: "1em",
    paddingBottom: "1em",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    width: "75%",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },

  titulo: {
    textAlign: "center",
    marginBottom: "30px",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
  }
};

const CardPicking = props => {
  const agregarALoteDeSalidaPicking = () => {
    let res = {};
    // let tipo_picking = null;
    let referencia_seleccionada = props.radio_group_picking;
    var cantidad = 0;
    let contenedores = [];
    var ubicacion_id = props.datos_completos_ubicacion_picking.id;

    if (referencia_seleccionada === "bulto") {
      cantidad = props.bultos_seleccionados;

      let unidades_por_bulto =
        props.datos_completos_ubicacion_picking.unidades_por_bulto;
      let unidades_a_restar = unidades_por_bulto * cantidad;
      let unidades_resultantes =
        props.datos_completos_ubicacion_picking.unidades_totales -
        unidades_a_restar;
      let displays_resultantes =
        props.datos_completos_ubicacion_picking.displays_totales !==
        "No corresponde"
          ? parseInt(
              unidades_resultantes /
                props.datos_completos_ubicacion_picking.unidades_por_display
            )
          : props.datos_completos_ubicacion_picking.displays_totales;
      let displays_a_restar =
        props.datos_completos_ubicacion_picking.displays_totales -
        displays_resultantes;
      let bultos_resultantes =
        props.datos_completos_ubicacion_picking.bultos_totales !==
        "No corresponde"
          ? props.datos_completos_ubicacion_picking.bultos_totales - cantidad
          : props.datos_completos_ubicacion_picking.bultos_totales;

      props.restarBultosDePicking(ubicacion_id, cantidad);
      if (props.datos_completos_ubicacion_picking.displays_totales === 0) {
        props.restarUnidadesDePicking(ubicacion_id, unidades_a_restar);
      } else {
        props.restarUnidadesDePicking(ubicacion_id, unidades_a_restar);
        props.restarDisplaysDePicking(ubicacion_id, displays_a_restar);
      }
      props.setMaximosCantidadPicking(
        bultos_resultantes,
        displays_resultantes,
        unidades_resultantes
      );
    }

    if (referencia_seleccionada === "display") {
      cantidad = props.displays_seleccionados;
      let unidades_por_display =
        props.datos_completos_ubicacion_picking.unidades_por_display;
      let unidades_por_bulto =
        props.datos_completos_ubicacion_picking.unidades_por_bulto;
      let unidades_a_restar = unidades_por_display * cantidad;
      let unidades_resultantes =
        props.datos_completos_ubicacion_picking.unidades_totales -
        unidades_a_restar;

      let bultos_resultantes =
        props.datos_completos_ubicacion_picking.bultos_totales !==
        "No corresponde"
          ? parseInt(unidades_resultantes / unidades_por_bulto)
          : props.datos_completos_ubicacion_picking.bultos_totales;
      let bultos_a_restar =
        props.datos_completos_ubicacion_picking.bultos_totales -
        bultos_resultantes;

      let displays_resultantes =
        props.datos_completos_ubicacion_picking.displays_totales !==
        "No corresponde"
          ? props.datos_completos_ubicacion_picking.displays_totales - cantidad
          : props.datos_completos_ubicacion_picking.displays_totales;
      props.restarDisplaysDePicking(
        props.datos_completos_ubicacion_picking.id,
        cantidad
      );

      if (props.datos_completos_ubicacion_picking.bultos_totales === 0) {
        props.restarUnidadesDePicking(ubicacion_id, unidades_a_restar);
      } else {
        props.restarUnidadesDePicking(ubicacion_id, unidades_a_restar);
        props.restarBultosDePicking(ubicacion_id, bultos_a_restar);
      }
      props.setMaximosCantidadPicking(
        bultos_resultantes,
        displays_resultantes,
        unidades_resultantes
      );
    }

    if (referencia_seleccionada === "unidad") {
      cantidad = props.unidades_seleccionadas;
      let unidades_por_display =
        props.datos_completos_ubicacion_picking.unidades_por_display;
      let unidades_por_bulto =
        props.datos_completos_ubicacion_picking.unidades_por_bulto;
      let unidades_resultantes =
        props.datos_completos_ubicacion_picking.unidades_totales - cantidad;
      props.restarUnidadesDePicking(ubicacion_id, cantidad);

      let bultos_resultantes =
        props.datos_completos_ubicacion_picking.bultos_totales !==
        "No corresponde"
          ? Math.floor(unidades_resultantes / unidades_por_bulto)
          : props.datos_completos_ubicacion_picking.bultos_totales;
      if (bultos_resultantes !== "No corresponde" && bultos_resultantes >= 0) {
        props.restarBultosDePicking(
          ubicacion_id,
          props.datos_completos_ubicacion_picking.bultos_totales -
            bultos_resultantes
        );
      }

      let displays_resultantes =
        props.datos_completos_ubicacion_picking.displays_totales !==
        "No corresponde"
          ? Math.floor(unidades_resultantes / unidades_por_display)
          : props.datos_completos_ubicacion_picking.displays_resultantes;
      if (
        displays_resultantes !== "No corresponde" &&
        displays_resultantes >= 0
      ) {
        props.restarDisplaysDePicking(
          ubicacion_id,
          props.datos_completos_ubicacion_picking.displays_totales -
            displays_resultantes
        );
      }
      props.setMaximosCantidadPicking(
        bultos_resultantes,
        displays_resultantes,
        unidades_resultantes
      );
    }

    console.log(props.datos_completos_ubicacion_picking);

    contenedores = props.contenedores_ubicacion_picking;
    res.referencia_seleccionada = referencia_seleccionada;
    res.unidades_por_bulto =
      props.datos_completos_ubicacion_picking.unidades_por_bulto;
    res.unidades_por_display =
      props.datos_completos_ubicacion_picking.unidades_por_display;
    res.cantidad = cantidad;
    res.contenedores = contenedores;
    res.tipo_picking = props.tipo_picking;
    res.id_ubicacion = props.datos_completos_ubicacion_picking.id;
    res.nombre_producto_unidad =
      props.datos_completos_ubicacion_picking.nombre_producto_unidad;
    res.nombre_ubicacion =
      props.datos_completos_ubicacion_picking.nombre_ubicacion;
    res.id_cliente = props.datos_completos_ubicacion_picking.id_cliente;

    res.producto = props.datos_completos_ubicacion_picking.producto;
    res.ean13 = props.datos_completos_ubicacion_picking.ean13;
    res.codigo = props.datos_completos_ubicacion_picking.codigo;

    props.addPickingALoteDeSalida(res);
    toast.info("Agregado al lote!", {
      position: "top-right",
      transition: Zoom
    });
    props.resetSelectorCantidadPicking();
  };

  let desabilitado = false;

  if (
    !props.bultos_seleccionados &&
    !props.displays_seleccionados &&
    !props.unidades_seleccionadas
  ) {
    desabilitado = true;
  }

  props.disable_total_picking(props.radio_group_picking);
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Card className={props.classes.card}>
        <Typography className={props.classes.titulo}>
          Seleccione Nivel de Referencia Para Retirar A Calle de Salida
        </Typography>
        <RadioGroupPicking></RadioGroupPicking>
        <SelectorCantidadPicking></SelectorCantidadPicking>
        <Button
          onClick={() => agregarALoteDeSalidaPicking()}
          color="primary"
          disabled={desabilitado}
          variant="contained"
        >
          Agregar al lote de salida{" "}
          <FaWarehouse style={{ marginLeft: "10px" }} />
        </Button>
      </Card>
      <ConfirmPickingDialog />
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    hasSelectedClient: state.pedidossalidas.hasSelectedClient,
    idSelectedClient: state.pedidossalidas.idSelectedClient,
    openBuscarClienteDialog: state.pedidossalidas.openBuscarClienteDialog,
    pickingEnLoteDeSalida: state.salidas.pickingEnLoteDeSalida,
    radio_group_picking: state.salidas.radio_group_picking,
    selectedCliente: state.pedidossalidas.selectedCliente,
    bultos_seleccionados: state.salidas.bultos_seleccionados,
    displays_seleccionados: state.salidas.displays_seleccionados,
    unidades_seleccionadas: state.salidas.unidades_seleccionadas,
    contenedores_ubicacion_picking:
      state.salidas.contenedores_ubicacion_picking,
    tipo_picking: state.salidas.tipo_picking,
    lote_salida_picking: state.salidas.lote_salida_picking,
    datos_completos_ubicacion_picking:
      state.salidas.datos_completos_ubicacion_picking
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addPickingALoteDeSalida: lote_salida_picking =>
      dispatch(actionCreators.addPickingALoteDeSalida(lote_salida_picking)),
    addBultosALoteDeSalida: bultos =>
      dispatch(actionCreators.addBultosALoteDeSalida(bultos)),
    addDisplaysALoteDeSalida: displays =>
      dispatch(actionCreators.addDisplaysALoteDeSalida(displays)),
    addUnidadesALoteDeSalida: unidades =>
      dispatch(actionCreators.addUnidadesALoteDeSalida(unidades)),
    disable_total_picking: radio_group_picking =>
      dispatch(actionCreators.disable_total_picking(radio_group_picking)),
    handleOpenBuscarClienteDialog: () =>
      dispatch(actionCreators.handleOpenBuscarClienteDialog()),
    handleCloseClienteDialog: () =>
      dispatch(actionCreators.handleCloseClienteDialog()),
    handleAcceptModalCliente: cliente =>
      dispatch(actionCreators.handleAcceptModalCliente(cliente)),
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal)),
    setMaximosCantidadPicking: (total_bultos, total_displays, total_unidades) =>
      dispatch(
        actionCreators.setMaximosCantidadPicking(
          total_bultos,
          total_displays,
          total_unidades
        )
      ),
    restarBultosDePicking: (id_ubicacion, n_bultos) =>
      dispatch(actionCreators.restarBultosDePicking(id_ubicacion, n_bultos)),
    restarUnidadesDePicking: (id_ubicacion, n_unidades) =>
      dispatch(
        actionCreators.restarUnidadesDePicking(id_ubicacion, n_unidades)
      ),
    restarDisplaysDePicking: (id_ubicacion, n_displays) =>
      dispatch(
        actionCreators.restarDisplaysDePicking(id_ubicacion, n_displays)
      ),
    resetSelectorCantidadPicking: () =>
      dispatch(actionCreators.resetSelectorCantidadPicking())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(CardPicking));
