import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
  StateNewPedido,
  ColumnsListado,
  ColumnsListadoProductos
} from "../VariablesState";

import MaterialTable from "material-table";
import { localization } from "variables/general";
import Paper from "@material-ui/core/Paper";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
//import ModalNuevaUnidad from "./ModalNuevaUnidad.js";
//import ModalNuevaPresentacion from "./ModalNuevaPresentacion.js";
import ModalSeleccionarCliente from "./ModalSeleccionarCliente.js";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import StepAgregarProducto from "./StepAgregarProducto";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewPedido extends Component {
  state = {
    ...JSON.parse(JSON.stringify(StateNewPedido)),
    submitting: false
  };
  muelles = [];

  handleSubmitNewPedido(event) {
    event.preventDefault();

    if (!this.state.infoCliente || this.state.detallepedidos.length === 0) {
      const errorMessage = !this.state.infoCliente
        ? "Por favor, seleccione un cliente antes de continuar."
        : this.state.detallepedidos.length === 0
        ? "Por favor, añada al menos un producto al pedido."
        : null;

      toast.error(errorMessage);
      this.setState({ submitting: false });
      return;
    }
    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }

    this.setState({ submitting: true });

    objeto.cliente = this.state.infoCliente;
    objeto.detallepedidos = this.state.detallepedidos;

    console.log(objeto.cliente);
    console.log(objeto.detallepedidos);
    console.log(objeto);

    Database.post("/insert-pedido/", objeto, this).then(
      res => {
        toast.success("El pedido se registro con exito");
        this.setState({
          ...JSON.parse(JSON.stringify(StateNewPedido)),
          submitting: false
        });

        // this.props.getItems();
        this.props.getPedidosAdmin();
      },
      err => {
        toast.error(err.message);
        this.setState({ submitting: false });
      }
    );
  }

  getMuellesDevolucion = () => {
    Database.get(
      "/list-muelles-devolucion/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.orderForm };
        let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle));
        id_muelle.elementConfig.options = a;
        formulario.id_muelle = id_muelle;

        this.setState({
          orderForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  afterProductoPresentacionSelect = id => {
    Database.get("/list-producto-presentacion/" + id, this).then(
      res => {
        let resultado = res.result;
        this.setState({
          rowProductoPresentacion: resultado,
          openDialogProducto: true
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  afterProductoUnidadSelect = id => {
    Database.get("/list-producto-unidad/" + id, this).then(
      res => {
        let resultado = res.result;
        console.log(resultado);
        this.setState({
          infoProductoUnidad: resultado
          //   openDialogPresentacion: true
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    console.log(this.state);
  }

  closeDialog() {
    this.setState({ openDialogProducto: false, openDialogCliente: false });
  }

  onClickProducto = (rowData, objeto) => {
    console.log("NewPedido onClickProducto");
    console.log(rowData);
    console.log(objeto);
  
    let detallepedidos = [...this.state.detallepedidos];
  
    const existingProducto = detallepedidos.find(
      item => item.id_producto_presentacion === rowData.id
    );
  
    if (existingProducto) {
      toast.error("El producto ya ha sido añadido al pedido.");
      return;
    }
  
    let item = {};
    for (let key in objeto) {
      item[key] = objeto[key];
    }
    
    item.id_producto_unidad = rowData.id_producto_unidad;
    item.id_producto_presentacion = rowData.id;
    // if (item.cantidad_bultos && rowData.cantidad_unidades_bulto)
    //   item.cantidad_unidades = parseInt(item.cantidad_bultos) * parseInt(rowData.cantidad_unidades_bulto);
  
    item.presentacion = { ...rowData };
    
    console.log(item);
  
    detallepedidos.push(item);
  
    this.setState({
      openDialogProducto: false,
      rowEditProducto: null,
      detallepedidos: detallepedidos
    });
  };
  

  onClickItemCliente = rowData => {
    this.closeDialog();
    if (this.state.infoCliente && this.state.infoCliente.id == rowData.id) {
    } else {
      this.setState({
        infoCliente: rowData,
        detallepedidos: []
      });
    }
  };

  deleteProducto = rowData => {
    let detallepedidosant = [...this.state.detallepedidos];
    detallepedidosant.splice(detallepedidosant.indexOf(rowData), 1);
    this.setState({
      detallepedidos: detallepedidosant
    });
  };

  // checkValidity = (value, rules) => {
  //   let isValid = true;
  //   let textValid = null;

  //   if (rules.required && isValid) {
  //     isValid = value.toString().trim() !== '';
  //     textValid = 'El campo es requerido'
  //   }

  //   if (rules.minLength && isValid) {
  //     isValid = value.length >= rules.minLength;
  //     textValid = 'La cantidad de caracteres minimos es ' + rules.minLength
  //   }

  //   if (rules.maxLength && isValid) {
  //     isValid = value.length <= rules.maxLength;
  //     textValid = 'Supera el maximo de caracteres';
  //   }

  //   return { isValid: isValid, textValid: textValid };
  // }

  inputChangedHandler = (event, inputIdentifier, newValue) => {
    // let checkValid;
    const updatedOrderForm = {
      ...this.state.orderForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    // checkValid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = true; //checkValid.isValid;
    updatedFormElement.textValid = true; //checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      orderForm: updatedOrderForm,
      formIsValid: formIsValidAlt
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }
    return [
      <form
        onSubmit={event => {
          this.handleSubmitNewPedido(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Nuevo Pedido</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario de alta de Pedido
            </p>
          </CardHeader>
          <CardBody>
            {/* <InputNew
              key={"newdevolucion"}
              objeto={this.state.orderForm.devolucion}
             
              changed={(event) => {
                let { orderForm, formIsValid } = inputChangedHandler(event, "devolucion", this.state.orderForm);
                this.setState({ orderForm: orderForm, formIsValid: formIsValid });
             //   this.cargarMuelles(event);
              }}
            />

            <InputNew
              key={"newpedidoreferencia"}
              objeto={this.state.orderForm.referencia}
              changed={(event) => {
                let { orderForm, formIsValid } = inputChangedHandler(event, "referencia", this.state.orderForm);
                this.setState({ orderForm: orderForm, formIsValid: formIsValid })
              }}
            />

            <InputNew
              key={"muellepedido-"}
              objeto={this.state.orderForm.id_muelle}
              changed={(event) => {
                let { orderForm, formIsValid } = inputChangedHandler(event, "id_muelle", this.state.orderForm);
                this.setState({ orderForm: orderForm, formIsValid: formIsValid })
              }}
            />

            <InputNew
              key={"comentario"}
              objeto={this.state.orderForm.comentario}
              changed={(event) => {
                let { orderForm, formIsValid } = inputChangedHandler(event, "comentario", this.state.orderForm);
                this.setState({ orderForm: orderForm, formIsValid: formIsValid })
              }}
            /> */}

            <div>
              {formElementsArray.map(formElement => (
                <Input
                  key={"newPedido-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event, newValue) =>
                    this.inputChangedHandler(event, formElement.id, newValue)
                  }
                />
              ))}

              <Button
                variant="contained"
                style={{ marginTop: "25px" }}
                color="primary"
                onClick={() => this.setState({ openDialogCliente: true })}
              >
                Seleccionar Cliente
              </Button>

              {this.state.infoCliente && (
                <p>
                  Nombre:{" "}
                  <strong>{this.state.infoCliente.nombre_fantasia}</strong>{" "}
                  <br />
                  Razón Social:{" "}
                  <strong>{this.state.infoCliente.razon_social}</strong> <br />
                  Número de Ciente: {this.state.infoCliente.numero_cliente}{" "}
                  <br />
                  RUC: {this.state.infoCliente.ruc}
                </p>
              )}
            </div>

            <div>
              <Button
                variant="contained"
                style={{ marginTop: "25px" }}
                color="primary"
                disabled={!this.state.infoCliente}
                onClick={() =>
                  this.setState({
                    openDialogProducto: true,
                    rowProductoPresentacion: null
                  })
                }
              >
                Seleccionar Dato Logístico
              </Button>
            </div>
            <br></br>
            <br></br>

            <Card>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Detalle Pedido
                </h4>
                <p className={this.props.classes.cardCategoryWhite}>
                  Detalle de los productos solicitados
                </p>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  columns={ColumnsListadoProductos}
                  data={this.state.detallepedidos}
                  title=""
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Eliminar Producto",
                      onClick: (event, rowData) => this.deleteProducto(rowData)
                    }
                  ]}
                  localization={localization}
                  components={{
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  options={{
                    //filtering: true,
                    actionsColumnIndex: -1
                  }}
                  detailPanel={rowData => {
                    return (
                      <div>
                        <p style={{ fontSize: "115%" }}>
                          Información adicional Dato Logístico
                        </p>
                        <p>
                          Unidades x bulto:{" "}
                          {rowData.presentacion.cantidad_unidades_bulto} <br />
                          Unidades x display:{" "}
                          {rowData.presentacion.cantidad_unidades_display}{" "}
                          <br />
                          displays x bulto:{" "}
                          {rowData.presentacion.cantidad_dispplays_bulto} <br />
                          <br />
                          Alto: {rowData.presentacion.alto_bulto} (cm), Largo:{" "}
                          {rowData.presentacion.largo_bulto} (cm), Ancho:{" "}
                          {rowData.presentacion.ancho_bulto} (cm)
                        </p>
                      </div>
                    );
                  }}
                />
              </CardBody>
            </Card>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.goBack()}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={
                this.state
                  .submitting /*!this.state.formIsValid || this.state.disableAllButtons || this.state.detallepedidos.length == 0 || !this.state.infoCliente*/
              }
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,
      <Dialog
        open={this.state.openDialogProducto}
        onClose={this.closeDialog.bind(this)}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          Seleccionar Dato Logístico
          <IconButton
            aria-label="close"
            className={this.props.classes.closeButton}
            onClick={this.closeDialog.bind(this)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {
          <DialogContent>
            {this.state.openDialogProducto && (
              <StepAgregarProducto
                infoCliente={this.state.infoCliente}
                rowEditProducto={this.state.rowEditProducto}
                ColumnsListado={ColumnsListado}
                onClickProducto={(id, cantidad, bultos, lote, vencimiento) =>
                  this.onClickProducto(id, cantidad, bultos, lote, vencimiento)
                }
                rowProductoPresentacion={this.state.rowProductoPresentacion}
              />
            )}
          </DialogContent>
        }
      </Dialog>,

      <div>
        {this.state.openDialogCliente && (
          <ModalSeleccionarCliente
            onClick={this.onClickItemCliente}
            openDialog={this.state.openDialogCliente}
            closeDialog={this.closeDialog.bind(this)}
          />
        )}
      </div>
    ];
  }
}

export default withRouter(withStyles(styles)(NewPedido));
