import React from "react";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";

import Save from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";

import Grid from "@material-ui/core/Grid";

// src/views/Productos/components/NewProductoPresentacion.js
// src/components/Card/Card.js

// const styles = {
//   cardCategoryWhite: {
//     "&,& a,& a:hover,& a:focus": {
//       color: "rgba(255,255,255,.62)",
//       margin: "0",
//       fontSize: "14px",
//       marginTop: "0",
//       marginBottom: "0"
//     },
//     "& a,& a:hover,& a:focus": {
//       color: "#FFFFFF"
//     }
//   },
//   cardTitleWhite: {
//     color: "#FFFFFF",
//     marginTop: "0px",
//     minHeight: "auto",
//     fontWeight: "300",
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     marginBottom: "3px",
//     textDecoration: "none",
//     "& small": {
//       color: "#777",
//       fontSize: "65%",
//       fontWeight: "400",
//       lineHeight: "1"
//     }
//   }
// };

const FormEdicionPresentacion = props => {
  let informacionDisplay = null;
  // let informacionBulto = null;

  // let estiloRenglon = {
  //   fontSize: "100%",
  //   fontWeight: "400"
  // };

  //let formDisplay = props.estado.orderForm2;
  /*
    if (props.estado.orderForm.display.value) {
        informacionDisplay = (
            <div style={{marginTop:'2em'}}> 
                <span style={{ fontSize: '120%', fontWeight: '600' }}>
                Información sobre display
                </span><br/>
                <span style={estiloRenglon}>
                    Cantidad de unidades: {formDisplay.cantidad_display.value}
                </span>
            </div>
        )
    } else {
        // console.log('no tiene')
    }
    */
  // console.log(props.estado.orderForm.display.value);
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={props.clases.cardTitleWhite}>Edición de presentación</h4>
        <p className={props.clases.cardCategoryWhite}>
          Formulario de edición de presentación
        </p>
      </CardHeader>
      <CardBody>
        <Grid item md={12}>
          <Alert severity="warning">
            Solo algunos campos pueden ser modificados. Si necesita modificar
            otro campo debe crear una nueva presentación
          </Alert>
        </Grid>
        <Grid item md={12}>
          <span
            style={{ fontSize: "100%", fontWeight: "600", marginTop: "50px" }}
          >
            {/* Producto: {props.parametrosRoute} */}
          </span>
        </Grid>
        <div className="mt-3 mb-3">
          <Grid item md={12}>
            {props.inputDescripcion}
          </Grid>
          <Grid item md={12}>
            {informacionDisplay}
          </Grid>
        </div>
        <Button
          style={{ marginTop: "25px" }}
          color="info"
          onClick={() => props.historia.goBack()}
        >
          <ArrowBack />
          Volver
        </Button>
        <Button
          style={{ marginTop: "25px" }}
          color="primary"
          variant="contained"
          disabled={!props.valido || props.estado.disableAllButtons}
          type="submit"
        >
          <Save /> Guardar
        </Button>
      </CardBody>
    </Card>
  );
};

export default FormEdicionPresentacion;
