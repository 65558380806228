// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  'Lines From Great Russian Literature? Or E-mails From My Boss?',
  'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
  'Create 4 Invisible User Experiences you Never Knew About',
]
var website = [
  'Modificar estructura de Rack 3 en calle 2, columna 1',
  'Eliminar usuario Manuel Rivas',
]
var server = [
  'Ingreso 142 retrasado',
  'Operadores activos 3',
  'Operador Matias Azul sin reportes en el día',
]

var localization = {
  body: {
    addTooltip: 'Agregar',
    editTooltip: 'Editar',
    deleteTooltip: 'Borrar fila',
    emptyDataSourceMessage: 'No se registran datos cargados',
    editRow: {
      deleteText: '¿Querés borrar esta fila?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Guardar',
    },
    filterRow: { filterTooltip: 'Filtrar' },
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    nRowsSelected: '',
    exportTitle: 'Exportar',
    exportAriaLabel: 'Exportar',
    // exportName: 'Exportar como',
  },
  pagination: {
    labelRowsSelect: 'Filas',
    labelDisplayedRows: ' {from}-{to} de {count}',
    firstTooltip: 'Primer página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Página siguiente',
    lastTooltip: 'Última página',
  },
  header: { actions: '' },
}

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server,
  localization,
}
