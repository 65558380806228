export const StateListSanciones = {
  sanciones: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditSancion = {
  idEmpleado: null,
  nombreEmpleado: null,
  editSancionForm: {
    // empleado: {
    //     elementType: 'select',
    //     elementConfig: {
    //       label: 'Empleado',
    //       options: [

    //       ],
    //       fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //       required: true
    //     },

    //     valid: false,
    //     touched: false
    //   },
    tipo: {
      elementType: "select",
      elementConfig: {
        label: "Tipo de Sancion",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    causa: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Causa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    observaciones: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Observaciones",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  fechaDesde: null,
  fechaHasta: null,
  sancionEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewSancion = {
  idEmpleado: null,
  nombreEmpleado: null,
  newSancionForm: {
    // empleado: {
    //     elementType: 'select',
    //     elementConfig: {
    //       label: 'Empleado',
    //       options: [

    //       ],
    //       fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //       required: true
    //     },

    //     valid: false,
    //     touched: false
    //   },
    tipo: {
      elementType: "select",
      elementConfig: {
        label: "Tipo de Sanción",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    causa: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Causa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    observaciones: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Observaciones",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  fechaDesde: null,
  fechaHasta: null,
  formIsValid: false,
  successSubmit: null,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Apellido", field: "apellido" },
  { title: "Nombre", field: "nombre" },
  { title: "Tipo", field: "sancion_tipo" },
  { title: "Fecha desde", field: "fecha_desde" },
  { title: "Fecha hasta", field: "fecha_hasta" },
  { title: "Causa", field: "causa" },
  { title: "Observaciones", field: "observaciones" }
];
