// Prefijos (tipo namespaces) usados
// Evitar chocar aca - Preferiblemente agregar prefijos a los actiontypes - <No hace falta agregarlos
// a las funciones que ya estaban definidas aunque no estaria tampoco de mas hacerlo>
// No hace falta prefijar las funciones de los reducers ya que las obtenemos mediante los actiontypes
// ---------------
// Prefijos Usados
// ---------------
// IM -> Importacion Masiva
//
//
export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const ADD = "ADD";
export const SUBTRACT = "SUBTRACT";
export const STORE_RESULT = "STORE_RESULT";
export const DELETE_RESULT = "DELETE_RESULT";

///////////////////////////
//////////////////////////
//Insumos
///////////////////////////
//////////////////////////
export const GUARDA_LISTADO_INSUMOS = "GUARDA_LISTADO_INSUMOS";
///////////////////////////
//////////////////////////
//Mapa
///////////////////////////
//////////////////////////
export const ADD_BLOQUES_EN_MAPA = "ADD_BLOQUES_EN_MAPA";
export const ADD_BLOQUES_EN_TABLA = "ADD_BLOQUES_EN_TABLA";
export const REMOVER_BLOQUE_DE_TABLA = "REMOVER_BLOQUE_DE_TABLA";
export const REMOVER_BLOQUE_DE_MAPA = "REMOVER_BLOQUE_DE_MAPA";
export const ROTATE_ELEMENT_HANDLER = "ROTATE_ELEMENT_HANDLER";
export const DESELECT_ALL_BLOQUES = "DESELECT_ALL_BLOQUES";
export const DESELECT_ALL_SPATIAL_OBJECTS = "DESELECT_ALL_SPATIAL_OBJECTS";
export const TOGGLE_ALL_SPATIAL_OBJECTS = "TOGGLE_ALL_SPATIAL_OBJECTS";
export const HIDE_REGLA_HANDLER = "HIDE_REGLA_HANDLER";
export const FILL_CALLES = "FILL_CALLES";
export const FILL_CALLES_DE_SALIDA = "FILL_CALLES_DE_SALIDA";
export const FILL_MUELLES = "FILL_MUELLES";
export const ADD_MUELLES_EN_MAPA = "ADD_MUELLES_EN_MAPA";
export const ADD_MUELLES_EN_TABLA = "ADD_MUELLES_EN_TABLA";
export const DATA_IS_LOADED = "DATA_IS_LOADED";
export const SET_LOADED_STATUS = "SET_LOADED_STATUS";
//
// SelectorMapas
//
export const UPDATE_BLOQUES_EN_MAPA = "UPDATE_BLOQUES_EN_MAPA";
//
// Bloques
//
export const SET_POSITION_BLOQUE = "SET_POSITION_BLOQUE";
//
// Salidas
//
export const SET_CONTENEDORES = "SET_CONTENEDORES";
export const ADD_CONTENEDOR_A_LOTE_DE_SALIDA =
  "ADD_CONTENEDOR_A_LOTE_DE_SALIDA";
export const REMOVE_CONTENEDOR_DE_LOTE_DE_SALIDA =
  "REMOVE_CONTENEDOR_DE_LOTE_DE_SALIDA";
export const SET_MODAL_VISIBILITY = "SET_MODAL_VISIBILITY";
export const SET_CALLE_DE_SALIDA = "SET_CALLE_DE_SALIDA";
export const RELOAD_TABLE = "RELOAD_TABLE";
export const RESET_FORM = "RESET_FORM";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_CONTENEDORES_EN_CALLES_DE_SALIDA =
  "SET_CONTENEDORES_EN_CALLES_DE_SALIDA";
export const SET_SE_PERDERAN_CONTENEDORES_SELECCIONADOS =
  "SET_SE_PERDERAN_CONTENEDORES_SELECCIONADOS";
export const SET_UBICACIONES_PICKING = "SET_UBICACIONES_PICKING";
export const RELOAD_TABLE_PICKING = "RELOAD_TABLE_PICKING";
export const SET_MAXIMOS_CANTIDAD_PICKING = "SET_MAXIMOS_CANTIDAD_PICKING";
export const SET_BULTOS_SELECCIONADOS = "SET_BULTOS_SELECCIONADOS";
export const SET_DISPLAYS_SELECCIONADOS = "SET_DISPLAYS_SELECCIONADOS";
export const SET_UNIDADES_SELECCIONADAS = "SET_UNIDADES_SELECCIONADAS";
export const SET_SELECTED_RADIO_BUTTON_PICKING =
  "SET_SELECTED_RADIO_BUTTON_PICKING";
export const RESET_SELECTED_CANTIDAD_PICKING =
  "RESET_SELECTED_CANTIDAD_PICKING";
export const ADD_BULTOS_A_LOTE_DE_SALIDA = "ADD_BULTOS_A_LOTE_DE_SALIDA";
export const ADD_DISPLAYS_A_LOTE_DE_SALIDA = "ADD_DISPLAYS_A_LOTE_DE_SALIDA";
export const ADD_UNIDADES_A_LOTE_DE_SALIDA = "ADD_UNIDADES_A_LOTE_DE_SALIDA";
export const DISABLE_TOTAL_PICKING = "DISABLE_TOTAL_PICKING";
export const SET_CONTENEDORES_UBICACIONES_PICKING =
  "SET_CONTENEDORES_UBICACIONES_PICKING";
export const SET_TIPO_PICKING_UBICACION = "SET_TIPO_PICKING_UBICACION";
export const ADD_PICKING_A_LOTE_DE_SALIDA = "ADD_PICKING_A_LOTE_DE_SALIDA";
export const SET_DATOS_UBICACION_PICKING = "SET_DATOS_UBICACION_PICKING";
export const REMOVE_UBICACION_LOTE_SALIDA = "REMOVE_UBICACION_LOTE_SALIDA";
export const ON_CHANGE_HANDLER_INPUT_NUMBER = "ON_CHANGE_HANDLER_INPUT_NUMBER";
export const RESTAR_BULTOS_DE_PICKING = "RESTAR_BULTOS_DE_PICKING";
export const RESTAR_DISPLAYS_DE_PICKING = "RESTAR_DISPLAYS_DE_PICKING";
export const RESTAR_UNIDADES_DE_PICKING = "RESTAR_UNIDADES_DE_PICKING";
//
// PedidosSalidas
//
export const HANDLE_OPEN_CLIENTE_DIALOG = "HANDLE_OPEN_CLIENTE_DIALOG";
export const HANDLE_CLOSE_CLIENTE_DIALOG = "HANDLE_CLOSE_CLIENTE_DIALOG";
export const HANDLE_ACCEPT_MODAL_CLIENTE = "HANDLE_ACCEPT_MODAL_CLIENTE";
export const ONCHANGE_HANDLER_INPUT_TEXT = "ONCHANGE_HANDLER_INPUT_TEXT";
export const SET_SELECTED_RADIO_BUTTON = "SET_SELECTED_RADIO_BUTTON";
export const SET_SELECTED_CLIENT_TO_CERO = "SET_SELECTED_CLIENT_TO_CERO";
export const RESET_FORM_PEDIDO = "RESET_FORM_PEDIDO";
export const SET_PEDIDO_SELECTED = "SET_PEDIDO_SELECTED";
export const CHANGE_RADIO_BUTTON_PEDIDO = "CHANGE_RADIO_BUTTON_PEDIDO";
export const SET_TRUE_NUEVA_REFERENCIA = "SET_TRUE_NUEVA_REFERENCIA";
export const SET_FALSE_NUEVA_REFERENCIA = "SET_FALSE_NUEVA_REFERENCIA";
export const RESET_NUEVA_REFERENCIA = "RESET_NUEVA_REFERENCIA";
export const SET_DISABLE_INPUT_TEXT = "SET_DISABLE_INPUT_TEXT";
export const SET_BUSCAR_REFERENCIA = "SET_BUSCAR_REFERENCIA";
//
// Auditoria
//
export const SET_MOVIMIENTOS = "SET_MOVIMIENTOS";
export const SET_AUDITORIA_CONTENEDORES = "SET_AUDITORIA_CONTENEDORES";
export const SET_INFO_CONTENEDOR = "SET_INFO_CONTENEDOR";
export const SET_OPEN = "SET_OPEN";
export const SET_CLOSE = "SET_CLOSE";
//
// Movimientos
//
export const SET_DATOS_PEDIDO = "SET_DATOS_PEDIDO";
export const SET_MODAL_PEDIDO_VISIBILITY = "SET_MODAL_PEDIDO_VISIBILITY";
export const SET_CONFIRM_ELIMINAR_PALLET_PEDIDO =
  "SET_CONFIRM_ELIMINAR_PALLET_PEDIDO";
//
// Importacion Masiva
//
export const IM_ADD_CAMPO = "IM_ADD_CAMPO";
export const IM_REMOVE_CAMPO = "IM_REMOVE_CAMPO";
export const IM_SET_CAMPOS_HABILITADOS = "IM_SET_CAMPOS_HABILITADOS";
export const IM_CARGA_CSV = "IM_CARGA_CSV";
export const IM_TOUCH_CAMPOS_CARGADOS = "IM_TOUCH_CAMPOS_CARGADOS";
export const IM_TOGGLE_CONFIRM_DIALOG = "IM_TOGGLE_CONFIRM_DIALOG";
export const IM_TOUCH_RELOAD_TABLE = "IM_TOUCH_RELOAD_TABLE";
//
// Alertas
//
export const ALERTAS_SET_VENCIMIENTO = "ALERTAS_SET_VENCIMIENTO";
export const ALERTAS_SET_UMBRAL = "ALERTAS_SET_UMBRAL";
