import * as actionTypes from "./actionTypes";

export const addCampo = campo => {
  return {
    type: actionTypes.IM_ADD_CAMPO,
    campo: campo
  };
};

export const removeCampo = campo => {
  return {
    type: actionTypes.IM_REMOVE_CAMPO,
    campo: campo
  };
};

export const cargaCsv = csv => {
  return {
    type: actionTypes.IM_CARGA_CSV,
    csv: csv
  };
};

export const touch_reload_table = () => {
  return {
    type: actionTypes.IM_TOUCH_RELOAD_TABLE
  };
};

export const setCamposHabilitados = campos_habilitados => {
  return {
    type: actionTypes.IM_SET_CAMPOS_HABILITADOS,
    campos_habilitados: campos_habilitados
  };
};

export const touch_campos_cargados = () => {
  return {
    type: actionTypes.IM_TOUCH_CAMPOS_CARGADOS
  };
};

export const toggleConfirmDialog = (db_in = null) => {
  return {
    type: actionTypes.IM_TOGGLE_CONFIRM_DIALOG,
    db_in: db_in
  };
};
