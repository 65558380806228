import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { toast } from 'react-toastify'

class AddItems extends Component {
  state = {
    showDialog: false,
    selectedValue: 'IMEI',
    inputValue: '',
    addedItems: [],
    quantity: 0,
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Ejecuta la función que desees cuando se presione Enter.
      this.handleAddItem()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ addedItems: this.props.selectedRow.items }) // Reiniciar la lista al abrir el diálogo
    }
  }

  handleSelectChange = (event) => {
    this.setState({ selectedValue: event.target.value })
  }

  handleInputValueChange = (event) => {
    this.setState({ inputValue: event.target.value })
  }

  handleAddItem = () => {
    const newItem = {
      id: '',
      type: this.state.selectedValue,
      value: this.state.inputValue,
    }

    // Verificar que los valores no estén vacíos
    if (newItem.type.trim() === '' || newItem.value.trim() === '') {
      return // No hacer nada si alguno de los valores está vacío
    }

    const isDuplicate = this.state.addedItems.some(
      (item) => item.type === newItem.type && item.value === newItem.value
    )

    if (!isDuplicate) {
      // Encontrar el último ID en la lista actual de elementos agregados
      const lastItemId =
        this.state.addedItems.length > 0
          ? this.state.addedItems[this.state.addedItems.length - 1].id
          : 0

      // Asignar el próximo ID
      newItem.id = lastItemId + 1

      this.setState((prevState) => ({
        addedItems: [...prevState.addedItems, newItem],
        inputValue: '',
        quantity: this.state.addedItems.length + 1,
      }))
    }
  }

  handleFinishDialog = () => {
    // Copia el objeto selectedRow para evitar mutaciones directas
    const updatedSelectedRow = { ...this.props.selectedRow }

    // Reemplaza los items en selectedRow con los elementos en addedItems
    updatedSelectedRow.items = [...this.state.addedItems]
    updatedSelectedRow.quantity = this.state.addedItems.length

    if (
      updatedSelectedRow.quantity > updatedSelectedRow.cant_unidades_esperada
    ) {
      const fechaHora = new Date().toLocaleString()

      let messageIndex = updatedSelectedRow.messages.length // Inicializa el índice de mensajes

      const unidadesSobran =
        updatedSelectedRow.quantity - updatedSelectedRow.cant_unidades_esperada

      const newMessage = {
        id: ++messageIndex, // Asigna un nuevo ID de mensaje
        date: fechaHora,
        message: `Sobran ${unidadesSobran} items`,
      }

      toast.warning(
        'Sobran ' + unidadesSobran + ' items de ' + updatedSelectedRow.name
      )

      updatedSelectedRow.messages.push(newMessage)
      updatedSelectedRow.warning = 2
    }

    this.props.actualizarDatos(updatedSelectedRow)
    this.props.onClose() // Llamar a la función onClose del padre para cerrar el diálogo
    this.setState({ addedItems: [], inputValue: '', quantity: 0 }) // Reiniciar la lista al cerrar el diálogo
  }

  handleCloseDialog = () => {
    this.setState({ addedItems: [], inputValue: '', quantity: 0 }) // Reiniciar la lista al cerrar el diálogo
    this.props.onClose() // Llamar a la función onClose del padre para cerrar el diálogo
  }

  render() {
    const groupedItems = this.state.addedItems.reduce((groups, item) => {
      if (!groups[item.type]) {
        groups[item.type] = []
      }
      groups[item.type].push(item.value)
      return groups
    }, {})

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.handleCloseDialog}>
          <DialogTitle>Agregar Items</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccionar un tipo y luego ingrese el valor correspondiente.
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Select
                value={this.state.selectedValue}
                onChange={this.handleSelectChange}
                label='Tipo'
              >
                <MenuItem value='IMEI'>IMEI</MenuItem>
                <MenuItem value='Nro_serie'>Número de Serie</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label={
                this.state.selectedValue === 'IMEI' ? 'IMEI' : 'Número de Serie'
              }
              value={this.state.inputValue}
              onChange={this.handleInputValueChange}
              onKeyPress={this.handleKeyPress}
              fullWidth
            />
            <Button
              onClick={this.handleAddItem}
              variant='contained'
              color='primary'
              style={{ marginTop: '10px' }}
            >
              Agregar
            </Button>
            <div style={{ width: '400px' }}>
              <List>
                {Object.entries(groupedItems).map(([type, values], index) => (
                  <ListItem key={index}>
                    <ListItemText primary={`${type}: ${values.join(', ')}`} />
                  </ListItem>
                ))}
              </List>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              variant='contained'
              style={{
                backgroundColor: 'red',
                color: 'white',
                width: '33%',
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleFinishDialog}
              variant='contained'
              style={{
                backgroundColor: 'green',
                color: 'white',
                width: '33%',
              }}
            >
              Finalizar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AddItems
