import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";
import Files from "react-files";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import BackupIcon from "@material-ui/icons/Backup";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

import { StateEditEmpleado } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditEmpleado extends Component {
  state = JSON.parse(JSON.stringify(StateEditEmpleado));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  getEmpleadoEdit = id => {
    Database.get("/list-empleados/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          empleadoEdit: resultado.result[0]
        });

        let EditEmpleadoFormAlt = { ...this.state.EditEmpleadoForm };
        EditEmpleadoFormAlt.nombre.value = resultado.result[0].nombre;
        EditEmpleadoFormAlt.apellido.value = resultado.result[0].apellido;
        EditEmpleadoFormAlt.numero_legajo.value =
          resultado.result[0].numero_legajo;
        EditEmpleadoFormAlt.dni.value = resultado.result[0].dni;
        EditEmpleadoFormAlt.cuil.value = resultado.result[0].cuil;
        EditEmpleadoFormAlt.nro_tramite_dni.value =
          resultado.result[0].nro_tramite_dni;
        this.state.EditEmpleadoForm2.id_empresa_empleadora.value =
          resultado.result[0].id_empresa_empleadora;
        this.state.EditEmpleadoForm2.direccion_completa.value =
          resultado.result[0].direccion_completa;
        this.state.EditEmpleadoForm2.direccion_alternativa.value =
          resultado.result[0].direccion_alternativa;
        this.state.EditEmpleadoForm2.telefono_contacto.value =
          resultado.result[0].telefono_contacto;
        this.state.EditEmpleadoForm2.telefono_personal.value =
          resultado.result[0].telefono_personal;
        this.state.EditEmpleadoForm3.id_superior_jerarquico.value =
          resultado.result[0].id_superior_jerarquico;
        this.state.EditEmpleadoForm3.id_remera.value =
          resultado.result[0].id_remera;
        this.state.EditEmpleadoForm3.id_pantalon.value =
          resultado.result[0].id_pantalon;
        this.state.EditEmpleadoForm3.id_zapato.value =
          resultado.result[0].id_zapato;
        this.state.EditEmpleadoForm.id_estado_civil.value =
          resultado.result[0].id_estado_civil;
        this.state.EditEmpleadoForm.conyuge.value = resultado.result[0].conyuge;
        this.state.EditEmpleadoForm4.id_categoria_licencia.value =
          resultado.result[0].id_categoria_licencia;
        this.state.EditEmpleadoForm4.id_puesto.value =
          resultado.result[0].id_puesto;
        this.state.EditEmpleadoForm4.convenio_camioneros.value =
          resultado.result[0].convenio_camioneros;
        this.state.EditEmpleadoForm4.alta_dia.value =
          resultado.result[0].alta_dia;
        this.state.EditEmpleadoForm4.preocupacional_obser.value =
          resultado.result[0].preocupacional_obser;
        this.state.fechaNacimiento = resultado.result[0].fecha_nac;
        this.state.fechaVencimientoLinti = resultado.result[0].vto_linti;
        this.state.fechaVencimientoPsicofisico =
          resultado.result[0].vto_psicofisico;
        this.state.fechaVencimientoCurso = resultado.result[0].vto_curso;
        this.state.fechaVencimientoLibretaSanitaria =
          resultado.result[0].vto_libreta_sanitaria;
        this.state.fechaVencimientoLicencia =
          resultado.result[0].vto_licencia_municipal;
        this.state.fechaIngreso = resultado.result[0].fecha_ingreso;
        this.state.fechaPreocupacional =
          resultado.result[0].preocupacional_fecha;

        for (let key in EditEmpleadoFormAlt) {
          EditEmpleadoFormAlt[key].touched = true;
          EditEmpleadoFormAlt[key].valid = true;
        }

        this.setState({
          EditEmpleadoForm: EditEmpleadoFormAlt,
          url_foto: resultado.result[0].foto,
          url_dni: resultado.result[0].dni_foto,
          url_linti: resultado.result[0].vto_linti_foto,
          url_libreta_sanitaria: resultado.result[0].vto_libreta_sanitaria_foto,
          url_licencia: resultado.result[0].vto_licencia_municipal_foto,
          url_preocupacional: resultado.result[0].preocupacional_foto
        });
        // this.getClientesType("edit", EditEmpleadoFormAlt);
      } else {
        this.setState({
          empleadoEdit: null
        });
      }
    });
  };

  handleChangePass = event => {
    event.preventDefault();
    this.setState({
      openChangePass: false
    });

    Database.post(`/update-pass`, {
      id: this.props.match.params.idcliente,
      newpass: event.target.contrasenia.value
    }).then(
      res => {
        toast.success("El Empleado se ha modificado con exito!");
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitEditEmpleado = event => {
    event.preventDefault();
    console.log(this.state.EditEmpleadoForm2.id_empresa_empleadora.value);
    Database.post(`/update-empleado`, {
      id: this.props.match.params.id,
      nombre: this.state.EditEmpleadoForm.nombre.value,
      apellido: this.state.EditEmpleadoForm.apellido.value,
      numero_legajo: this.state.EditEmpleadoForm.numero_legajo.value,
      dni: this.state.EditEmpleadoForm.dni.value,
      cuil: this.state.EditEmpleadoForm.cuil.value,
      nro_tramite_dni: this.state.EditEmpleadoForm.nro_tramite_dni.value,
      id_empresa_empleadora: this.state.EditEmpleadoForm2.id_empresa_empleadora
        .value,
      direccion_completa: this.state.EditEmpleadoForm2.direccion_completa.value,
      direccion_alternativa: this.state.EditEmpleadoForm2.direccion_alternativa
        .value,
      telefono_contacto: this.state.EditEmpleadoForm2.telefono_contacto.value,
      telefono_personal: this.state.EditEmpleadoForm2.telefono_personal.value,
      id_puesto: this.state.EditEmpleadoForm4.id_puesto.value,
      id_remera: this.state.EditEmpleadoForm3.id_remera.value,
      id_pantalon: this.state.EditEmpleadoForm3.id_pantalon.value,
      id_zapato: this.state.EditEmpleadoForm3.id_zapato.value,
      id_superior_jerarquico: this.state.EditEmpleadoForm3
        .id_superior_jerarquico.value,
      id_estado_civil: this.state.EditEmpleadoForm.id_estado_civil.value,
      id_categoria_licencia: this.state.EditEmpleadoForm4.id_categoria_licencia
        .value,
      conyuge: this.state.EditEmpleadoForm.conyuge.value,
      convenio_camioneros: this.state.EditEmpleadoForm4.convenio_camioneros
        .value,
      alta_dia: this.state.EditEmpleadoForm4.alta_dia.value,
      preocupacional_obser: this.state.EditEmpleadoForm4.preocupacional_obser
        .value,
      fecha_nac: this.state.fechaNacimiento,
      vto_linti: this.state.fechaVencimientoLinti,
      vto_psicofisico: this.state.fechaVencimientoPsicofisico,
      vto_curso: this.state.fechaVencimientoCurso,
      vto_libreta_sanitaria: this.state.fechaVencimientoLibretaSanitaria,
      vto_licencia_municipal: this.state.fechaVencimientoLicencia,
      fecha_ingreso: this.state.fechaIngreso,
      preocupacional_fecha: this.state.fechaPreocupacional
    }).then(
      res => {
        this.setState(
          {
            successSubmitEdit: true,
            editFormIsValid: false,
            disableAllButtons: false
          },
          () => {
            toast.success("El Empleado se ha modificado con exito!");

            this.props.getEmpleadosAdmin();
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.EditEmpleadoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      EditEmpleadoForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  inputEditChangedHandler2 = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.EditEmpleadoForm2
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      EditEmpleadoForm2: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  inputEditChangedHandler3 = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.EditEmpleadoForm3
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      EditEmpleadoForm3: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  inputEditChangedHandler4 = (event, inputIdentifier, newValue) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.EditEmpleadoForm4
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }

    if (inputIdentifier == "alta_dia") {
      console.log("cambiando alta_dia: " + newValue);
      if (newValue == true) updatedOrderForm["alta_dia"].value = 1;
      else updatedOrderForm["alta_dia"].value = 0;
    }
    if (inputIdentifier == "convenio_camioneros") {
      console.log("cambiando convenio_camioneros: " + newValue);
      if (newValue == true) updatedOrderForm["convenio_camioneros"].value = 1;
      else updatedOrderForm["convenio_camioneros"].value = 0;
    }

    this.setState({
      EditEmpleadoForm4: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  onFilesFotoChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("foto", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = this.props.match.params.id; //buscar id

    Database.post(
      "/insert-foto/" + id + "/" + files[0].name,
      formData,
      this,
      false,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesFotoError = (error, file) => {
    toast.warn(
      "Error al subir el archivo " + error.code + ": " + error.message
    );
    console.log("error code " + error.code + ": " + error.message);
  };

  onFilesDNIChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("dni_foto", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = this.props.match.params.id; //buscar id

    Database.post(
      "/insert-dni-foto/" + id + "/" + files[0].name,
      formData,
      this,
      false,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesDNIError = (error, file) => {
    toast.warn(
      "Error al subir el archivo " + error.code + ": " + error.message
    );
    console.log("error code " + error.code + ": " + error.message);
  };

  onFilesLintiChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("linti", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = this.props.match.params.id; //buscar id

    Database.post(
      "/insert-linti/" + id + "/" + files[0].name,
      formData,
      this,
      false,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesLintiError = (error, file) => {
    toast.warn(
      "Error al subir el archivo " + error.code + ": " + error.message
    );
    console.log("error code " + error.code + ": " + error.message);
  };

  onFilesLibretaChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("libreta", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = this.props.match.params.id; //buscar id

    Database.post(
      "/insert-libreta/" + id + "/" + files[0].name,
      formData,
      this,
      false,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesLibretaError = (error, file) => {
    toast.warn(
      "Error al subir el archivo " + error.code + ": " + error.message
    );
    console.log("error code " + error.code + ": " + error.message);
  };

  onFilesLicenciaChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("licencia", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = this.props.match.params.id; //buscar id

    Database.post(
      "/insert-licencia/" + id + "/" + files[0].name,
      formData,
      this,
      false,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesLicenciaError = (error, file) => {
    toast.warn(
      "Error al subir el archivo " + error.code + ": " + error.message
    );
    console.log("error code " + error.code + ": " + error.message);
  };

  onFilesPreocupacionalChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("preocupacional", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = this.props.match.params.id; //buscar id

    Database.post(
      "/insert-preocupacional/" + id + "/" + files[0].name,
      formData,
      this,
      false,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesPreocupacionalError = (error, file) => {
    toast.warn(
      "Error al subir el archivo " + error.code + ": " + error.message
    );
    console.log("error code " + error.code + ": " + error.message);
  };

  getEmpresaEmpleadora = () => {
    Database.get("/list-empresa-empleadora", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.nombre
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm2 };
        formulario.id_empresa_empleadora.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm2: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getSuperiorJerarquico = () => {
    Database.get("/list-superior-jerarquico", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.apellido + ", " + entry.nombre
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm3 };
        formulario.id_superior_jerarquico.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getTalleRemera = () => {
    Database.get("/list-talle-remera", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm3 };
        formulario.id_remera.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getTallePantalon = () => {
    Database.get("/list-talle-pantalon", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm3 };
        formulario.id_pantalon.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getTalleZapato = () => {
    Database.get("/list-talle-zapato", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm3 };
        formulario.id_zapato.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getEstadoCivil = () => {
    Database.get("/list-estado-civil", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm };
        formulario.id_estado_civil.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getCategoriaLicencia = () => {
    Database.get("/list-categoria-licencia", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm4 };
        formulario.id_categoria_licencia.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm4: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getPuesto = () => {
    Database.get("/list-puesto", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.EditEmpleadoForm4 };
        formulario.id_puesto.elementConfig.options = [...a];
        this.setState({
          EditEmpleadoForm4: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  resetEditForm = () => {
    let EditEmpleadoFormAlt = { ...this.state.EditEmpleadoForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in EditEmpleadoFormAlt) {
      EditEmpleadoFormAlt[key].value = "";
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    });
  };

  componentDidMount() {
    // this.getClientesType();
    console.log(this.props.match.params.id);
    this.getEmpleadoEdit(this.props.match.params.id);
    this.getEmpresaEmpleadora();
    this.getSuperiorJerarquico();
    this.getTalleRemera();
    this.getTallePantalon();
    this.getTalleZapato();
    this.getEstadoCivil();
    this.getCategoriaLicencia();
    this.getPuesto();
  }

  handleFechaNac = date => {
    this.setState({
      fechaNacimiento: date
    });
  };
  handleVencLinti = date => {
    this.setState({
      fechaVencimientoLinti: date
    });
  };
  handleVencPsico = date => {
    this.setState({
      fechaVencimientoPsicofisico: date
    });
  };
  handleVencCurso = date => {
    this.setState({
      fechaVencimientoCurso: date
    });
  };
  handleVencLibreta = date => {
    this.setState({
      fechaVencimientoLibretaSanitaria: date
    });
  };
  handleVencLicencia = date => {
    this.setState({
      fechaVencimientoLicencia: date
    });
  };
  handleFechaIngreso = date => {
    this.setState({
      fechaIngreso: date
    });
  };
  handlePreocupacional = date => {
    this.setState({
      fechaPreocupacional: date
    });
  };

  render() {
    const formElementsArray1 = [];
    for (let key in this.state.EditEmpleadoForm) {
      formElementsArray1.push({
        id: key,
        config: this.state.EditEmpleadoForm[key]
      });
    }
    const formElementsArray2 = [];
    for (let key in this.state.EditEmpleadoForm2) {
      formElementsArray2.push({
        id: key,
        config: this.state.EditEmpleadoForm2[key]
      });
    }

    const formElementsArray3 = [];
    for (let key in this.state.EditEmpleadoForm3) {
      formElementsArray3.push({
        id: key,
        config: this.state.EditEmpleadoForm3[key]
      });
    }

    const formElementsArray4 = [];
    for (let key in this.state.EditEmpleadoForm4) {
      formElementsArray4.push({
        id: key,
        config: this.state.EditEmpleadoForm4[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitEditEmpleado(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Editar Empleado
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del empleado
            </p>
          </CardHeader>
          <CardBody>
            {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
        Cambiar Contraseña
      </Button> */}

            <div className="mt-3 mb-3">
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Datos Personales
                </h4>
              </CardHeader>

              <CardBody>
                <div className="files">
                  <Files
                    className="files-dropzone"
                    onChange={this.onFilesFotoChange}
                    onError={this.onFilesFotoError}
                    accepts={[
                      "image/png",
                      ".pdf",
                      "audio/*",
                      ".docx",
                      ".doc",
                      ".jpg"
                    ]}
                    multiple
                    maxFiles={3}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Button
                      style={{ marginTop: "25px" }}
                      color="primary"
                      variant="contained"
                    >
                      <BackupIcon />
                      &nbsp; Adjuntar foto del Empleado
                    </Button>
                  </Files>
                  <br></br>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.url_foto}
                  >
                    ver foto actual
                  </a>
                </div>

                {formElementsArray1.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={event =>
                      this.inputEditChangedHandler(event, formElement.id)
                    }
                  />
                ))}

                <div className="files">
                  <Files
                    className="files-dropzone"
                    onChange={this.onFilesDNIChange}
                    onError={this.onFilesDNIError}
                    accepts={[
                      "image/png",
                      ".pdf",
                      "audio/*",
                      ".docx",
                      ".doc",
                      ".jpg"
                    ]}
                    multiple
                    maxFiles={3}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Button
                      style={{ marginTop: "25px" }}
                      color="primary"
                      variant="contained"
                    >
                      <BackupIcon />
                      &nbsp; Adjuntar foto del DNI
                    </Button>
                  </Files>
                  <br></br>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.url_dni}
                  >
                    ver foto actual
                  </a>
                </div>

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechanacimiento"
                      label="Fecha de nacimiento"
                      format="dd/MM/yyyy"
                      value={this.state.fechaNacimiento}
                      onChange={this.handleFechaNac}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Datos de la Empresa Empleadora y de contacto
                </h4>
              </CardHeader>
              <CardBody>
                {formElementsArray2.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={event =>
                      this.inputEditChangedHandler2(event, formElement.id)
                    }
                  />
                ))}
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Superior Jerárquico y Talles
                </h4>
              </CardHeader>
              <CardBody>
                {formElementsArray3.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={event =>
                      this.inputEditChangedHandler3(event, formElement.id)
                    }
                  />
                ))}
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  {" "}
                  Licencia Nacional de Transporte Interurbano y Libreta
                  Sanitaria
                </h4>
              </CardHeader>
              <CardBody>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimiento"
                      label="Vencimiento LINTI"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoLinti}
                      onChange={this.handleVencLinti}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <div className="files">
                  <Files
                    className="files-dropzone"
                    onChange={this.onFilesLintiChange}
                    onError={this.onFilesLintiError}
                    accepts={[
                      "image/png",
                      ".pdf",
                      "audio/*",
                      ".docx",
                      ".doc",
                      ".jpg"
                    ]}
                    multiple
                    maxFiles={3}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Button
                      style={{ marginTop: "25px" }}
                      color="primary"
                      variant="contained"
                    >
                      <BackupIcon />
                      &nbsp; Adjuntar Linti
                    </Button>
                  </Files>
                  <br></br>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.url_linti}
                  >
                    ver foto actual
                  </a>
                </div>

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientopsicofisico"
                      label="Vencimiento Psicofisico"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoPsicofisico}
                      onChange={this.handleVencPsico}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientocurso"
                      label="Vencimiento Curso"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoCurso}
                      onChange={this.handleVencCurso}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientolibretasanitaria"
                      label="Venc. Libreta Sanitaria"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoLibretaSanitaria}
                      onChange={this.handleVencLibreta}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <div className="files">
                  <Files
                    className="files-dropzone"
                    onChange={this.onFilesLibretaChange}
                    onError={this.onFilesLibretaError}
                    accepts={[
                      "image/png",
                      ".pdf",
                      "audio/*",
                      ".docx",
                      ".doc",
                      ".jpg"
                    ]}
                    multiple
                    maxFiles={3}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Button
                      style={{ marginTop: "25px" }}
                      color="primary"
                      variant="contained"
                    >
                      <BackupIcon />
                      &nbsp; Adjuntar Libreta Sanitaria
                    </Button>
                  </Files>
                  <br></br>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.url_libreta_sanitaria}
                  >
                    ver foto actual
                  </a>
                </div>
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Puesto, Licencia Municipal y Preocupacional
                </h4>
              </CardHeader>

              <CardBody>
                {formElementsArray4.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={(event, newValue) =>
                      this.inputEditChangedHandler4(
                        event,
                        formElement.id,
                        newValue
                      )
                    }
                  />
                ))}
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientolicencia"
                      label="Venc. Licencia Municipal"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoLicencia}
                      onChange={this.handleVencLicencia}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <div className="files">
                  <Files
                    className="files-dropzone"
                    onChange={this.onFilesLicenciaChange}
                    onError={this.onFilesLicenciaError}
                    accepts={[
                      "image/png",
                      ".pdf",
                      "audio/*",
                      ".docx",
                      ".doc",
                      ".jpg"
                    ]}
                    multiple
                    maxFiles={3}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Button
                      style={{ marginTop: "25px" }}
                      color="primary"
                      variant="contained"
                    >
                      <BackupIcon />
                      &nbsp; Adjuntar Licencia Municipal
                    </Button>
                  </Files>
                  <br></br>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.url_licencia}
                  >
                    ver foto actual
                  </a>
                </div>

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechapreocupacional"
                      label="Fecha del Preocupacional"
                      format="dd/MM/yyyy"
                      value={this.state.fechaPreocupacional}
                      onChange={this.handlePreocupacional}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <div className="files">
                  <Files
                    className="files-dropzone"
                    onChange={this.onFilesPreocupacionalChange}
                    onError={this.onFilesPreocupacionalError}
                    accepts={[
                      "image/png",
                      ".pdf",
                      "audio/*",
                      ".docx",
                      ".doc",
                      ".jpg"
                    ]}
                    multiple
                    maxFiles={3}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <Button
                      style={{ marginTop: "25px" }}
                      color="primary"
                      variant="contained"
                    >
                      <BackupIcon />
                      &nbsp; Adjuntar examen preocupacional
                    </Button>
                  </Files>
                  <br></br>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.url_preocupacional}
                  >
                    ver foto actual
                  </a>
                </div>

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaingreso"
                      label="Fecha de Ingreso"
                      format="dd/MM/yyyy"
                      value={this.state.fechaIngreso}
                      onChange={this.handleFechaIngreso}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </CardBody>
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/empleados")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,

      <Dialog
        open={this.state.openChangePass}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
        <form
          onSubmit={event => {
            this.handleChangePass(event);
          }}
        >
          {this.state.openChangePass && (
            <DialogContent>
              <DialogContentText>
                Ingrese una nueva contraseña para el Empleado
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="contrasenia"
                name="contrasenia"
                label="nueva contraseña"
                type="password"
                fullWidth
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(EditEmpleado));
