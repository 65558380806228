export const StateListColumnas = {
  listado: [],
  calles: [],
  id_calle: "",
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateColumnas = {
  orderForm: {
    desde: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Desde",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true,
        rango: { tipo: "desde", grupo: 1, identificadorAlt: "hasta" }
      },
      valid: false,
      touched: false
    },
    hasta: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Hasta",
        fullWidth: true
      },
      value: "",
      validation: {
        rango: { tipo: "hasta", grupo: 1, identificadorAlt: "desde" }
      },
      valid: false,
      touched: false
    },
    numerico: {
      elementType: "select",
      elementConfig: {
        label: "Rango Numérico",
        options: [
          { value: 1, displayValue: "Ambos" },
          { value: 2, displayValue: "Par" },
          { value: 3, displayValue: "Impar" }
        ],
        fullWidth: true
      },
      value: 1,
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },

    id_columna_tipo: {
      elementType: "select",
      elementConfig: {
        label: "Tipo",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    dinamica: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Dinamica",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  orderForm2: {
    ancho_pallet: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (Pallet)",
        fullWidth: true
      },
      value: 1,
      validation: {
        required: false
      },
      valid: false,
      touched: false
    }
  },

  orderForm3: {
    ancho: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    }
  },
  formIsValid2: false,
  formIsValid3: false,

  formIsValid: false,
  disableAllButtons: false,
  items: [],
  openDialog: false,
  rowItem: null
};

export const StateColumnas2 = {
  orderForm: {
    numero: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Número",
        fullWidth: true,
        disabled: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    id_columna_tipo: {
      elementType: "select",
      elementConfig: {
        disabled: true,
        label: "Tipo",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    dinamica: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Dinamica",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },

      valid: true,
      touched: false
    }
  },
  orderForm2: {
    ancho_pallet: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (Pallet)",
        fullWidth: true
      },
      value: 1,
      validation: {
        required: false
      },
      valid: false,
      touched: false
    }
  },

  orderForm3: {
    ancho: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    }
  },
  formIsValid2: false,
  formIsValid3: false,

  formIsValid: false,
  disableAllButtons: false,
  items: [],
  openDialog: false,
  rowItem: null
};

export const ColumnsListado = [
  { title: "Número", field: "numero" },
  { title: "Tipo", field: "columna_tipo_descripcion" },
  { title: "Niveles", field: "cantidad_niveles" },
  {
    title: "Pasillo",
    field: "calle_numero",
    render: rowdata => {
      return "Pasillo " + rowdata.calle_numero;
    }
  }
  /*
  {
    title: "Estado", field: "estado", render: (rowdata) => {
      if (rowdata.estado == 1)
        return "Habilitado";
      if (rowdata.estado == 2)
        return "Deshabilitado";

    }
  }
  */
];
