import * as actionTypes from "../actions/actionTypes";

const initialState = {
  campos_habilitados: [], // campos habilitados es un array de ids de la tabla de importacion masiva
  csv: [],
  campos_cargados: true,
  confirmDialog: false,
  selected_db_id: null,
  reload_table: false
};

const touch_campos_cargados = state => {
  let state_copy = state;
  state_copy.campos_cargados = !state_copy.campos_cargados;
  return { ...state_copy };
};

const touch_reload_table = state => {
  let state_copy = state;
  state_copy.reload_table = !state_copy.reload_table;
  return { ...state_copy };
};

const toggleConfirmDialog = (state, action) => {
  let state_copy = state;
  state_copy.confirmDialog = !state_copy.confirmDialog;
  state_copy.selected_db_id = action.db_in;
  return { ...state_copy };
};

const setCamposHabilitados = (state, action) => {
  let state_copy = state;
  let campos_habilitados = state_copy.campos_habilitados;
  campos_habilitados = [...action.campos_habilitados];
  state_copy.campos_habilitados = campos_habilitados;
  return { ...state_copy };
};

const addCampo = (state, action) => {
  let state_copy = state;
  let campos_habilitados = state_copy.campos_habilitados;
  campos_habilitados = [...campos_habilitados, ...[action.campo]];
  state_copy.campos_habilitados = campos_habilitados;
  return { ...state_copy };
};

const removeCampo = (state, action) => {
  let state_copy = state;
  let campos_habilitados = state_copy.campos_habilitados;
  campos_habilitados = campos_habilitados.filter(el => {
    if (el === action.campo) {
      return false;
    } else {
      return true;
    }
  });
  state_copy.campos_habilitados = campos_habilitados;
  return { ...state_copy };
};

const cargaCsv = (state, action) => {
  let state_copy = state;
  let csv = state_copy.csv;
  csv = action.csv;
  state_copy.csv = csv;
  return { ...state_copy };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.IM_ADD_CAMPO:
      return addCampo(state, action);

    case actionTypes.IM_REMOVE_CAMPO:
      return removeCampo(state, action);

    case actionTypes.IM_SET_CAMPOS_HABILITADOS:
      return setCamposHabilitados(state, action);

    case actionTypes.IM_CARGA_CSV:
      return cargaCsv(state, action);

    case actionTypes.IM_TOUCH_CAMPOS_CARGADOS:
      return touch_campos_cargados(state);

    case actionTypes.IM_TOUCH_RELOAD_TABLE:
      return touch_reload_table(state);

    case actionTypes.IM_TOGGLE_CONFIRM_DIALOG:
      return toggleConfirmDialog(state, action);

    default:
      return state;
  }
};

export default reducer;
