/* eslint-disable */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/index";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";

import Database from "variables/Database.js";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      marginLeft: "5%",
      marginRight: "5%",
      marginBottom: "30px",
      // margin: theme.spacing(1),
      width: "40%"
    }
  }
}));

const classes = {
  card: {
    color: "black",
    margin: "2em",
    marginBottom: "0px",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingBottom: "1em",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    width: "90%",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  titulo: {
    color: "black",
    marginTop: "10px",
    marginBottom: "3px",
    marginLeft: "20px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button_panel: {
    marginLeft: "30px",
    marginBottom: "30px"
  },
  button_custom: {
    marginTop: "10px",
    marginLeft: "30px",
    width: "25%"
  }
};

let disabledNuevaRef = true;

const postReferenciaNueva = props => {
  let referencia_pedido_salida = {};
  referencia_pedido_salida.referencia = props.referenciaPedido;
  Database.post(
    `/referencia-pedido-salida`,
    { referencia_pedido_salida },
    this
  ).then(
    res => {
      let cantidad_pedidos_con_esa_referencia = res.result;
      if (cantidad_pedidos_con_esa_referencia === 0) {
        // no hay pedidos para esa referencia -> es nueva
        props.setTrueNuevaReferencia();
        props.setDisableInputText(true);
      } else {
        // ya existen pedidos con esa referencia
        props.setFalseNuevaReferencia();
        props.setDisableInputText(false);
      }
    },
    err => {
      toast.error(err.message);
    }
  );
};

const editarReferenciaNueva = props => {
  props.setDisableInputText(false);
  props.resetNuevaReferencia();
};

const openModalPedidos = props => {
  props.resetNuevaReferencia();
  props.setModalVisibility(true, "pedido");
  disabledNuevaRef = true;
  props.setDisableInputText(false);
  props.resetFormPedido();
  props.setBuscarReferencia(true);
};

const habilitarNuevaReferencia = props => {
  disabledNuevaRef = false;
  props.setBuscarReferencia(false);
};

const inputTextReferencia = props => {
  const inputReferencia = props.referenciaPedido ? props.referenciaPedido : "";

  return (
    <form noValidate autoComplete="off">
      <div>
        <TextField
          required
          id="referencia-pedido-salida"
          label="Referencia del pedido"
          InputLabelProps={{ shrink: true }}
          onClick={event => habilitarNuevaReferencia(props)}
          value={inputReferencia}
          disabled={props.disableInputText}
          onChange={event => props.onChangeHandlerInputText(event)}
        />
        {props.nuevaReferencia === 1 ? (
          <Button
            id="editar-nueva-referencia"
            variant="contained"
            color="secondary"
            disabled={disabledNuevaRef}
            className={props.classes.button_custom}
            onClick={event => editarReferenciaNueva(props)}
          >
            {" "}
            <EditIcon></EditIcon>Editar Nueva Referencia
          </Button>
        ) : (
          <Button
            id="guardar-nueva-referencia"
            variant="contained"
            color="primary"
            disabled={disabledNuevaRef}
            className={props.classes.button_custom}
            onClick={event => postReferenciaNueva(props)}
          >
            {" "}
            <SaveIcon></SaveIcon>Guardar Nueva Referencia
          </Button>
        )}
        <Button
          id="buscar-referencia"
          color="primary"
          aria-label="edit"
          variant="contained"
          className={props.classes.button_custom}
          onClick={() => openModalPedidos(props)}
        >
          <SearchIcon />
          Buscar Referencia
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    referenciaPedido: state.pedidossalidas.referenciaPedido,
    nuevaReferencia: state.pedidossalidas.nuevaReferencia,
    disableInputText: state.pedidossalidas.disableInputText,
    buscarReferencia: state.pedidossalidas.buscarReferencia
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setBuscarReferencia: bool_buscar =>
      dispatch(actionCreators.setBuscarReferencia(bool_buscar)),
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal)),
    resetFormPedido: () => dispatch(actionCreators.resetFormPedido()),
    setTrueNuevaReferencia: () =>
      dispatch(actionCreators.setTrueNuevaReferencia()),
    setFalseNuevaReferencia: () =>
      dispatch(actionCreators.setFalseNuevaReferencia()),
    resetNuevaReferencia: () => dispatch(actionCreators.resetNuevaReferencia()),
    setDisableInputText: is_disabled =>
      dispatch(actionCreators.setDisableInputText(is_disabled)),
    onChangeHandlerInputText: referencia =>
      dispatch(actionCreators.onChangeHandlerInputText(referencia))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(inputTextReferencia));
