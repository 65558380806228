export const StateListOrdenesPicking = {
  ordenespicking: [],
  offset: 0,
  // checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  disableAllButtons: false,
  isLoading: false
};

export const StateListOrdenesPickingProductos = {
  ordenespickingproductos: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditOrdenPicking = {
  EditOrdenPickingForm: {
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  ordentrabajoEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateOrdenesPicking = {
  orderForm: {
    referencia: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Referencia",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    id_muelle: {
      elementType: "select",
      elementConfig: {
        label: "Muelle de Entrada",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    devolucion: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Es devolución",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    comentario: {
      elementType: "textarea",
      elementConfig: {
        label: "Comentarios",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false,
  openDialogProducto: false,
  openDialogCliente: false,
  openDialogUnidad: false,
  openDialogPresentacion: false,
  rowProductoPresentacion: null,
  rowEditProducto: null,
  detalleordenespicking: [],
  infoCliente: null
};

//en en back filtramos el user para hacer un select de ordenes de picking selectivo
export const ColumnsListado = [
  { title: "N° Orden", field: "id" },
  { title: "Sector", field: "descripcion" },
  { title: "Fecha Creacion", field: "fecha_mostrar" },
  { title: "N° de Procesamiento de Pedido", field: "id_orden_trabajo" },
  { title: "N° de Pedidos Procesados", field: "pedidos" },
  { title: "Recolector", field: "username" },
  { title: "Calle Salida", field: "calle_salida" },
  { title: "Estado", field: "estado" }
];

// export const ColumnsListadoPedidos = [
//     { title: "Numero", field: "id" },
//     { title: "Referencia", field: "descripcion" },
//     { title: "Cliente", field: "razon_social" },
//     { title: "Destino", field: "destino" },
//     { title: "Fecha", field: "fecha_mostrar" },
//     { title: "Estado", field: "estado" }
//     ];

//enviar el id_almacen en la request
export const ColumnsListadoOrdenPickingProductos = [
  { title: "Producto Presentacion", field: "producto", editable: "never" },
  { title: "Referencia", field: "pedido_descripcion", editable: "never" },
  {
    title: "Ubicacion",
    field: "ubicacion",
    editable: "never",
    defaultSort: "desc"
  },
  { title: "N° de Pedido", field: "id_pedido", editable: "never" }
  // { title: "Segmento", field: "calle_segmento", editable: 'never'},
  // { title: "Bultos Solicitados", field: "cantidad_bultos", editable: "never" }, //stock solicitado
  // {
  //   title: "Displays Solicitados",
  //   field: "cantidad_displays",
  //   editable: "never"
  // }, //stock solicitado
  // {
  //   title: "Unidades Solicitadas",
  //   field: "cantidad_unidades",
  //   editable: "never"
  // }, //stock solicitado
  // {
  //   title: "Bultos Cumplidos",
  //   field: "cantidad_bultos_cumplidos",
  //   editable: "onUpdate",
  //   cellStyle: {
  //     backgroundColor: "rgba(104, 159, 56, 0.2)"
  //   }
  // }, //stock cumplido
  // {
  //   title: "Displays Cumplidos",
  //   field: "cantidad_displays_cumplidos",
  //   editable: "onUpdate",
  //   cellStyle: {
  //     backgroundColor: "rgba(104, 159, 56, 0.2)"
  //   }
  // }, //stock cumplido
  // {
  //   title: "Unidades Cumplidas",
  //   field: "cantidad_unidades_cumplidas",
  //   editable: "onUpdate",
  //   cellStyle: {
  //     backgroundColor: "rgba(104, 159, 56, 0.2)"
  //   }
  // }, //stock cumplido
  // {
  //   title: "Bultos Disponibles",
  //   field: "cantidad_bultos_picking",
  //   editable: "never"
  // }, //stock actual
  // {
  //   title: "Displays Disponibles",
  //   field: "cantidad_displays_picking",
  //   editable: "never"
  // }, //stock actual
  // {
  //   title: "Unidades Disponibles",
  //   field: "cantidad_unidades_picking",
  //   editable: "never"
  // }, //stock actual

  //{ title: "Estado", field: "estado", editable: "never" } //el estado de id_pedido_detalle (que tiene ordenes_picking_productos)
  //este será alterado por el backend al guardar cambios en orden de picking
];

// export const ColumnsListadoProductos = [
//     { title: "Dato Logístico", field: "presentacion.descripcion" },
//     { title: "Articulo", field: "presentacion.ProductosUnidad.descripcion" },
//     { title: "EAN13", field: "presentacion.ProductosUnidad.ean13" },
//     { title: "Cod. Artículo", field: "presentacion.ProductosUnidad.codigo" },
//     { title: "Pallets", field: "cantidad_contenedores" },
//     { title: "Bultos", field: "cantidad_bultos" },
//     { title: "Displays", field: "cantidad_displays"},
//     { title: "Unidades", field: "cantidad_unidades" },

// {
//     title: "Vencimiento", field: "vencimiento_mostrar", customSort: (a, b) => parseInt(moment(a.vencimiento).format("YYYYMMDD")) - parseInt(moment(b.vencimiento).format("YYYYMMDD"))
// }

//{ title: 'Cantidad', field: 'cantidad', render: rowData => <input type="text"/>}
//];
