import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// stepper
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
// basicos
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardBody from "components/Card/CardBody.js";
// componentes
import CardPedido from "./CardPedido.js";
import CardPicking from "../CardPicking/CardPicking.js";
import TablaLotesASalida from "../Tablas/TablaLotesASalida";
import TablaPicking from "../Tablas/TablaPickingASalida";

// estilos stepper
const useStyles = makeStyles(theme => ({
  stepperStyle: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  buttonsContainer: {
    marginBottom: theme.spacing(2)
  }
}));

// componente funcional stepper
const StepperVerticalDeSalidaPedidos = props => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const getStepsNames = () => {
    // fija los steps que van a recorrerse
    if (props.selectedTab === 0) {
      return ["Referencia del pedido", "Seleccionar 1 o más Pallets"];
    }
    if (props.selectedTab === 1) {
      return [
        "Referencia del pedido",
        "Seleccionar 1 o más artículos de picking"
      ];
    }
  };

  const steps = getStepsNames();

  const getStepContent = step => {
    // Contenido para cada step
    let stepOneContent;
    if (props.selectedTab === 0) {
      stepOneContent = (
        <CardBody>
          <TablaLotesASalida />
        </CardBody>
      );
    }
    if (props.selectedTab === 1) {
      stepOneContent = (
        <CardBody>
          <CardPicking></CardPicking>
          <TablaPicking></TablaPicking>
        </CardBody>
      );
    }
    switch (step) {
      case 0:
        return <CardPedido></CardPedido>;
      case 1:
        return stepOneContent;
      default:
        return;
    }
  };

  // condiciones para habilitar o no el paso al siguiente step
  let disable_next_step = false;
  if (activeStep === 0) {
    if (!props.referenciaPedido) {
      disable_next_step = true;
    } else if (
      props.referenciaPedido &&
      props.nuevaReferencia === 0 &&
      !props.buscarReferencia
    ) {
      disable_next_step = true;
    }
  }

  // elemento buttons (next y back)
  let buttonsNextBack;
  if (activeStep === 0) {
    buttonsNextBack = (
      <div>
        <Button disabled={true} onClick={handleBack} className={classes.button}>
          Atrás
        </Button>
        <Button
          disabled={disable_next_step}
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          Siguiente
        </Button>
      </div>
    );
  }
  if (activeStep === 1) {
    buttonsNextBack = (
      <div>
        <Button
          disabled={false}
          onClick={handleBack}
          className={classes.button}
        >
          Atrás
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.stepperStyle}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((nombreStep, index) => (
          <Step key={nombreStep}>
            <StepLabel>{nombreStep}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.buttonsContainer}>{buttonsNextBack}</div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    openBuscarClienteDialog: state.pedidossalidas.openBuscarClienteDialog,
    referenciaPedido: state.pedidossalidas.referenciaPedido,
    nuevaReferencia: state.pedidossalidas.nuevaReferencia,
    buscarReferencia: state.pedidossalidas.buscarReferencia,
    selectedTab: state.salidas.selectedTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOpenBuscarClienteDialog: () =>
      dispatch(actionCreators.handleOpenBuscarClienteDialog()),
    handleCloseClienteDialog: () =>
      dispatch(actionCreators.handleCloseClienteDialog()),
    handleAcceptModalCliente: cliente =>
      dispatch(actionCreators.handleAcceptModalCliente(cliente))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepperVerticalDeSalidaPedidos);
