import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "store/actions/index";
// CSV
import MapeadorColumnas from "./MapeadorColumnas";
import CargarCSV from "./CargarCsv";
import Titulo from "./Titulo";
import ValidarCSV from "./ValidarCsv";
import ConfirmDialog from "./ConfirmDeleteField";
import { Button, Grid } from "@material-ui/core";
import CSVReader from "react-csv-reader";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const ImportacionMasiva = function(props) {
  const classes = useStyles();
  const [shouldRender, setShouldRender] = useState(false); // Controla si se debe volver a renderizar
  const [fileName, setFileName] = useState("");
  const [csvData, setCsvData] = useState(""); // Inicialmente no hay datos de CSV

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true
  };

  const handleRemoveCsv = () => {
    setCsvData("");
    setShouldRender(false);
  };

  const handleSetShouldRender = shouldRender => {
    setShouldRender(shouldRender);
    setCsvData(""); // Actualiza csvData a vacío o nulo al cambiar shouldRender
  };

  return (
    <div className={classes.root}>
      <Titulo titulo="1: Mapear los campos BD > CSV" />
      <MapeadorColumnas />
      <Titulo titulo="2: Cargar el archivo .csv" />
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "10px" }}
        ></Grid>
        {!shouldRender && (
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={1}
          >
            <CSVReader
              cssClass="csv-reader-input"
              label="Buscar CSV "
              onFileLoaded={(data, fileInfo) => {
                setCsvData(data);
                setShouldRender(true);
                setFileName(fileInfo.name);
              }}
              onError={() => console.log("Error al cargar el archivo CSV")}
              parserOptions={parseOptions}
              inputId="cargar-csv"
              inputStyle={{ color: "red" }}
            />
          </Grid>
        )}
        {shouldRender && (
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            direction="column"
            spacing={1}
          >
            <Grid item>
              <p>Nombre del archivo: {fileName}</p>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleRemoveCsv}>
                Quitar archivo
              </Button>
            </Grid>
          </Grid>
        )}
      </>
      <Titulo titulo="3: Validar los datos y dar alta masiva" />
      {<MapeadorColumnas /> ? (
        <ValidarCSV
          csvData={csvData}
          setShouldRender={handleSetShouldRender}
          shouldRender={shouldRender}
        />
      ) : null}
      <ConfirmDialog />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    campos_habilitados: state.importacionmasiva.campos_habilitados,
    confirmDialog: state.importacionmasiva.confirmDialog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCampo: campo => dispatch(actionCreators.addCampo(campo)),
    removeCampo: campo => dispatch(actionCreators.removeCampo(campo)),
    setCamposHabilitados: campos_habilitados =>
      dispatch(actionCreators.setCamposHabilitados(campos_habilitados))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportacionMasiva);
