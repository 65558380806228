import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

import { StateEditOperacion } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditOperacion extends Component {
  state = JSON.parse(JSON.stringify(StateEditOperacion));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  getEmpleado = id => {
    Database.get("/list-empleado/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          nombreEmpleado:
            resultado.result[0].nombre.toUpperCase() +
            " " +
            resultado.result[0].apellido.toUpperCase(),
          idEmpleado: id
        });
      }
    });
  };

  getOperacionEdit = id => {
    Database.get("/list-operaciones/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          nombreEmpleado: this.getEmpleado(resultado.result[0].id_empleado),
          operacionEdit: resultado.result[0]
        });

        let editOperacionFormAlt = { ...this.state.editOperacionForm };
        editOperacionFormAlt.id_cliente.value = resultado.result[0].id_cliente;
        editOperacionFormAlt.id_suboperacion.value =
          resultado.result[0].id_suboperacion;
        editOperacionFormAlt.id_centro_distribuidor.value =
          resultado.result[0].id_centro_distribuidor;
        this.state.fechaDesde = resultado.result[0].fecha_desde;
        this.state.fechaHasta = resultado.result[0].fecha_hasta;
        for (let key in editOperacionFormAlt) {
          editOperacionFormAlt[key].touched = true;
          editOperacionFormAlt[key].valid = true;
        }

        this.setState({
          editOperacionForm: editOperacionFormAlt
        });
        // this.getOperacionesType("edit", editOperacionFormAlt);
      } else {
        this.setState({
          operacionEdit: null
        });
      }
    });

    Database.get("/list-clientes/").then(resultado => {
      console.log(resultado.result);
      if (resultado.result.length > 0) {
        console.log("entro");
        let editOperacionFormAlt = { ...this.state.editOperacionForm };

        let res = [...resultado.result];
        console.log(res);
        let s = [];
        res.forEach(function(entry) {
          s.push({
            value: entry.id,
            displayValue: entry.nombre_fantasia
          });
        });

        console.log(s);

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.editOperacionForm };
        // let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle))
        formulario.id_cliente.elementConfig.options = s;
        // formulario.id_muelle = id_muelle;

        for (let key in editOperacionFormAlt) {
          editOperacionFormAlt[key].touched = true;
          editOperacionFormAlt[key].valid = true;
        }

        this.setState({
          editOperacionForm: editOperacionFormAlt
        });
      } else {
        // this.setState({
        //   bancoEdit: null
        // })
      }
    });

    Database.get("/list-suboperaciones/").then(resultado => {
      console.log(resultado.result);
      if (resultado.result.length > 0) {
        console.log("entro");
        let editOperacionFormAlt = { ...this.state.editOperacionForm };

        let res = [...resultado.result];
        console.log(res);
        let s = [];
        res.forEach(function(entry) {
          s.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        console.log(s);

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.editOperacionForm };
        // let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle))
        formulario.id_suboperacion.elementConfig.options = s;
        // formulario.id_muelle = id_muelle;

        for (let key in editOperacionFormAlt) {
          editOperacionFormAlt[key].touched = true;
          editOperacionFormAlt[key].valid = true;
        }

        this.setState({
          editOperacionForm: editOperacionFormAlt
        });
      } else {
        // this.setState({
        //   bancoEdit: null
        // })
      }
    });

    Database.get("/list-centros-distribuidores/").then(resultado => {
      console.log(resultado.result);
      if (resultado.result.length > 0) {
        console.log("entro");
        let editOperacionFormAlt = { ...this.state.editOperacionForm };

        let res = [...resultado.result];
        console.log(res);
        let s = [];
        res.forEach(function(entry) {
          s.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        console.log(s);

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.editOperacionForm };
        // let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle))
        formulario.id_centro_distribuidor.elementConfig.options = s;
        // formulario.id_muelle = id_muelle;

        for (let key in editOperacionFormAlt) {
          editOperacionFormAlt[key].touched = true;
          editOperacionFormAlt[key].valid = true;
        }

        this.setState({
          editOperacionForm: editOperacionFormAlt
        });
      } else {
        // this.setState({
        //   bancoEdit: null
        // })
      }
    });
  };

  //   getOperacionTipo = () => {
  //   Database.get('/list-operaciones-tipo/')
  //   .then(res => {

  //     let resultado = [...res.result];
  //     let a = [];
  //     resultado.forEach(function (entry) {
  //       a.push({
  //         value: entry.id,
  //         displayValue: entry.descripcion
  //       });
  //     })
  //     let formulario = { ...this.state.EditOperacionForm }
  //     formulario.tipo.elementConfig.options = [...a];
  //     this.setState({
  //       EditOperacionForm: formulario
  //     })
  // },err => {
  //   toast.error(err.message);
  // })
  // }

  // handleChangePass = (event) => {
  //   event.preventDefault();
  //   this.setState({
  //     openChangePass:false
  //   })

  //   Database.post(`/update-pass`,{id: this.props.match.params.idoperacion,newpass:event.target.contrasenia.value})
  //     .then(res => {
  //       toast.success("La operación se ha modificado con exito!");
  //     },err =>{
  //       toast.error(err.message);

  //   })
  // }

  handleSubmitEditOperacion = event => {
    event.preventDefault();
    Database.post(
      `/update-operacion`,
      {
        id: this.props.match.params.idoperacion,
        id_cliente: this.state.editOperacionForm.id_cliente.value,
        id_suboperacion: this.state.editOperacionForm.id_suboperacion.value,
        id_centro_distribuidor: this.state.editOperacionForm
          .id_centro_distribuidor.value,
        fecha_desde: this.state.fechaDesde,
        fecha_hasta: this.state.fechaHasta
      },
      this
    ).then(
      res => {
        this.setState(
          {
            successSubmitEdit: true,
            editFormIsValid: false,
            disableAllButtons: false
          },
          () => {
            toast.success("La operación se ha modificado con exito!");

            //this.props.getOperacionesAdmin();
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editOperacionForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      editOperacionForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  resetEditForm = () => {
    let editOperacionFormAlt = { ...this.state.editOperacionForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in editOperacionFormAlt) {
      editOperacionFormAlt[key].value = "";
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    });
  };

  componentDidMount() {
    // this.getOperacionesType();
    console.log(this.props.match.params);
    this.getOperacionEdit(this.props.match.params.idoperacion);
    //this.getOperacionTipo();
  }

  handleFechaDesde = date => {
    this.setState({
      fechaDesde: date
    });
  };
  handleFechaHasta = date => {
    this.setState({
      fechaHasta: date
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.editOperacionForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editOperacionForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitEditOperacion(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              {this.state.nombreEmpleado}
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos de la operación
            </p>
          </CardHeader>
          <CardBody>
            {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
        Cambiar Contraseña
      </Button> */}

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"editoperacion-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={event =>
                    this.inputEditChangedHandler(event, formElement.id)
                  }
                />
              ))}
            </div>

            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechadesde"
                  label="Fecha Inicio"
                  format="dd/MM/yyyy"
                  value={this.state.fechaDesde}
                  onChange={this.handleFechaDesde}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechahasta"
                  label="Fecha Finalización"
                  format="dd/MM/yyyy"
                  value={this.state.fechaHasta}
                  onChange={this.handleFechaHasta}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/operaciones")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,

      <Dialog
        open={this.state.openChangePass}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
        <form
          onSubmit={event => {
            this.handleChangePass(event);
          }}
        >
          {this.state.openChangePass && (
            <DialogContent>
              <DialogContentText>
                Ingrese una nueva contraseña para la Operacion
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="contrasenia"
                name="contrasenia"
                label="nueva contraseña"
                type="password"
                fullWidth
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(EditOperacion));
