import React, { Component } from "react";
// encabezado
import EncabezadoCard from "components/EncabezadoCard/EncabezadoCard";
// Grid
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// Card
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Tablas
import TablaMovimientos from "./Tablas/TablaMovimientos.js";
import ModalDetalleAuditoria from "./ModalDetalleAuditoria/ModalDetalleAuditoria.js";

class Auditoria extends Component {
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <EncabezadoCard
                titulo_card="Auditoria"
                subtitulo_card="Visualización de Auditoria"
              ></EncabezadoCard>
              <CardBody>
                <TablaMovimientos></TablaMovimientos>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <ModalDetalleAuditoria></ModalDetalleAuditoria>
      </div>
    );
  }
}

export default Auditoria;
