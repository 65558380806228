import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SelectorProductos from "./SelectorProductosNew";

export default function ModalBuscarProductos(props) {
  const [selectedProducto, setSelectedProducto] = React.useState([null]);
  const [enabledButton, setButtonStatus] = React.useState(false);

  const getSelectedProducto = producto => {
    setSelectedProducto(producto);
    if (producto != null) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  return (
    <Dialog
      open={props.openBuscarProductoDialog}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Buscar un producto {enabledButton}
      </DialogTitle>
      <DialogContent>
        <SelectorProductos
          getDataSelectedProducto={p => getSelectedProducto(p)}
        ></SelectorProductos>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => props.handleAccept(selectedProducto)}
          color="primary"
          autoFocus
          disabled={!enabledButton}
        >
          Seleccionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
