/* eslint-disable */
import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

// core components
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import ModalNuevaUbicacion from "./ModalNuevaUbicacion";
import ModalDatosPedido from "./ModalDatosPedido/ModalDatosPedido";
import { localization } from "variables/general.js";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import { toast } from "react-toastify";

import { StateListMovimientos, ColumnsListado } from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Movimientos extends Component {
  state = { ...StateListMovimientos };

  componentDidMount() {
    this.getMovimientosAdmin();
  }

  handleToggle = value => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    let deleteEnabled = false;
    let editEnabled = false;
    const botonesAcc = { ...this.state.botonesAcciones };
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) {
      deleteEnabled = true;
      if (newChecked.length == 1) editEnabled = true;
    }
    botonesAcc.editar.enabled = editEnabled;
    botonesAcc.delete.enabled = deleteEnabled;
    this.setState({
      botonesAcciones: botonesAcc,
      checked: newChecked
    });
  };

  menuHandleClose = value => {
    this.setState({
      menuContext: null
    });
  };

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    });
  };
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE MOVIMIENTOS
  ////////////////////////
  ////////////////////////
  getMovimientosAdmin = () => {
    this.setState({
      isLoading: true
    });

    //alert(process.env.REACT_APP_ID_ALMACEN);

    Database.get(
      "/list-contenedores-almacenaje/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];

        resultado = resultado.map(elem => {
          return {
            ...elem,
            vencimiento: moment(elem.vencimiento).isValid()
              ? moment.parseZone(elem.vencimiento).format("DD/MM/YYYY")
              : ""
          };
        });

        this.setState({
          isLoading: false,
          movimientos: resultado,
          checked: [],
          menuContext: null
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  getDatosPedidoPorIdContenedor = async id_contenedor => {
    var datos_pedido = false;
    await Database.post(
      `/pedido-por-contenedor`,
      { id_contenedor: id_contenedor },
      this
    ).then(
      res => {
        datos_pedido = res.result;
      },
      err => {
        toast.error(err.message);
      }
    );
    return datos_pedido;
  };

  resModalDatosPedidos = async rowData => {
    let id_contenedor = rowData.id;
    let datos_pedido = false;
    datos_pedido = await this.getDatosPedidoPorIdContenedor(id_contenedor);
    await this.props.setDatosPedido(datos_pedido);
    if (datos_pedido) {
      await this.props.setModalPedidoVisibility(true);
    }
  };

  getStateEliminarPallet = () => {
    return this.props.eliminarPallet;
  };

  handleRowClick = async (event, rowData) => {
    // si es calle de salida, abre el modal de pedidos y guarda los datos de esa fila
    if (rowData.calle_salida) {
      await this.resModalDatosPedidos(rowData);
      this.setState({
        selectedRowData: rowData
      });
    }
    if (rowData.muelle || rowData.ubicacion) {
      await this.props.setDatosPedido(null);
      this.setState({
        openNuevaUbicacionDialog: true,
        selectedRowData: rowData
      });
    }
  };

  /*Database.get('/list-ubicaciones_disponibles/' + process.env.REACT_APP_ID_ALMACEN, this)
      .then(res => {
        let resultado = [...res.result];
        this.setState({
          isLoading:false,
          ubicaciones_disponibles: resultado,
          checked: [],
          menuContext: null
        })


      },err =>{
        toast.error(err.message);

      })*/

  /*
    alert("click");
    let ubicacionesDisponibles = this.muelles.filter(elem => {
      if(this.state.muellesAsociados.includes(elem))
        return false;

      return true;
    })

    this.setState({
        idMuelle: null,
        muellesDisponibles: muellesDisponibles,
        openMuelleDialog: true
    });
    */

  handleModalPedidos = () => {
    this.props.setConfirmEliminarPalletPedido(false);
    this.setState({ openNuevaUbicacionDialog: true });
  };

  handleCloseNuevaUbicacionDialog = () => {
    this.setState({ openNuevaUbicacionDialog: false });
  };

  handleAccept(muelle, calleSalida, calle, columna, ubicacion) {
    this.setState({ openNuevaUbicacionDialog: false });

    console.log("muelle", muelle);
    console.log("calleSalida", calleSalida);
    console.log("calle", calle);
    console.log("columna", columna);
    console.log("ubicacion", ubicacion);

    let idTipoUbicacion = 0;
    let idUbicacion = 0;
    let datosPedido = this.props.datosPedido;

    if (muelle != null) {
      idTipoUbicacion = 2;
      idUbicacion = muelle;
    }

    if (calleSalida != null) {
      idTipoUbicacion = 3;
      idUbicacion = calleSalida;
    }

    if (calle != null && columna != null && ubicacion != null) {
      idTipoUbicacion = 1;
      idUbicacion = ubicacion;
    }

    Database.post(`/mover-contenedor`, {
      id_contenedor: this.state.selectedRowData.id,
      id_nueva_ubicacion: idUbicacion,
      id_tipo_ubicacion: idTipoUbicacion,
      datosPedido: datosPedido
    }).then(
      res => {
        toast.success("La ubicación se ha modificado con exito!");
        this.getMovimientosAdmin();
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }

    if (this.props.eliminarPallet) {
      this.handleModalPedidos();
    }
    return [
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Movimientos</h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Pallets
              </p>
            </CardHeader>
            <CardBody>
              {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevomovimiento')} color="primary"><AddIcon /> Nueva Movimiento</Button> */}
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListado}
                data={this.state.movimientos}
                title=""
                onRowClick={this.handleRowClick}
                localization={localization}
                components={{
                  Container: props => <Paper elevation={0} {...props} />
                }}
                options={{
                  filtering: true,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName:
                    "Movimientos " + moment().format("DD-MM-YYYY"),
                  exportDelimiter: ";",
                  headerStyle: {
                    backgroundColor: lightGreen[700],
                    color: "#FFF"
                  }
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>,
      <ModalNuevaUbicacion
        openNuevaUbicacionDialog={this.state.openNuevaUbicacionDialog}
        handleClose={() => this.handleCloseNuevaUbicacionDialog()}
        handleAccept={(muelle, calleSalida, calle, columna, ubicacion) =>
          this.handleAccept(muelle, calleSalida, calle, columna, ubicacion)
        }
      />,
      <ModalDatosPedido></ModalDatosPedido>
    ];
  }
}

const mapStateToProps = state => {
  return {
    // confirmDialog: state.salidas.confirmDialog,
    datosPedido: state.movimientos.datosPedido,
    openModalPedido: state.movimientos.openModalPedido,
    eliminarPallet: state.movimientos.eliminarPallet
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // setModalVisibility: (status, modal) => dispatch(actionCreators.setModalVisibility(status, modal)),
    // resetForm: (selected_tab) => dispatch(actionCreators.resetForm(selected_tab)),
    // setSelectedClientToCero: () => dispatch(actionCreators.setSelectedClientToCero()),
    setConfirmEliminarPalletPedido: status =>
      dispatch(actionCreators.setConfirmEliminarPalletPedido(status)),
    setDatosPedido: datos_pedido =>
      dispatch(actionCreators.setDatosPedido(datos_pedido)),
    setModalPedidoVisibility: status =>
      dispatch(actionCreators.setModalPedidoVisibility(status))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Movimientos)
);
