/* eslint-disable */
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Database from "variables/Database.js";
import { toast } from "react-toastify";
import moment from "moment";

import MaterialTable from "material-table";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import SelectorProductoPresentacion from "./SelectorProductoPresentacion";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "35%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

export default function ModalMover(props) {
  const columnasTablaPalletsAPicking = [
    { title: "Pallet", field: "id" },
    { title: "Ubicacion", field: "ubicacionString" },
    { title: "Lote", field: "lote" },
    { title: "Vencimiento", field: "vencimiento" }
  ];

  const [enabledButton, setButtonStatus] = React.useState(true);

  const [tableData, setTableData] = React.useState([]);

  const [lote, setLote] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const [disableAllButtons, setDisableAllButtons] = React.useState(false);

  //   const [selectedProductoPresentacion, setSelectedProductoPresentacion] = React.useState([null]);

  useEffect(() => {
    getContenedores();
  }, []);

  const getContenedores = () => {
    setIsLoading(true);
    Database.get(
      "/list-contenedores-a-picking/" +
        props.ubicacionPickingSeleccionada.id_producto_presentacion,
      null,
      props
    ).then(
      res => {
        setIsLoading(false);
        console.log(props.ubicacionPickingSeleccionada);
        console.log(res.result);
        let ubicacionActual;
        let resultado = res.result.map(elem => {
          if (elem.Ubicacion) {
            ubicacionActual =
              elem.Ubicacion?.Calle?.numero +
              "-" +
              elem.Ubicacion?.Columna?.numero +
              "-" +
              elem.Ubicacion?.nivel;
          } else if (elem.muelle) {
            ubicacionActual = elem.muelle?.descripcion;
          } else ubicacionActual = "Sin ubicación";

          return {
            ...elem,
            ubicacionString: ubicacionActual,

            vencimiento: moment.utc(elem.vencimiento).format("DD-MM-YYYY")
          };
        });
        setTableData(resultado);
      },
      err => {
        setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  const addToLote = rowData => {
    let array = [...lote];
    array.push(rowData);
    setLote(array);
    // console.log('addToLote', lote)
    setButtonStatus(true);
    //  props.addContenedorALoteDeSalida(rowData);
  };

  const isInLote = rowData => {
    let test = lote.filter(c => {
      return c.id === rowData.id;
    });
    return test.length == 0;
  };

  const removeFromLote = rowData => {
    let array = [...lote];
    if (array.indexOf(rowData) !== -1) {
      array.splice(array.indexOf(rowData), 1);
      setLote(array);
    }

    if (array.length == 0) setButtonStatus(false);

    // console.log('removeFromLote', lote)
    //props.removeContenedorDeLoteDeSalida(rowData);
  };

  const clickAceptar = () => {
    //almacena en cada posicion del nuevo array un objeto
    let array = lote.map(elem => {
      return {
        id_contenedor: elem.id,
        id_ubicacion: props.ubicacionPickingSeleccionada.id,
        id_muelle: elem.id_muelle
      };
    });
    setDisableAllButtons(true);
    Database.post(
      "/update-contenedores-a-picking",
      { array },
      null,
      props
    ).then(
      res => {
        var arrayDePallets = res.message;

        const texto = `Los siguientes pallets se asignaron con éxito: ${arrayDePallets.join(
          ", "
        )}`;
        setDisableAllButtons(false);
        toast.success(texto);
        getContenedores();

        // Reseteo el lote asi no vuelve a enviar todo lo que estaba antes
        setLote([]);
      },
      err => {
        setDisableAllButtons(false);
        toast.error(err.message);
      }
    );
  };

  // const getDataSelectedProductoPresentacion = (data) => {
  //     setSelectedProductoPresentacion(data)
  //    // setCalle(data);
  //   //  setColumnaStatus(true);
  //     if (data !== null) {
  //         setButtonStatus(true);
  //     }
  // }

  return (
    <Dialog
      open={props.openAsignarDialog}
      onClose={props.handleClose}
      maxWidth="xl"
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Seleccionar los pallets a mover {enabledButton}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardHeader color="primary">
            <h4>Pallets</h4>
          </CardHeader>
          <CardBody>
            <h5>Información sobre ubicacion Destino</h5>
            <p>
              <span>Ubicación:</span>
              <strong>
                {props.ubicacionPickingSeleccionada &&
                  props.ubicacionPickingSeleccionada["Calle.calle"] +
                    "-" +
                    props.ubicacionPickingSeleccionada["Columna.columna"] +
                    "-" +
                    props.ubicacionPickingSeleccionada.nivel}
              </strong>{" "}
              <br />
              <span>Dato Logístico:</span>
              <strong>
                {props.ubicacionPickingSeleccionada &&
                  props.ubicacionPickingSeleccionada[
                    "ProductosPresentacion.producto"
                  ]}
              </strong>
            </p>

            <MaterialTable
              id="tabla-pallets-a-picking"
              isLoading={isLoading}
              columns={columnasTablaPalletsAPicking}
              data={tableData}
              title=""
              //      localization={localization_options}
              actions={[
                rowData => ({
                  icon: "send",
                  tooltip: "Enviar a ubicación de pícking",
                  onClick: (event, rowData) => addToLote(rowData),
                  disabled: !isInLote(rowData)
                }),
                rowData => ({
                  icon: "undo",
                  tooltip: "Devolver a su ubicación actual",
                  onClick: (event, rowData) => removeFromLote(rowData),
                  disabled: isInLote(rowData)
                })
              ]}
              options={{
                filtering: true,
                exportDelimiter: ";",
                headerStyle: {
                  backgroundColor: lightGreen[700],
                  color: "#FFF"
                },
                selection: false,
                actionsColumnIndex: -1
              }}
            ></MaterialTable>
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={clickAceptar}
          color="primary"
          autoFocus
          disabled={disableAllButtons}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
