import React, { useState, useEffect } from "react";
// style
import lightGreen from "@material-ui/core/colors/lightGreen";
// material table
import MaterialTable from "material-table";
import { localization } from "variables/general.js";
//redux
//import { connect } from "react-redux";
//import * as actionCreators from "../../../store/actions/index";

import Database from "variables/Database.js";
import { toast } from "react-toastify";
import moment from "moment";

export const ColumnsListadoContenedor = [
  //{ title: "Numero", field: "id" },
  { title: "Pallet", field: "id" },
  { title: "Ubicacion", field: "ubicacion" },
  { title: "Muelle", field: "muelle" },
  { title: "Calle Salida", field: "calle_salida" },
  { title: "Dato Logístico", field: "producto_presentacion" },
  { title: "Cod. Interno", field: "codigo" },
  { title: "Lote", field: "lote" },
  { title: "Cliente", field: "razon_social" },
  { title: "Bultos", field: "cantidad_bultos" },
  { title: "Unidades", field: "cantidad_unidades" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento, "DD/MM/YYYY").format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento, "DD/MM/YYYY").format("YYYYMMDD"))
  }
];

let localization_options = {
  header: { actions: "" }
};
localization_options = {
  ...JSON.parse(JSON.stringify(localization)),
  ...localization_options
};

function TablaMovimientos(props) {
  // Inicializar estado para guardar datos
  const [dataColumnsContenedor, setdataColumnsContenedor] = useState([]);

  useEffect(() => {
    // Obtener los datos cuando se monta el componente
    Database.get("/list-contenedores-cliente").then(
      result => {
        let dataMovimientos = [...result.result];

        dataMovimientos = dataMovimientos.map(elem => {
          return {
            ...elem,

            vencimiento_mostrar: moment(elem.vencimiento_mostrar).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          };
        });
        // Guardar datos en el state
        setdataColumnsContenedor(dataMovimientos);
        console.log(dataMovimientos);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, []);
  //props.reload

  return (
    <MaterialTable
      id="tabla-movimientos"
      isLoading={props.isLoading}
      columns={ColumnsListadoContenedor}
      data={dataColumnsContenedor}
      style={{ width: "100%" }}
      title=""
      localization={localization_options}
      actions={
        [
          /*
      rowData => ({
        icon: "search",
        tooltip: "Ver más información",
        onClick: (event, rowData) => getExtraInfoContenedor(rowData, props)
      })*/
        ]
      }
      options={{
        filtering: true,
        exportDelimiter: ";",
        headerStyle: {
          backgroundColor: lightGreen[700],
          color: "#FFF"
        },
        selection: false,
        pageSizeOptions: [5, 10, 20, 50, 100, 200, 400, 800],
        pageSize: 20,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1
        // pageSize: 10,
      }}
    ></MaterialTable>
  );
}
/*

const mapStateToProps = state => {
    return {
        dataMovimientos: state.auditoria.dataMovimientos,
        isLoading: state.auditoria.isLoading, 
        reload: state.auditoria.reload,
        infoContenedor: state.auditoria.infoContenedor, 
    }
  };
  
const mapDispatchToProps = dispatch => {
    return {
      setMovimientos: (dataMovimientos) => dispatch(actionCreators.setMovimientos(dataMovimientos)),
      setInfoContenedor: (infoContenedor) => dispatch(actionCreators.setInfoContenedor(infoContenedor)),
      setOpen: (status) => dispatch(actionCreators.setOpen(status)),
    }
  };
*/

export default /*connect(mapStateToProps, mapDispatchToProps)*/ TablaMovimientos;
