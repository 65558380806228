import moment from "moment";

/// Entradas ///
export const StateListAuditoriaEntradas = {
  isLoading: false
};

export const ColumnsListado = [
  { title: "ID", field: "id" },
  { title: "Nro Entrada", field: "id_aud_entrada" },
  { title: "Codigo", field: "codigo" },
  { title: "Presentacion", field: "producto_presentacion" },
  { title: "Unidad", field: "producto_unidad" },
  { title: "Pallet", field: "id_contenedor" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento).format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  },
  { title: "Lote", field: "lote" },
  { title: "Cantidad Unidades", field: "cantidad_unidades" }
];
/// Entradas ///

/// Movimientos ///
export const StateListAuditoriaMovimientos = {
  isLoading: false
};

export const ColumnsListadoMovimientos = [
  { title: "Cliente", field: "razon_social" },
  {
    title: "Fecha",
    field: "updatedTimestamp_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.updatedTimestamp).format("YYYYMMDD")) -
      parseInt(moment(b.updatedTimestamp).format("YYYYMMDD"))
  },
  { title: "Pallet", field: "id_contenedor" },
  { title: "Codigo", field: "codigo" },
  { title: "Presentacion", field: "producto_presentacion" },
  { title: "Unidad", field: "producto_unidad" },
  { title: "Lote", field: "lote" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento).format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  },
  { title: "Cantidad Unidades", field: "cantidad_unidades" },
  { title: "Muelle", field: "muelle" },
  { title: "Calle de Salida", field: "calle_salida" },
  { title: "Ubicacion", field: "ubicacion" }
];
/// Movimientos ///

/// Salidas ///
export const StateListAuditoriaSalidas = {
  isLoading: false
};

export const ColumnsListadoSalidas = [
  { title: "ID", field: "id" },
  // { title: "Fecha", field: "fecha_mostrar" },
  {
    title: "Fecha",
    field: "fecha_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.createdTimestamp).format("YYYYMMDD")) -
      parseInt(moment(b.createdTimestamp).format("YYYYMMDD"))
  },
  { title: "Nro Salida", field: "audSalidaId" },
  { title: "Pallet", field: "id_contenedor" },
  { title: "Codigo", field: "codigo" },
  { title: "Presentacion", field: "producto_presentacion" },
  { title: "Unidad", field: "producto_unidad" },
  { title: "Lote", field: "lote" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento).format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  },
  { title: "Cantidad Unidades", field: "cantidad_unidades" }
];
/// Salidas ///

/// Ajustes ///
export const StateListAuditoriaAjustes = {
  isLoading: false
};

export const ColumnsListadoAjustes = [
  { title: "Cliente", field: "razon_social" },
  {
    title: "Fecha",
    field: "fecha_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.fecha).format("YYYYMMDD")) -
      parseInt(moment(b.fecha).format("YYYYMMDD"))
  },
  { title: "Pallet", field: "id_contenedor" },
  { title: "Codigo", field: "codigo" },
  { title: "Presentacion", field: "producto_presentacion" },
  { title: "Unidad", field: "producto_unidad" },
  { title: "Cantidad Unidades", field: "cantidad_unidades" },
  { title: "Cantidad Bultos", field: "cantidad_bultos" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento).format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  },
  { title: "Lote", field: "lote" },
  { title: "Usuario", field: "username" }
];
/// Ajustes ///
