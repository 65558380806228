import moment from "moment";

export const StateEntradas = {
  orderForm: {
    referencia: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Referencia",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    id_muelle: {
      elementType: "select",
      elementConfig: {
        label: "Muelle de Entrada",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    devolucion: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Es devolución",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    comentario: {
      elementType: "textarea",
      elementConfig: {
        label: "Comentarios",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false,
  openDialogProducto: false,
  openDialogCliente: false,
  openDialogUnidad: false,
  openDialogPresentacion: false,
  rowProductoPresentacion: null,
  rowEditProducto: null,
  detalleentradas: [],
  infoCliente: null
};

export const ColumnsListado = [
  { title: "Codigo", field: "codigo", editable: "never" },
  { title: "Descripcion", field: "descripcion", editable: "never" },
  { title: "Cantidad", field: "cantidad", type: "numeric" }
  //{ title: 'Cantidad', field: 'cantidad', render: rowData => <input type="text"/>}
];

export const ColumnsListadoProductos = [
  { title: "Dato Logístico", field: "presentacion.descripcion" },
  { title: "Articulo", field: "presentacion.ProductosUnidad.descripcion" },
  { title: "EAN13", field: "presentacion.ProductosUnidad.ean13" },
  { title: "Cod. Artículo", field: "presentacion.ProductosUnidad.codigo" },
  { title: "Cantidad de Pallets", field: "cantidad_contenedores" },
  { title: "Bultos por Pallet", field: "cantidad_bultos" },
  { title: "Cantidad de Unidades", field: "cantidad_unidades" },
  { title: "Lote", field: "lote", editable: "never" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento).format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  }

  //{ title: 'Cantidad', field: 'cantidad', render: rowData => <input type="text"/>}
];

// export const ColumnsListado = [
// { title: "Numero", field: "numero" },
// { title: "Descripcion", field: "descripcion" },
// { title: "Largo (mts)", field: "largo" },
// { title: "Sentido", field: "sentido" },
// { title: "Segmento", field: "segmento" },
// { title: "Sector", field: "sector" },
// { title: "Picking", field: "picking" }
// ];
