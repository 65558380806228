export const StateListMuelles = {
  muelles: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateMuelles = {
  orderForm: {
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    capacidad: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Capacidad (pallets)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    ancho: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (m)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (m)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    es_devolucion: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Habilitado para recibir devoluciones",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Descripcion", field: "descripcion" },
  { title: "Capacidad", field: "capacidad" },
  { title: "Ocupacion", field: "ocupacion" },
  { title: "Devolucion", field: "es_devolucion" }
];
