import * as actionTypes from "../actions/actionTypes";

const initialState = {
  dataMovimientos: [],
  isLoading: true,
  reload: false,
  contenedores: [],
  infoContenedor: null,
  openModalTabla: false
};

// TODO: Borrar?
// const reloadTable = (state) => {
//     let state_copy = {...state};
//     state_copy.reload = !state_copy.reload;
//     return {...state_copy}
// }

const setMovimientos = (state, action) => {
  let state_copy = state;
  state_copy.dataMovimientos = action.dataMovimientos;
  state_copy.isLoading = false;
  return { ...state_copy };
};

const setAuditoriaContenedores = (state, action) => {
  let state_copy = state;
  state_copy.contenedores = action.contenedores;
  state_copy.isLoading = false;
  return { ...state_copy };
};

const setInfoContenedor = (state, action) => {
  let state_copy = state;
  state_copy.infoContenedor = action.infoContenedor;
  state_copy.isLoading = false;
  return { ...state_copy };
};

const setOpen = (state, action) => {
  let state_copy = { ...state };
  state_copy.openModalTabla = true;
  return { ...state_copy };
};

const setClose = (state, action) => {
  let state_copy = { ...state };
  state_copy.openModalTabla = false;
  return { ...state_copy };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MOVIMIENTOS:
      return setMovimientos(state, action);

    case actionTypes.SET_AUDITORIA_CONTENEDORES:
      return setAuditoriaContenedores(state, action);

    case actionTypes.SET_INFO_CONTENEDOR:
      return setInfoContenedor(state, action);

    case actionTypes.SET_OPEN:
      return setOpen(state, action);

    case actionTypes.SET_CLOSE:
      return setClose(state, action);

    default:
      return state;
  }
};

export default reducer;
