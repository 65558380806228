const assert = (condition, message) => {
  if (!condition) {
    throw new Error(message || "Assertion failed");
  }
};

const _assertEsBloque = calle => {
  return assert(
    calle.className.includes("Bloque"),
    "Error de tipo de clase: Calle debe ser de la clase Bloque"
  );
};

const _getCalleById = id_calle => {
  assert(
    typeof id_calle === "string",
    "Error de tipo: id_calle debe ser un string"
  );
  let calles = document.getElementsByClassName("Bloque");
  for (let c of calles) {
    if (c.id === id_calle) {
      return c;
    }
  }
};

const _getCallePosicionX = calle => {
  _assertEsBloque(calle);
  let pos_x =
    calle.getAttribute("data-x") != null ? calle.getAttribute("data-x") : "0";
  return pos_x;
};

const _getCallePosicionY = calle => {
  _assertEsBloque(calle);
  let pos_y =
    calle.getAttribute("data-y") != null ? calle.getAttribute("data-y") : "0";
  return pos_y;
};

const _getCalleRotacion = calle => {
  _assertEsBloque(calle);
  return calle.getAttribute("degrees");
};

const getDataCalleById = id_calle => {
  let calle = _getCalleById(id_calle);
  let x = _getCallePosicionX(calle);
  let y = _getCallePosicionY(calle);
  let rotacion = _getCalleRotacion(calle);
  return { id: id_calle, x: x, y: y, rotacion: rotacion };
};

export default getDataCalleById;
