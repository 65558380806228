import moment from "moment";

export const StateListContenedores = {
  contenedores: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false,
  openNuevaUbicacionDialog: false,
  selectedRowData: null
};

export const StateContenedores = {
  orderForm: {
    id: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número",
        fullWidth: true,
        disabled: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    codigo: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Cod. Interno",
        fullWidth: true,
        disabled: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripcion",
        fullWidth: true,
        disabled: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    lote: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Lote",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    bultos: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad de Bultos",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    unidades: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad de Unidades",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }

    // sentido: {
    //     elementType: 'select',
    //     elementConfig: {
    //       label: 'Sentido',
    //       options: [
    //         { value: "A", displayValue: "Ascendente" },
    //         { value: "D", displayValue: "Descendente" }
    //       ],
    //       fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //       required: true
    //     },

    //     valid: false,
    //     touched: false
    //   }
    // orden: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Orden',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
  },

  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListado = [
  //{ title: "Numero", field: "id" },
  { title: "Pallet", field: "id" },
  { title: "Ubicacion (pasillo-columna-nivel)", field: "ubicacion" },
  { title: "Muelle", field: "muelle" },
  { title: "Calle Salida", field: "calle_salida" },
  { title: "Dato Logístico", field: "producto_presentacion" },
  { title: "Cod. Interno", field: "codigo" },
  { title: "Lote", field: "lote" },
  { title: "Bultos", field: "cantidad_bultos" },
  { title: "Unidades", field: "cantidad_unidades" },
  {
    title: "Vencimiento",
    field: "vencimiento",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento, "DD/MM/YYYY").format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento, "DD/MM/YYYY").format("YYYYMMDD"))
  },
  { title: "Cliente", field: "razon_social" }
];
