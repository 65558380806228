import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";
import ModalAgregarNivel from "./ModalAgregarNivel";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import UndoIcon from "@material-ui/icons/Undo";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

import { StateColumnas, StateColumnas2 } from "../VariablesState";

import Grid from "@material-ui/core/Grid";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },

  card: {}
};

const SortableItem = sortableElement(
  ({ value, deleteItem, editItem, undoItem }) => {
    let style = {};
    let iconos = null;
    if (value.eliminado) {
      style.backgroundColor = "lightcoral";
    } else if (value.insertado) {
      style.backgroundColor = "lightgreen";
    } else if (value.modificado) {
      style.backgroundColor = "lightblue";
    }

    if (value.eliminado) {
      iconos = (
        <IconButton onClick={() => undoItem(value)}>
          <UndoIcon />
        </IconButton>
      );
    } else {
      iconos = [
        <IconButton key="icono1" onClick={() => deleteItem(value)}>
          <DeleteIcon />
        </IconButton>,
        <IconButton key="icono2" onClick={() => editItem(value)}>
          <EditIcon />
        </IconButton>
      ];
    }

    return (
      <TableRow style={style}>
        {/* <TableCell>
        <DragHandle />
      </TableCell>
     */}
        <TableCell>{iconos}</TableCell>
        <TableCell>{value.nivel}</TableCell>
        <TableCell>{value.altura}</TableCell>
        <TableCell>{value.peso_maximo}</TableCell>
      </TableRow>
    );
  }
);

// const DragHandle = sortableHandle(() => (
//   <span>
//     <ControlCamera />
//   </span>
// ));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <Table style={{ backgroundColor: "#F9F9F9" }} size="small">
      <TableHead>
        <TableRow>
          {/* <TableCell>Ordenar</TableCell> */}
          <TableCell>Acciones</TableCell>
          <TableCell>Nivel</TableCell>
          <TableCell>Altura</TableCell>
          <TableCell>Peso Máximo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
});

class EditColumna extends Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.idColumna)
      this.state = JSON.parse(JSON.stringify(StateColumnas2));
    else this.state = JSON.parse(JSON.stringify(StateColumnas));
  }

  deleteItem = rowItem => {
    let items = [...this.state.items];
    let indice = items.indexOf(rowItem);
    if (rowItem.insertado) {
      items.splice(indice, 1);
    } else {
      items[indice].eliminado = true;
    }
    this.setState({
      items: items
    });
  };

  editItem = rowItem => {
    this.setState({
      rowItem: rowItem,
      openDialog: true
    });
  };

  undoItem = rowItem => {
    // console.log(rowItem);
    let objeto = { ...rowItem };
    objeto.eliminado = false;

    let items = [...this.state.items];
    let indice = items.indexOf(rowItem);

    items[indice] = objeto;

    this.setState({
      items: items
    });
  };

  getTipos = () => {
    Database.get("/list-columnas-tipos/", this).then(
      resultado => {
        let array = resultado.result;
        array = array.map(elem => {
          return { value: elem.id, displayValue: elem.descripcion };
        });

        let orderFormCopy = { ...this.state.orderForm };

        orderFormCopy.id_columna_tipo.elementConfig.options = array;

        this.setState({
          orderForm: orderFormCopy
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getColumna = id => {
    Database.get(
      "/list-columna/" + process.env.REACT_APP_ID_ALMACEN + "/" + id,
      this
    ).then(
      resultado => {
        let array = resultado.result[0];
        let array2 = resultado.result[1];
        if (array.length > 0) {
          let orderFormCopy = { ...this.state.orderForm };
          let orderFormCopy2 = { ...this.state.orderForm2 };
          let orderFormCopy3 = { ...this.state.orderForm3 };
          for (let key in orderFormCopy) {
            if (array[0][key]) orderFormCopy[key].value = array[0][key];
          }
          for (let key in orderFormCopy2) {
            if (array[0][key]) orderFormCopy2[key].value = array[0][key];
          }
          for (let key in orderFormCopy3) {
            if (array[0][key]) orderFormCopy3[key].value = array[0][key];
          }

          let items = [];
          if (array[0].id_columna_tipo == 1) items = array2;

          let { orderForm, formIsValid } = inputAllChangedHandler(
            orderFormCopy
          );

          this.setState({
            orderForm: orderForm,
            orderForm2: orderFormCopy2,
            orderForm3: orderFormCopy3,
            formIsValid: formIsValid,
            items: items
          });
        }
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitForm = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      let sql;
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }

      if (this.state.orderForm.dinamica.value) {
        for (let key in this.state.orderForm3) {
          objeto[key] = this.state.orderForm3[key].value;
        }
      } else {
        for (let key in this.state.orderForm2) {
          objeto[key] = this.state.orderForm2[key].value;
        }
      }

      if (this.props.match.params.idColumna) {
        sql = "/update-columna/";
        objeto.id = this.props.match.params.idColumna;
        objeto.id_almacen = process.env.REACT_APP_ID_ALMACEN;
        objeto.id_calle = this.props.idCalle;
        objeto.estado = 1;
      } else {
        sql = "/insert-columnas";
        objeto.id_almacen = process.env.REACT_APP_ID_ALMACEN;
        objeto.id_calle = this.props.idCalle;
        objeto.estado = 1;
      }

      objeto.id_almacen = process.env.REACT_APP_ID_ALMACEN;
      objeto.id_calle = this.props.idCalle;
      objeto.estado = 1;

      if (objeto.id_columna_tipo == 1) {
        objeto.niveles = this.state.items;
      } else {
        objeto.niveles = [
          { es_picking: 1, nivel: 1, altura: 0, peso_maximo: 0 }
        ];
      }

      this.setState({
        disableAllButtons: true
      });

      Database.post(sql, {
        id_almacen: process.env.REACT_APP_ID_ALMACEN,
        id_calle: this.props.idCalle,
        niveles: this.state.items,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              toast.success("Las columnas se han insertado con éxito");
              this.props.getListado();
              if (this.props.match.params.idColumna)
                this.getColumna(this.props.match.params.idColumna);
              else this.props.history.goBack();
            }
          );
        },
        err => {
          // console.log(err);
          this.setState({
            disableAllButtons: false
          });
          toast.error(err.message);
        }
      );
    } else {
      this.setState({
        disableAllButtons: false
      });
      toast.error("verificar los campos en el formulario");
    }
  };

  handleChange = (event, identifier) => {
    let orderFormCopy = { ...this.state.orderForm };

    let { orderForm, formIsValid } = inputChangedHandler(
      event,
      identifier,
      orderFormCopy
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });
  };

  handleChange2 = (event, identifier) => {
    let orderFormCopy2 = { ...this.state.orderForm2 };

    let { orderForm, formIsValid } = inputChangedHandler(
      event,
      identifier,
      orderFormCopy2
    );
    this.setState({ orderForm2: orderForm, formIsValid2: formIsValid });
  };
  handleChange3 = (event, identifier) => {
    let orderFormCopy3 = { ...this.state.orderForm3 };

    let { orderForm, formIsValid } = inputChangedHandler(
      event,
      identifier,
      orderFormCopy3
    );
    this.setState({ orderForm3: orderForm, formIsValid3: formIsValid });
  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
      rowItem: null
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let itemsCopy = arrayMove(this.state.items, oldIndex, newIndex);

    itemsCopy = itemsCopy.map((elem, index) => {
      return {
        ...elem,
        nivel: index
      };
    });

    this.setState({ items: itemsCopy });
  };

  onClickItem = (rowItem, objeto) => {
    // alert("entro");

    let items = [...this.state.items];
    if (rowItem) {
      let indice = items.indexOf(rowItem);
      for (let key in objeto) {
        if (key in items[indice]) items[indice][key] = objeto[key];
      }
      items[indice].modificado = true;
    } else {
      //objeto.nivel = items.length;
      objeto.insertado = true;
      items.push(objeto);
    }
    this.setState({ items: items, openDialog: false, rowItem: false });
  };

  componentDidMount() {
    if (!this.props.idCalle) {
      this.props.history.goBack();
      toast.error("No existe id de calle asignada");
    }

    if (this.props.match.params.idColumna) {
      this.getColumna(this.props.match.params.idColumna);
    }

    this.getTipos();
  }

  render() {
    const formElementsArray = [];

    //console.log(this.state.orderForm);
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    //console.log(this.state.orderForm);

    return [
      <form
        key="formu"
        onSubmit={event => {
          this.handleSubmitForm(event);
        }}
      >
        <Card className={this.props.classes.card}>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              {this.props.match.params.idColumna ? (
                <span>
                  Editar{" "}
                  <strong>
                    Columna Nº {this.state.orderForm.numero.value}
                  </strong>
                </span>
              ) : (
                <span>Nuevas Columnas</span>
              )}{" "}
              de{" "}
              <strong>
                Calle Nº {this.props.rowCalle && this.props.rowCalle.numero}
              </strong>
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para{" "}
              {this.props.match.params.idColumna ? (
                <span>editar una Columna</span>
              ) : (
                <span>agregar columnas nuevas</span>
              )}{" "}
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {!this.props.match.params.idColumna && (
                <div>
                  <InputNew
                    objeto={this.state.orderForm.desde}
                    changed={event => {
                      this.handleChange(event, "desde");
                    }}
                  />

                  <InputNew
                    objeto={this.state.orderForm.hasta}
                    changed={event => {
                      this.handleChange(event, "hasta");
                    }}
                  />
                </div>
              )}

              {this.props.match.params.idColumna && (
                <div>
                  <InputNew
                    objeto={this.state.orderForm.numero}
                    changed={event => {
                      this.handleChange(event, "numero");
                    }}
                  />
                </div>
              )}

              {this.props.rowCalle &&
                this.props.rowCalle.id_posicion_picking == 3 && (
                  <InputNew
                    objeto={this.state.orderForm.numerico}
                    changed={event => {
                      this.handleChange(event, "numerico");
                    }}
                  />
                )}

              <InputNew
                objeto={this.state.orderForm.id_columna_tipo}
                changed={event => {
                  this.handleChange(event, "id_columna_tipo");
                }}
              />
              <Grid container spacing={2}>
                {this.state.orderForm.dinamica.value == 0 && (
                  <Grid item md={6}>
                    <InputNew
                      objeto={this.state.orderForm2.ancho_pallet}
                      changed={event => {
                        this.handleChange2(event, "ancho_pallet");
                      }}
                    />
                  </Grid>
                )}

                {this.state.orderForm.dinamica.value == 1 && (
                  <Grid item md={6}>
                    <InputNew
                      objeto={this.state.orderForm3.ancho}
                      changed={event => {
                        this.handleChange3(event, "ancho");
                      }}
                    />
                  </Grid>
                )}

                <Grid item md={6}>
                  <InputNew
                    objeto={this.state.orderForm.dinamica}
                    changed={event => {
                      this.handleChange(event, "dinamica");
                    }}
                  />
                </Grid>
              </Grid>

              {this.state.orderForm.id_columna_tipo.value == 1 && (
                <div>
                  <Button
                    style={{ marginTop: "25px" }}
                    onClick={() => this.setState({ openDialog: true })}
                    color="primary"
                  >
                    <AddIcon /> Agregar Nivel
                  </Button>
                  <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                    {this.state.items.map((elem, index) => (
                      <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={elem}
                        deleteItem={this.deleteItem}
                        editItem={this.editItem}
                        undoItem={this.undoItem}
                      />
                    ))}
                  </SortableContainer>
                  {this.state.isLoading && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div style={{ marginTop: 25 }}>
              <Button
                style={{ marginTop: "25px" }}
                color="info"
                onClick={() => this.props.history.goBack()}
              >
                <ArrowBack />
                Volver
              </Button>
              <Button
                style={{ marginTop: "25px" }}
                color="primary"
                variant="contained"
                disabled={this.state.disableAllButtons}
                type="submit"
              >
                <Save /> Guardar
              </Button>
            </div>
          </CardBody>
        </Card>
      </form>,

      <div>
        {this.state.openDialog && (
          <ModalAgregarNivel
            rowItem={this.state.rowItem}
            onClick={this.onClickItem}
            openDialog={this.state.openDialog}
            closeDialog={this.closeDialog}
          />
        )}
      </div>
    ];
  }
}

export default withRouter(withStyles(styles)(EditColumna));
