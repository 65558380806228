import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { StateEditHijo } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditHijo extends Component {
  state = JSON.parse(JSON.stringify(StateEditHijo));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  getHijoEdit = id => {
    Database.get("/list-hijos-empleados/" + id).then(resultado => {
      console.log("HIJO RESULT: " + resultado.result[0]);
      if (resultado.result.length > 0) {
        this.setState({
          hijoEdit: resultado.result[0]
        });

        let editHijoFormAlt = { ...this.state.editHijoForm };
        editHijoFormAlt.nombre.value = resultado.result[0].nombre;
        editHijoFormAlt.apellido.value = resultado.result[0].apellido;
        editHijoFormAlt.certificado_foto.value =
          resultado.result[0].certificado_foto;
        this.state.fechaVencimiento = resultado.result[0].fechaVencimiento;
        for (let key in editHijoFormAlt) {
          editHijoFormAlt[key].touched = true;
          editHijoFormAlt[key].valid = true;
        }

        this.setState({
          editHijoForm: editHijoFormAlt
        });
        // this.getHijosType("edit", editHijoFormAlt);
      } else {
        this.setState({
          hijoEdit: null
        });
      }
    });
  };

  // handleChangePass = (event) => {
  //   event.preventDefault();
  //   this.setState({
  //     openChangePass:false
  //   })

  //   Database.post(`/update-pass`,{id: this.props.match.params.idhijo,newpass:event.target.contrasenia.value})
  //     .then(res => {
  //       toast.success("El hijo se ha modificado con exito!");
  //     },err =>{
  //       toast.error(err.message);

  //   })
  // }

  handleSubmitEditHijo = event => {
    event.preventDefault();

    Database.post(
      `/update-hijo-empleado`,
      {
        id: this.props.match.params.idhijo,
        nombre: this.state.editHijoForm.nombre.value,
        apellido: this.state.editHijoForm.apellido.value,
        certificado_foto: this.state.editHijoForm.certificado_foto.value,
        fechaVencimiento: this.state.fechaVencimiento
      },
      this
    )
      //Database.post(`/update-hijo`, { id: this.props.match.params.idhijo, razon_social: this.state.editAusentismoForm.razon_social.value, nombre_fantasia: this.state.editAusentismoForm.nombre_fantasia.value, numero_ausentismo: this.state.editAusentismoForm.numero_ausentismo.value,  ruc: this.state.editAusentismoForm.ruc.value,  telefono: this.state.editAusentismoForm.telefono.value })
      .then(
        res => {
          this.setState(
            {
              successSubmitEdit: true,
              editFormIsValid: false,
              disableAllButtons: false
            },
            () => {
              toast.success("El hijo se ha modificado con exito!");

              this.props.getHijosAdmin();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
  };

  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editHijoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      editHijoForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  resetEditForm = () => {
    let editHijoFormAlt = { ...this.state.editHijoForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in editHijoFormAlt) {
      editHijoFormAlt[key].value = "";
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    });
  };

  componentDidMount() {
    console.log(this.props.match.params);
    this.getHijoEdit(this.props.match.params.idhijo);
  }

  handleDateChange = (date, value) => {
    this.setState({
      fechaVencimiento: date
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.editHijoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editHijoForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitEditHijo(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Editar Hijo</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos de la sanción
            </p>
          </CardHeader>
          <CardBody>
            {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
        Cambiar Contraseña
      </Button> */}

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"edithijo-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={event =>
                    this.inputEditChangedHandler(event, formElement.id)
                  }
                />
              ))}

              <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="vencimiento"
                  label="Vencimiento Certificado"
                  format="dd/MM/yyyy"
                  value={this.state.fechaVencimiento}
                  onChange={this.handleDateChange}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/hijos")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={
                !this.state.editFormIsValid || this.state.disableAllButtons
              }
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,

      <Dialog
        open={this.state.openChangePass}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
        <form
          onSubmit={event => {
            this.handleChangePass(event);
          }}
        >
          {this.state.openChangePass && (
            <DialogContent>
              <DialogContentText>
                Ingrese una nueva contraseña para el Hijo
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="contrasenia"
                name="contrasenia"
                label="nueva contraseña"
                type="password"
                fullWidth
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(EditHijo));
