import React from "react";
import Columna from "./Columna/Columna";
import * as calc from "../CalculosAccesorios/CalculosAccesorios";

// props tipo columnas cantcol rotacion nombresCol listasNombresCol

const Columnasparimpar = props => {
  // Agregamos las columnas a comps
  let columna = [];
  let colIzqDer = "";
  let nombresCol = [];
  let listasNombresCol = props.listasNombresCol;
  let pos_x_cols = props.pos_x_cols;
  let pos_y = 0;

  if (props.tipo === "PI") {
    props.columnas.map((c, indexcol) => {
      colIzqDer = calc.indexIzqDer(indexcol);
      if (colIzqDer === "izquierda") {
        nombresCol = listasNombresCol[1];
      } else {
        nombresCol = listasNombresCol[0];
      }
      columna.push(
        <Columna
          es_movible={false}
          pos_x={pos_x_cols[indexcol]}
          pos_y={pos_y}
          id={c.columna_nombre}
          width={c.width}
          height={c.height}
          cantCol={props.cantcol}
          colIzqDer={colIzqDer}
          rotacion_col={props.rotacion}
          nombrescol={nombresCol}
        />
      );
      return null;
    });
  }
  if (props.tipo === "I") {
    colIzqDer = "izquierda";
    nombresCol = listasNombresCol[1];
    props.columnas.map((c, indexcol) => {
      columna.push(
        <Columna
          es_movible={false}
          pos_x={pos_x_cols[indexcol]}
          pos_y={pos_y}
          id={c.columna_nombre}
          width={c.width}
          height={c.height}
          cantCol={props.cantcol}
          colIzqDer={colIzqDer}
          rotacion_col={props.rotacion}
          nombrescol={nombresCol}
        />
      );
    });
  }
  if (props.tipo === "P") {
    colIzqDer = "derecha";
    nombresCol = listasNombresCol[0];
    let pos_x = pos_x_cols[1];
    let columnaPar = props.columnas[0];
    columna.push(
      <Columna
        es_movible={false}
        pos_x={pos_x}
        pos_y={pos_y}
        id={columnaPar.columna_nombre}
        width={columnaPar.width}
        height={columnaPar.height}
        cantCol={props.cantcol}
        colIzqDer={colIzqDer}
        rotacion_col={props.rotacion}
        nombrescol={nombresCol}
      />
    );
  }
  return columna;
};

export default Columnasparimpar;
