import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import { StateCalles } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditCalle extends Component {
  state = JSON.parse(JSON.stringify(StateCalles));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  getCalleEdit = id => {
    Database.get("/list-calle/" + id, this).then(
      resultado => {
        let objeto = resultado.result[0] || null;
        if (objeto) {
          let orderFormCopy = { ...this.state.orderForm };

          orderFormCopy.numero.value = objeto.numero || null;
          orderFormCopy.descripcion.value = objeto.descripcion || null;
          orderFormCopy.largo.value = objeto.largo || 0;
          orderFormCopy.sentido.value = objeto.sentido || null;
          orderFormCopy.segmento.value = objeto.segmento || 0;
          orderFormCopy.sector.value = objeto.id_sector || 0;
          orderFormCopy.picking.value = objeto.id_posicion_picking || null;

          let { orderForm, formIsValid } = inputAllChangedHandler(
            orderFormCopy
          );

          this.setState({
            orderForm: orderForm,
            formIsValid: formIsValid
          });
        }
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getSectores = () => {
    Database.get(
      "/list-sectores/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultadoSectores = [...res.result];
        let a = [];
        console.log(resultadoSectores[0]);
        resultadoSectores[0].forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.orderForm };
        formulario.sector.elementConfig.options = [...a];
        console.log(formulario);
        this.setState({
          orderForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitForm = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }
      this.setState({
        disableAllButtons: true
      });
      Database.post(`/update-calle`, {
        id: this.props.match.params.idcalle,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              if (res.result[0].resultado == -1)
                toast.error(
                  "Ya existe un pasillo y segmento con esos valores. Verifique los valores ingresados."
                );
              else toast.success("El pasillo se ha modificado con exito!");
              this.props.getCallesAdmin();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
    } else {
      toast.error("verificar los campos en el formulario");
    }
  };

  // resetEditForm = () => {
  //   let editCalleFormAlt = { ...this.state.editCalleForm };
  //   let successSubmitEdit = this.state.successSubmitEdit;
  //   for (let key in editCalleFormAlt) {
  //     editCalleFormAlt[key].value = ''
  //   }

  //   this.setState({
  //     editFormIsValid: false,
  //     successSubmitEdit: successSubmitEdit
  //   })

  // }

  getPicking = () => {
    Database.get("/list-posicion-picking/", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        console.log(resultado);
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.orderForm };
        formulario.picking.elementConfig.options = [...a];
        console.log(formulario);
        this.setState({
          orderForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    this.getSectores();
    this.getCalleEdit(this.props.match.params.idcalle);
    this.getPicking();
  }

  render() {
    const formElementsArray = [];

    //console.log(this.state.orderForm);
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitForm(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Editar Pasillo
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del pasillo
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <InputNew
                  key={"input-" + formElement.id}
                  objeto={formElement.config}
                  changed={event => {
                    let { orderForm, formIsValid } = inputChangedHandler(
                      event,
                      formElement.id,
                      this.state.orderForm
                    );
                    this.setState({
                      orderForm: orderForm,
                      formIsValid: formIsValid
                    });
                  }}
                />
              ))}
            </div>
            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/calles")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    ];
  }
}

export default withRouter(withStyles(styles)(EditCalle));
