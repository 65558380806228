import React, { Component } from "react";
import * as calc from "./CalculosAccesorios/CalculosAccesorios";
import "./Mapa.css";
import Tabla from "./Tabla/Tabla";
import Bloque from "./Bloque/Bloque";
import Muelle from "./Muelle/Muelle";
import Controles from "./Controles/Controles";
import Regla from "./Regla/Regla";
import SelectorTablas from "./SelectorTablas/SelectorTablas";
import { Animated } from "react-animated-css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import getDataCalleById from "./db_mapa/db_calles";
import { SubmitDataEspacial } from "./db_mapa/db_guardar_data_espacial";
import { deleteDataEspacial } from "./db_mapa/db_borrar_data_espacial";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

// Icons
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";

// dimensiones del almacen ingresan en metros
// const dimensionesAlmacen = [{ height: 100, width: 200 }]

/*
const getIdBloqueMapa = (bloque) => { 
  if (esCalle(bloque)) { 
    return bloque.calle.id;
  }
}
*/

class App extends Component {
  getAllSpatialObjectsOnMap = () => {
    let bloques = this.props.bloquesEnMapa;
    let muelles = this.props.muellesEnMapa;
    let calles_salida = this.props.callesDeSalidaEnMapa;

    let allSpatialObjectsOnMap = [...bloques, ...muelles, ...calles_salida];
    return allSpatialObjectsOnMap;
  };

  // checkAnyBlockSelected = () => {
  //   window.test = this.props.bloquesEnMapa;
  //   let selected_array = this.props.bloquesEnMapa.map( b => b.is_selected);
  //   return selected_array.some( (x) => { return (x === true)})
  // }

  checkIfAnySpatialObjectSelected = array_in => {
    let selected_array = array_in.map(e => e.is_selected);
    return selected_array.some(x => {
      return x === true;
    });
  };

  getTitleSelectedBlock = () => {
    let bloquesEnMapa_copy = this.props.bloquesEnMapa;
    let titulo = "";
    bloquesEnMapa_copy.map(b => {
      if (b.is_selected) {
        titulo = b.calle.nombre_calle;
      }
      return titulo;
    });
    return titulo;
  };

  getTitleSelectedSpatialObject = array_objects => {
    let titulo = "";
    let selectedObjectIndex = this.indexSelectedSpatialObject(array_objects);
    let spatialObject = array_objects[selectedObjectIndex];

    if (spatialObject.spatialObjectType === "bloque") {
      titulo = spatialObject.calle.nombre_calle;
    }
    if (spatialObject.spatialObjectType === "muelle") {
      titulo = spatialObject.descripcion;
    }
    if (spatialObject.spatialObjectType === "calle_salida") {
      titulo = spatialObject.descripcion;
    }
    return titulo;
  };

  getCallePorID = calle_id => {
    let indice_calle = this.props.bloques.findIndex(
      bloq => bloq.calle.id === calle_id
    );
    return this.props.bloques[indice_calle];
  };

  clickHandler = e => {
    let spatial_object_id = e.id;
    let fuente = e.fuenteData;
    if (fuente === "calle") {
      let bloque = this.getCallePorID(spatial_object_id); //TODO: Hacer general para manejar otras estructuras
      this.props.removerBloqueDeTabla(spatial_object_id);
      this.props.addBloquesEnMapa(bloque);
    } else if (fuente === "muelle") {
      let muelle = this.getMuelleById(spatial_object_id);
      this.props.addMuellesEnMapa(muelle);
    }
  };

  getMuelleById = muelle_id => {
    let muelle = this.props.muelles.filter(x => {
      return x.id === muelle_id;
    });
    return muelle[0];
  };

  indexSelectedBloque = () => {
    // get the index of selected bloque from array this.state.bloquesEnMapa
    const bloqueIndex = [...this.props.bloquesEnMapa].findIndex(x => {
      return x.is_selected === true;
    });
    return bloqueIndex;
  };

  indexSelectedSpatialObject = array_in => {
    const spatialObjectIndex = array_in.findIndex(x => {
      return x.is_selected === true;
    });
    return spatialObjectIndex;
  };

  getIdSelectedBloque = () => {
    // get the Id of the selected bloque from array this.state.bloquesEnMapa
    let selectedIndex = this.indexSelectedBloque();
    let bloqueId = this.props.bloquesEnMapa[selectedIndex].calle.id;
    return bloqueId;
  };

  getDataSelected = tipo => {
    if (tipo === "calle") {
      return { tipo: tipo, id: this.getIdSelectedBloque() };
    }
  };

  removerElementoMapa = data_elemento => {
    if (data_elemento.tipo === "calle") {
      this.removerBloqueHandler(data_elemento);
    }
    if (data_elemento.tipo === "muelle") {
      // TODO remover otros tipos de elementos
    }
  };

  removerBloqueHandler = spatial_object => {
    let selectedIndex = this.indexSelectedBloque();
    let bloqueXcopia = {
      ...this.props.bloquesEnMapa[selectedIndex]
    };
    this.props.addBloquesEnTabla(bloqueXcopia);
    this.props.removerBloqueDeMapa(bloqueXcopia);
    deleteDataEspacial(spatial_object);
  };

  guardarDataPosicion = data_elemento => {
    let tipo = data_elemento.tipo;
    let id_elem = String(data_elemento.id);
    let data_espacial = null;
    if (tipo === "calle") {
      data_espacial = getDataCalleById(id_elem);
      data_espacial["tipo"] = data_elemento.tipo;
    }
    SubmitDataEspacial(data_espacial);
  };

  render() {
    let heightAlmacen = calc.deMetrosAPixeles(
      this.props.dimensionesAlmacen[0].height
    );
    heightAlmacen = String(heightAlmacen) + "px";
    let widthAlmacen = calc.deMetrosAPixeles(
      this.props.dimensionesAlmacen[0].width
    );
    widthAlmacen = String(widthAlmacen) + "px";

    const styleAlmacen = {
      height: heightAlmacen,
      width: widthAlmacen
    };

    let spatialObjects = this.getAllSpatialObjectsOnMap();

    let control_titulo = null;
    let control_visible = false;
    let data_control = { tipo: null, id: null };
    let objectIndex = null;
    let spatialObjectX = null;
    if (this.checkIfAnySpatialObjectSelected(spatialObjects)) {
      control_visible = true;
      control_titulo = this.getTitleSelectedSpatialObject(spatialObjects);

      // TODO las siguientes 4 lineas usan "if bloque" porque falta hacer
      // data_control para muelle y calle de salida
      objectIndex = this.indexSelectedSpatialObject(spatialObjects);
      spatialObjectX = spatialObjects[objectIndex];
      if (spatialObjectX.spatialObjectType === "bloque") {
        data_control = this.getDataSelected("calle");
      }
    }

    return (
      <div className="App">
        <h1>Mapa</h1>
        <hr></hr>
        <div id="almacen" className="almacen">
          <Regla
            click_hide={this.hideReglaHandler}
            width={this.props.regla.width}
            iconType={this.props.regla.iconType}
          ></Regla>
          <TransformWrapper
            defaultScale={1}
            wheel={{ disabled: true }}
            pan={{
              disabled: false,
              disableOnTarget: [
                "Bloque",
                "ColumnaFixed",
                "CalleFixed",
                "ubicacion",
                "flecha",
                "Muelle"
              ]
            }}
            options={{ minScale: 0.1 }}
            pinch={{ disabled: true }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <div className="tools tools-fixed">
                  <button onClick={zoomIn}>
                    <ZoomInIcon />
                  </button>
                  <button onClick={zoomOut}>
                    <ZoomOutIcon />
                  </button>
                  <button onClick={resetTransform}>
                    <ZoomOutMapIcon />
                  </button>
                </div>
                <TransformComponent>
                  <div
                    id="mapa"
                    className="canvas"
                    style={styleAlmacen}
                    onClick={this.props.deselectAllSpatialObjects}
                  >
                    {spatialObjects.map((spatialObject, index) => {
                      if (spatialObject.spatialObjectType === "bloque") {
                        let bloque = spatialObject;
                        return (
                          <Bloque
                            id={bloque.calle.id}
                            calle={bloque.calle}
                            columnas={bloque.columnas}
                            cantcol={bloque.calle.cantcol}
                            key={bloque.calle.id}
                            is_selected={bloque.is_selected}
                            rotacion={bloque.rotacion}
                            degrees={bloque.rotacion}
                            tipo={bloque.tipo}
                            sentido={bloque.sentido}
                            sector={bloque.sector}
                            click={event =>
                              this.props.toggleAllSpatialObjects(event, index)
                            }
                            data_pos={bloque.data_pos || null}
                          ></Bloque>
                        );
                      }
                      if (spatialObject.spatialObjectType === "muelle") {
                        let muelle = spatialObject;
                        return (
                          <Muelle
                            id={muelle.id}
                            key={muelle.id}
                            is_selected={muelle.is_selected}
                            rotacion={muelle.rotacion}
                            degrees={muelle.rotacion}
                            descripcion={muelle.descripcion}
                            ancho={muelle.ancho}
                            largo={muelle.largo}
                            click={event =>
                              this.props.toggleAllSpatialObjects(event, index)
                            }
                            data_pos={muelle.data_pos || null}
                          ></Muelle>
                        );
                      }
                      if (spatialObject.spatialObjectType === "calle_salida") {
                        // let calle_salida = spatialObject;
                        return;
                      }
                    })}
                  </div>
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
        <div>
          <Animated
            animationIn="pulse"
            animationOut="slideOutUp"
            animationInDuration={1000}
            animationOutDuration={0}
            isVisible={control_visible}
          >
            <Controles
              titulo={control_titulo}
              click_rotar={this.props.rotateElementHandler}
              remover={tipo => this.removerElementoMapa(tipo)}
              guardar={tipo => this.guardarDataPosicion(tipo)}
              data={data_control}
            ></Controles>
          </Animated>
        </div>
        <div className="selector-tablas">
          <SelectorTablas
            fillCalles={this.props.fillCalles}
            fillMuelles={this.props.fillMuelles}
            fillCallesDeSalida={this.props.fillCallesDeSalida}
          ></SelectorTablas>
        </div>
        <div className="tabla-datos">
          <Tabla
            rows={this.props.datosTabla}
            agregarCalle={this.clickHandler}
            fuenteData={this.props.headerTabla}
          ></Tabla>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bloques: state.mapa.bloques,
    bloquesEnMapa: state.mapa.bloquesEnMapa,
    dimensionesAlmacen: state.mapa.dimensionesAlmacen,
    muelles: state.mapa.muelles,
    muellesEnMapa: state.mapa.muellesEnMapa,
    callesDeSalida: state.mapa.callesDeSalida,
    callesDeSalidaEnMapa: state.mapa.callesDeSalidaEnMapa,
    regla: state.mapa.regla,
    control_visible: state.mapa.control_visible,
    datosTabla: state.mapa.datosTabla,
    headerTabla: state.mapa.headerTabla,
    isLoaded: state.mapa.isLoaded
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBloquesEnMapa: (b, data_pos) =>
      dispatch(actionCreators.addBloquesEnMapa(b, data_pos)),
    addBloquesEnTabla: b => dispatch(actionCreators.addBloquesEnTabla(b)),
    addMuellesEnMapa: muelle =>
      dispatch(actionCreators.addMuellesEnMapa(muelle)),
    removerBloqueDeTabla: id_row =>
      dispatch(actionCreators.removerBloqueDeTabla(id_row)),
    removerBloqueDeMapa: bloque =>
      dispatch(actionCreators.removerBloqueDeMapa(bloque)),
    rotateElementHandler: () => dispatch(actionCreators.rotateElementHandler()),
    deselectAllBloques: event =>
      dispatch(actionCreators.deselectAllBloques(event)),
    deselectAllSpatialObjects: event =>
      dispatch(actionCreators.deselectAllSpatialObjects(event)),
    toggleAllSpatialObjects: (event, index, object) =>
      dispatch(actionCreators.toggleAllSpatialObjects(event, index)),
    hideReglaHandler: () => dispatch(actionCreators.hideReglaHandler()),
    fillCalles: data => dispatch(actionCreators.fillCalles(data)),
    fillCallesDeSalida: data =>
      dispatch(actionCreators.fillCallesDeSalida(data)),
    fillMuelles: data => dispatch(actionCreators.fillMuelles(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
