// variables
export let style_alert_peso_display = { display: "none" };
export let mensaje_error_peso_display = "";
export let style_alert_peso_bulto = { display: "none" };
export let mensaje_error_peso_bulto = "";

const _changeIntValueToSioNo = value => {
  let siNo = "";
  if (value === 1) {
    siNo = "Si";
  } else {
    siNo = "No";
  }
  return siNo;
};

export const changeIntValueToSioNoAllDisplays = data_listado => {
  // input: all data from productos_presentaciones table
  // this function maps all rows and change "diplay: 1/0" to "display: Si/No"
  let display_int = 0;
  let display_siNo = "";
  let new_data_listado = [...data_listado];
  data_listado.map((row, index) => {
    display_int = row.display;
    display_siNo = _changeIntValueToSioNo(display_int);
    new_data_listado[index].display = display_siNo;
    return new_data_listado;
  });
  return new_data_listado;
};

const _getPesoProductoUnidad = producto_seleccionado => {
  if (producto_seleccionado) {
    return producto_seleccionado.peso;
  }
  return null;
};

export const getPesoParaUnidadesEnDisplay = (
  producto_seleccionado,
  orderFormDisplay
) => {
  let peso_unidad = null;
  let peso_display = null;
  peso_unidad = _getPesoProductoUnidad(producto_seleccionado);
  if (peso_unidad) {
    peso_display = peso_unidad * orderFormDisplay.cantidad_display.value;
    orderFormDisplay.peso_display.value = peso_display;
    orderFormDisplay.cantidad_display.elementConfig.label =
      "Cantidad de unidades";
  }
  return;
};

export const getPesoParaUnidadesEnBulto = (
  producto_seleccionado,
  orderFormBulto
) => {
  let peso_unidad = null;
  let peso_bulto = null;
  peso_unidad = _getPesoProductoUnidad(producto_seleccionado);
  if (peso_unidad) {
    peso_bulto = peso_unidad * orderFormBulto.elementos_por_bulto.value;
    orderFormBulto.peso_bulto.value = peso_bulto;
    orderFormBulto.elementos_por_bulto.elementConfig.label =
      "Cantidad de unidades";
  }
  return;
};

export const getPesoParaDisplayEnBulto = (
  orderFormBulto,
  cantidad_bultos,
  peso_display
) => {
  let peso_bulto = peso_display * cantidad_bultos;
  orderFormBulto.peso_bulto.value = peso_bulto;
  return;
};

export const setPesoErrorMessageDisplay = errorMessageDisplay => {
  // raice an alert below 'Información Display' title
  style_alert_peso_display = { display: "block" };
  mensaje_error_peso_display = errorMessageDisplay;
};

export const unsetPesoErrorMessageDisplay = () => {
  // Hide peso display alert
  style_alert_peso_display = { display: "none" };
  mensaje_error_peso_display = "";
};

export const setPesoErrorMessageBulto = errorMessageBulto => {
  // raice an alert below 'Información Bulto' title
  style_alert_peso_bulto = { display: "block" };
  mensaje_error_peso_bulto = errorMessageBulto;
};

export const unsetPesoErrorMessageBulto = () => {
  // Hide peso bulto alert
  style_alert_peso_bulto = { display: "none" };
  mensaje_error_peso_bulto = "";
};
