import * as actionTypes from "./actionTypes";

export const setMovimientos = dataMovimientos => {
  return {
    type: actionTypes.SET_MOVIMIENTOS,
    dataMovimientos: dataMovimientos
  };
};

export const setAuditoriaContenedores = contenedores => {
  return {
    type: actionTypes.SET_MOVIMIENTOS,
    contenedores: contenedores
  };
};

export const setInfoContenedor = infoContenedor => {
  return {
    type: actionTypes.SET_INFO_CONTENEDOR,
    infoContenedor: infoContenedor
  };
};

export const setOpen = () => {
  return {
    type: actionTypes.SET_OPEN
  };
};

export const setClose = () => {
  return {
    type: actionTypes.SET_CLOSE
  };
};
