//COMPONENTES GENERALES
import React from "react";
// import Database from "variables/Database.js";

//COMPONENTES LOCALES
import InputNew from "components/Input/InputNew";
// import { localization } from "variables/general.js";
// import { toast } from "react-toastify";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";

//ESTILOS Y COLORES
import { makeStyles } from "@material-ui/core/styles";

//CONTENEDORES
// import MaterialTable, { MTableBodyRow } from "material-table";
// import Paper from "@material-ui/core/Paper";
// import $ from "jquery";

//BOTONES Y VARIOS
import Button from "@material-ui/core/Button";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
// import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  root: {
    width: "90%"
  },
  closeButton: {
    position: "absolute",
    right: "0.5em",
    top: "0.5em",
    color: "grey"
  }
}));

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [orderForm, setOrderForm] = React.useState({
    es_picking: {
      elementType: "checkbox",
      elementConfig: {
        label: "Es Picking"
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    nivel: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Nivel",
        fullWidth: true,
        inputProps: {
          autoFocus: true
        }
      },
      value: "",
      validation: {
        required: true,
        mayor1: true
      },
      valid: false,
      touched: false
    },
    altura: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Altura",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true,
        mayor0: true
      },
      valid: false,
      touched: false
    },
    peso_maximo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Peso Máximo",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true,
        mayor0: true
      },
      valid: false,
      touched: false
    }
  });
  const [formIsValid, setFormIsValid] = React.useState(false);

  const formElementsArray = [];

  React.useEffect(() => {
    if (props.rowItem) {
      let orderFormCopy = { ...orderForm };
      for (let key in orderFormCopy) {
        if (props.rowItem[key]) orderFormCopy[key].value = props.rowItem[key];
      }

      let objeto = inputAllChangedHandler(orderFormCopy);
      setOrderForm(objeto.orderForm);
      setFormIsValid(objeto.formIsValid);
    }
  }, []);

  for (let key in orderForm) {
    formElementsArray.push({
      id: key,
      config: orderForm[key]
    });
  }

  return (
    <Dialog
      open={props.openDialog}
      onClose={props.closeDialog}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>
        Agregar Nivel
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.closeDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {formElementsArray.map(formElement => {
          return (
            <InputNew
              key={"input-" + formElement.id}
              objeto={formElement.config}
              changed={event => {
                let objeto = inputChangedHandler(
                  event,
                  formElement.id,
                  orderForm
                );
                setOrderForm(objeto.orderForm);
                setFormIsValid(objeto.formIsValid);
              }}
            />
          );
        })}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => props.closeDialog()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (formIsValid) {
              let objeto = {};
              for (let key in orderForm) {
                objeto[key] = orderForm[key].value;
              }
              console.log(props.rowItem);
              console.log(objeto);

              props.onClick(props.rowItem, objeto);
            }
          }}
          color="primary"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
