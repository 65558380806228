import * as actionTypes from "../actions/actionTypes";

const initialState = {
  pos_x: null,
  pos_y: null,
  rotacion: null
};

const setPositionBloque = (state, action) => {
  let new_state = [...state];
  new_state.pos_x = action.pos_x;
  new_state.pos_y = action.pos_y;
  new_state.rotacion = action.rotacion;
  return { new_state };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POSITION_BLOQUE:
      return setPositionBloque(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
