import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/styles";
import { StateSectores } from "../VariablesState";

import Database from "variables/Database.js";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import Straighten from "@material-ui/icons/Straighten";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const ColumnsListadoMuelles = [{ title: "Descripcion", field: "descripcion" }];

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewSector extends Component {
  state = JSON.parse(JSON.stringify(StateSectores));
  muelles = [];

  handleSubmitNewSector = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }
      this.setState({
        disableAllButtons: true
      });

      let listadoMuelles = "";

      this.state.muellesAsociados.forEach(elem => {
        listadoMuelles += elem.id + ",";
      });

      if (listadoMuelles.length > 0)
        listadoMuelles = listadoMuelles.substring(0, listadoMuelles.length - 1); //elimino la última coma

      console.log(listadoMuelles);

      objeto["listado_muelles"] = listadoMuelles;

      Database.post(`/insert-sectores`, {
        id: process.env.REACT_APP_ID_ALMACEN,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              /* if(res.result[0].resultado == -1)
               toast.error("Alguna de las calles ya fueron asignadas a otro sector. Verifique los valores ingresados.");
             else*/
              toast.success("El sector se ha creado con exito!");
              this.props.getSectoresAdmin();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
      // console.log(objeto);
    } else {
      toast.error("Verificar los campos en el formulario");
    }
  };

  resetNewForm = all => {
    let orderFormAlt = { ...this.state.orderForm };
    let successSubmit = this.state.successSubmit;
    for (let key in orderFormAlt) {
      orderFormAlt[key].value = "";
    }
    if (all) successSubmit = false;

    this.setState({
      successSubmit: successSubmit,
      formIsValid: false
    });
  };

  deleteAsociacionMuelle = rowData => {
    //alert("eliminando: " + this.state.detallepedidos.indexOf(rowData));
    //data.splice(data.indexOf(oldData), 1);
    let muelles = [...this.state.muellesAsociados];
    muelles.splice(muelles.indexOf(rowData), 1);
    this.setState({
      muellesAsociados: muelles
    });
    //this.state.detallepedidos.splice(this.state.detallepedidos.indexOf(rowData), 1);
  };

  getSectoresType = () => {
    Database.get(
      "/list_sectores_tipo/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultadoSectoresType = [...res.result];
        let a = [];
        resultadoSectoresType.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.orderForm };
        formulario.tipo_sector.elementConfig.options = [...a];
        this.setState({
          newUserForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getMuelles = () => {
    Database.get(
      "/list-muelles/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        console.log([...res.result]);
        let muelles = [...res.result];

        this.muelles = muelles;
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    this.getSectoresType();

    this.state.actions = [
      {
        icon: "delete",
        tooltip: "Eliminar asociación con Muelle ",
        onClick: (event, rowData) => this.deleteAsociacionMuelle(rowData)
      }
    ];

    this.getMuelles();
  }

  openMuelle = () => {
    let muellesDisponibles = this.muelles.filter(elem => {
      if (this.state.muellesAsociados.includes(elem)) return false;

      return true;
    });

    this.setState({
      idMuelle: "",
      muellesDisponibles: muellesDisponibles,
      openMuelleDialog: true
    });
  };

  closeDialog() {
    this.setState({
      open: false,
      openMuelleDialog: false,
      openDuplicadoDialog: false
    });
  }

  handleSelectMuelle = event => {
    event.preventDefault();
    let idMuelle = parseInt(event.target.value);

    this.setState({ idMuelle: idMuelle });
  };

  handleMuelles = () => {
    this.closeDialog();
    let muellesAsociados = [...this.state.muellesAsociados];

    let indice = this.muelles.findIndex(elem => {
      return elem.id == this.state.idMuelle;
    });

    muellesAsociados.push(this.muelles[indice]);

    this.setState({ muellesAsociados: muellesAsociados });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }
    return (
      <div>
        <form
          onSubmit={event => {
            this.handleSubmitNewSector(event);
          }}
        >
          <Card>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>
                Nuevo Sector
              </h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Formulario de un sector nuevo
              </p>
            </CardHeader>
            <CardBody>
              <div className="mt-3 mb-3">
                {formElementsArray.map(formElement => (
                  <InputNew
                    key={"editsector-" + formElement.id}
                    objeto={formElement.config}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        formElement.id,
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                ))}
                <Button
                  style={{ marginTop: "3.5em", marginBottom: "3.5em" }}
                  color="default"
                  disabled={this.state.disableAllButtons}
                  onClick={this.openMuelle.bind(this)}
                >
                  <Straighten /> Asignar Muelle
                </Button>

                <MaterialTable
                  columns={ColumnsListadoMuelles}
                  data={this.state.muellesAsociados}
                  title="Muelles asociados"
                  actions={this.state.actions}
                />
              </div>

              <Button
                style={{ marginTop: "25px" }}
                color="default"
                onClick={() => this.props.history.push("/admin/sectores")}
              >
                <ArrowBack />
                Volver
              </Button>
              <Button
                style={{ marginTop: "25px" }}
                color="primary"
                variant="contained"
                disabled={
                  !this.state.formIsValid || this.state.disableAllButtons
                }
                type="submit"
              >
                <Save /> Guardar
              </Button>
            </CardBody>
          </Card>
        </form>

        <Dialog
          open={this.state.openMuelleDialog}
          onClose={this.closeDialog.bind(this)}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>
            Seleccionar Muelle
            <IconButton
              aria-label="close"
              className={this.props.classes.closeButton}
              onClick={this.closeDialog.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <form onSubmit={this.handleSubmitMuelles}>
              <FormControl className={this.props.classes.formControl}>
                <InputLabel id="muelles-label">Muelles</InputLabel>
                <Select
                  labelId="muelles-label"
                  id="muelles-select"
                  value={this.state.idMuelle}
                  onChange={this.handleSelectMuelle}
                >
                  {this.state.muellesDisponibles.map(elem => {
                    return (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.descripcion}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <div style={{ marginTop: "25px", textAlign: "right" }}>
                <Button
                  onClick={this.closeDialog.bind(this)}
                  style={{ marginRight: "10px" }}
                >
                  Cancelar
                </Button>
                <Button
                  type="button"
                  onClick={this.handleMuelles}
                  variant="contained"
                  color="primary"
                >
                  Seleccionar
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(NewSector));
