import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

class SelectorClientes extends Component {
  state = {
    clientes: [],
    selected_cliente: null,
    inputCliente: null
  };

  componentDidMount() {
    Database.get("/list-clientes", this, null, true).then(
      res => {
        let clientes = [...res.result];
        this.setState({ clientes: clientes });
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  render() {
    return (
      <Autocomplete
        id="combo-box-clientes"
        options={this.state.clientes}
        getOptionLabel={c => `${c.razon_social}`}
        value={this.state.selected_cliente}
        onChange={(event, newValue) => {
          this.props.getDataSelectedCliente(newValue);
          this.setState({ selected_cliente: newValue });
        }}
        inputValue={this.state.inputCliente}
        onInputChange={(event, newInputValue) => {
          this.setState({ inputCliente: newInputValue });
        }}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Seleccionar un cliente"
            variant="outlined"
          />
        )}
      ></Autocomplete>
    );
  }
}

export default SelectorClientes;
