import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

// Redux
import { connect } from "react-redux";
import * as actionCreators from "store/actions/index";

// DB
import Database from "variables/Database.js";
import { toast } from "react-toastify";

var ALLOW_NULL_FIELDS = true;
var FILAS_CON_ERRORES = [];
var FILAS_SIN_ERRORES = 0;
var PROBLEMAS_STRING = [];
var PROBLEMAS_STRING_FILAS = [];
var CSV_DEPURADO = [];

const ValidarCSV = props => {
  const [campos_db, setCamposDB] = React.useState([]);
  const [campos_a_filtrar, setCamposAFiltrar] = React.useState([]);
  const [csv_depurado, setCsvDepurado] = React.useState([]);
  const [csv_valido, setCsvValido] = React.useState(false);

  const [tooltip_text_validar, setTooltip_text_validar] = React.useState(false);
  const [tooltip_text_importar, setTooltip_text_importar] = React.useState(
    "Debe primero validar el archivo .csv"
  );

  React.useEffect(() => {
    if (props.shouldRender) {
      setTooltip_text_validar("Validar el archivo CSV");
    } else {
      setTooltip_text_validar("Debe primero cargar un archivo .csv");
    }
  }, [props.shouldRender]);

  // const csv_cargado = props.csv.length > 0;
  // const tooltip_text = csv_cargado
  //   ? "Validar el archivo CSV"
  //   : "Debe primero cargar un archivo .csv";

  const resetLogs = () => {
    FILAS_CON_ERRORES = [];
    FILAS_SIN_ERRORES = 0;
    PROBLEMAS_STRING = [];
    PROBLEMAS_STRING_FILAS = [];
    CSV_DEPURADO = [];
    setCsvDepurado([]);
    setCsvValido(false);
  };

  React.useEffect(() => {
    Database.get("/get-campos-importacion", null, null, true).then(
      res => {
        let campos = [...res.result];
        setCamposDB(campos);
        FILAS_CON_ERRORES = [];
        props.touch_campos_cargados();
      },
      err => {
        toast.error(err.message);
      }
    );
  }, [props.reload_table]);

  React.useEffect(() => {
    let campos_a_filtrar_tmp = campos_db.filter(el => {
      return props.campos_habilitados.includes(`${el.id}`);
    });
    setCamposAFiltrar(campos_a_filtrar_tmp);
    // alert(campos_a_filtrar_tmp);
    FILAS_CON_ERRORES = [];
  }, [props.campos_habilitados, props.campos_cargados]);

  React.useEffect(() => {
    resetLogs();
  }, [props.csvData]);

  const importarCSV = () => {
    let data = { csv: csv_depurado, campos_a_filtrar: campos_a_filtrar };
    Database.post(`/importar-csv`, { data }, this).then(
      res => {
        toast.success("Productos importados!");
        props.setShouldRender(false);
      },
      err => {
        toast.error(err.message);
        props.setShouldRender(false);
      }
    );
  };

  const _check_is_valid_field = (csv_row, field, csv_index) => {
    try {
      if (ALLOW_NULL_FIELDS) {
        return true;
      } else {
        if (csv_row[field] !== null) {
          return true;
        } else {
          FILAS_CON_ERRORES.push(csv_index);
          let msg = `La fila ${csv_index +
            1} contiene el campo ${field} como null`;
          PROBLEMAS_STRING_FILAS.push(msg);
          return false;
        }
      }
    } catch (e) {
      console.log(e);
      FILAS_CON_ERRORES.push(csv_index);
      return false;
    }
  };

  const _check_row_has_field = (csv_row, field, csv_index) => {
    try {
      if (csv_row.hasOwnProperty(field)) {
        return true;
      } else {
        let msg = `La tabla no contiene la columna: ${field}`;
        if (!PROBLEMAS_STRING.includes(msg)) {
          PROBLEMAS_STRING.push(msg);
        }
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const appendRow = csv_row => {
    CSV_DEPURADO.push(csv_row);
  };

  const validarCSV = () => {
    resetLogs();
    for (let i = 0; i < props.csvData.length; i++) {
      let fila_csv = props.csvData[i];
      var fila_con_error = false;
      for (let c of campos_a_filtrar) {
        let test_1 = _check_row_has_field(fila_csv, c.campo_csv, i + 1);
        let test_2 = _check_is_valid_field(fila_csv, c.campo_csv, i + 1);
        // console.log(`test 1: ${test_1} y test_2: ${test_2} for ${c.campo_csv}`);
        if (!test_1 || !test_2) {
          fila_con_error = true;
        }
      }
      if (!fila_con_error) {
        appendRow(fila_csv);
        FILAS_SIN_ERRORES++;
      }
    }
    if (FILAS_CON_ERRORES.length > 0) {
      toast.error(`Existen ${FILAS_CON_ERRORES.length} filas con problemas`, {
        autoClose: false
      });
    }

    if (PROBLEMAS_STRING.length > 0) {
      PROBLEMAS_STRING.map(el => toast.error(el, { autoClose: false }));
    }

    if (FILAS_SIN_ERRORES > 0) {
      toast.success(`${FILAS_SIN_ERRORES} filas no tienen errores`);
    }

    if (CSV_DEPURADO.length > 0) {
      setCsvDepurado(CSV_DEPURADO);
      setCsvValido(true);
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Tooltip title={tooltip_text_validar}>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!props.shouldRender}
              onClick={validarCSV}
            >
              Validar CSV
            </Button>
          </Grid>
        </Tooltip>
        <Tooltip title={tooltip_text_importar}>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!csv_valido}
              onClick={importarCSV}
            >
              Importar CSV
            </Button>
          </Grid>
        </Tooltip>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    campos_habilitados: state.importacionmasiva.campos_habilitados,
    csv: state.importacionmasiva.csv,
    campos_cargados: state.importacionmasiva.campos_cargados,
    reload_table: state.importacionmasiva.reload_table
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cargaCsv: csv => dispatch(actionCreators.cargaCsv(csv)),
    touch_campos_cargados: () =>
      dispatch(actionCreators.touch_campos_cargados())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidarCSV);
