import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

class SelectorCliente extends Component {
  state = {
    clientes: [],
    selected_cliente: null,
    inputCliente: ""
  };

  componentDidMount() {
    Database.get("/list-clientes/", this).then(
      res => {
        let resultado = [...res.result];
        let clientes = resultado;
        this.setState({ clientes: clientes });
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  render() {
    return (
      <Autocomplete
        id="combo-box-productos"
        options={this.state.clientes}
        getOptionLabel={cliente => `${cliente.id} - ${cliente.razon_social} `}
        value={this.state.selected_cliente}
        onChange={(event, newValue) => {
          this.props.getSelectedDataCliente(newValue);
          console.log(newValue);
          this.setState({ selected_cliente: newValue });
        }}
        inputValue={this.state.inputCliente}
        onInputChange={(event, newInputValue) => {
          console.log(newInputValue);
          this.setState({ inputCliente: newInputValue });
        }}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Seleccionar un Cliente"
            variant="outlined"
          />
        )}
      ></Autocomplete>
    );
  }
}

export default SelectorCliente;
