export const StateListSectores = {
  sectores: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateSectores = {
  actions: [],
  muellesAsociados: [],
  muellesDisponibles: [],
  openMuelleDialog: false,
  orderForm: {
    tipo_sector: {
      elementType: "select",
      elementConfig: {
        label: "Tipo de Sector",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Descripcion", field: "descripcion" },
  { title: "Tipo Sector", field: "tipo_sector" }
];
