import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React, { Component } from 'react'

class DialogFinishCheck extends Component {
  render() {
    const { mostrar, onAceptarFinalizar, onCancelarFinalizar } = this.props

    if (!mostrar) {
      return null
    }

    return (
      <div>
        <Dialog open={mostrar} onClose={onCancelarFinalizar}>
          <DialogTitle>AVISO</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Está seguro que quiere finalizar el control?
            </DialogContentText>
          </DialogContent>
          <DialogActions align='center'>
            <Button
              onClick={onCancelarFinalizar}
              variant='contained'
              style={{
                backgroundColor: 'red',
                color: 'white',
                width: '50%',
              }}
            >
              No
            </Button>
            <Button
              onClick={onAceptarFinalizar}
              variant='contained'
              style={{
                backgroundColor: 'green',
                color: 'white',
                width: '50%',
              }}
            >
              Si
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default DialogFinishCheck
