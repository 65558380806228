export const StateListCallessalida = {
  callessalida: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateCallessalida = {
  orderForm: {
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    capacidad: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Capacidad (pallets)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    ancho: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (m)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },

    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (m)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    }
    // ,

    // ocupacion: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'number',
    //         label: 'Ocupación',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
    // orden: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Orden',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
  },

  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Descripcion", field: "descripcion" },
  { title: "Capacidad", field: "capacidad" },
  { title: "Ocupacion", field: "ocupacion" }
];
