/* eslint-disable */
import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import LocalShipping from "@material-ui/icons/LocalShipping";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import Alarm from "@material-ui/icons/Alarm";
import Schedule from "@material-ui/icons/Schedule";
import Check from "@material-ui/icons/Check";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Database from "variables/Database.js";
import { toast } from "react-toastify";
import { Route, Switch, Link } from "react-router-dom";

import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  entradasPorDia,
  salidasPorDia,
  movimientosPorDia
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { history } = props;
  const classes = useStyles();

  const [count, setCount] = useState(0);
  const [ocupacion, setOcupacion] = useState(0);
  const [clientes, setClientes] = useState(0);
  const [TotalPedidosNuevos, setTotalPedidosNuevos] = useState(0);
  const [TotalPedidosSinTerminar, setTotalPedidosSinTerminar] = useState(0);
  const [PedidosNuevos, setPedidosNuevos] = useState([]);
  const [PedidosSinTerminar, setPedidosSinTerminar] = useState([]);

  var dia = new Date();

  const [entradasDiariasData, setEntradasDiariasData] = useState({
    labels: [],
    series: [[]]
  });

  const [movimientosDiariosData, setMovimientosDiariosData] = useState({
    labels: [],
    series: [[]]
  });

  const [salidasDiariasData, setSalidasDiariasData] = useState({
    labels: [],
    series: [[]]
  });

  const cantidad = 5; // la cantidad de elementos a mostrar en los gráficos

  /*******************FUNCIONES AUXILIARES**********************/

  /* Función que suma o resta días a una fecha, si el parámetro
   días es negativo restará los días*/
  function sumarDias(fecha, dias) {
    //console.log(fecha);
    fecha.setDate(fecha.getDate() + dias);
    // console.log(fecha);
    return fecha;
  }

  /** a partir de una fecha devuelve dd/mm **/
  function diames(fecha) {
    let f = new Date(fecha);
    let h = new Date(f.getUTCFullYear(), f.getUTCMonth(), f.getUTCDate());
    let mes = h.getUTCMonth() + 1;
    return h.getUTCDate() + "/" + mes;
  }

  function cargarEntradasPorDia(entradas) {
    let entradasCantidad = [];
    let entradasValores = [];

    console.log(entradas);
    console.log(entradas.length);

    //invierto el array para que ordene por fecha descendente
    const ultimasEntradas = entradas.reverse();

    //si tengo mas elementos que el total a mostrar entonces elimino
    if (ultimasEntradas.length > cantidad) {
      ultimasEntradas.splice(cantidad, ultimasEntradas.length - cantidad);
    }

    //   console.log(ultimasEntradas);

    ultimasEntradas.forEach(element => {
      entradasCantidad.push(diames(Object.values(element)[0]));
      entradasValores.push(Object.values(element)[1]);
      //   console.log(Object.values(element)[0]);
      //   console.log(Object.values(element)[1]);
    });

    setEntradasDiariasData({
      labels: entradasCantidad.reverse(),
      series: [entradasValores.reverse()]
    });

    return 1;
  }

  function cargarSalidasPorDia(salidas) {
    let salidasCantidad = [];
    let salidasValores = [];

    console.log(salidas);
    console.log(salidas.length);

    //invierto el array para que ordene por fecha descendente
    const ultimasSalidas = salidas.reverse();

    //si tengo mas elementos que el total a mostrar entonces elimino
    if (ultimasSalidas.length > cantidad) {
      ultimasSalidas.splice(cantidad, ultimasSalidas.length - cantidad);
    }

    ultimasSalidas.forEach(element => {
      salidasCantidad.push(diames(Object.values(element)[0]));
      salidasValores.push(Object.values(element)[1]);
    });

    setSalidasDiariasData({
      labels: salidasCantidad.reverse(),
      series: [salidasValores.reverse()]
    });

    return 1;
  }

  function cargarMovimientosPorDia(movimientos) {
    let movimientosCantidad = [];
    let movimientosValores = [];

    console.log(movimientos);
    console.log(movimientos.length);

    //invierto el array para que ordene por fecha descendente
    const ultimasMovimientos = movimientos.reverse();

    //si tengo mas elementos que el total a mostrar entonces elimino
    if (ultimasMovimientos.length > cantidad) {
      ultimasMovimientos.splice(cantidad, ultimasMovimientos.length - cantidad);
    }

    ultimasMovimientos.forEach(element => {
      movimientosCantidad.push(diames(Object.values(element)[0]));
      movimientosValores.push(Object.values(element)[1]);
    });

    setMovimientosDiariosData({
      labels: movimientosCantidad.reverse(),
      series: [movimientosValores.reverse()]
    });

    return 1;
  }
  /*******************FUNCIONES AUXILIARES**********************/

  useEffect(() => {
    Database.get("/get_estadisticas", this, null, true).then(
      res => {
        console.log(res);
        // count = res[0];
        console.log(res.result);
        setCount(res.result);

        const ocupacionAlmacen = (
          (res.result[2] * 100) /
          res.result[0]
        ).toFixed(2);

        /*OCUPACION ALMACEN*/
        setOcupacion(ocupacionAlmacen);

        /*CLIENTES EN ALMACEN*/
        setClientes(res.result[3]);

        console.log(diames(Object.values(res.result[4][0])[0]));

        cargarEntradasPorDia(res.result[4]);

        cargarSalidasPorDia(res.result[5]);

        cargarMovimientosPorDia(res.result[6]);

        /*TOTAL PEDIDOS NUEVOS EN ALMACEN*/
        setTotalPedidosNuevos(res.result[7]);

        setTotalPedidosSinTerminar(res.result[8]);

        /*lista pedidos nuevos*/
        let pedidos_nuevos = [...res.lista_pedidos_nuevos];
        setPedidosNuevos(pedidos_nuevos);

        /*lista pedidos sin terminar*/
        let pedidos_sin_terminar = [...res.lista_pedidos_sin_terminar];
        setPedidosSinTerminar(pedidos_sin_terminar);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Espacio Utilizado</p>
              <h3 className={classes.cardTitle}>{ocupacion}%</h3>
            </CardHeader>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                 Actualizado recientemente
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Pedidos Nuevos</p>
              <h3 className={classes.cardTitle}>{TotalPedidosNuevos}</h3>
            </CardHeader>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
               Actualizado hace 7 minutos
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Tareas Pendientes</p>
              <h3 className={classes.cardTitle}>Desconocido</h3>
            </CardHeader>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Trackeado desde App LD
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Clientes en Almacén</p>
              <h3 className={classes.cardTitle}>{clientes}</h3>
            </CardHeader>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Actualizado recientemente
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={entradasDiariasData}
                type="Line"
                options={entradasPorDia.options}
                listener={entradasPorDia.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Entradas por día</h4>
              <p className={classes.cardCategory}>
                Últimas entradas realizadas
              </p>
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> actualizado hace 28 minutos
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={movimientosDiariosData}
                type="Line"
                options={movimientosPorDia.options}
                listener={movimientosPorDia.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Movimientos por día</h4>
              <p className={classes.cardCategory}>
                Últimos movimientos realizados
              </p>
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> actualizado hace 3 minutos
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={salidasDiariasData}
                type="Line"
                options={salidasPorDia.options}
                listener={salidasPorDia.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Salidas por día</h4>
              <p className={classes.cardCategory}>Últimas salidas realizadas</p>
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> actualizado hace 3 minutos
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>

        {/* <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Ocupación por día</h4>
              <p className={classes.cardCategory}>% ocupación por día</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> actualizado hace 15 horas
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Pedidos:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Sin Procesar (" + TotalPedidosNuevos + ")",
                tabIcon: Schedule,
                tabContent: (
                  <Tasks
                    checkedIndexes={[]}
                    tasksIndexes={PedidosNuevos.map(v =>
                      PedidosNuevos.indexOf(v)
                    )}
                    tasks={PedidosNuevos}
                    history={history}
                    tipo="1"
                  />
                )
              },
              {
                tabName: "Sin Terminar (" + TotalPedidosSinTerminar + ")",
                tabIcon: Alarm,
                tabContent: (
                  <Tasks
                    checkedIndexes={[]}
                    tasksIndexes={PedidosSinTerminar.map(v =>
                      PedidosSinTerminar.indexOf(v)
                    )}
                    tasks={PedidosSinTerminar}
                    history={history}
                    tipo="2"
                  />
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
