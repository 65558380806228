import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

class SelectorProductoPresentacion extends Component {
  state = {
    articulos: [],
    selected_articulo: null,
    inputArticulo: ""
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.cliente !== prevProps.cliente) {
      this.props.resetvalue();
      this.setState({
        articulos: [],
        selected_articulo: null,
        inputArticulo: ""
      });

      if (this.props.cliente) {
        Database.get(
          "/list-productos-presentacion/" + this.props.cliente.id,
          this
        ).then(
          res => {
            let resultado = [...res.result];

            let articulos = resultado;
            this.setState({ articulos: articulos });
          },
          err => {
            toast.error(err.message);
          }
        );
      }
    }
  }

  render() {
    return (
      <Autocomplete
        disabled={this.props.disabled}
        id="combo-box-productos"
        options={this.state.articulos}
        getOptionLabel={articulo =>
          `${articulo.id_producto_unidad} - ${articulo.descripcion} `
        }
        value={this.state.selected_articulo}
        onChange={(event, newValue) => {
          this.props.getDataSelectedProductoPresentacion(newValue);
          this.setState({ selected_articulo: newValue });
        }}
        inputValue={this.state.inputArticulo}
        onInputChange={(event, newInputValue) => {
          this.setState({ inputArticulo: newInputValue });
        }}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Seleccionar un Articulo"
            variant="outlined"
          />
        )}
      ></Autocomplete>
    );
  }
}

export default SelectorProductoPresentacion;
