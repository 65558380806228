import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { StateNewHijo } from "../VariablesState";

import Database from "variables/Database.js";

import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewHijo extends Component {
  state = JSON.parse(JSON.stringify(StateNewHijo));

  handleSubmitNewHijo = event => {
    event.preventDefault();

    Database.post(
      `/insert-hijos-empleados`,
      {
        idEmpleado: this.state.idEmpleado,
        nombre: this.state.newHijoForm.nombre.value,
        apellido: this.state.newHijoForm.apellido.value,
        certificado_foto: this.state.newHijoForm.certificado_foto.value,
        fechaVencimiento: this.state.fechaVencimiento
      },
      this
    ).then(
      res => {
        toast.success("El hijo se ha creado con exito!");
        this.setState(
          {
            successSubmit: true,
            formIsValid: false
          },
          () => {
            //this.props.getHijosAdmin();
          }
        );
        this.resetNewForm();
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  inputChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newHijoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      newHijoForm: updatedOrderForm,
      formIsValid: formIsValidAlt
    });
  };

  resetNewForm = all => {
    let newHijoFormAlt = { ...this.state.newHijoForm };
    let successSubmit = this.state.successSubmit;
    for (let key in newHijoFormAlt) {
      newHijoFormAlt[key].value = "";
    }
    if (all) successSubmit = false;

    this.setState({
      successSubmit: successSubmit,
      formIsValid: false
    });
    //this.getHijosType("new", newHijoFormAlt);
  };

  getEmpleado = id => {
    Database.get("/list-empleado/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          nombreEmpleado:
            resultado.result[0].nombre.toUpperCase() +
            " " +
            resultado.result[0].apellido.toUpperCase(),
          idEmpleado: id
        });

        //   let editBancoFormAlt = { ...this.state.editBancoForm };
        //   editBancoFormAlt.nombre.value = resultado.result[0].nombre;
        //   editBancoFormAlt.sucursal.value = resultado.result[0].sucursal;
        //   editBancoFormAlt.ejecutivo_asignado.value = resultado.result[0].ejecutivo_asignado;
        //   editBancoFormAlt.ejecutivo_mail.value = resultado.result[0].ejecutivo_mail;
        //   editBancoFormAlt.ejecutivo_telefono.value = resultado.result[0].ejecutivo_telefono;
        //   for (let key in editBancoFormAlt) {
        //     editBancoFormAlt[key].touched = true;
        //     editBancoFormAlt[key].valid = true;
        //   }

        //   this.setState({
        //     editBancoForm: editBancoFormAlt
        //   })
        //  // this.getBancosType("edit", editBancoFormAlt);
        // }
        // else {
        //   this.setState({
        //     bancoEdit: null
        //   })
        // }
      }
    });

    // Database.get('/list-hijos-tipo/')
    //   .then(resultado => {

    //     console.log(resultado.result);
    //     if (resultado.result.length > 0) {
    //       console.log("entro");
    //       let newHijoFormAlt = { ...this.state.newHijoForm };

    //       let res = [...resultado.result];
    //       console.log(res);
    //       let s = [];
    //       res.forEach(function (entry) {
    //         s.push({
    //           value: entry.id,
    //           displayValue: entry.descripcion
    //         });
    //       })

    //       console.log(s);

    //       //  console.log(this.state.orderForm);
    //       let formulario = { ...this.state.newHijoForm };
    //       // let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle))
    //       formulario.tipo.elementConfig.options = s;
    //      // formulario.id_muelle = id_muelle;

    //       for (let key in newHijoFormAlt) {
    //         newHijoFormAlt[key].touched = true;
    //         newHijoFormAlt[key].valid = true;
    //       }

    //       this.setState({
    //         newHijoForm: newHijoFormAlt
    //       })
    //     }
    //     else {
    //         // this.setState({
    //         //   bancoEdit: null
    //         // })
    //       }
    //   }
    //   )
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  componentDidMount() {
    this.getEmpleado(this.props.match.params.id);
    //this.getHijosType();
  }

  handleDateChange = (date, value) => {
    //alert("cambio fecha");
    this.setState({
      fechaVencimiento: date
    });
    //setfecha(date);
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.newHijoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.newHijoForm[key]
      });
    }
    return (
      <form
        onSubmit={event => {
          this.handleSubmitNewHijo(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              {this.state.nombreEmpleado}
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario de Nuevo Hijo
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"edithijo-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={event =>
                    this.inputChangedHandler(event, formElement.id)
                  }
                />
              ))}

              <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="vencimiento"
                  label="Vencimiento Certificado"
                  format="dd/MM/yyyy"
                  value={this.state.fechaVencimiento}
                  onChange={this.handleDateChange}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/empleados")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={!this.state.formIsValid || this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(NewHijo));
