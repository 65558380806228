import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SelectorCalles from "./SelectorCallesDeSalida";

//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

function ModalBuscarCallesDeSalida(props) {
  return (
    <Dialog
      open={props.buscarCalleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Seleccionar una calle</DialogTitle>
      <DialogContent>
        <SelectorCalles />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setModalVisibility(false, "calle")}
          color="primary"
          autoFocus
        >
          {props.calle_de_salida == null
            ? "Cancelar"
            : `Seleccionar ${props.calle_de_salida.descripcion}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    calle_de_salida: state.salidas.calle_de_salida,
    buscarCalleDialog: state.salidas.buscarCalleDialog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBuscarCallesDeSalida);
