/* eslint-disable */
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Input from "components/Input/Input";
import InputNew from "components/Input/InputNew";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import SelectorProductoPresentacion from "./SelectorProductoPresentacion";
import SelectorCliente from "./SelectorCliente";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "35%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

export default function ModalAsignar(props) {
  const [enabledButton, setButtonStatus] = React.useState(false);
  const [
    selectedProductoPresentacion,
    setSelectedProductoPresentacion
  ] = React.useState([null]);
  const [cliente, setCliente] = React.useState(null);
  const [enabledPresentacion, setPresentacionStatus] = React.useState(false);
  const [umbral, setUmbral] = React.useState(1);

  const clickAceptar = () => {
    console.log(umbral);
    if (umbral == "") setUmbral(0);

    props.handleAccept(selectedProductoPresentacion, umbral);
  };

  const getDataSelectedProductoPresentacion = data => {
    setSelectedProductoPresentacion(data);
    // setCalle(data);
    //  setColumnaStatus(true);
    if (data !== null) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  const resetpresentacion = () => {
    setSelectedProductoPresentacion(null);
  };

  const getSelectedDataCliente = data => {
    //setSelectedDataCalle(data)
    setCliente(data);

    if (data !== null) {
      //    setButtonStatus(true);
    } else {
      resetpresentacion();
      setButtonStatus(false);
    }
  };

  const resetPresentacion = () => {
    setSelectedProductoPresentacion(null);
    setButtonStatus(false);
  };

  const handleChange = event => {
    if (event.target.value == "") setUmbral("");
    else {
      if (!Number(event.target.value)) return;
      setUmbral(event.target.value);
    }

    //alert(event.target.value);
  };

  return (
    <Dialog
      open={props.openAsignarDialog}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Seleccionar el artículo que contendrá {enabledButton}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardHeader color="primary">
            <h4>Artículo</h4>
          </CardHeader>
          <CardBody>
            <SelectorCliente getSelectedDataCliente={getSelectedDataCliente} />
            <SelectorProductoPresentacion
              disabled={!cliente}
              resetvalue={resetPresentacion}
              cliente={cliente}
              getDataSelectedProductoPresentacion={
                getDataSelectedProductoPresentacion
              }
            />
            <h5>Umbral (unidades)</h5>
            <input type="text" value={umbral} onChange={handleChange} />
            {/* <Input
                            objeto={umbral}
                            changed={alert("cambio")}
                            // changed={(event) => {
                            //     let { umbral} = inputChangedHandler(event, umbral);
                            //    // this.setState({ orderForm: orderForm, formIsValid: formIsValid })
                            //   }}
                            // changed={(event) => {
                            //     console.log(event);
                            //     setUmbral(event.value);
                            // }}
                        /> */}
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={clickAceptar}
          color="primary"
          autoFocus
          disabled={!enabledButton}
        >
          Seleccionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
