import moment from "moment";

export const StateListContenedores = {
  contenedores: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false,
  tabSelect: 0
};

export const StateContenedores = {
  orderForm: {
    id: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo Total (mts)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    sentido: {
      elementType: "select",
      elementConfig: {
        label: "Sentido",
        options: [
          { value: "A", displayValue: "Ascendente" },
          { value: "D", displayValue: "Descendente" }
        ],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    }
    // orden: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Orden',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
  },
  vencimiento: null,
  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListado = [
  //{ title: "Numero", field: "id" },
  { title: "Pallet", field: "id" },
  { title: "Ubicacion", field: "ubicacion" },
  { title: "Muelle", field: "muelle" },
  { title: "Calle Salida", field: "calle_salida" },
  { title: "Dato Logístico", field: "producto_presentacion" },
  { title: "Cod. Interno", field: "codigo" },
  { title: "Lote", field: "lote" },
  { title: "Cliente", field: "razon_social" },
  { title: "Bultos", field: "cantidad_bultos" },
  { title: "Unidades", field: "cantidad_unidades" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento, "DD/MM/YYYY").format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento, "DD/MM/YYYY").format("YYYYMMDD"))
  }
];
