import React, { Component } from "react";
// encabezado
import EncabezadoCard from "components/EncabezadoCard/EncabezadoCard";
// Grid
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// Card
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Tablas
//import TablaMovimientos from './Tablas/TablaMovimientos.js'
import ContenedoresClientePallets from "../Contenedores/ContenedoresClientePallets";
import ModalDetalleCliente from "./ModalDetalleCliente/ModalDetalleCliente.js";

class ClientePallets extends Component {
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <EncabezadoCard
                titulo_card="Mercadería"
                subtitulo_card="Visualización de Mercadería"
              ></EncabezadoCard>
              <CardBody>
                <ContenedoresClientePallets></ContenedoresClientePallets>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <ModalDetalleCliente></ModalDetalleCliente>
      </div>
    );
  }
}

export default ClientePallets;
