import React from "react";

export const StateEditProductosPresentacion = {
  hasDisplay: null,
  presentaciones: null,

  orderForm: {
    estado: {
      elementType: "select",
      elementConfig: {
        label: "Estado",
        options: [
          { displayValue: "Habilitado", value: 1 },
          { displayValue: "Deshabilitado", value: 2 }
        ],
        fullWidth: true
      },
      value: 1,
      validation: {
        required: true
      },

      valid: true,
      touched: false
    },
    id_picking_tipo: {
      elementType: "select",
      elementConfig: {
        label: "Tipo de Picking",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: true,
      touched: false
    },

    descripcion: {
      elementType: "textarea",
      elementConfig: {
        label: "Descripción de la presentación",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    dun14: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "DUN14",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false,
        exactLength: 14
      },
      valid: true,
      touched: false
    },

    cantidad_bultos_capa: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad Bultos por Capa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false
    },
    cantidad_capas: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad de Capas",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false
};

export const StateProductosPresentacion = {
  openBuscarProductoDialog: false,
  //openBuscarClienteDialog: false,
  selectedProduct: false,
  // selectedCliente: false,
  hasSelectedProducto: false,
  hasDisplay: null,
  presentaciones: null,

  orderForm: {
    display_opcional: {
      elementType: "radiogroup",
      elementConfig: { isRow: true },
      radio_group_values: ["Si", "No"],
      radio_seleccionado: null,
      valid: true
    },

    display: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Información sobre el Display",
        fullWidth: true,
        disabled: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },

    bulto: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Información sobre el Bulto",
        fullWidth: true,
        display: false,
        disabled: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },

    estado: {
      elementType: "select",
      elementConfig: {
        label: "Estado",
        options: [
          { displayValue: "Habilitado", value: 1 },
          { displayValue: "Deshabilitado", value: 2 }
        ],
        fullWidth: true
      },
      value: 1,
      validation: {
        required: true
      },

      valid: true,
      touched: false
    },

    id_picking_tipo: {
      elementType: "select",
      elementConfig: {
        label: "Tipo de Picking",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: true,
      touched: false
    },

    descripcion: {
      elementType: "textarea",
      elementConfig: {
        label: "Descripción del Dato Logístico",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false,

  orderForm2: {
    cantidad_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad de Unidades",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    peso_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Peso (Kg)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    alto_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Alto (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    largo_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    ancho_display: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  formIsValid2: false,

  orderForm3: {
    elementos_por_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad Display/Unidades",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    peso_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Peso (Kg)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    alto_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Alto (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    largo_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    ancho_bulto: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Ancho (cm)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    dun14: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "DUN14",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false,
        exactLength: 14
      },
      valid: true,
      touched: false
    },

    cantidad_bultos_capa: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad Bultos por Capa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false
    },
    cantidad_capas: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Cantidad de Capas",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false
    }
  },
  formIsValid3: false
};

export const ColumnsListadoPresentacion = [
  { title: "Descripción", field: "descripcion" },
  { title: "Unidad", field: "ProductosUnidad.descripcion" },
  { title: "EAN13", field: "ProductosUnidad.ean13" },
  { title: "DUN14", field: "dun14" },
  { title: "Display", field: "display" },
  {
    title: "Estado",
    field: "estado",
    render: rowData => {
      let estado = null;
      if (rowData.estado == 1) estado = "Habilitado";
      if (rowData.estado == 2) estado = "Deshabilitado";

      return <span>{estado}</span>;
    }
  }
];

export const StateListProductosPresentacion = {
  listado: [],
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};
