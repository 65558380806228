import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import CardBody from 'components/Card/CardBody.js'

const DetallePedidoCheck = ({
  dataListado,
  pedidoId,
  selectedWorker,
  handleCerrarCheckList,
}) => {
  return (
    <div>
      <CardBody style={{ border: '1px solid #ccc', borderRadius: '4px' }}>
        <Button
          style={{
            backgroundColor: 'red',
            color: 'white',
            float: 'right',
            marginBottom: '10px',
          }}
          onClick={handleCerrarCheckList}
        >
          Cerrar CheckList
        </Button>
        <Grid container style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
          <Grid item xs={12}>
            <h3>
              <b>Datos del pedido</b>
            </h3>
          </Grid>
          <Grid item xs={4}>
            <h4>
              ID Pedido: <b>{pedidoId}</b>
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h4>
              Recolectado por: <b>{dataListado.trabajador}</b>
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h4>
              Controlado por: <b>{selectedWorker}</b>
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h4>
              Cliente:{' '}
              <b>
                {dataListado.id_cliente} - {dataListado.razon_social}
              </b>
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h4>
              Referencia: <b>{dataListado.referencia}</b>
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h4>
              Destino: <b>{dataListado.destino}</b>
            </h4>
          </Grid>
        </Grid>
      </CardBody>
    </div>
  )
}

export default DetallePedidoCheck
