export const generaNColores = numColores => {
  let colores = {};
  let color = "";
  let i = 0;
  for (i = 1; i < numColores + 1; i++) {
    color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);
    colores[i] = color;
  }
  return colores;
};

export const diccionario1000Col = {
  "1": "#19c440",
  "2": "#c31fae",
  "3": "#98062b",
  "4": "#9de20d",
  "5": "#6d7515",
  "6": "#9b3a18",
  "7": "#9bb000",
  "8": "#222084",
  "9": "#4492f8",
  "10": "#a54710",
  "11": "#d6b949",
  "12": "#ea6a5e",
  "13": "#95e73f",
  "14": "#6ed596",
  "15": "#867030",
  "16": "#197072",
  "17": "#fca437",
  "18": "#e2511d",
  "19": "#a3442f",
  "20": "#62c5a",
  "21": "#8e56ac",
  "22": "#509dfd",
  "23": "#481f5f",
  "24": "#766212",
  "25": "#95ddb8",
  "26": "#4b4cec",
  "27": "#aeea6",
  "28": "#27e248",
  "29": "#4e25cc",
  "30": "#42a2a3",
  "31": "#ea5e07",
  "32": "#61db2",
  "33": "#8b32b4",
  "34": "#2ae4cc",
  "35": "#a2d5a8",
  "36": "#dd93ef",
  "37": "#fc5b01",
  "38": "#94dceb",
  "39": "#d6792d",
  "40": "#91b89",
  "41": "#46d9be",
  "42": "#cf1ab9",
  "43": "#60910f",
  "44": "#9fd9c5",
  "45": "#1d7305",
  "46": "#7c3232",
  "47": "#7e6110",
  "48": "#9f9005",
  "49": "#7569a5",
  "50": "#b56565",
  "51": "#d0ffd3",
  "52": "#91970f",
  "53": "#3c0f7",
  "54": "#8e9978",
  "55": "#dc808f",
  "56": "#a696c5",
  "57": "#cddf6",
  "58": "#c8a247",
  "59": "#ee89b8",
  "60": "#662f51",
  "61": "#835602",
  "62": "#56ffc6",
  "63": "#2921b4",
  "64": "#7f73c7",
  "65": "#ecbe05",
  "66": "#ed3352",
  "67": "#44f904",
  "68": "#a235bb",
  "69": "#fbe29e",
  "70": "#9014c9",
  "71": "#170d7b",
  "72": "#75966e",
  "73": "#97a159",
  "74": "#e69ddb",
  "75": "#400267",
  "76": "#2cd46d",
  "77": "#aeff24",
  "78": "#14c54b",
  "79": "#c49db4",
  "80": "#c416c6",
  "81": "#f8233d",
  "82": "#b1b232",
  "83": "#c4f75a",
  "84": "#bd4aef",
  "85": "#ac644f",
  "86": "#c50d66",
  "87": "#3024e3",
  "88": "#af7bd1",
  "89": "#cb57e6",
  "90": "#a16a5d",
  "91": "#1ffeca",
  "92": "#f1eff",
  "93": "#5675fb",
  "94": "#b9107e",
  "95": "#100da5",
  "96": "#3c6752",
  "97": "#ac74f6",
  "98": "#90fa5",
  "99": "#3a2114",
  "100": "#7cf1",
  "101": "#a99b93",
  "102": "#d2225d",
  "103": "#f04c39",
  "104": "#6084bb",
  "105": "#fe7831",
  "106": "#181927",
  "107": "#70d089",
  "108": "#edce71",
  "109": "#53d68f",
  "110": "#2b18fe",
  "111": "#b96260",
  "112": "#ed4811",
  "113": "#6aec8d",
  "114": "#7bf357",
  "115": "#541e15",
  "116": "#7e695e",
  "117": "#9d0ebc",
  "118": "#d9c3fc",
  "119": "#3ec52b",
  "120": "#ac6557",
  "121": "#e58ad5",
  "122": "#265164",
  "123": "#52098f",
  "124": "#a4559c",
  "125": "#769812",
  "126": "#5661e1",
  "127": "#b1fcf5",
  "128": "#3d2b47",
  "129": "#86c55e",
  "130": "#ac2069",
  "131": "#4ffce2",
  "132": "#7ff45",
  "133": "#a1811d",
  "134": "#6b7cd8",
  "135": "#8c2a1",
  "136": "#18424c",
  "137": "#c72d43",
  "138": "#e719c8",
  "139": "#adf152",
  "140": "#b5f031",
  "141": "#c40e76",
  "142": "#de618a",
  "143": "#915e9",
  "144": "#daddb9",
  "145": "#995619",
  "146": "#2084b7",
  "147": "#1b80a6",
  "148": "#45ad46",
  "149": "#7396ad",
  "150": "#2fa0b0",
  "151": "#933bff",
  "152": "#40427d",
  "153": "#24d0dc",
  "154": "#b2cfad",
  "155": "#59a772",
  "156": "#fa7e92",
  "157": "#5739db",
  "158": "#7a03d5",
  "159": "#8c1dee",
  "160": "#f9d5c5",
  "161": "#b424f",
  "162": "#fa402a",
  "163": "#5b4512",
  "164": "#75eb23",
  "165": "#d4c81b",
  "166": "#ddccdc",
  "167": "#24c3b3",
  "168": "#30ee36",
  "169": "#7480a3",
  "170": "#df3262",
  "171": "#a468ec",
  "172": "#28dafe",
  "173": "#da3c0c",
  "174": "#650922",
  "175": "#430108",
  "176": "#62da87",
  "177": "#68661b",
  "178": "#b5cdaa",
  "179": "#13ba14",
  "180": "#b67ea4",
  "181": "#7842aa",
  "182": "#9465a0",
  "183": "#63c8d8",
  "184": "#679b77",
  "185": "#9186bd",
  "186": "#f2be5f",
  "187": "#a7cae1",
  "188": "#f19926",
  "189": "#ed5b9c",
  "190": "#1a4ab",
  "191": "#989df9",
  "192": "#7a75ae",
  "193": "#6b4131",
  "194": "#47fb0c",
  "195": "#8ce009",
  "196": "#477d6e",
  "197": "#e67e3a",
  "198": "#99d9ef",
  "199": "#4cc296",
  "200": "#b62bd8",
  "201": "#1e6cbf",
  "202": "#f4326e",
  "203": "#75b925",
  "204": "#afce76",
  "205": "#c2f21d",
  "206": "#759662",
  "207": "#c95e3a",
  "208": "#8c9d77",
  "209": "#92a53e",
  "210": "#518e37",
  "211": "#ef648b",
  "212": "#acebce",
  "213": "#3913de",
  "214": "#99b4b5",
  "215": "#baea4d",
  "216": "#daf8f2",
  "217": "#9e8ecf",
  "218": "#568082",
  "219": "#a6f2bc",
  "220": "#c2e3b5",
  "221": "#68c8e6",
  "222": "#37dc69",
  "223": "#702fb4",
  "224": "#6e1a01",
  "225": "#c4a355",
  "226": "#3d50e6",
  "227": "#b88a70",
  "228": "#44d7f6",
  "229": "#fd6c43",
  "230": "#ae362e",
  "231": "#d54f28",
  "232": "#85bc8a",
  "233": "#4baf8a",
  "234": "#a45d75",
  "235": "#882590",
  "236": "#a8c4c6",
  "237": "#8f1231",
  "238": "#938370",
  "239": "#49067d",
  "240": "#a9a556",
  "241": "#314355",
  "242": "#ccc01d",
  "243": "#87ae76",
  "244": "#e8481b",
  "245": "#35f033",
  "246": "#583708",
  "247": "#bfddfb",
  "248": "#f5ccaa",
  "249": "#63871e",
  "250": "#fde58",
  "251": "#c0d079",
  "252": "#a1bc15",
  "253": "#fb622b",
  "254": "#e2fee2",
  "255": "#7602a4",
  "256": "#53f4f8",
  "257": "#4db2c3",
  "258": "#38a9a2",
  "259": "#a4c712",
  "260": "#9536e3",
  "261": "#f78266",
  "262": "#60caa8",
  "263": "#9cc883",
  "264": "#fd7041",
  "265": "#6b602a",
  "266": "#d1dd9e",
  "267": "#817010",
  "268": "#a310e8",
  "269": "#6242bf",
  "270": "#167eda",
  "271": "#55440e",
  "272": "#f88b33",
  "273": "#2a1719",
  "274": "#20d5cf",
  "275": "#401c56",
  "276": "#2a5c4d",
  "277": "#1685c2",
  "278": "#5ddd2a",
  "279": "#c5f5be",
  "280": "#166b42",
  "281": "#240f32",
  "282": "#d33495",
  "283": "#546239",
  "284": "#5b8d9a",
  "285": "#8da2d",
  "286": "#8f5db0",
  "287": "#d2de1c",
  "288": "#7d83a0",
  "289": "#884929",
  "290": "#7c058c",
  "291": "#16f38b",
  "292": "#ea6e9b",
  "293": "#d3262f",
  "294": "#9450d6",
  "295": "#ce3f09",
  "296": "#88f1f3",
  "297": "#3d2a8",
  "298": "#e1fd23",
  "299": "#429a01",
  "300": "#1c56fe",
  "301": "#bbe3ce",
  "302": "#de809f",
  "303": "#426cc8",
  "304": "#1ec7af",
  "305": "#4701c8",
  "306": "#8904ff",
  "307": "#30e5ec",
  "308": "#35b44c",
  "309": "#1548f6",
  "310": "#bfcced",
  "311": "#41c5a0",
  "312": "#ac4320",
  "313": "#c99ec6",
  "314": "#d898f4",
  "315": "#65d608",
  "316": "#fe1e06",
  "317": "#1b0380",
  "318": "#ae9c9",
  "319": "#475c14",
  "320": "#21b287",
  "321": "#cc6389",
  "322": "#1a002e",
  "323": "#47b92d",
  "324": "#76a5fc",
  "325": "#be81fb",
  "326": "#39226b",
  "327": "#236228",
  "328": "#cd3449",
  "329": "#bf71d6",
  "330": "#8a953e",
  "331": "#f65ac1",
  "332": "#4192a6",
  "333": "#20b46",
  "334": "#46a568",
  "335": "#382ff6",
  "336": "#3e8577",
  "337": "#437706",
  "338": "#d43402",
  "339": "#e8374f",
  "340": "#4b850f",
  "341": "#efc5ca",
  "342": "#f62282",
  "343": "#6feef5",
  "344": "#3392fd",
  "345": "#7b45a8",
  "346": "#d9dab3",
  "347": "#45d2f8",
  "348": "#e15cc9",
  "349": "#9a5805",
  "350": "#7e652d",
  "351": "#77640",
  "352": "#ca0f73",
  "353": "#cdfd99",
  "354": "#9b52b0",
  "355": "#586ad3",
  "356": "#fc006",
  "357": "#63505a",
  "358": "#15184b",
  "359": "#58a53b",
  "360": "#adbf0",
  "361": "#b7b99",
  "362": "#dd32d6",
  "363": "#950c0a",
  "364": "#5dd894",
  "365": "#868672",
  "366": "#76084e",
  "367": "#6416f0",
  "368": "#321c0b",
  "369": "#d35bb3",
  "370": "#83981b",
  "371": "#fa685a",
  "372": "#dd022f",
  "373": "#97381d",
  "374": "#30a24a",
  "375": "#32544e",
  "376": "#29386f",
  "377": "#3c8609",
  "378": "#c82433",
  "379": "#949ed5",
  "380": "#d4139c",
  "381": "#35c5ed",
  "382": "#d3b9c4",
  "383": "#f0285b",
  "384": "#1c2846",
  "385": "#f03a",
  "386": "#74b31f",
  "387": "#148eca",
  "388": "#94ffd1",
  "389": "#42b0be",
  "390": "#b49976",
  "391": "#82a5e1",
  "392": "#c79817",
  "393": "#117926",
  "394": "#84c518",
  "395": "#1f4fe2",
  "396": "#fa0038",
  "397": "#dea35f",
  "398": "#f9a6ac",
  "399": "#5962f0",
  "400": "#6d8066",
  "401": "#70cb1b",
  "402": "#b8ced0",
  "403": "#ab8061",
  "404": "#1c09ce",
  "405": "#4feee",
  "406": "#750d70",
  "407": "#31f5bc",
  "408": "#68be76",
  "409": "#3d16ed",
  "410": "#470140",
  "411": "#93572d",
  "412": "#cb9975",
  "413": "#647baf",
  "414": "#fb4c78",
  "415": "#da4aef",
  "416": "#8f3731",
  "417": "#5a9f5b",
  "418": "#cf3ee8",
  "419": "#8223aa",
  "420": "#49a45",
  "421": "#7c6326",
  "422": "#dfa6fa",
  "423": "#5f6000",
  "424": "#da093c",
  "425": "#6dbda4",
  "426": "#9c6cae",
  "427": "#79ebd9",
  "428": "#b72459",
  "429": "#921c3d",
  "430": "#dc99cc",
  "431": "#264954",
  "432": "#94448f",
  "433": "#f638d5",
  "434": "#83632b",
  "435": "#cfdb0e",
  "436": "#2ce01a",
  "437": "#5551ae",
  "438": "#563f17",
  "439": "#1826ac",
  "440": "#4be67a",
  "441": "#9e6237",
  "442": "#51327d",
  "443": "#5b8dfe",
  "444": "#44642c",
  "445": "#4d9759",
  "446": "#40bb2",
  "447": "#d85ed1",
  "448": "#cdc29e",
  "449": "#70d7ef",
  "450": "#49d436",
  "451": "#ccd2d9",
  "452": "#23243f",
  "453": "#f3ef8b",
  "454": "#c35e29",
  "455": "#d5fe5a",
  "456": "#fd0b63",
  "457": "#f80f1c",
  "458": "#2dd3f8",
  "459": "#105dff",
  "460": "#ffc3e9",
  "461": "#53a4bb",
  "462": "#ed7d21",
  "463": "#130263",
  "464": "#eee96",
  "465": "#9340df",
  "466": "#ddb866",
  "467": "#33cb0c",
  "468": "#a74b8e",
  "469": "#b05b93",
  "470": "#2a5092",
  "471": "#6bc73a",
  "472": "#db6e0d",
  "473": "#840b70",
  "474": "#c8ecb6",
  "475": "#c773e6",
  "476": "#fa9b5d",
  "477": "#37ec36",
  "478": "#d1f579",
  "479": "#bc4cff",
  "480": "#76c358",
  "481": "#e7ba65",
  "482": "#448a59",
  "483": "#b1a8e6",
  "484": "#ee5ce1",
  "485": "#f5804c",
  "486": "#2f663b",
  "487": "#baef0d",
  "488": "#f3397f",
  "489": "#aaf171",
  "490": "#2be585",
  "491": "#29e097",
  "492": "#cf40b7",
  "493": "#f817c0",
  "494": "#aad824",
  "495": "#17ccff",
  "496": "#6b52e2",
  "497": "#43ffa7",
  "498": "#20b135",
  "499": "#b90320",
  "500": "#fdbeba",
  "501": "#24edcc",
  "502": "#9b23cb",
  "503": "#74678",
  "504": "#6cae4f",
  "505": "#d322a6",
  "506": "#f88773",
  "507": "#f1f823",
  "508": "#2b1bbc",
  "509": "#f2e0e1",
  "510": "#3d2450",
  "511": "#d8a756",
  "512": "#bb0c70",
  "513": "#ca6b35",
  "514": "#91b1e8",
  "515": "#b96d76",
  "516": "#342195",
  "517": "#92285a",
  "518": "#2069df",
  "519": "#73dba9",
  "520": "#da1122",
  "521": "#1e5a88",
  "522": "#16ddfa",
  "523": "#d82351",
  "524": "#ef61df",
  "525": "#4e91bd",
  "526": "#4d426b",
  "527": "#f5821b",
  "528": "#d0c4ab",
  "529": "#d2850a",
  "530": "#fd210b",
  "531": "#ed0a0a",
  "532": "#93d14d",
  "533": "#8e59e8",
  "534": "#39922",
  "535": "#2d382f",
  "536": "#7ca3c9",
  "537": "#67daa2",
  "538": "#8bac12",
  "539": "#d411be",
  "540": "#a26036",
  "541": "#717809",
  "542": "#d7b697",
  "543": "#bd7943",
  "544": "#a62714",
  "545": "#f462e",
  "546": "#50478",
  "547": "#8da390",
  "548": "#5ad92b",
  "549": "#28e383",
  "550": "#f4992",
  "551": "#4cfa06",
  "552": "#4d69d1",
  "553": "#2e0b6f",
  "554": "#9e30e4",
  "555": "#4f00aa",
  "556": "#d0f22a",
  "557": "#ccd8c7",
  "558": "#519dbd",
  "559": "#fbc8b7",
  "560": "#1f0aec",
  "561": "#bdfa1",
  "562": "#de48d8",
  "563": "#477a74",
  "564": "#3f79cb",
  "565": "#fec218",
  "566": "#1bd8c0",
  "567": "#654287",
  "568": "#13ab53",
  "569": "#383abf",
  "570": "#9f03dd",
  "571": "#9a9b98",
  "572": "#9cc526",
  "573": "#ebabf8",
  "574": "#ce8e21",
  "575": "#4a4b47",
  "576": "#667547",
  "577": "#75081",
  "578": "#df3e97",
  "579": "#df20e0",
  "580": "#cd31cf",
  "581": "#61060a",
  "582": "#c83471",
  "583": "#6427bc",
  "584": "#b8691a",
  "585": "#2c366f",
  "586": "#f4b477",
  "587": "#e61417",
  "588": "#ed23b3",
  "589": "#2ae97e",
  "590": "#c6f411",
  "591": "#b5ae01",
  "592": "#77b333",
  "593": "#488587",
  "594": "#40e146",
  "595": "#1d9e9d",
  "596": "#9f7321",
  "597": "#e394aa",
  "598": "#4c0ff5",
  "599": "#bab8e1",
  "600": "#62908",
  "601": "#a4aaf1",
  "602": "#a4ccc0",
  "603": "#ae3275",
  "604": "#375fe0",
  "605": "#58ede5",
  "606": "#704804",
  "607": "#fa13",
  "608": "#549424",
  "609": "#40379",
  "610": "#61fab9",
  "611": "#65747d",
  "612": "#ddefad",
  "613": "#2f4a50",
  "614": "#251821",
  "615": "#300ecd",
  "616": "#b669d7",
  "617": "#1ae219",
  "618": "#996c97",
  "619": "#fd429",
  "620": "#b24cbe",
  "621": "#7e2d4e",
  "622": "#6579d1",
  "623": "#d57b9a",
  "624": "#376fae",
  "625": "#d0a108",
  "626": "#6f7094",
  "627": "#15848a",
  "628": "#3be54e",
  "629": "#8c8918",
  "630": "#2019b3",
  "631": "#21d816",
  "632": "#412c0a",
  "633": "#19d0c9",
  "634": "#7314d2",
  "635": "#415dca",
  "636": "#630986",
  "637": "#62aab1",
  "638": "#1218e8",
  "639": "#f24c82",
  "640": "#5f7901",
  "641": "#3bb2ed",
  "642": "#48b400",
  "643": "#f8540a",
  "644": "#2390f9",
  "645": "#3aee54",
  "646": "#c422bf",
  "647": "#fe3818",
  "648": "#447ff6",
  "649": "#4ca15d",
  "650": "#376c7",
  "651": "#bf66f3",
  "652": "#36b5e3",
  "653": "#ffe5f4",
  "654": "#4020fd",
  "655": "#2abec0",
  "656": "#d1441b",
  "657": "#b6b73d",
  "658": "#502a9a",
  "659": "#ee1aa4",
  "660": "#368c74",
  "661": "#5d14c9",
  "662": "#ebe7db",
  "663": "#ac2b28",
  "664": "#1da18c",
  "665": "#9474ae",
  "666": "#ef6a4c",
  "667": "#79cb77",
  "668": "#605c9b",
  "669": "#8772b5",
  "670": "#8892f0",
  "671": "#7b75c9",
  "672": "#ac6a1f",
  "673": "#e25b6b",
  "674": "#823913",
  "675": "#df77bc",
  "676": "#6b2547",
  "677": "#dea38b",
  "678": "#79d98a",
  "679": "#1ba8fc",
  "680": "#d04903",
  "681": "#c2ff79",
  "682": "#4dc1da",
  "683": "#2aed5b",
  "684": "#4f0280",
  "685": "#980093",
  "686": "#b04a9e",
  "687": "#1e2a21",
  "688": "#98ffb0",
  "689": "#2b61db",
  "690": "#1c2200",
  "691": "#20569e",
  "692": "#dc6afc",
  "693": "#f5efa",
  "694": "#b98df2",
  "695": "#f09b50",
  "696": "#f309aa",
  "697": "#9a496d",
  "698": "#3e4fb4",
  "699": "#6e767a",
  "700": "#468a99",
  "701": "#a37164",
  "702": "#24ee08",
  "703": "#ba94ae",
  "704": "#ccd9bd",
  "705": "#681d21",
  "706": "#6bdca3",
  "707": "#836f10",
  "708": "#26a0da",
  "709": "#cc54b0",
  "710": "#a88781",
  "711": "#e13087",
  "712": "#e076fc",
  "713": "#b2f303",
  "714": "#424763",
  "715": "#a60e84",
  "716": "#ea42fb",
  "717": "#1d27e7",
  "718": "#bfa2da",
  "719": "#16448c",
  "720": "#63d5db",
  "721": "#856564",
  "722": "#68f693",
  "723": "#e7c0e4",
  "724": "#b1288c",
  "725": "#e87820",
  "726": "#fcd243",
  "727": "#a7f10b",
  "728": "#8a5d9a",
  "729": "#196829",
  "730": "#2daeaa",
  "731": "#83e88",
  "732": "#c957be",
  "733": "#cb0d70",
  "734": "#25f725",
  "735": "#ba753f",
  "736": "#3e4fe1",
  "737": "#fc70e9",
  "738": "#f33e79",
  "739": "#162bd1",
  "740": "#d607f5",
  "741": "#f1cbc2",
  "742": "#751482",
  "743": "#ed2025",
  "744": "#118ec0",
  "745": "#4d0abb",
  "746": "#d39004",
  "747": "#af8a7b",
  "748": "#61bc66",
  "749": "#88c475",
  "750": "#560aeb",
  "751": "#986e6c",
  "752": "#e38438",
  "753": "#a86b40",
  "754": "#9d5be8",
  "755": "#2400d0",
  "756": "#74b42b",
  "757": "#edb6d0",
  "758": "#9d8e64",
  "759": "#aea495",
  "760": "#19b182",
  "761": "#e75a",
  "762": "#202c83",
  "763": "#16853",
  "764": "#e7af21",
  "765": "#585804",
  "766": "#4038fb",
  "767": "#1c4a12",
  "768": "#8bb650",
  "769": "#5addc7",
  "770": "#fd99dd",
  "771": "#368345",
  "772": "#11bd42",
  "773": "#1ce562",
  "774": "#bcd465",
  "775": "#2cab9d",
  "776": "#2ac5ed",
  "777": "#9f799e",
  "778": "#3be238",
  "779": "#7dd4c8",
  "780": "#dec117",
  "781": "#788ee6",
  "782": "#f21f4a",
  "783": "#3bccd5",
  "784": "#c59108",
  "785": "#f235e0",
  "786": "#32475b",
  "787": "#428b35",
  "788": "#b4cd21",
  "789": "#718fc0",
  "790": "#d2af8b",
  "791": "#865b93",
  "792": "#d5d69c",
  "793": "#d4c632",
  "794": "#dec0ce",
  "795": "#36ad04",
  "796": "#6a73a0",
  "797": "#3c6c7e",
  "798": "#bfcb4",
  "799": "#1dec49",
  "800": "#6829c3",
  "801": "#62bd38",
  "802": "#d3ba5a",
  "803": "#dc10c",
  "804": "#91a1e9",
  "805": "#ac5718",
  "806": "#c18bd5",
  "807": "#39178b",
  "808": "#375c1d",
  "809": "#7eb34b",
  "810": "#6f1172",
  "811": "#4e3bb6",
  "812": "#860f42",
  "813": "#bc584f",
  "814": "#aa98dd",
  "815": "#e2db80",
  "816": "#1767c3",
  "817": "#4956cc",
  "818": "#d573e4",
  "819": "#95f861",
  "820": "#adb0aa",
  "821": "#f61b58",
  "822": "#c727da",
  "823": "#e35f7a",
  "824": "#9a3679",
  "825": "#ed687",
  "826": "#87e8cc",
  "827": "#8e7195",
  "828": "#70d44b",
  "829": "#56580",
  "830": "#e548af",
  "831": "#b2238",
  "832": "#26571b",
  "833": "#c8b5a0",
  "834": "#8d7376",
  "835": "#722256",
  "836": "#673747",
  "837": "#8a350e",
  "838": "#a32ff2",
  "839": "#ecb56e",
  "840": "#ac9568",
  "841": "#431809",
  "842": "#950b4c",
  "843": "#d0fd88",
  "844": "#eeec23",
  "845": "#ce519d",
  "846": "#65bb54",
  "847": "#fed460",
  "848": "#4e1d13",
  "849": "#2bb1dd",
  "850": "#882cca",
  "851": "#85ef40",
  "852": "#eb9616",
  "853": "#2f7b8f",
  "854": "#8f562a",
  "855": "#784f86",
  "856": "#aa369e",
  "857": "#d9cf48",
  "858": "#427dcc",
  "859": "#6786e0",
  "860": "#c245b",
  "861": "#1dec40",
  "862": "#f16c77",
  "863": "#7ebd47",
  "864": "#e08460",
  "865": "#4d89b",
  "866": "#8dee9",
  "867": "#3646a6",
  "868": "#ce9882",
  "869": "#470e2c",
  "870": "#11f68d",
  "871": "#baf1ec",
  "872": "#666f39",
  "873": "#122f79",
  "874": "#28c0d",
  "875": "#160215",
  "876": "#9afd6f",
  "877": "#f9cbd8",
  "878": "#d081c5",
  "879": "#bdca26",
  "880": "#a18a10",
  "881": "#7507d1",
  "882": "#6a9238",
  "883": "#403dd6",
  "884": "#64f53",
  "885": "#4d367f",
  "886": "#e0fe60",
  "887": "#760cad",
  "888": "#104267",
  "889": "#dda9ca",
  "890": "#2e1654",
  "891": "#a172c8",
  "892": "#177357",
  "893": "#597e43",
  "894": "#cd3471",
  "895": "#4746d1",
  "896": "#65a36b",
  "897": "#e8fd23",
  "898": "#3d4c33",
  "899": "#70dc67",
  "900": "#7abc42",
  "901": "#12119",
  "902": "#670297",
  "903": "#4d262",
  "904": "#c9b191",
  "905": "#d37b16",
  "906": "#60a9b4",
  "907": "#8154f2",
  "908": "#ac2e28",
  "909": "#8536c5",
  "910": "#5ecbbe",
  "911": "#49d575",
  "912": "#e73993",
  "913": "#93b0dc",
  "914": "#da3683",
  "915": "#7cbac6",
  "916": "#1d6063",
  "917": "#286453",
  "918": "#d89cf8",
  "919": "#6bb252",
  "920": "#e885f7",
  "921": "#f65a71",
  "922": "#a6c448",
  "923": "#666629",
  "924": "#e6e6d6",
  "925": "#5b6b00",
  "926": "#e59b9",
  "927": "#8e4f54",
  "928": "#fbb9a7",
  "929": "#48070e",
  "930": "#75d10a",
  "931": "#82cc74",
  "932": "#e8d5bc",
  "933": "#2ffe52",
  "934": "#469ebd",
  "935": "#21b403",
  "936": "#afd03a",
  "937": "#777098",
  "938": "#6c53a5",
  "939": "#636b14",
  "940": "#ed38ab",
  "941": "#707ac5",
  "942": "#c0e719",
  "943": "#a95e1",
  "944": "#7f4299",
  "945": "#e2059c",
  "946": "#b181ac",
  "947": "#6ff08",
  "948": "#1ae32",
  "949": "#c1ec12",
  "950": "#61814e",
  "951": "#f34efa",
  "952": "#31d68e",
  "953": "#c64414",
  "954": "#c2ed74",
  "955": "#4c03c9",
  "956": "#77caed",
  "957": "#7f40e9",
  "958": "#5d3ec4",
  "959": "#c91575",
  "960": "#cfd23b",
  "961": "#98fd2c",
  "962": "#8ff51",
  "963": "#460205",
  "964": "#ad69c1",
  "965": "#5e706",
  "966": "#af5bef",
  "967": "#52183c",
  "968": "#b8dd9c",
  "969": "#c85db4",
  "970": "#3d3ff6",
  "971": "#a692c6",
  "972": "#a9c47b",
  "973": "#4650c7",
  "974": "#2b2b34",
  "975": "#494c8a",
  "976": "#2d9fe8",
  "977": "#a6c21c",
  "978": "#928f1e",
  "979": "#d5416a",
  "980": "#c1863f",
  "981": "#d7243f",
  "982": "#58cb7d",
  "983": "#c72f33",
  "984": "#4af00a",
  "985": "#fd8aa9",
  "986": "#33e5ad",
  "987": "#476f7f",
  "988": "#274138",
  "989": "#88a596",
  "990": "#5d3e00",
  "991": "#3e08fb",
  "992": "#77f36e",
  "993": "#62015f",
  "994": "#fdfcc1",
  "995": "#b3b0f8",
  "996": "#da896b",
  "997": "#9c9b4e",
  "998": "#60be2d",
  "999": "#da3ce6",
  "1000": "#4b1caf"
};
