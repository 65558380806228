import React from "react";

import "./Ubicacion.css";
import * as calc from "../CalculosAccesorios/CalculosAccesorios";

const Ubicacion = props => {
  let alturaUbicacion = calc.deMetrosAPixeles(1) - 2;

  const style = {
    height: String(alturaUbicacion) + "px",
    backgroundColor: "lightgreen",
    borderColor: "black",
    borderWidth: "1px",
    borderStyle: "solid",
    lineHeight: String(alturaUbicacion) + "px"
  };

  let ubicacionName = "";
  let currentScale = Number(calc.getCurrentScale());
  if (currentScale >= 1.5) {
    ubicacionName = props.name;
  } else {
    ubicacionName = "";
  }

  return (
    <div className="ubicacion" id={props.id} style={style}>
      {ubicacionName}
    </div>
  );
};

export default Ubicacion;
