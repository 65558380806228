import React from "react";
// Material
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "store/actions/index";
// DB
// import Database from 'variables/Database.js'
// import { toast } from 'react-toastify'

function ConfirmDialog(props) {
  const setEstadoCero = () => {
    // let data = { isDeleted: props.selected_db_id }
    // Database.post(`/set-estado-cero`, { data }, this).then(
    //   res => {
    //     toast.success("Campo eliminado!");
    //     props.toggleConfirmDialog();
    //     props.touch_reload_table();
    //   },
    //   err => {
    //     toast.error(err.message);
    //   }
    // );
    props.setIsDeleted(true);
    props.toggleConfirmDialog();
    props.touch_reload_table();
  };

  return (
    <div>
      <Dialog
        open={props.confirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro que quiere eliminar este campo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setEstadoCero} color="primary" autoFocus>
            {props.text_yes ? props.text_no : `SI`}
          </Button>
          <Button onClick={() => props.toggleConfirmDialog()} color="primary">
            {props.text_no ? props.text_no : `NO`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    confirmDialog: state.importacionmasiva.confirmDialog,
    reload_table: state.importacionmasiva.reload_table,
    selected_db_id: state.importacionmasiva.selected_db_id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleConfirmDialog: () => dispatch(actionCreators.toggleConfirmDialog()),
    touch_reload_table: () => dispatch(actionCreators.touch_reload_table())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
