import React, { Component } from "react";
import Database from "variables/Database.js";
import { Route, Switch } from "react-router-dom";

// import { AddBox, ArrowUpward } from "@material-ui/icons";
// import ReactDOM from "react-dom";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import moment from "moment";
import { localization } from "variables/general.js";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Button from "components/CustomButtons/Button.js";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import AddIcon from "@material-ui/icons/Add";

import DetalleEntradas from "./components/DetalleEntradas";
import DetallePrint from "./components/DetallePrint";
import NewEntrada from "./components/NewEntrada";

import { withStyles } from "@material-ui/styles";

const columns = [
  { title: "Identificador", field: "id", customSort: (a, b) => a.id - b.id },
  { title: "Clientes", field: "cliente.nombre_fantasia" },
  { title: "Devolucion", field: "devolucion" },
  { title: "Referencia", field: "referencia" },
  { title: "Muelle", field: "muelle.descripcion" },
  {
    title: "Fecha",
    field: "fecha_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.fecha).format("YYYYMMDDHHmmSS")) -
      parseInt(moment(b.fecha).format("YYYYMMDDHHmmSS"))
  },
  { title: "Usuario", field: "user.nombre" }
];

/*
render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}
 <Moment format="YYYY/MM/DD">
                1976-04-19T12:59-0500
            </Moment>
 */

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Entradas extends Component {
  state = {
    listado: [],
    actions: [],
    isLoading: false
  };

  getItems = () => {
    this.setState({
      isLoading: true
    });
    Database.get("/list-entradas", this, null, true).then(
      res => {
        this.setState({
          isLoading: false
        });
        let resultado = res.result;
        resultado = resultado.map(elem => {
          return {
            ...elem,
            devolucion: elem.es_devolucion === 0 ? "NO" : "SI",
            fecha_mostrar: moment(elem.fecha).format("DD/MM/YYYY HH:mm")
          };
        });

        console.log(resultado);

        this.setState({
          listado: resultado
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    this.getItems();
  }

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }
    return (
      <div style={{ maxWidth: "100%" }}>
        <Card style={style}>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>ENTRADAS</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Listado de Entradas
            </p>
          </CardHeader>
          <CardBody>
            <Button
              style={{ marginTop: "25px" }}
              onClick={() =>
                this.props.history.push(this.props.match.url + "/nueva")
              }
              color="primary"
            >
              <AddIcon /> Nueva Entrada
            </Button>

            <MaterialTable
              isLoading={this.state.isLoading}
              columns={columns}
              data={this.state.listado}
              title=""
              actions={this.state.actions}
              localization={localization}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                exportFileName: "Entradas " + moment().format("DD-MM-YYYY"),
                exportDelimiter: ";",
                headerStyle: {
                  backgroundColor: lightGreen[700],
                  color: "#FFF"
                }
              }}
              detailPanel={rowData => {
                return <DetalleEntradas idEntrada={rowData.id} />;
              }}
              actions={[
                {
                  icon: "description",
                  tooltip: "Detalle de la Entrada",
                  onClick: (_event, rowData) =>
                    this.props.history.push(
                      this.props.match.url + "/detalle/" + rowData.id
                    )
                }
              ]}
            />
          </CardBody>
        </Card>

        <Switch>
          <Route
            path={this.props.match.url + "/nueva"}
            exact
            render={() => <NewEntrada getItems={() => this.getItems()} />}
          />

          <Route
            path={this.props.match.url + "/detalle/:idEntrada"}
            exact
            render={() => <DetallePrint />}
          />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(Entradas);
