import { Paper } from "@material-ui/core";
// React base
import React from "react";
// Styles
import { withStyles } from "@material-ui/styles";
// Material
import Fab from "@material-ui/core/Fab";
import ForwardIcon from "@material-ui/icons/Forward";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
// Confirm Dialog
import ConfirmDialog from "../ModalConfirm/ModalConfirm";
import PickingConfirm from "../CardPicking/PickingConfirm";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

const classes = {
  paper: { margin: "2em", paddingLeft: "1em", paddingBottom: "1em" },

  contenido: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(0,0,0,.62)",
      marginLeft: "35px",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  titulo: {
    color: "black",
    marginTop: "10px",
    marginBottom: "3px",
    marginLeft: "20px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button_panel: {
    marginLeft: "30px",
    marginBottom: "30px"
  },
  button_custom: {
    marginLeft: "10px"
  }
};

const getTooltipText = props => {
  if (props.selectedTab === 0) {
    if (
      props.calle_de_salida === null &&
      props.contenedoresEnLoteDeSalida.length > 0
    ) {
      return "Debe seleccionar una calle de salida";
    } else if (
      props.calle_de_salida === null &&
      props.contenedoresEnLoteDeSalida.length === 0
    ) {
      return "Debe seleccionar contenedores y agregarlos al lote de salida";
    } else if (
      props.calle_de_salida != null &&
      props.contenedoresEnLoteDeSalida.length === 0
    ) {
      return `Debe seleccionar contenedores y agregarlos al lote de salida para enviarlos a: ${props.calle_de_salida.descripcion}`;
    } else {
      return `Enviar lote a la calle: ${props.calle_de_salida.descripcion}`;
    }
  } else if (props.selectedTab === 2) {
    if (props.contenedoresEnLoteDeSalida.length === 0) {
      return "Debe seleccionar contenedores y agregarlos al lote de salida";
    } else {
      return "Dar salida al lote creado";
    }
  }
};

const salidaIsDisabled = props => {
  if (props.selectedTab !== 2) {
    return !(props.calle_de_salida !== null && props.editEnabled)
      ? true
      : false;
  } else if (props.selectedTab === 2) {
    return !props.editEnabled;
  }
};

const getTitleConfirmDialog = props => {
  if (
    props.selectedTab === 0 &&
    props.selectedRadioButton === "nopedido" &&
    !props.sePerderanContenedoresSeleccionados
  ) {
    return "Enviar lote a calle de salida?";
  } else if (
    props.selectedTab === 0 &&
    props.selectedRadioButton === "pedido" &&
    !props.sePerderanContenedoresSeleccionados
  ) {
    return "Enviar pedido a calle de salida?";
  } else if (props.selectedTab === 2) {
    return "Dar salida del almacén?";
  } else if (props.sePerderanContenedoresSeleccionados === true) {
    return "Existen contenedores seleccionados que no fueron enviados a calle de salida";
  }
};

const getDescriptionConfirmDialog = props => {
  if (
    props.selectedTab === 0 &&
    props.selectedRadioButton === "nopedido" &&
    !props.sePerderanContenedoresSeleccionados
  ) {
    return "Confirma que desea enviar el lote actual a la calle de salida?";
  } else if (
    props.selectedTab === 0 &&
    props.selectedRadioButton === "pedido" &&
    !props.sePerderanContenedoresSeleccionados
  ) {
    return "Confirma que desea enviar el pedido a la calle de salida?";
  } else if (props.selectedTab === 2) {
    return "Confirma que desea dar salida del almacén al lote?";
  } else if (props.sePerderanContenedoresSeleccionados === true) {
    return "Confirma que desea descartar los pallets seleccionados?";
  }
};

const encabezadoPaper = props => {
  return (
    <div>
      <Paper className={props.classes.paper} variant="outlined">
        <h4 className={props.classes.titulo}>{props.titulo_paper}</h4>
        <p className={props.classes.contenido}>{props.contenido_paper}</p>
        <div className={props.classes.button_panel}>
          {props.selectedTab === 2 ? (
            <Tooltip title="Seleccionar un pedido para obtener una lista de pallets">
              <Fab
                color="secondary"
                aria-label="edit"
                variant="extended"
                className={props.classes.button_custom}
                onClick={() => props.setModalVisibility(true, "pedido")}
              >
                <SearchIcon />
                Buscar un pedido
              </Fab>
            </Tooltip>
          ) : null}
          {props.selectedTab === 1 ? (
            <Fab
              color="secondary"
              aria-label="edit"
              variant="extended"
              className={props.classes.button_custom}
              disabled={props.editEnabled ? false : true}
              onClick={() => props.setModalVisibility(true, "lote_picking")}
            >
              <EditIcon />
              Editar Lote de salida
            </Fab>
          ) : (
            <Fab
              color="secondary"
              aria-label="edit"
              variant="extended"
              className={props.classes.button_custom}
              disabled={props.editEnabled ? false : true}
              onClick={() => props.setModalVisibility(true, "lote")}
            >
              <EditIcon />
              Editar Lote de salida
            </Fab>
          )}
          {props.con_calle_de_salida ? (
            <Fab
              color="secondary"
              className={props.classes.button_custom}
              variant="extended"
              disabled={props.editEnabled ? false : true}
              onClick={() => props.setModalVisibility(true, "calle")}
            >
              Seleccionar una calle de salida
              <ForwardIcon />
            </Fab>
          ) : null}
          <Tooltip title={getTooltipText(props)}>
            <span>
              <Fab
                color="primary"
                className={props.classes.button_custom}
                variant="extended"
                disabled={salidaIsDisabled(props)}
                onClick={() =>
                  props.selectedTab === 1
                    ? props.setModalVisibility(true, "confirm_picking")
                    : props.setModalVisibility(true, "confirm")
                }
              >
                {props.boton_salida_label}
                <ForwardIcon />
              </Fab>
            </span>
          </Tooltip>
        </div>
      </Paper>
      <ConfirmDialog
        title={getTitleConfirmDialog(props)}
        description={getDescriptionConfirmDialog(props)}
      />
      <PickingConfirm />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    editLoteDialog: state.salidas.editLoteDialog,
    calle_de_salida: state.salidas.calle_de_salida,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    lote_salida_picking: state.salidas.lote_salida_picking,
    selectedTab: state.salidas.selectedTab,
    sePerderanContenedoresSeleccionados:
      state.salidas.sePerderanContenedoresSeleccionados,
    selectedRadioButton: state.pedidossalidas.selectedRadioButton
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(encabezadoPaper));
