import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { StateMuelles } from "../VariablesState";

import Database from "variables/Database.js";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewMuelle extends Component {
  state = JSON.parse(JSON.stringify(StateMuelles));

  handleSubmitNewMuelle = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }
      this.setState({
        disableAllButtons: true
      });
      Database.post(`/insert-muelles`, {
        id: process.env.REACT_APP_ID_ALMACEN,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              toast.success("La muelle se ha creado con exito!");
              this.props.getMuellesAdmin();
              this.resetNewForm();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
      // console.log(objeto);
    } else {
      toast.error("Verificar los campos en el formulario");
    }
  };

  resetNewForm = () => {
    let orderFormAlt = { ...this.state.orderForm };
    //let successSubmit = this.state.successSubmit;
    for (let key in orderFormAlt) {
      orderFormAlt[key].value = "";
    }
    // if (all)
    //    successSubmit = false;

    this.setState({
      //    successSubmit: successSubmit,
      formIsValid: false
    });
    //this.getClientesType("new", newClienteFormAlt);
  };

  componentDidMount() {}

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }
    return (
      <form
        onSubmit={event => {
          this.handleSubmitNewMuelle(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Nuevo Muelle</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario de un muelle nuevo
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <InputNew
                  key={"editmuelle-" + formElement.id}
                  objeto={formElement.config}
                  changed={event => {
                    let { orderForm, formIsValid } = inputChangedHandler(
                      event,
                      formElement.id,
                      this.state.orderForm
                    );
                    this.setState({
                      orderForm: orderForm,
                      formIsValid: formIsValid
                    });
                  }}
                />
              ))}
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/muelles")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={!this.state.formIsValid || this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(NewMuelle));
