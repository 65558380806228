/* eslint-disable */
import React from "react";

const detailTable = props => {
  let styleHide = { display: "none" };
  let styleShow = { display: "block" };
  let styleTitleDetail = {
    fontSize: "115%",
    color: "green"
  };
  let styleDisplay = { ...styleShow };
  let styleDisplayNoposee = { ...styleHide };
  let unidades_bulto_label = "";
  // let unidades_bulto_cantidad = '';

  // window.displaymama = rowData.display;

  if (props.fila["ProductosPresentacion.tiene_display"] == 0) {
    styleDisplay = { ...styleHide };
    styleDisplayNoposee = { ...styleShow };
    unidades_bulto_label = "Unidades por bulto";
    // unidades_bulto_cantidad = props.fila["ProductosPresentacion.cantidad_unidades_bulto"];
  } else {
    unidades_bulto_label = "Displays por bulto";
    // unidades_bulto_cantidad = props.fila["ProductosPresentacion.cantidad_displays_bulto"];
  }

  return (
    <div style={{ marginLeft: "4em" }}>
      <div>
        <p style={styleTitleDetail}>Información sobre Display</p>
        <p style={styleDisplay}>
          cantidad de Unidades:{" "}
          {props.fila["ProductosPresentacion.cantidad_unidades_display"]} <br />
        </p>
        <p style={styleDisplayNoposee}>
          No posee display <br />
        </p>
      </div>
      <div>
        <p style={styleTitleDetail}>Información sobre Bulto</p>
        <p>
          {unidades_bulto_label}:{" "}
          {props.fila["ProductosPresentacion.cantidad_unidades_bulto"]} <br />
        </p>
      </div>
      <div>
        <p style={styleTitleDetail}> DUN14</p>
        <p>{props.fila["ProductosPresentacion.dun14"] || "no posee"} </p>
      </div>
      <div>
        <p style={styleTitleDetail}> Fecha de alta</p>
        <p>{props.fila.fecha_alta || "no posee"} </p>
      </div>
      <div>
        <p style={styleTitleDetail}> Vencimiento</p>
        <p>{props.fila.vencimiento || "no posee"} </p>
      </div>
    </div>
  );
};

export default detailTable;
