import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

class SelectorProductos extends Component {
  state = {
    productos: [],
    selected_producto: null,
    inputProducto: null
  };

  componentDidMount() {
    Database.get("/list-productos-unidad/", this).then(
      res => {
        let resultado = [...res.result];
        let productos = resultado;
        this.setState({ productos: productos });
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  render() {
    return (
      <Autocomplete
        id="combo-box-productos"
        options={this.state.productos}
        getOptionLabel={producto =>
          `${producto.codigo} - ${producto.ean13} -${producto.descripcion} `
        }
        value={this.state.selected_producto}
        onChange={(event, newValue) => {
          this.props.getDataSelectedProducto(newValue);
          this.setState({ selected_producto: newValue });
        }}
        inputValue={this.state.inputProducto}
        onInputChange={(event, newInputValue) => {
          this.setState({ inputProducto: newInputValue });
        }}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Seleccionar una referencia"
            variant="outlined"
          />
        )}
      ></Autocomplete>
    );
  }
}

export default SelectorProductos;
