import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
// Componente filas
import CamposMapeados from "./CamposMapeados";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "store/actions/index";
// DB
import Database from "variables/Database.js";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const MapeadorColumnas = function(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [dataToRender, setDataToRender] = React.useState({});
  const watchTable = props.reload_table;

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getCampos = quien => {
    let comps = [];
    if (dataToRender.hasOwnProperty(quien)) {
      comps = dataToRender[quien].map((c, index) => {
        let data_db = JSON.parse(c.modelo_bd_campo);
        let modelo_db = data_db.modelo;
        let campo_db = data_db.campo;
        let texto_tooltip = `${modelo_db}__${campo_db}`;
        let comp = (
          <Grid item xs={12} key={`${c.id}`}>
            <CamposMapeados
              text_izq={c.campo_bd}
              text_der={c.campo_csv}
              tabla_db={texto_tooltip}
              data_db={data_db}
              db_id={c.id}
            />
          </Grid>
        );
        return comp;
      });
    }
    return [...comps];
  };

  const clientes = getCampos("clientes");
  const referencias = getCampos("referencias");
  const datos_logisticos = getCampos("datos_logisticos");
  const categorias = getCampos("categoria");

  React.useEffect(() => {
    Database.get("/get-campos-importacion", null, null, true).then(
      async res => {
        let campos_clientes = [];
        let campos_categoria = [];
        let campos_dato_logistico = [];
        let campos_referencias = [];
        let campos_habilitados = [];
        let campos = [...res.result];
        campos.map(el => {
          campos_habilitados.push(`${el.id}`);
          let modelo_bd_campo = JSON.parse(el.modelo_bd_campo);
          if (modelo_bd_campo.tipo === "categoria") {
            campos_categoria.push(el);
          } else if (modelo_bd_campo.tipo === "datos_logisticos") {
            campos_dato_logistico.push(el);
          } else if (modelo_bd_campo.tipo === "clientes") {
            campos_clientes.push(el);
          } else if (modelo_bd_campo.tipo === "referencias") {
            campos_referencias.push(el);
          }
          return null;
        });
        let data_to_render = {};
        data_to_render["clientes"] = campos_clientes;
        data_to_render["categoria"] = campos_categoria;
        data_to_render["datos_logisticos"] = campos_dato_logistico;
        data_to_render["referencias"] = campos_referencias;
        await setDataToRender(data_to_render);
        await props.setCamposHabilitados(campos_habilitados);
        await props.touch_campos_cargados();
      },
      err => {
        toast.error(err.message);
      }
    );
    props.touch_campos_cargados();
  }, [watchTable]);

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel_cliente"}
        onChange={handleChange("panel_cliente")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel_clientebh-content"
          id="panel_clientebh-header"
        >
          <Typography className={classes.heading}>Cliente</Typography>
          <Typography className={classes.secondaryHeading}>
            Editar el mapeo de columnas para los datos del cliente
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>{clientes}</Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Referencias</Typography>
          <Typography className={classes.secondaryHeading}>
            Editar el mapeo de columnas para las referencias
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>{referencias}</Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Datos Logísticos</Typography>
          <Typography className={classes.secondaryHeading}>
            Editar el mapeo de columnas para los datos logísticos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>{datos_logisticos}</Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Categorías</Typography>
          <Typography className={classes.secondaryHeading}>
            Editar el mapeo de columnas para las categorías
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>{categorias}</Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    campos_habilitados: state.importacionmasiva.campos_habilitados,
    campos_cargados: state.importacionmasiva.campos_cargados,
    reload_table: state.importacionmasiva.reload_table
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCamposHabilitados: campos_habilitados =>
      dispatch(actionCreators.setCamposHabilitados(campos_habilitados)),
    touch_campos_cargados: () =>
      dispatch(actionCreators.touch_campos_cargados())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapeadorColumnas);
