import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

import {
  columnasTablaPicking,
  localization_options
} from "../Tablas/TablaPickingASalida.js";
import MaterialTable from "material-table";
import lightGreen from "@material-ui/core/colors/lightGreen";

function ModalTablaLotePickingASalida(props) {
  const removeFromLote = (rowData, props) => {
    window.lat = rowData;
    var ubicacion_id = rowData.id_ubicacion;
    if (rowData.referencia_seleccionada === "bulto") {
      let unidades_por_bulto = rowData.unidades_por_bulto;
      let unidades_a_sumar = unidades_por_bulto * rowData.cantidad * -1;
      props.restarUnidadesDePicking(ubicacion_id, unidades_a_sumar);
    }

    if (rowData.referencia_seleccionada === "display") {
      let unidades_por_display = rowData.unidades_por_display;
      let unidades_a_sumar = unidades_por_display * rowData.cantidad * -1;
      props.restarUnidadesDePicking(ubicacion_id, unidades_a_sumar);
    }

    if (rowData.referencia_seleccionada === "unidad") {
      let unidades_a_sumar = rowData.cantidad * -1;
      props.restarUnidadesDePicking(ubicacion_id, unidades_a_sumar);
    }

    props.removeUbicacionLoteSalida(rowData.tableData.id);
  };

  const lote_salida_picking_a_formato_tabla = () => {
    let lotes = props.lote_salida_picking;
    console.log(lotes);
    lotes.map(lote => {
      if (lote.referencia_seleccionada === "bulto") {
        lote.bultos_totales = lote.cantidad;
      }
      if (lote.referencia_seleccionada === "display") {
        lote.displays_totales = lote.cantidad;
      }
      if (lote.referencia_seleccionada === "unidad") {
        lote.unidades_totales = lote.cantidad;
      }
    });
    return lotes;
  };

  let columnasTablaModal = columnasTablaPicking;
  let datos_tabla = lote_salida_picking_a_formato_tabla();

  localization_options["body"]["emptyDataSourceMessage"] =
    "No existen artículos en lote picking";

  return (
    <Dialog
      open={props.editLotePickingDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">Editar el lote</DialogTitle>
      <DialogContent>
        <MaterialTable
          id="tabla-en-lote"
          columns={columnasTablaModal}
          data={datos_tabla}
          title=""
          localization={localization_options}
          actions={[
            rowData => ({
              icon: "undo",
              tooltip: "Devolver a su ubicación",
              onClick: (event, rowData) => removeFromLote(rowData, props)
            })
          ]}
          options={{
            filtering: true,
            exportDelimiter: ";",
            headerStyle: {
              backgroundColor: lightGreen[700],
              color: "#FFF"
            },
            selection: false,
            actionsColumnIndex: -1
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setModalVisibility(false, "lote_picking")}
          color="primary"
          autoFocus
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    lote_salida_picking: state.salidas.lote_salida_picking,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    editLotePickingDialog: state.salidas.editLotePickingDialog,
    selectedTab: state.salidas.selectedTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeUbicacionLoteSalida: ubicacion_id =>
      dispatch(actionCreators.removeUbicacionLoteSalida(ubicacion_id)),
    removeContenedorDeLoteDeSalida: contenedor =>
      dispatch(actionCreators.removeContenedorDeLoteDeSalida(contenedor)),
    restarBultosDePicking: (id_ubicacion, n_bultos) =>
      dispatch(actionCreators.restarBultosDePicking(id_ubicacion, n_bultos)),
    restarUnidadesDePicking: (id_ubicacion, n_unidades) =>
      dispatch(
        actionCreators.restarUnidadesDePicking(id_ubicacion, n_unidades)
      ),
    restarDisplaysDePicking: (id_ubicacion, n_displays) =>
      dispatch(
        actionCreators.restarDisplaysDePicking(id_ubicacion, n_displays)
      ),
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalTablaLotePickingASalida);
