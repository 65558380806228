import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// Styles
import { withStyles } from "@material-ui/styles";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// modal confirm

const classes = {
  radio_custom: {
    margin: "2em",
    paddingBottom: "1em",
    color: "black",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const RadioGroupPicking = props => {
  const [radioButtonOption, setValue] = React.useState("bulto");

  const handleChange = event => {
    setValue(event.target.value);
    props.resetSelectorCantidadPicking();
  };

  // const openModalDescartarContenedores = () => {
  //   props.setModalVisibility(true, 'confirm')
  // }

  let selectedRadioButtonPicking = radioButtonOption;
  props.setSelectedRadioButtonPicking(selectedRadioButtonPicking);

  return (
    <FormControl className={props.classes.radio_custom} component="fieldset">
      <RadioGroup
        row
        value={selectedRadioButtonPicking}
        onChange={handleChange}
      >
        <FormControlLabel value="bulto" control={<Radio />} label="Bultos" />
        <FormControlLabel
          value="display"
          control={<Radio />}
          label="Displays"
        />
        <FormControlLabel value="unidad" control={<Radio />} label="Unidades" />
      </RadioGroup>
    </FormControl>
  );
};

const mapStateToProps = state => {
  return {
    idSelectedClient: state.pedidossalidas.idSelectedClient,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRadioButtonPicking: selectedRadioButtonPicking =>
      dispatch(
        actionCreators.setSelectedRadioButtonPicking(selectedRadioButtonPicking)
      ),
    resetSelectorCantidadPicking: () =>
      dispatch(actionCreators.resetSelectorCantidadPicking()),
    setSelectedClientToCero: () =>
      dispatch(actionCreators.setSelectedClientToCero()),
    resetFormPedido: () => dispatch(actionCreators.resetFormPedido()),
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal)),
    setSePerderanContenedoresSeleccionados: bool_value =>
      dispatch(
        actionCreators.setSePerderanContenedoresSeleccionados(bool_value)
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(RadioGroupPicking));
