import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { StateEditBancoEmpleado } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditBancoEmpleado extends Component {
  state = JSON.parse(JSON.stringify(StateEditBancoEmpleado));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  getEmpleado = id => {
    Database.get("/list-empleado/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          nombreEmpleado:
            resultado.result[0].nombre.toUpperCase() +
            " " +
            resultado.result[0].apellido.toUpperCase(),
          idEmpleado: id
        });
      }
    });
  };

  getBancoEmpleadoEdit = id => {
    Database.get("/list-bancos-empleados/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          nombreEmpleado: this.getEmpleado(resultado.result[0].id_empleado),
          bancoempleadoEdit: resultado.result[0]
        });

        let editBancoEmpleadoFormAlt = { ...this.state.editBancoEmpleadoForm };
        editBancoEmpleadoFormAlt.id_banco.value = resultado.result[0].id_banco;
        editBancoEmpleadoFormAlt.nro_cuenta.value =
          resultado.result[0].nro_cuenta;
        editBancoEmpleadoFormAlt.id_tipo_cuenta.value =
          resultado.result[0].id_tipo_cuenta;
        editBancoEmpleadoFormAlt.cbu.value = resultado.result[0].cbu;
        for (let key in editBancoEmpleadoFormAlt) {
          editBancoEmpleadoFormAlt[key].touched = true;
          editBancoEmpleadoFormAlt[key].valid = true;
        }

        this.setState({
          editBancoEmpleadoForm: editBancoEmpleadoFormAlt
        });
        // this.getBancosEmpleadosType("edit", editBancoEmpleadoFormAlt);
      } else {
        this.setState({
          bancoempleadoEdit: null
        });
      }
    });

    Database.get("/list-bancos/").then(resultado => {
      console.log(resultado.result);
      if (resultado.result.length > 0) {
        console.log("entro");
        let editBancoEmpleadoFormAlt = { ...this.state.editBancoEmpleadoForm };

        let res = [...resultado.result];
        console.log(res);
        let s = [];
        res.forEach(function(entry) {
          s.push({
            value: entry.id,
            displayValue: entry.nombre
          });
        });

        console.log(s);

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.editBancoEmpleadoForm };
        // let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle))
        formulario.id_banco.elementConfig.options = s;
        // formulario.id_muelle = id_muelle;

        for (let key in editBancoEmpleadoFormAlt) {
          editBancoEmpleadoFormAlt[key].touched = true;
          editBancoEmpleadoFormAlt[key].valid = true;
        }

        this.setState({
          editBancoEmpleadoForm: editBancoEmpleadoFormAlt
        });
      } else {
        // this.setState({
        //   bancoEdit: null
        // })
      }
    });

    Database.get("/list-bancos-tipo-cuenta/").then(resultado => {
      console.log(resultado.result);
      if (resultado.result.length > 0) {
        console.log("entro");
        let editBancoEmpleadoFormAlt = { ...this.state.editBancoEmpleadoForm };

        let res = [...resultado.result];
        console.log(res);
        let s = [];
        res.forEach(function(entry) {
          s.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        console.log(s);

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.editBancoEmpleadoForm };
        // let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle))
        formulario.id_tipo_cuenta.elementConfig.options = s;
        // formulario.id_muelle = id_muelle;

        for (let key in editBancoEmpleadoFormAlt) {
          editBancoEmpleadoFormAlt[key].touched = true;
          editBancoEmpleadoFormAlt[key].valid = true;
        }

        this.setState({
          editBancoEmpleadoForm: editBancoEmpleadoFormAlt
        });
      } else {
        // this.setState({
        //   bancoEdit: null
        // })
      }
    });
  };

  //   getBancoEmpleadoTipo = () => {
  //   Database.get('/list-bancosempleados-tipo/')
  //   .then(res => {

  //     let resultado = [...res.result];
  //     let a = [];
  //     resultado.forEach(function (entry) {
  //       a.push({
  //         value: entry.id,
  //         displayValue: entry.descripcion
  //       });
  //     })
  //     let formulario = { ...this.state.EditBancoEmpleadoForm }
  //     formulario.tipo.elementConfig.options = [...a];
  //     this.setState({
  //       EditBancoEmpleadoForm: formulario
  //     })
  // },err => {
  //   toast.error(err.message);
  // })
  // }

  // handleChangePass = (event) => {
  //   event.preventDefault();
  //   this.setState({
  //     openChangePass:false
  //   })

  //   Database.post(`/update-pass`,{id: this.props.match.params.idbancoempleado,newpass:event.target.contrasenia.value})
  //     .then(res => {
  //       toast.success("La operación se ha modificado con exito!");
  //     },err =>{
  //       toast.error(err.message);

  //   })
  // }

  handleSubmitEditBancoEmpleado = event => {
    event.preventDefault();
    Database.post(
      `/update-banco-empleado`,
      {
        id: this.props.match.params.idbancoempleado,
        id_banco: this.state.editBancoEmpleadoForm.id_banco.value,
        nro_cuenta: this.state.editBancoEmpleadoForm.nro_cuenta.value,
        id_tipo_cuenta: this.state.editBancoEmpleadoForm.id_tipo_cuenta.value,
        cbu: this.state.editBancoEmpleadoForm.cbu.value
      },
      this
    ).then(
      res => {
        this.setState(
          {
            successSubmitEdit: true,
            editFormIsValid: false,
            disableAllButtons: false
          },
          () => {
            toast.success("La cuenta bancaria se ha modificado con exito!");

            //this.props.getBancosEmpleadosAdmin();
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editBancoEmpleadoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      editBancoEmpleadoForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  resetEditForm = () => {
    let editBancoEmpleadoFormAlt = { ...this.state.editBancoEmpleadoForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in editBancoEmpleadoFormAlt) {
      editBancoEmpleadoFormAlt[key].value = "";
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    });
  };

  componentDidMount() {
    // this.getBancosEmpleadosType();
    console.log(this.props.match.params);
    this.getBancoEmpleadoEdit(this.props.match.params.idbancoempleado);
    //this.getBancoEmpleadoTipo();
  }

  handleFechaDesde = date => {
    this.setState({
      fechaDesde: date
    });
  };
  handleFechaHasta = date => {
    this.setState({
      fechaHasta: date
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.editBancoEmpleadoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editBancoEmpleadoForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitEditBancoEmpleado(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              {this.state.nombreEmpleado}
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos de la cuenta bancaria del
              empleado
            </p>
          </CardHeader>
          <CardBody>
            {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
        Cambiar Contraseña
      </Button> */}

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"editbancoempleado-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={event =>
                    this.inputEditChangedHandler(event, formElement.id)
                  }
                />
              ))}
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/bancosempleados")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,

      <Dialog
        open={this.state.openChangePass}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
        <form
          onSubmit={event => {
            this.handleChangePass(event);
          }}
        >
          {this.state.openChangePass && (
            <DialogContent>
              <DialogContentText>
                Ingrese una nueva contraseña para la BancoEmpleado
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="contrasenia"
                name="contrasenia"
                label="nueva contraseña"
                type="password"
                fullWidth
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(EditBancoEmpleado));
