import React, { Component } from "react";
import Database from "variables/Database.js";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";

// import { AddBox, ArrowUpward } from "@material-ui/icons";
// import ReactDOM from "react-dom";

import { withStyles } from "@material-ui/styles";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import { toast } from "react-toastify";

import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { StateNewCategoria } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewCategoria extends Component {
  state = JSON.parse(JSON.stringify(StateNewCategoria));

  getItem = id => {
    Database.get(
      "/list-producto-categoria/" + process.env.REACT_APP_ID_ALMACEN + "/" + id,
      this
    ).then(
      res => {
        let resultado = res.result[0][0];
        let orderForm = { ...this.state.orderForm };
        for (let key in orderForm) {
          if (resultado[key]) orderForm[key].value = resultado[key];
        }
        let objeto = inputAllChangedHandler(orderForm);

        this.setState({
          orderForm: objeto.orderForm,
          formIsValid: objeto.formIsValid
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitNew = event => {
    event.preventDefault();
    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }
    objeto["sectores"] = this.state.sectores;

    console.log(objeto);

    Database.post(
      "/insert-producto-categoria",
      { id_almacen: process.env.REACT_APP_ID_ALMACEN, ...objeto },
      this
    ).then(
      res => {
        this.props.getCategorias();

        toast.success("Nueva categoria creada");
        this.resetForm();
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitEdit = event => {
    event.preventDefault();
    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }
    objeto["sectores"] = this.state.sectores;

    Database.post(
      "/update-producto-categoria",
      {
        id: this.props.match.params.id,
        id_almacen: process.env.REACT_APP_ID_ALMACEN,
        ...objeto
      },
      this
    ).then(
      res => {
        this.props.getCategorias();

        toast.success("Categoria modificada con éxito");
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  resetForm = () => {
    let state = JSON.parse(JSON.stringify(StateNewCategoria));
    this.setState(state);
    let sectores = [...this.state.sectores];
    sectores = sectores.map(elem => {
      return {
        ...elem,
        checked: false
      };
    });
    this.setState({
      sectores: sectores
    });
  };

  handleCheckbox = (event, index) => {
    let resultado = [...this.state.sectores];
    resultado[index].checked = event.target.checked;
    console.log(...this.state.sectores);
    this.setState({
      sectores: resultado
    });
  };

  componentDidMount() {
    if (this.props.match.params.id) this.getItem(this.props.match.params.id);

    //si esta editando y else esta creando
    if (this.props.match.params.id) {
      Database.get(
        "/list-producto-categoria-sectores/" +
          process.env.REACT_APP_ID_ALMACEN +
          "/" +
          this.props.match.params.id,
        this,
        null,
        true
      ).then(
        resultado => {
          if (resultado.ProductoCategoria.length > 0) {
            resultado.sectores = resultado.sectores.map(elem => {
              let indexResultado = resultado.detalleSectores.findIndex(
                elem2 => {
                  return elem2.id_sector == elem.id;
                }
              );
              if (indexResultado > -1) {
                elem.checked = true;
              } else {
                elem.checked = false;
              }
              //los checkbox los controla resultado.sectores
              //crea y guarda en resultado.sectores.id_producto_categoria el id de la categoria consultada

              elem.id_producto_categoria = resultado.ProductoCategoria[0].id;

              return elem;
            });

            delete resultado.detalleSectores;

            this.setState({
              sectores: resultado.sectores
            });
          }
        },
        err => {
          toast.error(err.message);
        }
      );
    } else {
      Database.get(
        "/list-sectores/" + process.env.REACT_APP_ID_ALMACEN,
        this,
        null,
        true
      ).then(
        res => {
          let resultado = [...res.result[0]];
          resultado = resultado.map(elem => {
            return {
              ...elem,
              checked: false
            };
          });
          this.setState({
            sectores: resultado
          });
        },
        err => {
          toast.error(err.message);
        }
      );
    }
  }

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    return (
      <form
        onSubmit={event => {
          if (this.props.match.params.id) this.handleSubmitEdit(event);
          else this.handleSubmitNew(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Nueva Categoria
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario alta de Categoria
            </p>
          </CardHeader>
          <CardBody>
            {formElementsArray.map(formElement => (
              <InputNew
                key={"input-" + formElement.id}
                objeto={formElement.config}
                changed={event => {
                  let { orderForm, formIsValid } = inputChangedHandler(
                    event,
                    formElement.id,
                    this.state.orderForm
                  );
                  this.setState({
                    orderForm: orderForm,
                    formIsValid: formIsValid
                  });
                }}
              />
            ))}

            {/* abm categorias-sectores lo deshabilito porque no se estará utilizando esta funcionalidad  */}

            {/* <div className="mt-3 mb-3">
     <h4>Sectores</h4>  
    <FormGroup row>
 
    
     { this.state.sectores.map((elem,index) => {
      return (

       
           <FormControlLabel
             key={ "label-" + index  }
             control={
                <Checkbox checked={elem.checked} onChange={(event) => this.handleCheckbox(event,index)}  />
                }
                  label={elem.descripcion}
               />

      )

    })

    } }
    </FormGroup>
 
 
    </div> */}

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.goBack()}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={!this.state.formIsValid || this.state.disableAllButtons}
              type="submit"
            >
              <Save />
              Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(NewCategoria));
