export const StateListEmpleados = {
  empleados: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditEmpleado = {
  EditEmpleadoForm: {
    numero_legajo: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número Legajo",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    apellido: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Apellido",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    dni: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "DNI",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true,
        minLength: 7,
        maxLength: 8
      },
      valid: false,
      touched: false
    },
    cuil: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "CUIL",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true,
        minLength: 11,
        maxLength: 11
      },
      valid: false,
      touched: false
    },
    nro_tramite_dni: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número de Tramite de DNI",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    id_estado_civil: {
      elementType: "select",
      elementConfig: {
        label: "Estado Civil",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    conyuge: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Conyuge",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  EditEmpleadoForm2: {
    id_empresa_empleadora: {
      elementType: "select",
      elementConfig: {
        label: "Empresa Empleadora",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false
    },
    direccion_completa: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Direccion Completa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    direccion_alternativa: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Direccion Alternativa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    telefono_contacto: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Telefono Contacto",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    telefono_personal: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Telefono Personal",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  fechaNacimiento: null,
  EditEmpleadoForm3: {
    id_superior_jerarquico: {
      elementType: "select",
      elementConfig: {
        label: "Superior Jerarquico",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    id_remera: {
      elementType: "select",
      elementConfig: {
        label: "Talle Remera",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    id_pantalon: {
      elementType: "select",
      elementConfig: {
        label: "Talle Pantalon",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    id_zapato: {
      elementType: "select",
      elementConfig: {
        label: "Talle Zapato",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  fechaVencimientoLinti: null,
  fechaVencimientoPsicofisico: null,
  fechaVencimientoCurso: null,
  fechaVencimientoLibretaSanitaria: null,
  fechaVencimientoLicencia: null,
  EditEmpleadoForm4: {
    id_categoria_licencia: {
      elementType: "select",
      elementConfig: {
        label: "Categoria Licencia",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    id_puesto: {
      elementType: "select",
      elementConfig: {
        label: "Puesto",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    convenio_camioneros: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Dentro de Convenio de Camioneros (convenio 40/89)",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    alta_dia: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Alta en Dia%",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    preocupacional_obser: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Observaciones del examen preocupacional",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  fechaPreocupacional: null,
  fechaIngreso: null,
  url_foto: null,
  url_dni: null,
  url_linti: null,
  url_libreta_sanitaria: null,
  url_licencia: null,
  url_preocupacional: null,
  files: [],
  empleadoEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewEmpleado = {
  fechaVencimientoLinti: null,
  newEmpleadoForm: {
    numero_legajo: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número Legajo",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    apellido: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Apellido",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    dni: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "DNI",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true,
        minLength: 7,
        maxLength: 8
      },
      valid: false,
      touched: false
    },
    cuil: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "CUIL",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true,
        minLength: 11,
        maxLength: 11
      },
      valid: false,
      touched: false
    },
    nro_tramite_dni: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número de Tramite de DNI",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    id_estado_civil: {
      elementType: "select",
      elementConfig: {
        label: "Estado Civil",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    conyuge: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Conyuge",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  newEmpleadoForm2: {
    id_empresa_empleadora: {
      elementType: "select",
      elementConfig: {
        label: "Empresa Empleadora",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false
    },
    direccion_completa: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Direccion Completa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    direccion_alternativa: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Direccion Alternativa",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    telefono_contacto: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Telefono Contacto",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    telefono_personal: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Telefono Personal",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  newEmpleadoForm3: {
    id_superior_jerarquico: {
      elementType: "select",
      elementConfig: {
        label: "Superior Jerarquico",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    id_remera: {
      elementType: "select",
      elementConfig: {
        label: "Talle Remera",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    id_pantalon: {
      elementType: "select",
      elementConfig: {
        label: "Talle Pantalon",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    id_zapato: {
      elementType: "select",
      elementConfig: {
        label: "Talle Zapato",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  newEmpleadoForm4: {
    id_puesto: {
      elementType: "select",
      elementConfig: {
        label: "Puesto",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    id_categoria_licencia: {
      elementType: "select",
      elementConfig: {
        label: "Categoria Licencia",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    convenio_camioneros: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Dentro de Convenio de Camioneros (convenio 40/89)",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    alta_dia: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Alta en Dia%",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    preocupacional_obser: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Observaciones del examen preocupacional",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  fechaPreocupacional: null,
  fechaNacimiento: null,
  fechaVencimientoPsicofisico: null,
  fechaVencimientoCurso: null,
  fechaVencimientoLibretaSanitaria: null,
  fechaVencimientoLicencia: null,
  fechaIngreso: null,

  formIsValid: false,
  successSubmit: null,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Apellido", field: "apellido" },
  { title: "Nombre", field: "nombre" },
  { title: "DNI", field: "dni" },
  { title: "CUIL", field: "cuil" },
  { title: "Número Legajo", field: "numero_legajo" }
];
