import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import SelectorPedidos from './SelectorPedidos'
import SelectorPedidosSinCliente from "./SelectorPedidosSinCliente";

//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

function ModalBuscarPedidos(props) {
  return (
    <Dialog
      open={props.pedidoDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Seleccionar un pedido</DialogTitle>
      <DialogContent>
        <SelectorPedidosSinCliente />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setModalVisibility(false, "pedido")}
          color="primary"
          autoFocus
        >
          {props.pedido_seleccionado == null ? "Cancelar" : `Seleccionar`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    pedido_seleccionado: state.pedidossalidas.pedido_seleccionado,
    pedidoDialog: state.salidas.pedidoDialog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalBuscarPedidos);
