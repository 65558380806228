import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import { StateSectores } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditSector extends Component {
  state = JSON.parse(JSON.stringify(StateSectores));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  /*
    getUserEdit = (id) => {
      Database.get('/list-users/' + id)
        .then(resultado => {
  
            if (resultado.result.length > 0) {
              this.setState({
                userEdit: resultado.result[0]
              })
  
              let editUserFormAlt = { ...this.state.editUserForm };
              editUserFormAlt.username.value = resultado.result[0].username;
              editUserFormAlt.nombre.value = resultado.result[0].nombre;
              editUserFormAlt.tipoUser.value = resultado.result[0].id_tipo_sector.toString();
              for (let key in editUserFormAlt) {
                editUserFormAlt[key].touched = true;
                editUserFormAlt[key].valid = true;
              }
              this.getUsersType("edit", editUserFormAlt);
            }
            else {
              this.setState({
                userEdit: null
              })
            }
  
        })
    }
  */

  getSectoresType = () => {
    Database.get(
      "/list_sectores_tipo/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultadoSectoresType = [...res.result];
        let a = [];
        resultadoSectoresType.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.orderForm };
        formulario.tipo_sector.elementConfig.options = [...a];
        this.setState({
          newUserForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getSectorEdit = id => {
    Database.get("/list-sector/" + id, this).then(
      resultado => {
        let objeto = resultado.result[0] || null;
        if (objeto) {
          let orderFormCopy = { ...this.state.orderForm };

          orderFormCopy.descripcion.value = objeto.descripcion || null;
          orderFormCopy.tipo_sector.value = resultado.result[0].id_tipo_sector.toString();
          //   orderFormCopy.calle_desde.value = resultado.result[0].calle_desde.toString();
          //  orderFormCopy.calle_hasta.value = resultado.result[0].calle_hasta.toString();
          for (let key in orderFormCopy) {
            orderFormCopy[key].touched = true;
            orderFormCopy[key].valid = true;
          }
          this.getSectoresType("edit", orderFormCopy);

          let { orderForm, formIsValid } = inputAllChangedHandler(
            orderFormCopy
          );

          this.setState({
            orderForm: orderForm,
            formIsValid: formIsValid
          });
        }
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitForm = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }
      this.setState({
        disableAllButtons: true
      });
      Database.post(`/update-sector`, {
        id: this.props.match.params.idsector,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              /*console.log(res);
            if(res.result[0].resultado == -1)
            toast.error("Alguna de las calles ya fueron asignadas a otro sector. Verifique los valores ingresados.");
          else*/
              toast.success("El sector se ha modificado con exito!");
              this.props.getSectoresAdmin();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
    } else {
      toast.error("verificar los campos en el formulario");
    }
  };

  componentDidMount() {
    // this.getSectoresType();
    this.getSectorEdit(this.props.match.params.idsector);
  }

  render() {
    const formElementsArray = [];

    //console.log(this.state.orderForm);
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitForm(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Editar Sector</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos de la sector
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <InputNew
                  key={"input-" + formElement.id}
                  objeto={formElement.config}
                  changed={event => {
                    let { orderForm, formIsValid } = inputChangedHandler(
                      event,
                      formElement.id,
                      this.state.orderForm
                    );
                    this.setState({
                      orderForm: orderForm,
                      formIsValid: formIsValid
                    });
                  }}
                />
              ))}
            </div>
            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/sectores")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    ];
  }
}

export default withRouter(withStyles(styles)(EditSector));
