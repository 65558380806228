import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Database from "variables/Database.js";
import { toast } from "react-toastify";

//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

function SelectorCallesDeSalida(props) {
  const [inputValue, setInputValue] = React.useState("");
  const [calles, setCalles] = React.useState([]);
  const [selected_calle, setCalle] = React.useState(null);

  useEffect(() => {
    Database.get(
      "/list-callessalida/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let calles = [...res.result];
        setCalles(calles);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, []);

  return (
    <Autocomplete
      id="combo-box-calles"
      value={selected_calle !== null ? selected_calle : null}
      onChange={(event, newValue) => {
        setCalle(newValue);
        props.setCalleDeSalida(newValue);
      }}
      options={calles}
      getOptionLabel={calle => `${calle.descripcion}`}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      style={{ width: 300 }}
      renderInput={params => (
        <TextField
          {...params}
          label="Seleccionar una calle"
          variant="outlined"
        />
      )}
    ></Autocomplete>
  );
}

const mapStateToProps = state => {
  return {
    calle_de_salida: state.salidas.calle_de_salida
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCalleDeSalida: calle => dispatch(actionCreators.setCalleDeSalida(calle))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectorCallesDeSalida);
