// imports React
import React, { Component } from "react";
// Grid
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// Card
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import EncabezadoPaper from "./EncabezadoPaper/EncabezadoPaper";
// Tabla de lotes a salida
import TablaLotesASalida from "./Tablas/TablaLotesASalida";
// Modales
import ModalTablaLote from "./ModalTabla/ModalTabla";
import ModalBuscarCallesDeSalida from "./ModalBuscarCalleDeSalida/ModalBuscarCalleDeSalida";
import ModalPedido from "./ModalPedidos/ModalPedido";
import ModalTablaLotePickingASalida from "./ModalTabla/ModalTablaLotePickingASalida";
//redux
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import SalidaDeAlmacen from "./Tablas/TablaSalidaDeAlmacen";
import TablaPicking from "./Tablas/TablaPickingASalida";

import CustomizedTabs from "./Tabs/Tabs";
// import CardPedido from './CardPedido/CardPedido';
import StepperPedido from "./CardPedido/StepperPedido.js";
import RadioGroup from "./RadioGroup/RadioGroup";

import CardPicking from "./CardPicking/CardPicking.js";

class Salidas extends Component {
  id_timeout = "";
  counter = 1;

  getLabelEncabezado = () => {
    let l = this.props.contenedoresEnLoteDeSalida.length;
    let lp = this.props.lote_salida_picking.length;
    let tab = this.props.selectedTab;
    if (l === 0 && tab !== 1) {
      return "No hay pallets seleccionados";
    } else if (l === 1 && tab !== 1) {
      return "Hay un pallet seleccionado";
    } else if (l > 1 && tab !== 1) {
      return `Hay ${l} pallets seleccionados`;
    } else if (lp === 0 && tab === 1) {
      return "No hay articulos en el lote";
    } else if (lp === 1 && tab === 1) {
      return "Hay un artículo en el lote";
    } else if (lp > 1 && tab === 1) {
      return `Hay ${lp} artículos en el lote`;
    } else {
      return "";
    }
  };

  getLabelTituloPaper = () => {
    let selected_tab = this.props.selectedTab;
    if (selected_tab === 0) {
      return "Pallets a calle de salida";
    } else if (selected_tab === 1) {
      return "Picking a calle de salida";
    } else if (selected_tab === 2) {
      return "Salida del almacén";
    }
  };

  get_id_ubicacion_picking = () => {
    let id = this.props.datos_completos_ubicacion_picking.id;
    return id;
  };

  render() {
    let isEditEnabled =
      this.props.contenedoresEnLoteDeSalida.length > 0 ||
      this.props.lote_salida_picking.length > 0
        ? true
        : false;
    let label_contenedores_seleccionados = this.getLabelEncabezado();
    let con_calle_de_salida = this.props.selectedTab !== 2;
    let boton_salida_label =
      this.props.selectedTab !== 2
        ? "Enviar lote a calle de salida"
        : "Dar salida del Almacén";
    let label_titulo_paper = this.getLabelTituloPaper();
    let label_no_pedido =
      this.props.selectedTab === 0
        ? "Dar salida a pallets por fuera de un pedido"
        : "Dar salida de picking por fuera de un pedido";
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CustomizedTabs
                titulo_tabs="Salidas"
                labeltab1="Pallet a calle de salida"
                labeltab2="Picking a calle de salida"
                labeltab3="Salida del almacén"
              ></CustomizedTabs>
              {this.props.selectedTab !== 2 && (
                <RadioGroup label_no_pedido={label_no_pedido} />
              )}
              <EncabezadoPaper
                titulo_paper={label_titulo_paper}
                contenido_paper={label_contenedores_seleccionados}
                editEnabled={isEditEnabled}
                con_calle_de_salida={con_calle_de_salida}
                boton_salida_label={boton_salida_label}
              />
              <div>
                {this.props.selectedTab !== 2 &&
                  this.props.selectedRadioButton === "pedido" && (
                    <CardBody>
                      <StepperPedido></StepperPedido>
                    </CardBody>
                  )}
                {this.props.selectedTab === 0 && this.props.hasSelectedClient && (
                  <CardBody>
                    <TablaLotesASalida />
                  </CardBody>
                )}
                {this.props.selectedTab === 1 &&
                  this.props.selectedRadioButton === "nopedido" && (
                    <CardBody>
                      <CardPicking></CardPicking>
                      <TablaPicking></TablaPicking>
                    </CardBody>
                  )}
                {this.props.selectedTab === 2 && (
                  <CardBody>
                    <SalidaDeAlmacen />
                  </CardBody>
                )}
              </div>
            </Card>
          </GridItem>
        </GridContainer>
        <ModalTablaLote />
        <ModalBuscarCallesDeSalida />
        <ModalPedido />
        <ModalTablaLotePickingASalida />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contenedores_ubicacion_picking:
      state.salidas.contenedores_ubicacion_picking,
    ubicacionesPicking: state.salidas.ubicacionesPicking,
    contenedores: state.salidas.contenedores,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    selectedTab: state.salidas.selectedTab,
    openBuscarClienteDialog: state.pedidossalidas.openBuscarClienteDialog,
    hasSelectedClient: state.pedidossalidas.hasSelectedClient,
    selectedRadioButton: state.pedidossalidas.selectedRadioButton,
    lote_salida_picking: state.salidas.lote_salida_picking,
    datos_completos_ubicacion_picking:
      state.salidas.datos_completos_ubicacion_picking,
    referenciaPedido: state.pedidossalidas.referenciaPedido,
    buscarReferencia: state.pedidossalidas.buscarReferencia,
    nuevaReferencia: state.pedidossalidas.nuevaReferencia
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // pass
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Salidas)
);
