/* eslint-disable */
//COMPONENTES GENERALES
import React from "react";
import Database from "variables/Database.js";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import moment from "moment";

//COMPONENTES LOCALES
import InputNew from "components/Input/InputNew";
import { localization } from "variables/general.js";
import { toast } from "react-toastify";

//ESTILOS Y COLORES
import { makeStyles } from "@material-ui/core/styles";

//CONTENEDORES
import MaterialTable, { MTableBodyRow } from "material-table";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";

//BOTONES Y VARIOS
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const columns = [
  { title: "id_producto_presentacion", field: "id", editable: "never" },
  //  { title: "id_producto_unidad", field: "ProductosUnidad.id", editable: 'never' },
  {
    title: "Dato Logístico",
    field: "descripcion",
    editable: "never"
  },
  {
    title: "Artículo",
    field: "ProductosUnidad.descripcion",
    editable: "never"
  },
  {
    title: "Cod. Artículo",
    field: "ProductosUnidad.codigo",
    editable: "never"
  },
  { title: "DUN 14", field: "dun14", editable: "never" },
  { title: "EAN 13", field: "ProductosUnidad.ean13", editable: "never" },
  { title: "Cantidad de Capas", field: "cantidad_capas", editable: "never" },
  {
    title: "Cantidad Bultos x Capa",
    field: "cantidad_bultos_capa",
    editable: "never"
  },
  {
    title: "Cantidad Unidades x Bulto",
    field: "cantidad_unidades_bulto",
    editable: "never"
  },
  {
    title: "Cantidad Unidades x Display",
    field: "cantidad_unidades_display",
    editable: "never"
  },
  {
    title: "Cantidad Displays x Bulto",
    field: "cantidad_displays_bulto",
    editable: "never"
  }
];

function getSteps() {
  return ["Seleccionar un Producto", "Detalles"];
}

const orderFormCopy = {
  cantidad_contenedores: {
    elementType: "input",
    elementConfig: {
      type: "number",
      label: "Cantidad de Pallets",
      fullWidth: true,
      inputProps: {
        autoFocus: true
      }
    },
    value: 0,
    validation: {
      required: false,
      mayor0: true
    },
    valid: true,
    touched: true
  },
  cantidad_bultos: {
    elementType: "input",
    elementConfig: {
      type: "number",
      label: "Cantidad de Bultos",
      fullWidth: true,
      inputProps: {}
    },
    value: 0,
    validation: {
      required: false,
      mayor0: true
    },
    valid: true,
    touched: true
  },
  cantidad_displays: {
    elementType: "input",
    elementConfig: {
      type: "number",
      label: "Cantidad de Displays",
      fullWidth: true,
      inputProps: {}
    },
    value: 0,
    validation: {
      required: false,
      mayor0: true
    },
    valid: true,
    touched: true
  },
  cantidad_unidades: {
    elementType: "input",
    elementConfig: {
      type: "number",
      label: "Cantidad de Unidades",
      fullWidth: true,
      inputProps: {}
    },
    value: 0,
    validation: {
      required: false,
      mayor0: true
    },
    valid: true,
    touched: true
  }
  // lote: {
  //     elementType: 'input',
  //     elementConfig: {
  //         type: 'text',
  //         label: 'Lote',
  //         fullWidth: true,
  //         inputProps: {

  //         }
  //     },
  //     value: '',
  //     validation: {
  //         required: false

  //     },
  //     valid: true,
  //     touched: false
  // }
};

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [fecha, setfecha] = React.useState(null);
  const [orderForm, setOrderForm] = React.useState(
    JSON.parse(JSON.stringify(orderFormCopy))
  );
  const [formIsValid, setFormIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [productos, setProductos] = React.useState([]);
  const [rowProducto, setRowProducto] = React.useState(null);
  const formElementsArray = [];
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  React.useEffect(() => {
    if (props.infoCliente) getProductos(props.infoCliente);

    // if(props.rowEditProducto)
    // {
    //   setRowProducto(props.rowEditProducto);
    //   handleNext();
    //   let orderFormAlt = {...orderForm};

    //   orderFormAlt.cantidad.value = props.rowEditProducto.cantidad_requerida;
    //   orderFormAlt.cantidad.validation.asignada = true;
    //   setOrderForm(orderFormAlt);

    // }

    if (props.rowProductoPresentacion) {
      setRowProducto(props.rowProductoPresentacion);
      handleNext();
    }
  }, []);

  const getProductos = id => {
    setIsLoading(true);
    Database.get("/listar-datos-logisticos-cliente/" + id, this).then(
      res => {
        setIsLoading(false);

        let resultado = [...res.result];
        console.log(resultado);

        setProductos(resultado);
        /*
                $(".MuiDialog-root input").each(function (index, element) {
                    if (index == 0)
                        element.focus();
                })
                */
      },
      err => {
        setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleFinish = () => {
    let objeto = {};
    for (let key in orderForm) {
      objeto[key] = orderForm[key].value;
    }

    // if(fecha && fecha != "Invalid Date"){
    //     let fechaString = moment(fecha).format("YYYY-MM-DD");
    //     let fecha_utc = moment.utc(fechaString).format();
    //     objeto.vencimiento = fecha_utc
    // }

    console.log(objeto);

    props.onClickProducto(rowProducto, objeto);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDateChange = (date, value) => {
    setfecha(date);
  };
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <MaterialTable
            isLoading={isLoading}
            columns={columns}
            data={productos}
            title="Mis Productos"
            localization={localization}
            // onRowClick={(event, rowData) => {

            //     let orderFormAlt = JSON.parse(JSON.stringify(orderFormCopy));
            //     console.log(rowData);
            //     if (rowData.cantidad_bultos_capa && rowData.cantidad_capas) {
            //     // orderFormAlt.cantidad_bultos.value = parseInt(rowData.cantidad_bultos_capa) * parseInt(rowData.cantidad_capas);
            //         orderFormAlt.cantidad_bultos.valid = true;
            //     }

            //     setOrderForm(orderFormAlt);
            //     setfecha(null);
            //     setFormIsValid(false);
            //     setRowProducto(rowData);

            //     handleNext();
            // }}
            components={{
              Container: props => <Paper elevation={0} {...props} />
            }}
            options={{
              actionsColumnIndex: -1,
              exportButton: true,
              exportAllData: true,
              exportFileName: "Mis Productos " + moment().format("DD-MM-YYYY"),
              exportDelimiter: ";"
            }}
          />
        );

      case 1:
        console.log(rowProducto);
        return (
          <React.Fragment>
            <p>
              <span style={{ fontWeight: "300" }}>Articulo: </span>
              {rowProducto.ProductosUnidad.descripcion}
              <br />
              EAN13: {rowProducto.ProductosUnidad.ean13}
              <br />
              Cod. Artículo: {rowProducto.ProductosUnidad.codigo}
              <br />
            </p>

            <p>
              <span style={{ fontWeight: "300" }}>Dato Logístico: </span>
              {rowProducto.descripcion}
              <br />
              DUN14: {rowProducto.dun14}
              <br />
              unidades x bulto: {rowProducto.cantidad_unidades_bulto}, unidades
              x display : {rowProducto.cantidad_unidades_display}, display x
              bulto: {rowProducto.cantidad_displays_bulto}
            </p>
            {/* <p><span style={{ fontWeight:'300'}}>Unidad: </span>
                      {rowProducto.unidad}</p> */}

            {/* {


                        formElementsArray.map(formElement => (
                            <InputNew
                                objeto={formElement.config}
                                changed={(event) => {
                                    let objeto = inputChangedHandler(event, formElement.id, orderForm);
                                    setOrderForm(objeto.orderForm);
                                    setFormIsValid(objeto.formIsValid);
                                }}
                            />
                        ))
                    } */}
            {/* <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="vencimiento"
                            label="Fecha de Vencimiento"
                            format="dd/MM/yyyy"
                            value={fecha}
                            onChange={handleDateChange}
                            autoOk={true}
                            cancelLabel={"Cancelar"}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider> */}
          </React.Fragment>
        );
      default:
        return "Paso no seleccionado";
    }
  };

  for (let key in orderForm) {
    formElementsArray.push({
      id: key,
      config: orderForm[key]
    });
  }

  return (
    <div className={classes.root}>
      {/* <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper> */}

      {getStepContent(activeStep)}
      <div style={{ marginTop: "2em" }}>
        {!props.rowEditProducto && !props.rowProductoPresentacion && (
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Atras
          </Button>
        )}
        {/* {activeStep === steps.length - 1 ? (
                    <Button variant="contained" color="primary" disabled={!formIsValid} onClick={handleFinish}>
                        Agregar
                    </Button>
                ) : null} */}
      </div>
    </div>
  );
}
