import * as actionTypes from "./actionTypes";

export const addBloquesEnMapa = (b, data_pos = null) => {
  return {
    type: actionTypes.ADD_BLOQUES_EN_MAPA,
    b: b,
    data_pos: data_pos
  };
};

export const addBloquesEnTabla = b => {
  return {
    type: actionTypes.ADD_BLOQUES_EN_TABLA,
    b: b
  };
};

export const addMuellesEnMapa = muelle => {
  return {
    type: actionTypes.ADD_MUELLES_EN_MAPA,
    muelle: muelle
  };
};

export const addMuellesEnTabla = m => {
  return {
    type: actionTypes.ADD_MUELLES_EN_TABLA,
    m: m
  };
};

export const removerBloqueDeTabla = id_row => {
  return {
    type: actionTypes.REMOVER_BLOQUE_DE_TABLA,
    id_row: id_row
  };
};

export const removerBloqueDeMapa = bloque => {
  return {
    type: actionTypes.REMOVER_BLOQUE_DE_MAPA,
    bloque: bloque
  };
};

export const rotateElementHandler = () => {
  return {
    type: actionTypes.ROTATE_ELEMENT_HANDLER
  };
};

export const deselectAllBloques = event => {
  return {
    type: actionTypes.DESELECT_ALL_BLOQUES,
    event: event
  };
};

export const deselectAllSpatialObjects = event => {
  return {
    type: actionTypes.DESELECT_ALL_SPATIAL_OBJECTS,
    event: event
  };
};

export const toggleAllSpatialObjects = (event, index) => {
  return {
    type: actionTypes.TOGGLE_ALL_SPATIAL_OBJECTS,
    event: event,
    index: index
  };
};

export const hideReglaHandler = () => {
  return {
    type: actionTypes.HIDE_REGLA_HANDLER
  };
};

export const fillCalles = data => {
  return {
    type: actionTypes.FILL_CALLES,
    data: data
  };
};

export const fillCallesDeSalida = data => {
  return {
    type: actionTypes.FILL_CALLES_DE_SALIDA,
    data: data
  };
};

export const fillMuelles = data => {
  return {
    type: actionTypes.FILL_MUELLES,
    data: data
  };
};

export const setLoadedStatus = status => {
  return {
    type: actionTypes.SET_LOADED_STATUS,
    status: status
  };
};
