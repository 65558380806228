import React from "react";
import "./Controles.css";
import Card from "../Card/Card";

const opciones = props => {
  return (
    <div
      id="controlador-elementos-mapa"
      className="controlador"
      data-tipo={props.data.tipo}
      data-id_elem={props.data.id}
    >
      <Card
        titulo={props.titulo}
        click_rotar={props.click_rotar}
        click_remover={() =>
          props.remover({
            tipo: props.data.tipo,
            id_db: props.data.tipo + "_" + props.data.id,
            id: props.data.id
          })
        }
        click_guardar={() =>
          props.guardar({
            tipo: props.data.tipo,
            id_db: props.data.tipo + "_" + props.data.id,
            id: props.data.id
          })
        }
      />
    </div>
  );
};

export default opciones;
