import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { StateNewEmpleado } from "../VariablesState";

import Database from "variables/Database.js";

import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewEmpleado extends Component {
  state = JSON.parse(JSON.stringify(StateNewEmpleado));

  handleSubmitNewEmpleado = event => {
    event.preventDefault();

    console.log(this.state.fechaVencimientoLinti);
    console.log(this.state.files);

    Database.post(
      `/insert-empleados`,
      {
        nombre: this.state.newEmpleadoForm.nombre.value,
        apellido: this.state.newEmpleadoForm.apellido.value,
        numero_legajo: this.state.newEmpleadoForm.numero_legajo.value,
        dni: this.state.newEmpleadoForm.dni.value,
        cuil: this.state.newEmpleadoForm.cuil.value,
        nro_tramite_dni: this.state.newEmpleadoForm.nro_tramite_dni.value,
        id_empresa_empleadora: this.state.newEmpleadoForm2.id_empresa_empleadora
          .value,
        direccion_completa: this.state.newEmpleadoForm2.direccion_completa
          .value,
        direccion_alternativa: this.state.newEmpleadoForm2.direccion_alternativa
          .value,
        telefono_contacto: this.state.newEmpleadoForm2.telefono_contacto.value,
        telefono_personal: this.state.newEmpleadoForm2.telefono_personal.value,
        id_puesto: this.state.newEmpleadoForm4.id_puesto.value,
        id_remera: this.state.newEmpleadoForm3.id_remera.value,
        id_pantalon: this.state.newEmpleadoForm3.id_pantalon.value,
        id_zapato: this.state.newEmpleadoForm3.id_zapato.value,
        id_superior_jerarquico: this.state.newEmpleadoForm3
          .id_superior_jerarquico.value,
        id_estado_civil: this.state.newEmpleadoForm.id_estado_civil.value,
        id_categoria_licencia: this.state.newEmpleadoForm4.id_categoria_licencia
          .value,
        conyuge: this.state.newEmpleadoForm.conyuge.value,
        convenio_camioneros: this.state.newEmpleadoForm4.convenio_camioneros
          .value,
        alta_dia: this.state.newEmpleadoForm4.alta_dia.value,
        preocupacional_obser: this.state.newEmpleadoForm4.preocupacional_obser
          .value,
        files: this.state.files,
        fecha_nac: this.state.fechaNacimiento,
        vto_linti: this.state.fechaVencimientoLinti,
        vto_psicofisico: this.state.fechaVencimientoPsicofisico,
        vto_curso: this.state.fechaVencimientoCurso,
        vto_libreta_sanitaria: this.state.fechaVencimientoLibretaSanitaria,
        vto_licencia_municipal: this.state.fechaVencimientoLicencia,
        fecha_ingreso: this.state.fechaIngreso,
        preocupacional_fecha: this.state.fechaPreocupacional
      },
      this
    ).then(
      res => {
        toast.success("El empleado se ha creado con exito!");
        this.setState(
          {
            successSubmit: true,
            formIsValid: false
          },
          () => {
            this.props.getEmpleadosAdmin();
          }
        );
        this.resetNewForm();
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  inputChangedHandler = (event, inputIdentifier, newValue) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newEmpleadoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      newEmpleadoForm: updatedOrderForm,
      formIsValid: formIsValidAlt
    });
  };
  inputChangedHandler2 = (event, inputIdentifier, newValue) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newEmpleadoForm2
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      newEmpleadoForm2: updatedOrderForm,
      formIsValid: formIsValidAlt
    });
  };
  inputChangedHandler3 = (event, inputIdentifier, newValue) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newEmpleadoForm3
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      newEmpleadoForm3: updatedOrderForm,
      formIsValid: formIsValidAlt
    });
  };
  inputChangedHandler4 = (event, inputIdentifier, newValue) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.newEmpleadoForm4
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      newEmpleadoForm4: updatedOrderForm,
      formIsValid: formIsValidAlt
    });

    if (inputIdentifier == "alta_dia") {
      console.log("cambiando alta_dia: " + newValue);
      if (newValue == true) updatedOrderForm["alta_dia"].value = 1;
      else updatedOrderForm["alta_dia"].value = 0;
    }
    if (inputIdentifier == "convenio_camioneros") {
      console.log("cambiando convenio_camioneros: " + newValue);
      if (newValue == true) updatedOrderForm["convenio_camioneros"].value = 1;
      else updatedOrderForm["convenio_camioneros"].value = 0;
    }
  };

  resetNewForm = all => {
    let newEmpleadoFormAlt = { ...this.state.newEmpleadoForm };
    let successSubmit = this.state.successSubmit;
    for (let key in newEmpleadoFormAlt) {
      newEmpleadoFormAlt[key].value = "";
    }
    if (all) successSubmit = false;

    this.setState({
      successSubmit: successSubmit,
      formIsValid: false
    });
    //this.getClientesType("new", newEmpleadoFormAlt);
  };

  getEmpresaEmpleadora = () => {
    Database.get("/list-empresa-empleadora", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.nombre
          });
        });
        let formulario = { ...this.state.newEmpleadoForm2 };
        formulario.id_empresa_empleadora.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm2: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  onFilesChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("userPhoto", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = 25;

    Database.post("/insert-only-file/" + id, formData, this, false, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  getSuperiorJerarquico = () => {
    Database.get("/list-superior-jerarquico", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.apellido + ", " + entry.nombre
          });
        });
        let formulario = { ...this.state.newEmpleadoForm3 };
        formulario.id_superior_jerarquico.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getTalleRemera = () => {
    Database.get("/list-talle-remera", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.newEmpleadoForm3 };
        formulario.id_remera.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getTallePantalon = () => {
    Database.get("/list-talle-pantalon", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.newEmpleadoForm3 };
        formulario.id_pantalon.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getTalleZapato = () => {
    Database.get("/list-talle-zapato", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.newEmpleadoForm3 };
        formulario.id_zapato.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm3: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getEstadoCivil = () => {
    Database.get("/list-estado-civil", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.newEmpleadoForm };
        formulario.id_estado_civil.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getCategoriaLicencia = () => {
    Database.get("/list-categoria-licencia", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.newEmpleadoForm4 };
        formulario.id_categoria_licencia.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm4: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getPuesto = () => {
    Database.get("/list-puesto", this).then(
      res => {
        let resultado = [...res.result];
        let a = [];
        resultado.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });
        let formulario = { ...this.state.newEmpleadoForm4 };
        formulario.id_puesto.elementConfig.options = [...a];
        this.setState({
          newEmpleadoForm4: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  componentDidMount() {
    this.getEmpresaEmpleadora();
    this.getSuperiorJerarquico();
    this.getTalleRemera();
    this.getTallePantalon();
    this.getTalleZapato();
    this.getEstadoCivil();
    this.getCategoriaLicencia();
    this.getPuesto();
  }

  handleFechaNac = date => {
    this.setState({
      fechaNacimiento: date
    });
  };
  handleVencLinti = date => {
    this.setState({
      fechaVencimientoLinti: date
    });
  };
  handleVencPsico = date => {
    this.setState({
      fechaVencimientoPsicofisico: date
    });
  };
  handleVencCurso = date => {
    this.setState({
      fechaVencimientoCurso: date
    });
  };
  handleVencLibreta = date => {
    this.setState({
      fechaVencimientoLibretaSanitaria: date
    });
  };
  handleVencLicencia = date => {
    this.setState({
      fechaVencimientoLicencia: date
    });
  };
  handleFechaIngreso = date => {
    this.setState({
      fechaIngreso: date
    });
  };
  handlePreocupacional = date => {
    this.setState({
      fechaPreocupacional: date
    });
  };

  onChange = e => {
    var files = e.target.files;
    console.log(files);
    //  var filesArr = Array.prototype.slice.call(files);
    // console.log(filesArr);

    // this.setState({ files: [...this.state.files, ...filesArr] });
    this.setState({ files: files });
  };

  removeFile(f) {
    //  this.setState({ files: this.state.files.filter(x => x !== f) });
  }

  onFileUpload = () => {
    // Create an object of formData
    // const formData = new FormData();

    // // Update the formData object
    // formData.append(
    //   "myFile",
    //   this.state.selectedFile,
    //   this.state.selectedFile.name
    // );

    // Details of the uploaded file
    console.log(this.state.file);

    // Request made to the backend api
    // Send formData object
    //axios.post("api/uploadfile", formData);
    // console.log(formData);
  };

  render() {
    const formElementsArray1 = [];
    for (let key in this.state.newEmpleadoForm) {
      formElementsArray1.push({
        id: key,
        config: this.state.newEmpleadoForm[key]
      });
    }
    const formElementsArray2 = [];
    for (let key in this.state.newEmpleadoForm2) {
      formElementsArray2.push({
        id: key,
        config: this.state.newEmpleadoForm2[key]
      });
    }
    const formElementsArray3 = [];
    for (let key in this.state.newEmpleadoForm3) {
      formElementsArray3.push({
        id: key,
        config: this.state.newEmpleadoForm3[key]
      });
    }
    const formElementsArray4 = [];
    for (let key in this.state.newEmpleadoForm4) {
      formElementsArray4.push({
        id: key,
        config: this.state.newEmpleadoForm4[key]
      });
    }
    return (
      <form
        onSubmit={event => {
          this.handleSubmitNewEmpleado(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Nuevo Empleado
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario de un empleado nuevo
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {/* <div style={styles}>
                <label className="custom-file-upload">
                  <input type="file" multiple onChange={this.onChange} />
                  <i className="fa fa-cloud-upload" /> Adjuntar 
                </label>
                {this.state.files.map(x =>
                  <div className="file-preview" onClick={this.removeFile.bind(this, x)}>{x.name}</div>
                )}

              </div> */}

              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Datos Personales
                </h4>
              </CardHeader>
              <CardBody>
                {formElementsArray1.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={(event, newValue) =>
                      this.inputChangedHandler(event, formElement.id, newValue)
                    }
                  />
                ))}

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechanacimiento"
                      label="Fecha de nacimiento"
                      format="dd/MM/yyyy"
                      value={this.state.fechaNacimiento}
                      onChange={this.handleFechaNac}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Datos de la Empresa Empleadora y de contacto
                </h4>
              </CardHeader>
              <CardBody>
                {/* <Box fontSize="default"
                    fontWeight="fontWeightMedium"
                    m={3}
                    alignItems="center"> Datos de la Empresa Empleadora y de contacto:
                </Box> */}

                {formElementsArray2.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={event =>
                      this.inputChangedHandler2(event, formElement.id)
                    }
                  />
                ))}
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Superior Jerárquico y talles
                </h4>
              </CardHeader>
              <CardBody>
                {formElementsArray3.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={event =>
                      this.inputChangedHandler3(event, formElement.id)
                    }
                  />
                ))}
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  {" "}
                  Licencia Nacional de Transporte Interurbano y Libreta
                  Sanitaria
                </h4>
              </CardHeader>

              <CardBody>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimiento"
                      label="Vencimiento LINTI"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoLinti}
                      onChange={this.handleVencLinti}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientopsicofisico"
                      label="Vencimiento Psicofisico"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoPsicofisico}
                      onChange={this.handleVencPsico}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientocurso"
                      label="Vencimiento Curso"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoCurso}
                      onChange={this.handleVencCurso}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>

                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientolibretasanitaria"
                      label="Venc. Libreta Sanitaria"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoLibretaSanitaria}
                      onChange={this.handleVencLibreta}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </CardBody>
              <br></br>
              <br></br>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Puesto, Licencia Municipal y Preocupacional
                </h4>
              </CardHeader>

              <CardBody>
                {formElementsArray4.map(formElement => (
                  <Input
                    key={"EditEmpleado-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={(event, newValue) =>
                      this.inputChangedHandler4(event, formElement.id, newValue)
                    }
                  />
                ))}

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="vencimientolicencia"
                      label="Venc. Licencia Municipal"
                      format="dd/MM/yyyy"
                      value={this.state.fechaVencimientoLicencia}
                      onChange={this.handleVencLicencia}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechapreocupacional"
                      label="Fecha del Preocupacional"
                      format="dd/MM/yyyy"
                      value={this.state.fechaPreocupacional}
                      onChange={this.handlePreocupacional}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaingreso"
                      label="Fecha de Ingreso"
                      format="dd/MM/yyyy"
                      value={this.state.fechaIngreso}
                      onChange={this.handleFechaIngreso}
                      autoOk={true}
                      cancelLabel={"Cancelar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </CardBody>
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/empleados")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(NewEmpleado));
