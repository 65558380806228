/* eslint-disable */
import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Route, Switch, Link } from "react-router-dom";
// core components
import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import {
  StateListUbicaciones,
  ColumnsListado,
  ColumnsListadoLibres
} from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

import ModalAsignar from "./ModalAsignar";
import ModalMover from "./ModalMover";
import ModalDesasignar from "./ModalDesasignar";
import ModalReasignar from "./ModalReasignar";

const styles = {
  rowTable: {
    backgroundColor: "#DEFFDF"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

class PickingMovimientos extends Component {
  state = { ...StateListUbicaciones };

  componentDidMount() {
    this.getUbicacionesLibresAdmin();
    this.getUbicacionesAsignadas();
  }

  handleToggle = value => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    let deleteEnabled = false;
    let editEnabled = false;
    const botonesAcc = { ...this.state.botonesAcciones };
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) {
      deleteEnabled = true;
      if (newChecked.length == 1) editEnabled = true;
    }
    botonesAcc.editar.enabled = editEnabled;
    botonesAcc.delete.enabled = deleteEnabled;
    this.setState({
      botonesAcciones: botonesAcc,
      checked: newChecked
    });
  };

  menuHandleClose = value => {
    this.setState({
      menuContext: null
    });
  };

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    });
  };
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE UBICACIONES
  ////////////////////////
  ////////////////////////
  getUbicacionesLibresAdmin = () => {
    this.setState({
      isLoading: true
    });

    //alert(process.env.REACT_APP_ID_ALMACEN);
    Database.get(
      "/list-pickingubicacioneslibres/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        console.log(res.result);
        let resultado = [...res.result];
        this.setState({
          isLoading: false,
          ubicacionesLibres: resultado,
          checked: [],
          menuContext: null
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getUbicacionesAsignadas = () => {
    this.setState({
      isLoading: true
    });

    Database.get(
      "/list-pickingubicacionesasignadas/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        console.log(res.result);
        let resultado = [...res.result];
        this.setState({
          isLoading: false,
          ubicacionesAsignadas: resultado,
          checked: [],
          menuContext: null
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ tabSelect: newValue });
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  handleRowClick = (event, rowData) => {
    // alert("handleRowClick");
    console.log(rowData);

    this.setState({
      openAsignarDialog: true,
      selectedRowData: rowData
    });
  };

  handleRowClickAsignadas = (event, rowData) => {
    // alert("handleRowClick");
    console.log(rowData);

    this.setState({
      openMoverDialog: true,
      selectedRowDataAsignada: rowData
    });
  };

  handleCloseAsignarDialog = () => {
    //alert("handleCloseNuevaUbicacionDialog");
    this.setState({ openAsignarDialog: false });
  };

  handleCloseMoverDialog = () => {
    //alert("handleCloseNuevaUbicacionDialog");
    this.setState({ openMoverDialog: false });
  };

  handleAccept(productoPresentacion, umbral) {
    //alert("accept");
    console.log(productoPresentacion);
    console.log(umbral);

    console.log(this.state.selectedRowData);

    this.setState({ openAsignarDialog: false });

    if (productoPresentacion != null) {
      Database.post(`/picking-ubicacion-asignar`, {
        id_ubicacion: this.state.selectedRowData.id,
        id_producto_presentacion: productoPresentacion.id,
        umbral: umbral
      }).then(
        res => {
          toast.success("La asignación se ha realizado con exito!");
          this.getUbicacionesAsignadas();
          this.getUbicacionesLibresAdmin();
        },
        err => {
          toast.error(err.message);
        }
      );
    }
  }

  handleDesasignarUbicacion = rowData => {
    Database.post("/picking-ubicacion-desasignar", {
      id_ubicacion: rowData.id
    }).then(
      res => {
        toast.success("La desasignacion se ha realizado con exito!");
        this.getUbicacionesAsignadas();
        this.getUbicacionesLibresAdmin();
        this.handleModalClose();
      },
      err => {
        toast.error(err.message);
      }
    );
    // alert("handleDesasignarUbicacion");
  };

  handleCambiarUmbral(rowData) {
    console.log(rowData);

    Database.post(`/picking-ubicacion-cambiar-umbral`, {
      ubicacion: rowData
    }).then(
      res => {
        toast.success("El cambio de umbral se ha realizado con exito!");
        this.getUbicacionesAsignadas();
        this.getUbicacionesLibresAdmin();
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  handleAcceptReasignarUbicacion(rowData, ubicaciones_libres) {
    //alert("accept");
    console.log(rowData);
    console.log(ubicaciones_libres);

    this.setState({ openReasignarDialog: false });

    if (ubicaciones_libres != null) {
      Database.post(`/picking-ubicacion-reasignar`, {
        ubicacion: rowData,
        id_ubicacion_nueva: ubicaciones_libres.id
      }).then(
        res => {
          toast.success("La reasignación se ha realizado con exito!");
          this.getUbicacionesAsignadas();
          this.getUbicacionesLibresAdmin();
        },
        err => {
          toast.error(err.message);
        }
      );
    }
  }

  handleDesasignar = rowData => {
    console.log(rowData);
    this.setState({
      openDesasignarDialog: true,
      desasignarRowData: rowData
    });
  };

  handleReasignar = rowData => {
    console.log(rowData);
    this.setState({
      openReasignarDialog: true,
      reasignarRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openDesasignarDialog: false,
      desasignarRowData: null,
      openReasignarDialog: false,
      reasignarRowData: null
    });
  }

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }
    return [
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>
                Movimientos - Ubicaciones de Picking
              </h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Ubicaciones de Picking
              </p>
            </CardHeader>
            <CardBody>
              {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevoubicacion')} color="primary"><AddIcon /> Nueva Ubicacion</Button> */}
              <Paper>
                <Tabs
                  value={this.state.tabSelect}
                  onChange={this.handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Ubicaciones Libres" />
                  <Tab label="Ubicaciones Asignadas" />
                </Tabs>
              </Paper>

              <TabPanel value={this.state.tabSelect} index={0}>
                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListadoLibres}
                  data={this.state.ubicacionesLibres}
                  title=""
                  onRowClick={this.handleRowClick}
                  localization={localization}
                  components={{
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  // detailPanel={
                  //   rowData => {
                  //     return (
                  //       <div>
                  //         {rowData.id_ocupacion == 3 &&
                  //           <div>

                  //             <h5>Datos del Contenedor</h5>
                  //             <p>
                  //               id:<strong>{rowData.contenedor_id}</strong><br />
                  //           Producto Unidad:<strong>{rowData.producto_unidad_nombre}</strong><br />
                  //    Producto Presentacion:<strong>{rowData.producto_presentacion_descripcion}</strong><br />
                  //    cantidad de unidades:<strong>{rowData.contenedor_cantidad_unidades}</strong><br />
                  //    cantidad de bultos:<strong>{rowData.contenedor_cantidad_bultos}</strong><br />
                  //    cliente:<strong>{rowData.cliente_razon_social}</strong><br />

                  //             </p>
                  //           </div>
                  //         }
                  //       </div>
                  //     )
                  //   }}

                  components={{
                    Row: props => {
                      let class_alt = null;
                      if (props.data.id_ocupacion == 3)
                        class_alt = this.props.classes.rowTable;
                      return <MTableBodyRow className={class_alt} {...props} />;
                    },
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  options={{
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName:
                      "PickingUbicaciones " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: lightGreen[700],
                      color: "#FFF"
                    }
                  }}
                />
              </TabPanel>

              <TabPanel value={this.state.tabSelect} index={1}>
                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListado}
                  data={this.state.ubicacionesAsignadas}
                  title=""
                  onRowClick={this.handleRowClickAsignadas}
                  localization={localization}
                  components={{
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  components={{
                    Row: props => {
                      let class_alt = null;
                      if (props.data.id_ocupacion == 3)
                        class_alt = this.props.classes.rowTable;
                      return <MTableBodyRow className={class_alt} {...props} />;
                    },
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          var dataUpdate = [...this.state.ubicacionesAsignadas];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;
                          // setData([...dataUpdate]);
                          this.setState({ ubicacionesAsignadas: dataUpdate });
                          {
                            this.handleCambiarUmbral(newData);
                          }
                          resolve();
                        }, 1000);
                      })
                  }}
                  actions={[
                    {
                      icon: "logout",
                      tooltip: "Reasignar",
                      onClick: (event, rowData) => this.handleReasignar(rowData)
                    },
                    rowData =>
                      rowData.cantidad_unidades_picking === 0
                        ? {
                            icon: "close",
                            tooltip: "Desasignar",
                            onClick: (event, rowData) =>
                              this.handleDesasignar(rowData)
                          }
                        : null
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName:
                      "PickingUbicaciones " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: lightGreen[700],
                      color: "#FFF"
                    }
                  }}
                />
              </TabPanel>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>,

      <ModalDesasignar
        openDesasignarDialog={this.state.openDesasignarDialog}
        desasignarRowData={this.state.desasignarRowData}
        handleClose={() => this.handleModalClose()}
        handleDesasignar={rowData => this.handleDesasignarUbicacion(rowData)}
      />,

      <ModalReasignar
        openReasignarDialog={this.state.openReasignarDialog}
        reasignarRowData={this.state.reasignarRowData}
        handleClose={() => this.handleModalClose()}
        handleAccept={(rowData, ubicaciones_libres) =>
          this.handleAcceptReasignarUbicacion(rowData, ubicaciones_libres)
        }
      />,

      <ModalAsignar
        openAsignarDialog={this.state.openAsignarDialog}
        handleClose={() => this.handleCloseAsignarDialog()}
        handleAccept={(productoPresentacion, umbral) =>
          this.handleAccept(productoPresentacion, umbral)
        }
      />,
      <div>
        {this.state.openMoverDialog && (
          <ModalMover
            openAsignarDialog={this.state.openMoverDialog}
            ubicacionPickingSeleccionada={this.state.selectedRowDataAsignada}
            handleClose={() => this.handleCloseMoverDialog()}
            // handleAccept={(productoPresentacion) => this.handleAccept(productoPresentacion)}
          />
        )}
      </div>
    ];
  }
}

export default withStyles(styles)(PickingMovimientos);
