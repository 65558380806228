import React, { useEffect } from "react";
// style
import lightGreen from "@material-ui/core/colors/lightGreen";
// material table
import MaterialTable from "material-table";
import { localization } from "variables/general.js";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

import Database from "variables/Database.js";
import { toast } from "react-toastify";
import moment from "moment";

const columnasTablaMovimientos = [
  { title: "Pallet", field: "id_contenedor" },
  { title: "Fecha de entrada", field: "fecha_de_entrada" },
  { title: "Fecha de salida", field: "fecha_de_salida" },
  {
    title: "Presentacion",
    field: "AudEntradaDetalles.ProductosPresentacion.descripcion"
  },
  { title: "Codigo", field: "AudEntradaDetalles.ProductosUnidad.codigo" },
  { title: "Vencimiento", field: "vencimiento" },
  { title: "Lote", field: "AudEntradaDetalles.lote" }
];

let localization_options = { header: { actions: "" } };
localization_options = {
  ...JSON.parse(JSON.stringify(localization)),
  ...localization_options
};

const getExtraInfoContenedor = (rowdata, props) => {
  if (rowdata.id_contenedor) {
    let data_contenedor = {
      id_contenedor: rowdata.id_contenedor,
      id_auditoria_entrada: rowdata.id_auditoria_entrada || null,
      id_auditoria_entrada_detalle:
        rowdata.id_auditoria_entrada_detalle || null,
      id_auditoria_salida: rowdata.id_auditoria_salida || null,
      id_user_entrada: rowdata.id_user_entrada || null,
      id_user_salida: rowdata.id_user_salida || null
    };

    Database.post(`/get-info-extra-contenedor`, { data_contenedor }, this).then(
      res => {
        props.setInfoContenedor(res);
        props.setOpen();
      },
      err => {
        toast.error(err.message);
      }
    );
  }
};

function TablaMovimientos(props) {
  useEffect(() => {
    // Database.get('/list-contenedores-prod-unidad-presentacion-join/')
    Database.get("/get-tablas-auditoria/").then(
      result => {
        let dataMovimientos = [...result.result];

        console.log(dataMovimientos);

        dataMovimientos = dataMovimientos.map(elem => {
          return {
            ...elem,
            fecha_de_salida:
              moment(elem.fecha_de_salida).isValid() &&
              elem.fecha_de_salida != null
                ? moment(elem.fecha_de_salida).format("DD/MM/YYYY HH:mm:ss")
                : "",
            fecha_de_entrada: moment(elem.fecha_de_entrada).isValid()
              ? moment(elem.fecha_de_entrada).format("DD/MM/YYYY HH:mm:ss")
              : "",
            vencimiento: moment.utc(elem.vencimiento).isValid()
              ? moment.utc(elem.vencimiento).format("DD/MM/YYYY")
              : ""
            // vencimiento: moment(elem.vencimiento).format("DD/MM/YYYY HH:mm:ss")
          };
        });

        console.log(dataMovimientos);

        props.setMovimientos(dataMovimientos);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, [props.reload]);

  return (
    <MaterialTable
      id="tabla-movimientos"
      isLoading={props.isLoading}
      columns={columnasTablaMovimientos}
      data={props.dataMovimientos}
      style={{ width: "100%" }}
      title=""
      localization={localization_options}
      actions={[
        rowData => ({
          icon: "search",
          tooltip: "Ver más información",
          onClick: (event, rowData) => getExtraInfoContenedor(rowData, props)
        })
      ]}
      options={{
        filtering: true,
        exportDelimiter: ";",
        headerStyle: {
          backgroundColor: lightGreen[700],
          color: "#FFF"
        },
        selection: false,
        pageSizeOptions: [5, 10, 20, 50, 100, 200, 400, 800],
        pageSize: 50,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1
        // pageSize: 10,
      }}
    ></MaterialTable>
  );
}

const mapStateToProps = state => {
  return {
    dataMovimientos: state.auditoria.dataMovimientos,
    isLoading: state.auditoria.isLoading,
    reload: state.auditoria.reload,
    infoContenedor: state.auditoria.infoContenedor
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMovimientos: dataMovimientos =>
      dispatch(actionCreators.setMovimientos(dataMovimientos)),
    setInfoContenedor: infoContenedor =>
      dispatch(actionCreators.setInfoContenedor(infoContenedor)),
    setOpen: status => dispatch(actionCreators.setOpen(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaMovimientos);
