import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RoomIcon from "@material-ui/icons/Room";
import "./Tabla.css";

const headCells = {
  calle: [
    {
      id: "nombre_calle",
      numeric: false,
      disablePadding: true,
      label: "Nombre de la calle"
    }
  ],
  muelle: [
    {
      id: "nombre_muelle",
      numeric: false,
      disablePadding: true,
      label: "Nombre del muelle"
    }
  ],
  calles_de_salida: [
    {
      id: "nombre_calle",
      numeric: false,
      disablePadding: true,
      label: "Nombre de la calle"
    }
  ],
  inicial: [
    { id: "nombre_calle", numeric: false, disablePadding: true, label: "" }
  ]
};

function EnhancedTableHead(props) {
  // const {
  //   // classes,
  //   // onSelectAllClick,
  //   // order,
  //   // orderBy,
  //   // numSelected,
  //   // rowCount,
  //   onRequestSort
  // } = props;
  // const createSortHandler = property => event => {
  //   onRequestSort(event, property);
  // };

  let fuenteData = props.fuenteData;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells[fuenteData].map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  let { numSelected, row_id, nombre_row } = props;

  let fuenteData = props.fuenteData;

  let tituloTabla =
    fuenteData === "muelle"
      ? "Muelles"
      : fuenteData === "calles_de_salida"
      ? "Calles de Salida"
      : fuenteData === "calle"
      ? "Calles"
      : fuenteData === "inicial"
      ? "Seleccionar tipo de elemento a agregar"
      : "Seleccionar tipo de elemento a agregar";

  const runOnClick = () => {
    props.agrega();
    return { id: row_id, fuenteData: fuenteData };
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {nombre_row}{" "}
          {fuenteData === "muelle" ? "seleccionado" : "seleccionada"}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tituloTabla}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="UbicarPin">
          <IconButton
            aria-label="ubicar"
            onClick={() => props.click(runOnClick())}
          >
            <Typography
              className="ubicar-calle-label"
              variant="body1"
              id="tableTitle"
              component="div"
            >
              UBICAR EN EL MAPA
            </Typography>
            <RoomIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [nombre_row, setNombre] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const rows = props.rows;
  const fuenteData = props.fuenteData;

  const handleClick = (event, nombre_row, row_id) => {
    const selectedIndex = selected.indexOf(row_id);
    if (selectedIndex == 0) {
      setNombre([]);
      setSelected([]);
    } else {
      setNombre([nombre_row]);
      setSelected([row_id]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const reseteaNombres = () => {
    setNombre([]);
    setSelected([]);
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          row_id={selected[0]}
          nombre_row={nombre_row}
          click={props.agregarCalle}
          agrega={reseteaNombres}
          fuenteData={fuenteData}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              fuenteData={fuenteData}
              classes={classes}
              numSelected={selected.length}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event =>
                      handleClick(event, row.nombre_row, row.id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className="fila-tabla"
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={row.id}
                      scope="row"
                      padding="none"
                    >
                      {row.nombre_row}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage=""
          // rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
}
