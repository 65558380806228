import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

// import { AddBox, ArrowUpward } from "@material-ui/icons";
// import ReactDOM from "react-dom";
//import MaterialTable from "material-table";
import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import { toast } from "react-toastify";

import { localization } from "variables/general.js";
import lightGreen from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/styles";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const ColumnsListado = [
  { title: "Ubicación", field: "ubicacion_desc" },
  { title: "Dato Logístico", field: "ProductosPresentacion.descripcion" },
  { title: "Umbral", field: "umbral" },
  { title: "Cantidad Unidades", field: "contenedores" }
];

class AlertaUmbral extends Component {
  state = {
    listado: [],
    isLoading: false
  };

  getItems = () => {
    this.setState({
      isLoading: true
    });
    Database.get("/list-alertas-umbral", this, null, true).then(
      res => {
        let resultado = res.result.map(elem => {
          return {
            ...elem,
            ubicacion_desc:
              (elem["Calle.numero"] ?? "") +
              "-" +
              (elem["Columna.numero"] ?? "") +
              "-" +
              elem.nivel,
            contenedores: elem["Contenedors.cantidad_unidades"]
              ? elem["Contenedors.cantidad_unidades"]
              : 0
          };
        });

        this.setState({
          isLoading: false,
          listado: resultado
        });
        console.log(res.result);
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    this.getItems();
  }

  render() {
    let style = { maxWidth: "100%" };
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none", maxWidth: "100%" };
    }
    return [
      <div key={"pedidos-list-pedidos"} style={style}>
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              ALERTAS DE Umbral
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Listado de Alertas de Umbral
            </p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              isLoading={this.state.isLoading}
              columns={ColumnsListado}
              data={this.state.listado}
              title=""
              localization={localization}
              // actions={[{
              //   icon: 'edit',
              //   tooltip: 'Editar Pedido',
              //   onClick: (event, rowData) => this.props.history.push('/admin/costos/editarcosto/' + rowData.id)
              // }]}
              components={{
                Cell: props => {
                  let styles = null;
                  if (props.columnDef.field == "cantidad") {
                    //if (props.rowData.cantidad - props.rowData.minimo < 0) {
                    if (
                      props.rowData.cantidad +
                        props.rowData.pedido -
                        props.rowData.requerido <
                      props.rowData.minimo
                    ) {
                      styles = { backgroundColor: red[700], color: "white" };
                    } else {
                      styles = { backgroundColor: yellow[500], color: "white" };
                    }
                  }
                  return <MTableCell style={styles} {...props} />;
                },
                Row: props => {
                  return (
                    <MTableBodyRow
                      className={this.props.classes.rowTable}
                      {...props}
                    />
                  );
                },
                Container: props => <Paper elevation={0} {...props} />
              }}
              options={{
                exportButton: true,
                exportAllData: true,
                exportFileName: "AlertaUmbral " + moment().format("DD-MM-YYYY"),
                exportDelimiter: ";",
                headerStyle: {
                  backgroundColor: lightGreen[700],
                  color: "#FFF"
                }
              }}
              // detailPanel={(rowData) => {
              //   return <span></span>
              // }}
            />
          </CardBody>
        </Card>
      </div>
    ];
  }
}

export default withStyles(styles)(AlertaUmbral);
