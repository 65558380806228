import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Route, Switch } from "react-router-dom";
// core components
import MaterialTable from "material-table";
// import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";

//import NewOrdenTrabajo from "./components/NewOrdenTrabajo";
import OrdenPickingProductos from "./components/OrdenPickingProductos";
import ModalDelete from "./components/ModalDelete";

import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import { StateListOrdenesPicking, ColumnsListado } from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class OrdenesPicking extends Component {
  state = { ...StateListOrdenesPicking };

  componentDidMount() {
    this.getOrdenesPickingAdmin();
  }

  menuHandleClose = value => {
    this.setState({
      menuContext: null
    });
  };

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    });
  };
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE ORDENESPICKING
  ////////////////////////
  ////////////////////////
  getOrdenesPickingAdmin = () => {
    this.setState({
      isLoading: true
    });

    Database.get("/list-ordenespicking", this, null, true).then(
      res => {
        let resultado = [...res.result[0]];

        let permiso = res.permiso;

        console.log(resultado);

        resultado = resultado.map(elem => {
          return {
            ...elem,
            // vencimiento_mostrar: moment(elem.vencimiento).isValid() ? moment(elem.vencimiento).format("DD/MM/YYYY hh:mm:ss") : "",
            fecha_mostrar: moment(elem.fecha).isValid()
              ? moment(elem.fecha).format("DD/MM/YYYY hh:mm")
              : ""
          };
        });

        this.setState({
          isLoading: false,
          ordenespicking: resultado,
          tienepermiso: permiso,
          checked: [],
          menuContext: null,
          botonesAcciones: {
            nuevo: {
              enabled: true,
              texto: "Nuevo"
            },
            editar: {
              enabled: false,
              texto: "Editar"
            },
            delete: {
              enabled: false,
              texto: "Eliminar"
            }
          }
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  editSingleUser = value => {
    this.props.history.push(
      this.props.match.url + "/editarordentrabajo/" + value
    );
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  handleHabilitarEdicion = rowData => {
    Database.post("/editar-ordenpicking", { id: rowData.id }, this).then(
      res => {
        this.getOrdenesPickingAdmin();

        toast.success("La orden de picking ha sido habilitada para editar!");
      }
    );
  };

  handleDelete = rowData => {
    Database.post("/delete-ordenpicking", { id: rowData.id }, this).then(
      res => {
        let ordenespicking = [...this.state.ordenespicking];
        ordenespicking = ordenespicking.filter(elem => {
          if (elem.id === rowData.id) return false;

          return true;
        });

        this.getOrdenesPickingAdmin();

        this.setState(
          {
            ordenespicking: ordenespicking,
            openDeleteDialog: false
          },
          () => {
            toast.success("La orden de picking se ha eliminado con exito!");
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    });
  }

  //cambia el estado de bloqueo de la edicion
  handleFinalizarOrden = rowData => {
    let objeto = {};

    objeto["id_orden_picking"] = rowData.id;

    Database.post("/finalizar-ordenpicking", { ...objeto }, this).then(
      res => {
        this.getOrdenesPickingAdmin();

        toast.success(
          "La orden de picking ha sido finalizada y deshabilitada para editar!"
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  render() {
    let style = {};
    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: "none" };
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>
                Ordenes de Picking
              </h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Ordenes de Picking
              </p>
            </CardHeader>
            <CardBody>
              {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevoordentrabajo')} color="primary"><AddIcon /> Nueva Orden</Button> */}
              {this.state.tienepermiso === 1 ? (
                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListado}
                  data={this.state.ordenespicking}
                  title=""
                  localization={localization}
                  actions={[
                    {
                      icon: "description",
                      tooltip: "Ver Detalle de Orden",
                      onClick: (event, rowData) =>
                        this.props.history.push(
                          this.props.match.url +
                            "/ordenpicking_productos/" +
                            rowData.id
                        )
                    },
                    {
                      icon: "delete",
                      tooltip: "Borrar Orden",
                      onClick: (event, rowData) =>
                        this.handleDeleteButton(rowData)
                    },
                    rowData => ({
                      icon:
                        rowData.edicion_bloqueada == 1 ? "lock" : "lock_open",
                      tooltip:
                        rowData.edicion_bloqueada == 1
                          ? "Habilitar Edición de la Orden"
                          : "Bloquear Edicion de la Orden",
                      onClick:
                        rowData.edicion_bloqueada == 1
                          ? (event, rowData) =>
                              this.handleHabilitarEdicion(rowData)
                          : (event, rowData) =>
                              this.handleFinalizarOrden(rowData)
                    })
                  ]}
                  components={{
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName:
                      "OrdenesPicking " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: lightGreen[700],
                      color: "#FFF"
                    }
                  }}
                />
              ) : (
                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListado}
                  data={this.state.ordenespicking}
                  title=""
                  localization={localization}
                  actions={[
                    {
                      icon: "description",
                      tooltip: "Ver Detalle de Orden",
                      onClick: (event, rowData) =>
                        this.props.history.push(
                          this.props.match.url +
                            "/ordenpicking_productos/" +
                            rowData.id
                        )
                    }
                    //hasta aca termina tu permiso
                  ]}
                  components={{
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  options={{
                    filtering: true,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName:
                      "OrdenesPicking " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: lightGreen[700],
                      color: "#FFF"
                    }
                  }}
                />
              )}
            </CardBody>
          </Card>

          <Switch>
            {/* <Route path={this.props.match.url + "/nuevoordentrabajo"} render={() =>

              <NewOrdenTrabajo

                getOrdenesPickingAdmin={() => this.getOrdenesPickingAdmin()}
          //      handleListNewUser={(rowData) => this.handleListNewUser(rowData)}

          history={this.props.history}
   
          
                />}
              /> */}

            <Route
              path={this.props.match.url + "/ordenpicking_productos/:id"}
              render={() => (
                <OrdenPickingProductos
                  orderForm={this.state.EditOrdenPickingForm}
                  editFormIsValid={this.state.editFormIsValid}
                  successSubmitEdit={this.state.successSubmitEdit}
                  history={this.props.history}
                  getOrdenesPickingAdmin={() => this.getOrdenesPickingAdmin()}
                />
              )}
            />
          </Switch>
        </GridItem>
        <ModalDelete
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={rowData => this.handleDelete(rowData)}
        />
      </GridContainer>
    );
  }
}

export default withStyles(styles)(OrdenesPicking);
