import React, { Component } from "react";
import Database from "variables/Database.js";
import { withRouter } from "react-router-dom";

// import { AddBox, ArrowUpward } from "@material-ui/icons";
// import ReactDOM from "react-dom";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import moment from "moment";
import { localization } from "variables/general.js";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { withStyles } from "@material-ui/styles";

const columns = [
  { title: "Dato Logístico", field: "ProductosPresentacion.descripcion" },
  { title: "Articulo", field: "ProductosUnidad.descripcion" },
  { title: "Cantidad de Unidades", field: "cantidad_unidades" },
  { title: "Pallet", field: "id_contenedor" },
  { title: "Lote", field: "lote" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento).format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  },
  { title: "Referencia", field: "referencia" },
  { title: "Fecha de Ingreso", field: "fechadeingreso_mostrar" }
];

/*
render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}
 <Moment format="YYYY/MM/DD">
                1976-04-19T12:59-0500
            </Moment>
 */

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class DetallePrint extends Component {
  state = {
    item: null,
    listado: [],
    actions: [],
    isLoading: false
  };

  getItems = () => {
    this.setState({
      isLoading: true
    });
    Database.get(
      "/list-entrada-detalle/" + this.props.match.params.idEntrada,
      this
    ).then(
      res => {
        console.log(res.result);
        let resultado = res.result;
        if (resultado.AudEntradaDetalles)
          resultado.AudEntradaDetalles = resultado.AudEntradaDetalles.map(
            elem => {
              return {
                ...elem,
                vencimiento_mostrar:
                  elem.vencimiento &&
                  moment.parseZone(elem.vencimiento).format("DD/MM/YYYY"),
                fechadeingreso_mostrar: moment(resultado.fecha).format(
                  "DD/MM/YYYY HH:mm"
                ),
                referencia: resultado.referencia
              };
            }
          );

        this.setState({
          item: resultado,
          isLoading: false
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    this.getItems();
    /*
        const script = document.createElement("script");

        script.src = "http://localhost:3600/js/zip.js";
        script.async = true;
        script.onload = function () {

            const script2 = document.createElement("script");

            script2.src = "http://localhost:3600/js/zip-ext.js";
            script2.async = true;
            script2.onload = function () {
                const script3 = document.createElement("script");

                script3.src = "http://localhost:3600/js/deflate.js";
                script3.async = true;
                script3.onload = function () {
                    const script4 = document.createElement("script");

                    script4.src = "http://localhost:3600/js/JSPrintManager.js";
                    script4.sync = true;
                    script4.onload = function () {
                        const script5 = document.createElement("script");

                        script5.src = "http://localhost:3600/js/script1.js";
                        script5.async = true;

                        document.body.appendChild(script5);
                    }
                    document.body.appendChild(script4);
                }
                document.body.appendChild(script3);

            }
            document.body.appendChild(script2);

        }

        document.body.appendChild(script);




        */
  }

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }
    return [
      <Card>
        <CardHeader color="primary">
          <h4 className={this.props.classes.cardTitleWhite}>Detalle Entrada</h4>
          <p className={this.props.classes.cardCategoryWhite}>
            Detalle de Entrada
          </p>
        </CardHeader>
        <CardBody>
          {this.state.item && (
            <p>
              Referencia: <strong>{this.state.item.referencia}</strong> <br />
              Muelle:{" "}
              <strong>
                {this.state.item.muelle && this.state.item.muelle.descripcion}
              </strong>{" "}
              <br />
              Usuario:{" "}
              <strong>
                {this.state.item.user && this.state.item.user.nombre}
              </strong>{" "}
              <br />
              Fecha Entrada:{" "}
              <strong>
                {moment(this.state.item.fecha).format("DD/MM/YYYY")}
              </strong>{" "}
              <br />
              Comentario: <strong>{this.state.item.comentario}</strong>
            </p>
          )}

          <MaterialTable
            isLoading={this.state.isLoading}
            columns={columns}
            data={(this.state.item && this.state.item.AudEntradaDetalles) || []}
            title=""
            actions={this.state.actions}
            localization={localization}
            options={{
              selection: true,
              exportButton: true,
              exportAllData: true,
              exportFileName:
                "Entrada " +
                moment(this.state.item?.fecha).format("DD-MM-YYYY"),
              exportDelimiter: ";",
              headerStyle: {
                backgroundColor: lightGreen[700],
                color: "#FFF"
              }
            }}
            actions={[
              {
                icon: "printer",

                tooltip: "Imprimir",
                onClick: (event, rowData) => this.setState({ openDialog: true })
              }
            ]}
          />

          <Button
            style={{ marginTop: "25px" }}
            color="info"
            onClick={() => this.props.history.goBack()}
          >
            <ArrowBack />
            Volver
          </Button>
        </CardBody>
      </Card>,
      <Dialog
        open={this.state.openDialog}
        onClose={this.state.closeDialog}
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogTitle>
          Selecciones Impresión
          <IconButton
            aria-label="close"
            className={this.props.classes.closeButton}
            onClick={() => {
              this.setState({ openDialog: false });
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <h1>Print Intermec IPL commands from Javascript</h1>
            <hr />
            <label className="checkbox">
              <input type="checkbox" id="useDefaultPrinter" />{" "}
              <strong>Print to Default printer</strong>
            </label>
            <p>or...</p>
            <div id="installedPrinters">
              <label>Select an installed Printer:</label>
              <select
                name="installedPrinterName"
                id="installedPrinterName"
              ></select>
            </div>
            <br />
            <br />
            <button
              type="button"
              onClick={() => {
                window.print2();
              }}
            >
              Print Now...
            </button>
          </div>
        </DialogContent>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(DetallePrint));
