import React, { Component } from "react";
import Database from "variables/Database.js";
import { Route, Switch } from "react-router-dom";
import moment from "moment";

// import { AddBox, ArrowUpward } from "@material-ui/icons";
// import ReactDOM from "react-dom";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import NewTipoSector from "./NewTipoSector";
import EditTipoSector from "./components/EditTipoSector";
import ModalDeleteTipoSector from "./ModalDeleteTipoSector";

import { localization } from "variables/general.js";
import lightGreen from "@material-ui/core/colors/lightGreen";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";

import { withStyles } from "@material-ui/styles";

import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

// const ColumnsListado = [
//   { title: "Codigo", field: "codigo" },
//   { title: "Número", field: "numero" },
//   { title: "Descripcion", field: "descripcion" },
//   { title: "Unidad", field: "unidad" },
//   { title: "Stock Minimo", field: "minimo" }
// ];

const ColumnsListadoCategorias = [
  { title: "Descripcion", field: "descripcion" }
];

class TiposSectores extends Component {
  state = {
    isLoading: false,
    openDeleteDialog: false
  };

  /*
Database.get('/list-sectores/' + process.env.REACT_APP_ID_ALMACEN, this)
.then(res => {
  let resultado = [...res.result];
  console.log(resultado);
  this.setState({
    isLoading:false,
    sectores: resultado[0],
    checked: [],
    menuContext: null,
    botonesAcciones: {
      nuevo: {
        enabled: true,
        texto: 'Nuevo'
      },
      editar: {
        enabled: false,
        texto: 'Editar'
      },
      delete: {
        enabled: false,
        texto: 'Eliminar'
      }
    }
  })


},err =>{
  toast.error(err.message);

})
*/
  getTiposSectores = () => {
    this.setState({
      isLoading: true
    });
    Database.get(
      "/list-tipos-sectores/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        this.setState({
          isLoading: false
        });

        let resultado = [...res.result];
        this.setState({
          tiposSectores: resultado
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleClickOpen(rowData) {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  }

  handleClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    });
  }

  handleDelete(rowData) {
    if (rowData.id) {
      this.handleClose();
      Database.post(
        "/delete-tipo-sector",
        {
          id: rowData.id
        },
        this
      ).then(
        res => {
          this.getTiposSectores();
          toast.success("Tipo de sector Eliminado");
        },
        err => {
          toast.error(err.message);
        }
      );
    }
  }

  componentDidMount() {
    this.getTiposSectores();
  }

  deleteTipoSector = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  };

  reloadCategorias = () => {
    this.getTiposSectores();
  };

  render() {
    return [
      <Switch key="sector-switch">
        <Route
          path={this.props.match.url}
          exact
          render={() => (
            <div style={{ maxWidth: "100%" }}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={this.props.classes.cardTitleWhite}>
                    TIPOS DE SECTORES
                  </h4>
                  <p className={this.props.classes.cardCategoryWhite}>
                    Listado de Tipos de sectores
                  </p>
                </CardHeader>
                <CardBody>
                  <Button
                    style={{ marginTop: "25px" }}
                    onClick={() =>
                      this.props.history.push(
                        this.props.match.url + "/nuevotiposector"
                      )
                    }
                    color="primary"
                  >
                    <AddIcon /> Nuevo Tipo de Sector
                  </Button>

                  <MaterialTable
                    isLoading={this.state.isLoading}
                    columns={ColumnsListadoCategorias}
                    data={this.state.tiposSectores}
                    title=""
                    localization={localization}
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Editar Tipo Sector",
                        onClick: (event, rowData) =>
                          this.props.history.push(
                            this.props.match.url +
                              "/editartiposector/" +
                              rowData.id
                          )
                      },
                      {
                        icon: "delete",
                        tooltip: "Borrar Categoria",
                        onClick: (event, rowData) =>
                          this.deleteTipoSector(rowData)
                      }
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: true,
                      exportAllData: true,
                      exportFileName:
                        "Tipos Sectores " + moment().format("DD-MM-YYYY"),
                      exportDelimiter: ";",
                      headerStyle: {
                        backgroundColor: lightGreen[700],
                        color: "#FFF"
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </div>
          )}
        />

        <Route
          path={this.props.match.url + "/nuevotiposector/"}
          exact
          render={() => (
            <NewTipoSector getTiposSectores={() => this.getTiposSectores()} />
          )}
        />

        <Route
          path={this.props.match.url + "/editartiposector/:idTipoSector"}
          exact
          render={() => (
            <EditTipoSector getTiposSectores={() => this.getTiposSectores()} />
          )}
        />
      </Switch>,
      <ModalDeleteTipoSector
        key="sector-modal"
        openDeleteDialog={this.state.openDeleteDialog}
        deleteRowData={this.state.deleteRowData}
        handleClose={() => this.handleClose()}
        handleDelete={rowData => this.handleDelete(rowData)}
      />
    ];
  }
}

export default withStyles(styles)(TiposSectores);
