// imports React
import React from "react";
// Card
import Card from "components/Card/Card.js";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

// Componentes material
import InputText from "./InputText/InputText.js";
import Typography from "@material-ui/core/Typography";
// import Card from '@material-ui/core/Card';
// Styles
import { withStyles } from "@material-ui/styles";
import Alert from "@material-ui/lab/Alert";

const classes = {
  card: {
    color: "black",
    margin: "2em",
    marginBottom: "0px",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingBottom: "1em",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    width: "90%",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  titulo: {
    color: "black",
    marginTop: "10px",
    marginBottom: "3px",
    marginLeft: "20px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button_panel: {
    marginLeft: "30px",
    marginBottom: "30px"
  },
  button_custom: {
    marginLeft: "10px"
  }
};

const CardPedido = props => {
  let alert_referencia;
  if (props.nuevaReferencia === 1) {
    alert_referencia = (
      <Alert
        style={{ marginBottom: "20px", marginTop: "20px" }}
        severity="success"
      >
        La referencia {props.referenciaPedido} es correcta
      </Alert>
    );
  } else if (
    props.nuevaReferencia === 2 &&
    props.referenciaPedido &&
    !props.buscarReferencia
  ) {
    alert_referencia = (
      <Alert
        style={{ marginBottom: "20px", marginTop: "20px" }}
        severity="error"
      >
        La referencia ingresada ya existe
      </Alert>
    );
  } else if (props.referenciaPedido && props.buscarReferencia) {
    alert_referencia = (
      <Alert
        style={{ marginBottom: "20px", marginTop: "20px" }}
        severity="success"
      >
        Referencia seleccionada: {props.referenciaPedido}
      </Alert>
    );
  }

  return (
    <Card className={props.classes.card}>
      <Typography className={props.classes.titulo}>Datos del pedido</Typography>
      <div style={{ height: "70px", marginBottom: "20px" }}>
        {!props.referenciaPedido ? (
          <Alert
            style={{ marginBottom: "20px", marginTop: "20px" }}
            severity="info"
          >
            Debe ingresar una referencia de pedido nuevo o buscar una referencia
            de un pedido existente
          </Alert>
        ) : null}
        {alert_referencia}
      </div>
      <InputText></InputText>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    openBuscarClienteDialog: state.pedidossalidas.openBuscarClienteDialog,
    selectedCliente: state.pedidossalidas.selectedCliente,
    hasSelectedClient: state.pedidossalidas.hasSelectedClient,
    idSelectedClient: state.pedidossalidas.idSelectedClient,
    referenciaPedido: state.pedidossalidas.referenciaPedido,
    nuevaReferencia: state.pedidossalidas.nuevaReferencia,
    buscarReferencia: state.pedidossalidas.buscarReferencia
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOpenBuscarClienteDialog: () =>
      dispatch(actionCreators.handleOpenBuscarClienteDialog()),
    handleCloseClienteDialog: () =>
      dispatch(actionCreators.handleCloseClienteDialog()),
    handleAcceptModalCliente: cliente =>
      dispatch(actionCreators.handleAcceptModalCliente(cliente))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(CardPedido));
