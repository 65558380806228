// React base
import React from "react";
// Card
import CardHeader from "../Card/CardHeader.js";
// Styles
import { withStyles } from "@material-ui/styles";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const encabezadoCard = props => {
  return (
    <div>
      <CardHeader color="primary">
        <h4 className={props.classes.cardTitleWhite}>{props.titulo_card}</h4>
        <p className={props.classes.cardCategoryWhite}>
          {props.subtitulo_card}
        </p>
      </CardHeader>
    </div>
  );
};

export default withStyles(styles)(encabezadoCard);
