/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import Person from "@material-ui/icons/Person";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import AccountBox from "@material-ui/icons/AccountBox";
import AdjustIcon from "@material-ui/icons/Adjust";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import HomeWork from "@material-ui/icons/HomeWork";
import Home from "@material-ui/icons/Home";
import DragHandle from "@material-ui/icons/DragHandle";
import BrandingWatermark from "@material-ui/icons/BrandingWatermark";
import Straighten from "@material-ui/icons/Straighten";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Apps from "@material-ui/icons/Apps";
import OpenWith from "@material-ui/icons/OpenWith";
import Menu from "@material-ui/icons/Menu";
import Pause from "@material-ui/icons/Pause";
import TimelineIcon from "@material-ui/icons/Timeline";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import SettingsIcon from "@material-ui/icons/Settings";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BallotIcon from "@material-ui/icons/Ballot";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import WorkIcon from "@material-ui/icons/Work";
import AssignmentIcon from "@material-ui/icons/Assignment";

import DashboardPage from "views/Dashboard/Dashboard.js";
import Users from "views/Users/Users.js";
import Clientes from "views/Clientes/Clientes.js";
import TiposUsuarios from "views/Users/TiposUsuarios.js";
import Categorias from "views/Productos/Categorias";
import EditAlmacen from "views/Almacenes/components/EditAlmacen";
import Calles from "views/Calles/Calles";
import CallesSalida from "views/CallesSalida/CallesSalida";
import Columnas from "views/Almacenes/Columnas/Columnas";
import Sectores from "views/Sectores/Sectores";
import TipoSector from "views/Sectores/TiposSector";
import Muelles from "views/Muelles/Muelles";
import Ubicaciones from "views/Ubicaciones/Ubicaciones";
import ProductosUnidad from "views/Productos/ProductosUnidad";
import ProductosPresentacion from "views/Productos/ProductosPresentacion";
import Entradas from "views/Entradas/Entradas";
import Salidas from "views/Salidas/Salidas";
import Ajuste from "views/Ajuste/Ajuste";
import Contenedores from "views/Contenedores/Contenedores";
import Auditoria from "views/Auditoria/Auditoria.js";
import pickingUbicaciones from "views/PickingUbicaciones/PickingUbicaciones";
import PickingMovimientos from "views/PickingMovimientos/PickingMovimientos";
import Configuracion from "views/Configuracion/Configuracion";
import ImportacionMasiva from "views/ImportacionMasiva/ImportacionMasiva";
import Movimientos from "views/Movimientos/Movimientos";
import AlertaVencimientos from "views/Alertas/AlertaVencimientos";
import AlertaUmbral from "views/Alertas/AlertaUmbral";
import Bancos from "views/Bancos/Bancos";
import Ausentismos from "views/Empleados/Ausentismos/Ausentismo";
import Empleados from "views/Empleados/Empleados.js";
import Sanciones from "views/Empleados/Sanciones/Sancion.js";
import HijosEmpleados from "views/Empleados/Hijos/Hijo.js";
import Operaciones from "views/Empleados/Operaciones/Operacion.js";
import BancosEmpleados from "views/Empleados/BancosEmpleados/BancoEmpleado.js";
import Pedidos from "views/Pedidos/Pedidos";
import AuditoriaEntradas from "views/Auditoria/AuditoriaEntradas";
import AuditoriaSalidas from "views/Auditoria/AuditoriaSalidas";
import AuditoriaMovimientos from "views/Auditoria/AuditoriaMovimientos";
import AuditoriaAjustes from "views/Auditoria/AuditoriaAjustes";
import OrdenesTrabajo from "views/OrdenesTrabajo/OrdenesTrabajo";
import OrdenesPicking from "views/OrdenesPicking/OrdenesPicking";
import PedidosSalidas from "views/PedidosSalidas/Salidas";
import PedidosAlerta from "views/PedidosAlerta/PedidosAlerta";

import ClientePallets from "views/Cliente/ClientePallets";
import ClienteEntradas from "views/Cliente/ClienteEntradas";
import ClienteSalidas from "views/Cliente/ClienteSalidas";
import ClienteNuevoPedido from "views/Cliente/ClienteNuevoPedido";
import ClientePedidos from "views/Cliente/ClientePedidos";

const dashboardRoutes = [
  {
    accesos: [110],
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "DS",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    accesos: [101],
    path: "/importacion-masiva",
    name: "Importación Masiva",
    rtlName: "IM",
    icon: DescriptionIcon,
    component: ImportacionMasiva,
    layout: "/admin"
  },

  // {
  //   accesos: [101],
  //   path: "/recursos_humanos",
  //   name: "RRHH",
  //   rtlName: "IM",
  //   icon: DescriptionIcon,
  //   component: DashboardPage,
  //   layout: "/admin"
  // },

  {
    accesos: [150],
    groupComponent: true,
    name: "RRHH",
    open: "open202",
    icon: AssignmentInd,
    dependences: [
      {
        show: false,
        accesos: [150],
        path: "/empleados",
        name: "Empleados",
        rtlName: "Us",
        icon: AssignmentInd,
        component: Empleados,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [11, 12],
    groupComponent: true,
    name: "Usuarios",
    open: "open20",
    icon: AssignmentInd,
    dependences: [
      {
        show: false,
        accesos: [11],
        path: "/usuarios",
        name: "Usuarios",
        rtlName: "Us",
        icon: AssignmentInd,
        component: Users,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [12],
        path: "/tiposusuarios",
        name: "Tipos Usuarios",
        rtlName: "Us",
        icon: AccountBox,
        component: TiposUsuarios,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [160, 161, 162, 163, 164, 165, 166],
    groupComponent: true,
    name: "Administracion",
    open: "open80",
    icon: BallotIcon,
    dependences: [
      {
        show: false,
        accesos: [160],
        path: "/bancos",
        name: "Bancos",
        rtlName: "Us",
        icon: AccountBalanceIcon,
        component: Bancos,
        layout: "/admin"
      },

      // {
      //   prueba
      //   show: false,
      //   accesos: [161],
      //   path: "/tiposusuarios",
      //   name: "Clientes TMS",
      //   rtlName: "Us",
      //   icon: Person,
      //   component: TiposUsuarios,
      //   layout: "/admin"
      // },

      {
        show: false,
        accesos: [162],
        path: "/ausentismos",
        name: "Ausentismos",
        rtlName: "aus",
        icon: Person,
        component: Ausentismos,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [163],
        path: "/sanciones",
        name: "Sanciones",
        rtlName: "san",
        icon: ReportProblemIcon,
        component: Sanciones,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [164],
        path: "/hijos",
        name: "Hijos Empleados",
        rtlName: "hem",
        icon: ChildCareIcon,
        component: HijosEmpleados,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [165],
        path: "/operaciones",
        name: "Operaciones",
        rtlName: "ope",
        icon: WorkIcon,
        component: Operaciones,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [166],
        path: "/bancosempleados",
        name: "Cuentas Bancarias",
        rtlName: "cbe",
        icon: AccountBalanceIcon,
        component: BancosEmpleados,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [91, 92, 93, 94, 95, 96],
    groupComponent: true,
    name: "Warehouse",
    open: "open30",
    icon: HomeWork,
    dependences: [
      // {
      //   show: false,
      //   accesos: [],
      //   path: "/mapa",
      //   name: "Mapa",
      //   rtlName: "Us",
      //   icon: MapIcon,
      //   component: Mapa,
      //   layout: "/admin"
      // },
      {
        show: false,
        accesos: [91],
        path: "/almacen",
        name: "Almacen",
        rtlName: "Us",
        icon: Home,
        component: EditAlmacen,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [94],
        path: "/tiposectores",
        name: "Tipo Sectores",
        rtlName: "TSec",
        icon: BrandingWatermark,
        component: TipoSector,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [93],
        path: "/sectores",
        name: "Sectores",
        rtlName: "Sec",
        icon: BrandingWatermark,
        component: Sectores,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [92],
        path: "/calles",
        name: "Pasillos",
        rtlName: "Us",
        icon: DragHandle,
        component: Calles,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [92],
        path: "/columnas",
        name: "Columnas",
        rtlName: "Us",
        icon: Pause,
        component: Columnas,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [92],
        path: "/ubicaciones",
        name: "Ubicaciones",
        rtlName: "Ub",
        icon: Apps,
        component: Ubicaciones,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [92],
        path: "/pickingubicaciones",
        name: "Ubicaciones Picking",
        rtlName: "Ub",
        icon: Apps,
        component: pickingUbicaciones,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [95],
        path: "/muelles",
        name: "Muelles",
        rtlName: "Mue",
        icon: Straighten,
        component: Muelles,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [96],
        path: "/csalida",
        name: "Calles de Salida",
        rtlName: "Cas",
        icon: Menu,
        component: CallesSalida,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [120, 121],
    groupComponent: true,
    name: "Artículos",
    open: "open50",
    icon: WidgetsIcon,
    dependences: [
      {
        show: false,
        accesos: [120],
        path: "/produnidad",
        name: "Referencias",
        rtlName: "LCA",
        icon: WidgetsIcon,
        component: ProductosUnidad,
        layout: "/admin"
      },

      {
        show: false,
        accesos: [121],
        path: "/prodpresentacion",
        name: "Datos Logísticos",
        rtlName: "LCA",
        icon: WidgetsIcon,
        component: ProductosPresentacion,
        layout: "/admin"
      },

      /*
      {
        show: false,
        accesos: [21],
        path: "/productos",
        name: "Productos",
        rtlName: "LCA",
        icon: WidgetsIcon,
        component: Productos,
        layout: "/admin"
      },
      */
      {
        show: false,
        accesos: [120],
        path: "/prodcategorias",
        name: "Categorias",
        rtlName: "LCA",
        icon: ScatterPlotIcon,
        component: Categorias,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [130],
    groupComponent: true,
    name: "Clientes",
    open: "open201",
    icon: Person,
    dependences: [
      {
        show: false,
        accesos: [130],
        path: "/clientes",
        name: "Clientes",
        rtlName: "Cl",
        icon: Person,
        component: Clientes,
        layout: "/admin"
      }
    ]
  },

  // {
  //   show: false,
  //   accesos: [31, 32, 33, 34, 35, 36, 37],
  //   groupComponent: true,
  //   name: 'Alertas',
  //   open: 'open6',
  //   icon: AccessAlarmIcon,
  //   dependences: [

  //     {
  //       show: false,
  //       accesos: [31],
  //       path: "/alertaCosto",
  //       name: "Costos",
  //       rtlName: "ALC",
  //       icon: AttachMoneyIcon,
  //       component: AlertaCostos,
  //       layout: "/admin"
  //     },
  //     {
  //       show: false,
  //       accesos: [32],
  //       path: "/alertaPedidos",
  //       name: "Pedidos",
  //       rtlName: "ALP",
  //       icon: AssignmentIcon,
  //       component: AlertaPedidos,
  //       layout: "/admin"
  //     }
  //   ]
  // },

  {
    show: false,
    accesos: [41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
    groupComponent: true,
    name: "Stock",
    open: "open10",
    icon: FormatAlignJustifyIcon,
    dependences: [
      {
        show: false,
        accesos: [48],
        path: "/contenedores",
        name: "Pallets",
        rtlName: "Con",
        icon: AdjustIcon,
        component: Contenedores,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [45],
        path: "/entradas",
        name: "Entradas",
        rtlName: "ENT",
        icon: LocalShippingIcon,
        component: Entradas,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [43],
        path: "/salidas",
        name: "Salidas",
        rtlName: "SAL",
        icon: LocalShippingIcon,
        component: Salidas,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [50],
        path: "/pickingsalidas",
        name: "Salidas Picking",
        rtlName: "SAX",
        icon: LocalShippingIcon,
        component: PedidosSalidas,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [44],
        path: "/pedidos",
        name: "Pedidos",
        rtlName: "PED",
        icon: AssignmentIcon,
        component: Pedidos,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [51],
        path: "/alertapedidos",
        name: "Pedidos Alerta",
        rtlName: "PEF",
        icon: AssignmentIcon,
        component: PedidosAlerta,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [42],
        path: "/ordenestrabajo",
        name: "Procesamiento de Pedidos",
        rtlName: "OPI",
        icon: SettingsIcon,
        component: OrdenesTrabajo,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [41],
        path: "/ordenespicking",
        name: "Ordenes de Picking",
        rtlName: "OPZ",
        icon: Apps,
        component: OrdenesPicking,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [46],
        path: "/movimientos",
        name: "Movimientos",
        rtlName: "MVI",
        icon: OpenWith,
        component: Movimientos,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [49],
        path: "/pickingmovimientos",
        name: "Movimientos Picking",
        rtlName: "MVP",
        icon: OpenWith,
        component: PickingMovimientos,
        layout: "/admin"
      },
      {
        show: false,
        accesos: [47],
        path: "/ajustes",
        name: "Ajustes",
        rtlName: "AJU",
        icon: AdjustIcon,
        component: Ajuste,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [99],
    accesos_alertas: [1, 2],
    groupComponent: true,
    name: "Alertas",
    open: "open70",
    icon: AccessAlarmIcon,
    dependences: [
      {
        accesos: [99],
        accesos_alertas: [2],
        path: "/alertaumbral",
        name: "Umbral",
        rtlName: "AUDI",
        icon: TimelineIcon,
        component: AlertaUmbral,
        layout: "/admin"
      },
      {
        accesos: [99],
        accesos_alertas: [1],
        path: "/alertavencimientos",
        name: "Vencimientos",
        rtlName: "AUDI",
        icon: TimelineIcon,
        component: AlertaVencimientos,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [140, 141, 142, 143, 144],
    groupComponent: true,
    name: "Auditorias",
    open: "open140",
    icon: TimelineIcon,
    dependences: [
      {
        accesos: [140],
        path: "/auditoriapallets",
        name: "Pallets",
        rtlName: "AUDI",
        icon: AdjustIcon,
        component: Auditoria,
        layout: "/admin"
      },
      {
        accesos: [141],
        path: "/auditoriaentradas",
        name: "Entradas",
        rtlName: "AUEN",
        icon: LocalShippingIcon,
        component: AuditoriaEntradas,
        layout: "/admin"
      },
      {
        accesos: [142],
        path: "/auditoriasalidas",
        name: "Salidas",
        rtlName: "AUSA",
        icon: LocalShippingIcon,
        component: AuditoriaSalidas,
        layout: "/admin"
      },
      {
        accesos: [143],
        path: "/auditoriamovimientos",
        name: "Movimientos",
        rtlName: "AUMO",
        icon: OpenWith,
        component: AuditoriaMovimientos,
        layout: "/admin"
      },
      {
        accesos: [144],
        path: "/auditoriaajustes",
        name: "Ajustes",
        rtlName: "AUAJ",
        icon: AdjustIcon,
        component: AuditoriaAjustes,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [170, 171],
    groupComponent: true,
    name: "Mis Pedidos",
    open: "open210",
    icon: FormatAlignJustifyIcon,
    dependences: [
      {
        accesos: [171],
        path: "/clientepedidos",
        name: "Ver Pedidos",
        rtlName: "CLIF",
        icon: AssignmentIcon,
        component: ClientePedidos,
        layout: "/admin"
      },
      {
        accesos: [170],
        path: "/clientecargarpedido",
        name: "Nuevo Pedido",
        rtlName: "CLIF",
        icon: AssignmentIcon,
        component: ClienteNuevoPedido,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [167, 168, 169],
    groupComponent: true,
    name: "Mis Informes",
    open: "open220",
    icon: TimelineIcon,
    dependences: [
      {
        accesos: [167],
        path: "/clientepallets",
        name: "Mercadería",
        rtlName: "CLII",
        icon: WidgetsIcon,
        component: ClientePallets,
        layout: "/admin"
      },
      {
        accesos: [168],
        path: "/clienteentradas",
        name: "Entradas",
        rtlName: "CLIN",
        icon: LocalShippingIcon,
        component: ClienteEntradas,
        layout: "/admin"
      },
      {
        accesos: [169],
        path: "/clientesalidas",
        name: "Salidas",
        rtlName: "CLIA",
        icon: LocalShippingIcon,
        component: ClienteSalidas,
        layout: "/admin"
      }
    ]
  },

  {
    show: false,
    accesos: [1],
    path: "/configuracion",
    name: "Configuracion",
    rtlName: "Confi",
    icon: SettingsIcon,
    component: Configuracion,
    layout: "/admin"
  }
];

export default dashboardRoutes;
