import React, { useEffect } from "react";
// style
import lightGreen from "@material-ui/core/colors/lightGreen";
// material table
import MaterialTable from "material-table";
import { localization } from "variables/general.js";
import DetailTable from "../DetailTable/DetailTable";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import Database from "variables/Database.js";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

const addToLote = (rowData, props) => {
  props.addContenedorALoteDeSalida(rowData);
};

export const removeFromLote = (rowData, props) => {
  props.removeContenedorDeLoteDeSalida(rowData);
};

const isInLote = (rowData, props) => {
  let test = props.contenedoresEnLoteDeSalida.filter(c => {
    return c.id === rowData.id;
  });
  return test.length === 0;
};

export let localization_options = { header: { actions: "" } };
localization_options = {
  ...JSON.parse(JSON.stringify(localization)),
  ...localization_options
};

export const columnasTablaContenedores = [
  { title: "Pallet", field: "id" },
  { title: "Producto", field: "ProductosUnidad.nombre" },
  { title: "Cod. Interno", field: "ProductosUnidad.codigo" },
  { title: "DUN14", field: "ProductosPresentacion.dun14" },
  { title: "EAN13", field: "ProductosUnidad.ean13" },
  { title: "Ubicación actual", field: "tipo_ubicacion_actual" },
  { title: "Nombre ubicación actual", field: "nombre_ubicacion_actual" },
  { title: "Cliente", field: "cliente.cliente" },
  { title: "Lote", field: "lote" }
];

function TablaLotesASalida(props) {
  useEffect(() => {
    // Database.get('/list-contenedores-prod-unidad-presentacion-join/')
    Database.get("/list-contenedores-listar-filtro-cliente/0").then(
      result => {
        let contenedores = [...result.result];
        props.setContenedores(contenedores);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, [props.reload, props.idSelectedClient]);

  return (
    <MaterialTable
      id="tabla-salidas"
      isLoading={props.isLoading}
      columns={columnasTablaContenedores}
      data={props.contenedores}
      title=""
      localization={localization_options}
      actions={[
        rowData => ({
          icon: "send",
          tooltip: "Enviar a lote de Salida",
          onClick: (event, rowData) => addToLote(rowData, props),
          disabled: !isInLote(rowData, props)
        }),
        rowData => ({
          icon: "undo",
          tooltip: "Devolver a su ubicación",
          onClick: (event, rowData) => removeFromLote(rowData, props),
          disabled: isInLote(rowData, props)
        })
      ]}
      options={{
        filtering: true,
        exportDelimiter: ";",
        headerStyle: {
          backgroundColor: lightGreen[700],
          color: "#FFF"
        },
        selection: false,
        actionsColumnIndex: -1
      }}
      detailPanel={rowData => {
        return (
          <div>
            <DetailTable fila={rowData}></DetailTable>
          </div>
        );
      }}
    ></MaterialTable>
  );
}

const mapStateToProps = state => {
  return {
    contenedores: state.salidas.contenedores,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    isLoading: state.salidas.isLoading,
    reload: state.salidas.reload,
    selectedTab: state.salidas.selectedTab,
    selectedCliente: state.pedidossalidas.selectedCliente,
    idSelectedClient: state.pedidossalidas.idSelectedClient
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setContenedores: contenedores =>
      dispatch(actionCreators.setContenedores(contenedores)),
    removeContenedorDeLoteDeSalida: contenedor =>
      dispatch(actionCreators.removeContenedorDeLoteDeSalida(contenedor)),
    addContenedorALoteDeSalida: contenedor =>
      dispatch(actionCreators.addContenedorALoteDeSalida(contenedor))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TablaLotesASalida)
);
