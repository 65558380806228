export const StateListUbicaciones = {
  ubicacionesLibres: [],
  ubicacionesAsignadas: [],
  offset: 0,
  checked: [],
  menuContext: null,
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  desasignarRowData: null,
  isLoading: false,
  tabSelect: 0
};

export const StateUbicaciones = {
  orderForm: {
    id: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Número",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    largo: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Largo Total (mts)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    sentido: {
      elementType: "select",
      elementConfig: {
        label: "Sentido",
        options: [
          { value: "A", displayValue: "Ascendente" },
          { value: "D", displayValue: "Descendente" }
        ],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    }
    // orden: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         label: 'Orden',
    //         fullWidth: true
    //     },
    //     value: '',
    //     validation: {
    //         required: true
    //     },
    //     valid: false,
    //     touched: false
    // },
  },

  formIsValid: false,
  disableAllButtons: false
};

export const ColumnsListadoLibres = [
  //{ title: "Numero", field: "id" },
  { title: "Pasillo", field: "Calle.calle" },
  { title: "Columna", field: "Columna.columna" },
  { title: "Nivel", field: "nivel" },
  { title: "Altura (mts)", field: "altura" },
  { title: "Peso Máximo (kg)", field: "peso_maximo" }
  // { title: "Ocupacion", field: "Ocupacion.descripcion" },
  //{ title: "Producto", field: "ProductosPresentacion.producto" }
];

export const ColumnsListado = [
  //{ title: "Numero", field: "id" },
  { title: "Pasillo", field: "Calle.calle", editable: "never" },
  { title: "Columna", field: "Columna.columna", editable: "never" },
  { title: "Nivel", field: "nivel", editable: "never" },
  { title: "Altura (mts)", field: "altura", editable: "never" },
  { title: "Peso Máximo (kg)", field: "peso_maximo", editable: "never" },
  {
    title: "Codigo",
    field: "ProductosPresentacion.ProductosUnidad.codigo",
    editable: "never"
  },
  {
    title: "Dato Logístico",
    field: "ProductosPresentacion.producto",
    editable: "never"
  },
  {
    title: "EAN 13",
    field: "ProductosPresentacion.ProductosUnidad.ean13",
    editable: "never"
  },
  { title: "Unidades", field: "cantidad_unidades_picking", editable: "never" },
  {
    title: "Cliente",
    field: "ProductosPresentacion.ProductosUnidad.cliente.razon_social",
    editable: "never"
  },
  { title: "Umbral (unidades)", field: "umbral", editable: "onUpdate" }
];
