import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { StateProductosUnidad } from "../VariablesState";

import Database from "variables/Database.js";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";

import Alert from "@material-ui/lab/Alert";

import Grid from "@material-ui/core/Grid";

import ModalBuscarCliente from "./ModalBuscarCliente";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class NewProductoUnidad extends Component {
  state = JSON.parse(JSON.stringify(StateProductosUnidad));

  handleSubmit = event => {
    event.preventDefault();

    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }

    if (this.state.selectedCliente)
      objeto.id_cliente = this.state.selectedCliente.id;

    this.setState({
      disableAllButtons: true
    });

    Database.post(`/insert-producto-unidad`, { ...objeto }).then(
      res => {
        this.setState(
          {
            disableAllButtons: false
          },
          () => {
            toast.success("La referencia se ha creado con exito!");
            this.resetForm();
            if (this.props.onSave) {
              this.props.onSave(res.result.id);
            } else {
              this.props.getListado();
            }
          }
        );
      },
      err => {
        this.setState({
          disableAllButtons: false
        });
        toast.error(err.message);
      }
    );
    // console.log(objeto);
  };

  handleBuscarClienteDialog = () => {
    this.setState({ openBuscarClienteDialog: true });
  };

  handleModalClose() {
    this.setState({ openBuscarClienteDialog: false });
  }

  handleAccept(dataRow) {
    let selectedCliente = { ...this.state.selectedCliente };
    let orderForm = { ...this.state.orderForm };
    selectedCliente = dataRow;
    if (!orderForm.codigo.value) orderForm.codigo.value = dataRow.id + "-";

    this.setState({
      orderForm: orderForm,
      selectedCliente: selectedCliente,
      openBuscarClienteDialog: false
    });
  }

  handleSubmitEdit = event => {
    event.preventDefault();

    let objeto = {};
    for (let key in this.state.orderForm) {
      objeto[key] = this.state.orderForm[key].value;
    }

    if (this.state.selectedCliente)
      objeto.id_cliente = this.state.selectedCliente.id;

    this.setState({
      disableAllButtons: true
    });

    Database.post(`/update-producto-unidad`, {
      id: this.props.match.params.idProductoUnidad,
      ...objeto
    }).then(
      res => {
        this.setState(
          {
            disableAllButtons: false
          },
          () => {
            toast.success("La referencia se ha modificado con exito!");

            this.props.getListado();
          }
        );
      },
      err => {
        this.setState({
          disableAllButtons: false
        });

        toast.error(err.message);
      }
    );
    // console.log(objeto);
  };

  getItem = idProductoUnidad => {
    Database.get(`/list-producto-unidad/` + idProductoUnidad).then(
      res => {
        let resultado = res.result;

        let orderForm = { ...this.state.orderForm };

        for (let key in orderForm) {
          if (resultado[key]) orderForm[key].value = resultado[key];
        }

        let objeto = inputAllChangedHandler(orderForm);

        this.setState({
          orderForm: objeto.orderForm,
          formIsValid: objeto.formIsValid,
          selectedCliente: resultado.cliente
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getItemsSelect = () => {
    Database.get(
      "/list-productos-categorias/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        this.setState({
          isLoading: false
        });
        let resultado = [...res.result[0]];
        let orderFormCopy = { ...this.state.orderForm };
        resultado.forEach(elem => {
          orderFormCopy.id_producto_categoria.elementConfig.options.push({
            displayValue: elem.descripcion,
            value: elem.id
          });
        });

        this.setState({
          orderForm: orderFormCopy
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    if (this.props.match.params.idProductoUnidad)
      this.getItem(this.props.match.params.idProductoUnidad);

    this.getItemsSelect();

    if (this.props.rowCliente) {
      let orderfcopy = { ...this.state.orderForm };

      orderfcopy.codigo.value = this.props.rowCliente.id + "-";

      this.setState({
        orderform: orderfcopy,
        selectedCliente: this.props.rowCliente,
        hasSelectedCliente: true
      });
    }
  }

  resetForm = () => {
    this.setState(JSON.parse(JSON.stringify(StateProductosUnidad)));

    this.getItemsSelect();
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }
    return (
      <form
        onSubmit={event => {
          if (this.props.match.params.idProductoUnidad)
            this.handleSubmitEdit(event);
          else this.handleSubmit(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Nueva Referencia
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario de alta de referencia
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  {" "}
                  <span style={{ fontSize: "130%", fontWeight: "600" }}>
                    Información Referencia
                  </span>
                </Grid>

                <Grid item md={12}>
                  <Button
                    disabled={this.props.rowCliente}
                    variant="contained"
                    color="primary"
                    onClick={event => this.handleBuscarClienteDialog()}
                  >
                    <SearchIcon></SearchIcon>Buscar Cliente
                  </Button>

                  {this.state.selectedCliente ? (
                    <p>
                      <strong>
                        Cliente: {this.state.selectedCliente.razon_social}{" "}
                        <br /> RUC: {this.state.selectedCliente.ruc} <br /> ID:{" "}
                        {this.state.selectedCliente.id}
                      </strong>
                    </p>
                  ) : (
                    <p>
                      <Alert severity="warning">
                        Primero, debe seleccionar un cliente!
                      </Alert>
                    </p>
                  )}
                  {/*
                  <InputNew
                    textoInicial={((this.state.selectedCliente && this.state.selectedCliente.id) || '00') + ' - '} 
                    objeto={this.state.orderForm.codigo}
                    changed={(event) => {
                      let { orderForm, formIsValid } = inputChangedHandler(event, 'codigo', this.state.orderForm);
                      this.setState({ orderForm: orderForm, formIsValid: formIsValid })
                    }}
                  />
                    */}

                  <InputNew
                    objeto={this.state.orderForm.codigo}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "codigo",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />

                  <InputNew
                    objeto={this.state.orderForm.id_producto_categoria}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "id_producto_categoria",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                  <InputNew
                    objeto={this.state.orderForm.descripcion}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "descripcion",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputNew
                    objeto={this.state.orderForm.ean13}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "ean13",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>

                <Grid item md={12}>
                  <InputNew
                    objeto={this.state.orderForm.peso}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "peso",
                        this.state.orderForm
                      );

                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <InputNew
                    objeto={this.state.orderForm.alto}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "alto",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <InputNew
                    objeto={this.state.orderForm.largo}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "largo",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <InputNew
                    objeto={this.state.orderForm.ancho}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "ancho",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>

                <Grid item md={12}>
                  <InputNew
                    objeto={this.state.orderForm.estado}
                    changed={event => {
                      let { orderForm, formIsValid } = inputChangedHandler(
                        event,
                        "estado",
                        this.state.orderForm
                      );
                      this.setState({
                        orderForm: orderForm,
                        formIsValid: formIsValid
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {!this.props.modal && (
              <Button
                style={{ marginTop: "25px" }}
                color="info"
                onClick={() => this.props.history.goBack()}
              >
                <ArrowBack />
                Volver
              </Button>
            )}
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={
                !this.state.formIsValid ||
                this.state.disableAllButtons ||
                !this.state.selectedCliente
              }
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>

        <ModalBuscarCliente
          openBuscarClienteDialog={this.state.openBuscarClienteDialog}
          handleClose={() => {
            this.handleModalClose("cliente");
          }}
          handleAccept={clientData => this.handleAccept(clientData, "cliente")}
        />
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(NewProductoUnidad));
