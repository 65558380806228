import React from "react";
import interact from "interactjs";
import * as calc from "../CalculosAccesorios/CalculosAccesorios";
import * as calcBloques from "../CalculosAccesorios/CalculosAccesoriosBloques";
import Calle from "../Calle/Calle";
import "./Bloque.css";
import Columnasparimpar from "../Columnasparimpar/Columnasparimpar";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

var _ = require("lodash/core");

class Bloque extends React.Component {
  toggleSelected = () => {
    let clases = ["Bloque"];
    const is_selected = this.props.is_selected;
    if (is_selected) {
      clases.push("bloque-seleccionado");
    }
    return clases.join(" ");
  };

  reattach = target => {
    target.on("click", this.props.click);
  };

  render() {
    // Calculamos el alto y ancho para setear el estilo
    let clases = this.toggleSelected();

    let ancho_calle = 0;
    let ancho_bloque = 0;
    let alto_bloque = 0;
    let cantcol = 0;

    let rotacion = this.props.rotacion;

    ancho_calle =
      ancho_calle + calc.deMetrosAPixeles(Number(this.props.calle.width));
    alto_bloque =
      alto_bloque + calc.deMetrosAPixeles(Number(this.props.calle.height));
    this.props.columnas.map(c => {
      if (this.props.tipo === "PI") {
        ancho_bloque = ancho_calle + 2 * calc.deMetrosAPixeles(Number(c.width));
      } else {
        ancho_bloque = ancho_calle + calc.deMetrosAPixeles(Number(c.width));
      }
      return ancho_bloque;
    });

    let bloque_id = this.props.calle.id;
    let dataxBloque = 0;
    let datayBloque = 0;
    if (this.props.data_pos != null) {
      dataxBloque = this.props.data_pos.pos_x;
      datayBloque = this.props.data_pos.pos_y;
      rotacion = Number(this.props.data_pos.rotacion);
    } else {
      dataxBloque = calc.getPosicionX(bloque_id);
      datayBloque = calc.getPosicionY(bloque_id);
    }

    let style = {};
    style = calcBloques.setStyleTransformBloque(
      dataxBloque,
      datayBloque,
      rotacion,
      ancho_bloque,
      alto_bloque
    );

    // se setea posiciones de calles y columnas
    // posicion calle en x = ancho columna 1
    let ancho_col_num = calc.deMetrosAPixeles(
      Number(this.props.columnas[0].width)
    );
    let ancho_col = String(ancho_col_num);
    let pos_x_cols = [0, ancho_col_num + ancho_calle];
    if (this.props.tipo === "P") {
      pos_x_cols = [0, ancho_calle];
    }

    cantcol = this.props.cantcol;

    // nombres de columnas
    let listasNombresCol = [];
    let sortedNombresCol = _.sortBy(this.props.calle.nombres);
    listasNombresCol = calc.divideEnListasParesEImpares(sortedNombresCol);

    let pos_x_calle = "";
    if (this.props.tipo === "P") {
      pos_x_calle = "0";
    } else {
      pos_x_calle = ancho_col;
    }

    let columna = [];

    columna.push(
      <Columnasparimpar
        tipo={this.props.tipo}
        columnas={this.props.columnas}
        cantcol={cantcol}
        rotacion={rotacion}
        listasNombresCol={listasNombresCol}
        pos_x_cols={pos_x_cols}
      ></Columnasparimpar>
    );
    let comps = [];
    // Agrego la calle a comps
    comps.push(
      <Calle
        es_movible={false}
        pos_x={pos_x_calle}
        // pos_y={this.props.calle.pos_y}
        pos_y={0}
        id={this.props.calle.nombre_calle}
        width={ancho_calle}
        height={alto_bloque}
        sentido={this.props.sentido}
        sector={this.props.sector}
      ></Calle>
    );
    // Agregamos las columnas a comps
    // Agregamos los dos tipos de componentes en res
    const res = [...columna, comps];
    return (
      <div
        id={this.props.id}
        className={clases}
        degrees={rotacion}
        style={style}
        onClick={this.props.click}
        data-x={dataxBloque}
        data-y={datayBloque}
      >
        {res}
      </div>
    );
  }
}

function dragMoveListener(event) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
  var degrees = parseFloat(target.getAttribute("degrees")) || 0;
  // translate the element
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + y + "px) rotate(" + degrees + "deg)";

  // update the posiion attributes
  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);
  target.setAttribute("degrees", degrees);
}

window.dragMoveListener = dragMoveListener;

interact(".Bloque").draggable({
  listeners: { move: window.dragMoveListener },
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: ".canvas",
      endOnly: true
    })
  ]
});

const mapStateToProps = state => {
  return {
    pos_x_state: state.pos_x,
    pos_y_state: state.pos_y,
    rotacion_state: state.rotacion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPositionBloque: (pos_x, pos_y, rotacion) =>
      dispatch(actionCreators.setPositionBloque(pos_x, pos_y, rotacion))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bloque);

// export default Bloque;
