import * as actionTypes from "./actionTypes";

export const setPositionBloque = (pos_x, pos_y, rotacion) => {
  return {
    type: actionTypes.SET_POSITION_BLOQUE,
    pos_x: pos_x,
    pos_y: pos_y,
    rotacion: rotacion
  };
};
