export const StateListAusentismos = {
  ausentismos: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditAusentismo = {
  idEmpleado: null,
  nombreEmpleado: null,
  editAusentismoForm: {
    tipo: {
      elementType: "select",
      elementConfig: {
        label: "Tipo de Ausencia",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    dolencia: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Dolencia",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    diagnostico: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Diagnostico",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    evolucion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Evolución",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  files: [],
  fechaBaja: null,
  fechaProxControl: null,
  fechaAltaProbable: null,
  fechaAlta: null,
  ausentismoEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewAusentismo = {
  idEmpleado: null,
  nombreEmpleado: null,
  newAusentismoForm: {
    tipo: {
      elementType: "select",
      elementConfig: {
        label: "Tipo de Ausencia",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    dolencia: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Dolencia/Siniestro",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    diagnostico: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Diagnostico",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },

    evolucion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Estado/Evolución",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  fechaBaja: null,
  fechaProxControl: null,
  fechaAltaProbable: null,
  fechaAlta: null,
  formIsValid: false,
  successSubmit: null,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Apellido", field: "apellido" },
  { title: "Nombre", field: "nombre" },
  { title: "Tipo", field: "ausentismo_tipo" },
  { title: "Fecha Baja", field: "fecha_baja" },
  { title: "Dolencia", field: "dolencia" },
  { title: "Diagnostico", field: "diagnostico" },
  { title: "Evolucion", field: "evolucion" },
  { title: "Fecha Proximo Control", field: "fecha_prox_control" },
  { title: "Fecha Alta Probable", field: "fecha_alta_probable" },
  { title: "Fecha Alta Definitiva", field: "fecha_alta" }
];
