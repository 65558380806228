import * as actionTypes from "../actions/actionTypes";
import * as calc from "views/Mapa/CalculosAccesorios/CalculosAccesorios";

const initialState = {
  bloques: [],
  bloquesEnMapa: [],
  dimensionesAlmacen: [{ height: 100, width: 200 }],
  muelles: [],
  muellesEnMapa: [],
  callesDeSalida: [],
  callesDeSalidaEnMapa: [],
  regla: {
    show: false,
    width: 30,
    iconType: "forward"
  },
  control_visible: false,
  datosTabla: [],
  headerTabla: "inicial",
  isLoaded: false
};

const addBloquesEnMapa = (state, action) => {
  let bloques_mapa = [...state.bloquesEnMapa];
  let bloque = action.b;
  if (action.data_pos != null) {
    bloque.calle.pos_x = action.data_pos.pos_x;
    bloque.calle.pos_y = action.data_pos.pos_y;
    bloque.rotacion = action.data_pos.rotacion;
  }
  bloques_mapa.push(action.b);
  return { ...state, bloquesEnMapa: bloques_mapa };
};

const esCalle = x => {
  return x.hasOwnProperty("calle");
};

const dataIsLoaded = (state, action) => {
  return state.isLoaded;
};

const setLoadedStatus = (state, action) => {
  return { ...state, isLoaded: action.status };
};

const getDatosTabla = x => {
  if (esCalle(x)) {
    return { id: x.calle.id, nombre_row: x.calle.nombre_calle }; //,
    // nombre_calle: x.calle.nombre_calle};
  }
};

const addBloquesEnTabla = (state, action) => {
  let bloques = [...state.datosTabla];
  bloques.push(getDatosTabla(action.b));
  return { ...state, datosTabla: bloques };
};

const removerBloqueDeTabla = (state, action) => {
  let indice_bloque = state.datosTabla.findIndex(b => b.id === action.id_row);
  let copia_bloques = [...state.datosTabla];
  copia_bloques.splice(indice_bloque, 1);
  return { ...state, datosTabla: copia_bloques };
};

const removerBloqueDeMapa = (state, action) => {
  let copia_bloques = [...state.bloquesEnMapa];
  let indice_bloque = copia_bloques.findIndex(
    b => b.calle.id === action.bloque.calle.id
  );
  copia_bloques.splice(indice_bloque, 1);
  return { ...state, bloquesEnMapa: copia_bloques };
};

const indexSelectedSpatialObject = array_in => {
  const spatialObjectIndex = [...array_in].findIndex(x => {
    return x.is_selected === true;
  });
  return spatialObjectIndex;
};

// const indexSelectedBloque = (state) => {
//   // get the index of selected bloque from array this.state.bloquesEnMapa
//   const bloqueIndex = [...state.bloquesEnMapa].findIndex(x => {
//     return x.is_selected === true
//   })
//   return bloqueIndex
// }

const getAllSpatialObjectsOnMap = state => {
  // funcion que devulve dos arrays:
  // un array con los spatial objects en el mapa
  // un segundo array con una letra indicando el tipo de objeto
  let allSpatialObjectsOnMap = [
    ...state.bloquesEnMapa,
    ...state.muellesEnMapa,
    ...state.callesDeSalidaEnMapa
  ];
  return allSpatialObjectsOnMap;
};

const rotateElementHandler = state => {
  let spatialObjects = getAllSpatialObjectsOnMap(state);
  let selectedIndex = indexSelectedSpatialObject(spatialObjects);
  let spatialObject = spatialObjects[selectedIndex];

  let rotacion = spatialObject.rotacion;
  rotacion = Number(rotacion) + 15;
  if (rotacion >= 360) {
    rotacion = rotacion - 360;
  }
  spatialObject.rotacion = rotacion;
  spatialObject.data_pos = null;

  if (spatialObject.spatialObjectType === "bloque") {
    const stateCopia = [...state.bloquesEnMapa];
    stateCopia[selectedIndex] = spatialObject;
    return { ...state, bloquesEnMapa: stateCopia };
  }

  let muelle_initial_index = [...state.bloquesEnMapa].length;
  if (spatialObject.spatialObjectType === "muelle") {
    const stateCopia = [...state.muellesEnMapa];
    stateCopia[selectedIndex - muelle_initial_index] = spatialObject;
    return { ...state, muellesEnMapa: stateCopia };
  }

  let calle_salida_initial_index =
    [...state.bloquesEnMapa].length + [...state.muellesEnMapa].length;
  if (spatialObject.spatialObjectType === "calle_salida") {
    const stateCopia = [...state.callesDeSalidaEnMapa];
    stateCopia[selectedIndex - calle_salida_initial_index] = spatialObject;
    return { ...state, callesDeSalidaEnMapa: stateCopia };
  }
};

// const deselectAllBloques = (state, action) => {
//   if (action.event.target.id === 'mapa') {
//     let all_bloques_mapa = state.bloquesEnMapa;
//     let disabled_bloques_mapa = all_bloques_mapa.map((obj) => {
//       obj.is_selected = false;
//       return obj
//     })
//     return {
//       ...state,
//       bloquesEnMapa: disabled_bloques_mapa
//     };
//   } else {
//     return state
//   }
// }

const deselectAllSpatialObjects = (state, action) => {
  if (action.event.target.id === "mapa") {
    let all_bloques_mapa = state.bloquesEnMapa;
    let all_muelles_mapa = state.muellesEnMapa;
    let all_calles_salida = state.callesDeSalidaEnMapa;
    console.log(action);
    let disabled_bloques_mapa = all_bloques_mapa.map(obj => {
      obj.is_selected = false;
      return obj;
    });
    let disabled_muelles_mapa = all_muelles_mapa.map(obj => {
      obj.is_selected = false;
      return obj;
    });
    let disabled_calles_salida_mapa = all_calles_salida.map(obj => {
      obj.is_selected = false;
      return obj;
    });
    return {
      ...state,
      bloquesEnMapa: disabled_bloques_mapa,
      muellesEnMapa: disabled_muelles_mapa,
      callesDeSalidaEnMapa: disabled_calles_salida_mapa
    };
  } else {
    return state;
  }
};

const toggleAllSpatialObjects = (state, action) => {
  let old_state = getAllSpatialObjectsOnMap(state);

  action.event.preventDefault();
  let new_state_bloques = [];
  let new_state_muelles = [];
  let new_state_calles_salida = [];

  old_state.map((obj, i) => {
    if (i === action.index) {
      if (obj.is_selected) {
        obj.is_selected = false;
      } else {
        obj.is_selected = true;
      }
    } else {
      obj.is_selected = false;
    }
    if (obj.spatialObjectType === "bloque") {
      new_state_bloques.push(obj);
    }
    if (obj.spatialObjectType === "muelle") {
      new_state_muelles.push(obj);
    }
    if (obj.spatialObjectType === "calle_salida") {
      new_state_calles_salida.push(obj);
    }
    return obj;
  });

  return {
    ...state,
    bloquesEnMapa: new_state_bloques,
    muellesEnMapa: new_state_muelles,
    callesDeSalidaEnMapa: new_state_calles_salida
  };
};

const hideReglaHandler = (state, action) => {
  const showRegla = state.regla.show;
  // widthRegla 30 metros en terreno mas los 30px del boton arrow
  let widthRegla = 30 * calc.deMetrosAPixeles(1) + 30;
  let newState = {};
  if (showRegla) {
    newState = {
      show: false,
      width: 30,
      iconType: "forward"
    };
  } else {
    newState = {
      show: true,
      width: widthRegla,
      iconType: "back"
    };
  }
  return { ...state, regla: newState };
};

const mapearDatosCalles = data => {
  return data.map(x => {
    return { id: x.calle.id, nombre_row: x.calle.nombre_calle };
  });
};

const mapearDatosMuelles = data => {
  return data.map(x => {
    return { id: x.id, nombre_row: x.descripcion };
  });
};

const mapearDatosCallesDeSalida = data => {
  return data.map(x => {
    return { id: x.id, nombre_row: x.descripcion };
  });
};

const fillCalles = (state, action) => {
  // Setea las calles en la tabla
  let bloquesEnMapa = new Set(state.bloquesEnMapa);
  let nuevos_bloques = action.data.filter(x => !bloquesEnMapa.has(x));
  window.nub = nuevos_bloques;
  let datosTabla = mapearDatosCalles(nuevos_bloques);

  return {
    ...state,
    bloques: nuevos_bloques,
    datosTabla: datosTabla,
    headerTabla: "calle"
  };
};

const fillCallesDeSalida = (state, action) => {
  let callesDeSalidaEnMapa = new Set(state.callesDeSalidaEnMapa);
  let nuevas_calles = action.data.filter(x => !callesDeSalidaEnMapa.has(x));
  let datosTabla = mapearDatosCallesDeSalida(nuevas_calles);

  return {
    ...state,
    callesDeSalida: nuevas_calles,
    datosTabla: datosTabla,
    headerTabla: "calles_de_salida"
  };
};

const fillMuelles = (state, action) => {
  let muellesEnMapa = new Set(state.muellesEnMapa);
  let nuevos_muelles = action.data.filter(x => !muellesEnMapa.has(x));
  let datosTabla = mapearDatosMuelles(nuevos_muelles);

  return {
    ...state,
    muelles: nuevos_muelles,
    datosTabla: datosTabla,
    headerTabla: "muelle"
  };
};

const addMuellesEnMapa = (state, action) => {
  let muelles_mapa = [...state.muellesEnMapa];
  let muelle = action.muelle;
  if (action.data_pos != null) {
    muelle.pos_x = action.data_pos.pos_x;
    muelle.pos_y = action.data_pos.pos_y;
    muelle.rotacion = action.data_pos.rotacion;
  }
  muelles_mapa.push(muelle);
  let new_state = removeMuelleDeTabla(state, muelle.id);
  return { ...new_state, muellesEnMapa: muelles_mapa };
};

const removeMuelleDeTabla = (state, muelle_id) => {
  let muelles_copia = [...state.datosTabla];
  let index_muelle = muelles_copia.map((x, index) => {
    if (x.id === muelle_id) {
      return index;
    }
  });
  muelles_copia.splice(index_muelle, 1);
  return { ...state, datosTabla: muelles_copia };
};

const addMuellesEnTabla = (state, action) => {
  let muelles = [...state.datosTabla];
  muelles.push(getDatosTabla(action.b));
  return { ...state, datosTabla: muelles };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BLOQUES_EN_MAPA:
      return addBloquesEnMapa(state, action);

    case actionTypes.ADD_BLOQUES_EN_TABLA:
      return addBloquesEnTabla(state, action);

    case actionTypes.ADD_MUELLES_EN_MAPA:
      return addMuellesEnMapa(state, action);

    case actionTypes.ADD_MUELLES_EN_TABLA:
      return addMuellesEnTabla(state, action);

    case actionTypes.REMOVER_BLOQUE_DE_TABLA:
      return removerBloqueDeTabla(state, action);

    case actionTypes.REMOVER_BLOQUE_DE_MAPA:
      return removerBloqueDeMapa(state, action);

    case actionTypes.ROTATE_ELEMENT_HANDLER:
      return rotateElementHandler(state, action);

    case actionTypes.DESELECT_ALL_SPATIAL_OBJECTS:
      return deselectAllSpatialObjects(state, action);

    case actionTypes.TOGGLE_ALL_SPATIAL_OBJECTS:
      return toggleAllSpatialObjects(state, action);

    case actionTypes.HIDE_REGLA_HANDLER:
      return hideReglaHandler(state, action);

    case actionTypes.FILL_CALLES:
      return fillCalles(state, action);

    case actionTypes.FILL_CALLES_DE_SALIDA:
      return fillCallesDeSalida(state, action);

    case actionTypes.FILL_MUELLES:
      return fillMuelles(state, action);

    case actionTypes.DATA_IS_LOADED:
      return dataIsLoaded(state, action);

    case actionTypes.SET_LOADED_STATUS:
      return setLoadedStatus(state, action);

    default:
      return state;
  }
};

export default reducer;
