//COMPONENTES GENERALES
import React from "react";
import Database from "variables/Database.js";

//COMPONENTES LOCALES
import { localization } from "variables/general.js";
import { toast } from "react-toastify";

//ESTILOS Y COLORES
import { makeStyles } from "@material-ui/core/styles";

//CONTENEDORES
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  root: {
    width: "90%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  closeButton: {
    position: "absolute",
    right: "0.5em",
    top: "0.5em",
    color: "grey"
  }
}));

const columns = [
  { title: "Nombre", field: "descripcion" },
  { title: "Capacidad", field: "capacidad" }
];

export default function ModalSeleccionarCalleSalida(props) {
  const classes = useStyles();
  // const [orderForm, setOrderForm] = React.useState({
  //     cantidad: {
  //         elementType: 'input',
  //         elementConfig: {
  //             type: 'number',
  //             label: 'Cantidad',
  //             fullWidth: true,
  //             inputProps: {
  //                 autoFocus: true
  //             }
  //         },
  //         value: '',
  //         validation: {
  //             required: true,
  //             mayor0: true,

  //         },
  //         valid: false,
  //         touched: false
  //     }
  // });
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    setIsLoading(true);
    Database.get(
      "/list-calle-salida/" + process.env.REACT_APP_ID_ALMACEN,
      null,
      props
    ).then(
      res => {
        setIsLoading(false);

        let resultado = [...res.result];
        setItems(resultado);
      },
      err => {
        setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  return (
    <Dialog
      open={props.openDialog}
      onClose={props.closeDialog}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Seleccionar Calle Salida
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.closeDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className={classes.root}>
          <MaterialTable
            isLoading={isLoading}
            columns={columns}
            data={items}
            title="Calles"
            localization={localization}
            onRowClick={(event, rowData) => {
              props.onClick(rowData);
            }}
            components={{
              Container: props => <Paper elevation={0} {...props} />
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
