/* eslint-disable */
import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Route, Switch, Link } from "react-router-dom";
// core components
import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";
//import ModalNuevaUbicacion from "./ModalNuevaUbicacion";
import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import ModalDelete from "./ModalDelete";
import EditContenedor from "./EditContenedor.js";
import { StateListContenedores, ColumnsListado } from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Ajuste extends Component {
  state = { ...StateListContenedores };

  componentDidMount() {
    this.getContenedoresAdmin();
  }

  handleToggle = value => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    let deleteEnabled = false;
    let editEnabled = false;
    const botonesAcc = { ...this.state.botonesAcciones };
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) {
      deleteEnabled = true;
      if (newChecked.length == 1) editEnabled = true;
    }
    botonesAcc.editar.enabled = editEnabled;
    botonesAcc.delete.enabled = deleteEnabled;
    this.setState({
      botonesAcciones: botonesAcc,
      checked: newChecked
    });
  };

  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE CONTENEDORES
  ////////////////////////
  ////////////////////////
  getContenedoresAdmin = () => {
    this.setState({
      isLoading: true
    });

    //alert(process.env.REACT_APP_ID_ALMACEN);

    Database.get(
      "/list-contenedores/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];

        console.log(resultado);

        resultado = resultado.map(elem => {
          return {
            ...elem,
            vencimiento: moment(elem.vencimiento).isValid()
              ? moment.parseZone(elem.vencimiento).format("DD/MM/YYYY")
              : ""
          };
        });

        this.setState({
          isLoading: false,
          contenedores: resultado,
          checked: [],
          menuContext: null
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleDeleteContenedor = rowData => {
    //alert(rowData.id);
    Database.post(
      "/delete-contenedor",
      { id_contenedor: rowData.id },
      this
    ).then(
      res => {
        let contenedores = [...this.state.contenedores];
        contenedores = contenedores.filter(elem => {
          if (elem.id == rowData.id) return false;

          return true;
        });

        this.setState(
          {
            contenedores: contenedores,
            openDeleteDialog: false
          },
          () => {
            toast.success("El pallet se ha eliminado con exito!");
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    });
  }

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }
    return [
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Ajustes</h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Pallets
              </p>
            </CardHeader>
            <CardBody>
              {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevomovimiento')} color="primary"><AddIcon /> Nueva Movimiento</Button> */}
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListado}
                data={this.state.contenedores}
                title=""
                // onRowClick={this.handleRowClick}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar Pallet",
                    onClick: (event, rowData) =>
                      this.props.history.push(
                        this.props.match.url + "/editarcontenedor/" + rowData.id
                      )
                  },
                  {
                    icon: "delete",
                    tooltip: "Eliminar Pallet",
                    onClick: (event, rowData) =>
                      this.handleDeleteButton(rowData)
                  }
                ]}
                localization={localization}
                components={{
                  Container: props => <Paper elevation={0} {...props} />
                }}
                options={{
                  filtering: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: "Ajustes " + moment().format("DD-MM-YYYY"),
                  exportDelimiter: ";",
                  headerStyle: {
                    backgroundColor: lightGreen[700],
                    color: "#FFF"
                  }
                }}
              />
            </CardBody>
          </Card>

          <Route
            path={this.props.match.url + "/editarcontenedor/:idcontenedor"}
            render={() => (
              <EditContenedor
                orderForm={this.state.editSectorForm}
                handleSubmitEditSector={event => {
                  this.handleSubmitEditSector(event);
                }}
                inputEditChangedHandler={(event, inputIdentifier) =>
                  this.inputEditChangedHandler(event, inputIdentifier)
                }
                getUserEdit={id => {
                  this.getUserEdit(id);
                }}
                getContenedoresAdmin={() => this.getContenedoresAdmin()}
              />
            )}
          />
        </GridItem>
        <ModalDelete
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={rowData => this.handleDeleteContenedor(rowData)}
        />
      </GridContainer>
    ];
  }
}

export default withStyles(styles)(Ajuste);
