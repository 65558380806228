import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "white"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#FFFFFF",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: "300",
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  titulo: {
    padding: theme.spacing(3),
    // marginTop: '10px',
    margin: "10px 10px 10px 10px",
    fontWeight: "300",
    fontSize: "150%"
  },
  demo2: {
    backgroundColor: "#283593",
    color: "white",
    fontWeight: "300",
    fontSize: "110%",
    margin: "2em",
    paddingLeft: "1em",
    paddingBottom: "1em"
  }
}));

const CustomizedTabs = props => {
  const classes = useStyles();

  const [selectedTab, setTab] = React.useState(0);

  const handleChange = (event, newSelectedTab) => {
    setTab(newSelectedTab);
    props.resetForm(newSelectedTab);
  };

  let selectedTabValue = selectedTab;
  props.setSelectedTab(selectedTabValue);

  return (
    <div>
      <div className={classes.demo2}>
        <Typography className={classes.titulo}>SALIDAS </Typography>
        <StyledTabs
          value={selectedTabValue}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label={props.labeltab1} />
          <StyledTab label={props.labeltab2} />
          <StyledTab
            label={props.labeltab3}
            onClick={() => props.resetFormPedido()}
          />
        </StyledTabs>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedTabProp: state.salidas.selectedTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedTab: selectedTabValue =>
      dispatch(actionCreators.setSelectedTab(selectedTabValue)),
    resetForm: selectedTab => dispatch(actionCreators.resetForm(selectedTab)),
    resetFormPedido: () => dispatch(actionCreators.resetFormPedido())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedTabs);
