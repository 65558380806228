export const creaSecuenciaComoLista = (inicio, n) => {
  let res = [];
  let i = 0;
  for (i = inicio; i <= n; i++) {
    res.push(i);
  }
  return res;
};

export const agregaCerosAlNombre3Cifras = texto_in => {
  let resText = "";
  let ceros_delante = "";
  while (resText.length < 3) {
    resText = ceros_delante + texto_in;
    ceros_delante = ceros_delante + "0";
  }
  return resText;
};

export const divideEnListasParesEImpares = lista_in => {
  let listas = [];
  let listaPares = [];
  let listaImpares = [];
  let i = 0;
  for (i = 0; i < lista_in.length; i++) {
    if (lista_in[i] % 2 === 0) {
      const valorString = String(lista_in[i]);
      let nombreString = agregaCerosAlNombre3Cifras(valorString);
      listaPares.push(nombreString);
    } else {
      const valorString = String(lista_in[i]);
      let nombreString = agregaCerosAlNombre3Cifras(valorString);
      listaImpares.push(nombreString);
    }
  }
  listas[0] = listaPares;
  listas[1] = listaImpares;
  return listas;
};

export const indexIzqDer = indexIn => {
  let izqDer = "";
  if (indexIn % 2 === 0) {
    izqDer = "izquierda";
  } else {
    izqDer = "derecha";
  }
  return izqDer;
};

export const calculaColPorLado = numCol => {
  // calcula cuantas columnas hay en cada bloque de columnas
  // segun se haya dado de alta un numero de columnas par o impar
  let col_por_lado = {};
  if (numCol % 2 === 0) {
    col_por_lado["bloqueColImpar"] = numCol / 2;
    col_por_lado["bloqueColPar"] = numCol / 2;
  } else {
    numCol = numCol + 1;
    col_por_lado["bloqueColImpar"] = numCol / 2;
    col_por_lado["bloqueColPar"] = col_por_lado["bloqueColImpar"] - 1;
  }
  return col_por_lado;
};

export const creaNombresColumnas = cantCol => {
  let colList = [];
  let resCreaListParImpar = [];
  colList = creaSecuenciaComoLista(1, cantCol);
  resCreaListParImpar = divideEnListasParesEImpares(colList);
  return resCreaListParImpar;
};

export const canvasMetrosAPixeles = (
  canvasHeightMeters,
  canvasWidthMeters
) => {};

export const esHorizontal = el => {
  return (
    document.getElementById(el).offsetWidth >=
    document.getElementById(el).offsetHeight
  );
};

export const getPosicionX = el => {
  if (document.getElementById(el) != null) {
    return document.getElementById(el).getAttribute("data-x");
  }
  return 0;
};

export const getPosicionY = el => {
  if (document.getElementById(el) != null) {
    return document.getElementById(el).getAttribute("data-y");
  }
  return 0;
};

export const clonar = el => {
  return document.getElementById(el).cloneNode();
};

// dimensiones almacen
// 1 metro real equivale a 10 px

export const deMetrosAPixeles = metros_in => {
  return metros_in * 15;
};

export const dePixelesAMetros = px_in => {
  return px_in / 15;
};

export const getCurrentScale = () => {
  let clase = document.getElementsByClassName("react-transform-element");
  let strTransform = clase[0].style.transform;
  let indexInf = strTransform.indexOf("scale(");
  let parteScale = strTransform.substring(indexInf);
  let indexSup = parteScale.indexOf(")");
  let result = parteScale.slice(6, indexSup);
  return result;
};
