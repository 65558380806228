import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { StateEditCliente } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditCliente extends Component {
  state = JSON.parse(JSON.stringify(StateEditCliente));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  getClienteEdit = id => {
    Database.get("/list-clientes/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          clienteEdit: resultado.result[0]
        });

        let editClienteFormAlt = { ...this.state.editClienteForm };
        editClienteFormAlt.ruc.value = resultado.result[0].ruc;
        editClienteFormAlt.telefono.value = resultado.result[0].telefono;
        editClienteFormAlt.numero_cliente.value =
          resultado.result[0].numero_cliente;
        editClienteFormAlt.razon_social.value =
          resultado.result[0].razon_social;
        editClienteFormAlt.nombre_fantasia.value =
          resultado.result[0].nombre_fantasia;
        for (let key in editClienteFormAlt) {
          editClienteFormAlt[key].touched = true;
          editClienteFormAlt[key].valid = true;
        }

        this.setState({
          editClienteForm: editClienteFormAlt
        });
        // this.getClientesType("edit", editClienteFormAlt);
      } else {
        this.setState({
          clienteEdit: null
        });
      }
    });
  };

  handleChangePass = event => {
    event.preventDefault();
    this.setState({
      openChangePass: false
    });

    Database.post(`/update-tocken`, {
      id: this.props.match.params.idcliente,
      newtocken: event.target.contrasenia.value
    }).then(
      res => {
        toast.success("El Cliente se ha modificado con exito!");
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitEditCliente = event => {
    event.preventDefault();

    Database.post(`/update-cliente`, {
      id: this.props.match.params.idcliente,
      razon_social: this.state.editClienteForm.razon_social.value,
      nombre_fantasia: this.state.editClienteForm.nombre_fantasia.value,
      numero_cliente: this.state.editClienteForm.numero_cliente.value,
      ruc: this.state.editClienteForm.ruc.value,
      telefono: this.state.editClienteForm.telefono.value
    }).then(
      res => {
        this.setState(
          {
            successSubmitEdit: true,
            editFormIsValid: false,
            disableAllButtons: false
          },
          () => {
            toast.success("El Cliente se ha modificado con exito!");

            this.props.getClientesAdmin();
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editClienteForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      editClienteForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  resetEditForm = () => {
    let editClienteFormAlt = { ...this.state.editClienteForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in editClienteFormAlt) {
      editClienteFormAlt[key].value = "";
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    });
  };

  componentDidMount() {
    // this.getClientesType();
    this.getClienteEdit(this.props.match.params.idcliente);
  }

  render() {
    const formElementsArray = [];
    for (let key in this.state.editClienteForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editClienteForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitEditCliente(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Editar Cliente
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del cliente
            </p>
          </CardHeader>
          <CardBody>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            >
              Cambiar Token
            </Button>

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"editcliente-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={event =>
                    this.inputEditChangedHandler(event, formElement.id)
                  }
                />
              ))}
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/clientes")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={
                !this.state.editFormIsValid || this.state.disableAllButtons
              }
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,

      <Dialog
        open={this.state.openChangePass}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cambio de Token</DialogTitle>
        <form
          onSubmit={event => {
            this.handleChangePass(event);
          }}
        >
          {this.state.openChangePass && (
            <DialogContent>
              <DialogContentText>
                Ingrese un nuevo token para el Cliente
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="contrasenia"
                name="contrasenia"
                label="nuevo token"
                type="password"
                fullWidth
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(EditCliente));
