import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// Styles
import { withStyles } from "@material-ui/styles";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// modal confirm

const classes = {
  radio_custom: {
    margin: "2em",
    paddingLeft: "1em",
    paddingBottom: "1em",
    color: "black",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    marginLeft: "20px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

let previous_radio_button = null;

const PedidoRadioGroup = props => {
  const [radioButtonOption, setValue] = React.useState("pedido");

  const handleChange = event => {
    if (event.target.value === "pedido") {
      previous_radio_button = "nopedido";
    } else {
      previous_radio_button = "pedido";
    }

    event.preventDefault();
    setValue(event.target.value);

    if (props.contenedoresEnLoteDeSalida.length > 0) {
      props.setSePerderanContenedoresSeleccionados(true);
      setValue(previous_radio_button);
    }
  };

  const openModalDescartarContenedores = () => {
    props.setModalVisibility(true, "confirm");
  };

  let selectedRadioButton = radioButtonOption;
  props.setSelectedRadioButton(selectedRadioButton);

  return (
    <FormControl className={props.classes.radio_custom} component="fieldset">
      <RadioGroup row value={selectedRadioButton} onChange={handleChange}>
        {props.contenedoresEnLoteDeSalida.length === 0 ? (
          <FormControlLabel
            value="pedido"
            onClick={() => props.resetFormPedido()}
            control={<Radio />}
            label="Crear pedido"
          />
        ) : (
          <FormControlLabel
            value="pedido"
            onClick={() => openModalDescartarContenedores()}
            control={<Radio />}
            label="Crear pedido"
          />
        )}
        {props.contenedoresEnLoteDeSalida.length === 0 ? (
          <FormControlLabel
            value="nopedido"
            onClick={() => props.setSelectedClientToCero()}
            control={<Radio />}
            label={props.label_no_pedido}
          />
        ) : (
          <FormControlLabel
            value="nopedido"
            onClick={() => openModalDescartarContenedores()}
            control={<Radio />}
            label="Dar salida a pallets por fuera de un pedido"
          />
        )}
      </RadioGroup>
    </FormControl>
  );
};

const mapStateToProps = state => {
  return {
    selectedRadioButtonProp: state.pedidossalidas.selectedRadioButton,
    idSelectedClient: state.pedidossalidas.idSelectedClient,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRadioButton: selectedRadioButton =>
      dispatch(actionCreators.setSelectedRadioButton(selectedRadioButton)),
    setSelectedClientToCero: () =>
      dispatch(actionCreators.setSelectedClientToCero()),
    resetFormPedido: () => dispatch(actionCreators.resetFormPedido()),
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal)),
    setSePerderanContenedoresSeleccionados: bool_value =>
      dispatch(
        actionCreators.setSePerderanContenedoresSeleccionados(bool_value)
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classes)(PedidoRadioGroup));
