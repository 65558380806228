import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

// core components
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import {
  StateListAuditoriaAjustes,
  ColumnsListadoAjustes
} from "./VariablesState.js";

import lightGreen from "@material-ui/core/colors/lightGreen";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class AuditoriaAjustes extends Component {
  state = { ...StateListAuditoriaAjustes };

  componentDidMount() {
    this.getAuditoriaAjustesAdmin();
  }

  getAuditoriaAjustesAdmin = () => {
    this.setState({
      isLoading: true
    });

    Database.get("/list-auditoriaajustes", this, null, true).then(
      res => {
        let resultado = [...res.result[0]];

        console.log(resultado);
        resultado = resultado.map(elem => {
          return {
            ...elem,
            //  vencimiento_mostrar: moment(elem.vencimiento).isValid() ? moment(elem.vencimiento).format("DD/MM/YYYY hh:mm:ss") : "",
            vencimiento_mostrar: moment(elem.vencimiento).isValid()
              ? moment.parseZone(elem.vencimiento).format("DD/MM/YYYY")
              : "",
            fecha_mostrar: moment(elem.fecha).isValid()
              ? moment(elem.fecha).format("DD/MM/YYYY hh:mm:ss")
              : ""
          };
        });

        this.setState({
          isLoading: false,
          auditoriaajustes: resultado,
          checked: [],
          menuContext: null,
          botonesAcciones: {
            nuevo: {
              enabled: true,
              texto: "Nuevo"
            },
            editar: {
              enabled: false,
              texto: "Editar"
            },
            delete: {
              enabled: false,
              texto: "Eliminar"
            }
          }
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  render() {
    let style = {};
    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: "none" };
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>
                Auditoria Ajustes
              </h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Ajustes
              </p>
            </CardHeader>
            <CardBody>
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListadoAjustes}
                data={this.state.auditoriaajustes}
                title=""
                localization={localization}
                components={{
                  Container: props => <Paper elevation={0} {...props} />
                }}
                options={{
                  filtering: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName:
                    "AuditoriaAjustes " + moment().format("DD-MM-YYYY"),
                  exportDelimiter: ";",
                  pageSizeOptions: [5, 10, 20, 50, 100, 200, 400, 800],
                  pageSize: 50,
                  emptyRowsWhenPaging: false,
                  headerStyle: {
                    backgroundColor: lightGreen[700],
                    color: "#FFF"
                  }
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(AuditoriaAjustes);
