/* eslint-disable */
import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Route, Switch, Link } from "react-router-dom";
// core components
import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import { StateListUbicaciones, ColumnsListado } from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

const styles = {
  rowTable: {
    backgroundColor: "#DEFFDF"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

class Ubicaciones extends Component {
  state = { ...StateListUbicaciones };

  componentDidMount() {
    this.getUbicacionesAdmin();
  }

  handleToggle = value => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    let deleteEnabled = false;
    let editEnabled = false;
    const botonesAcc = { ...this.state.botonesAcciones };
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) {
      deleteEnabled = true;
      if (newChecked.length == 1) editEnabled = true;
    }
    botonesAcc.editar.enabled = editEnabled;
    botonesAcc.delete.enabled = deleteEnabled;
    this.setState({
      botonesAcciones: botonesAcc,
      checked: newChecked
    });
  };

  menuHandleClose = value => {
    this.setState({
      menuContext: null
    });
  };

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    });
  };
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE UBICACIONES
  ////////////////////////
  ////////////////////////
  getUbicacionesAdmin = () => {
    this.setState({
      isLoading: true
    });

    //alert(process.env.REACT_APP_ID_ALMACEN);

    Database.get(
      "/list-ubicaciones/" + process.env.REACT_APP_ID_ALMACEN,
      this
    ).then(
      res => {
        let resultado = [...res.result];
        this.setState({
          isLoading: false,
          ubicaciones: resultado,
          checked: [],
          menuContext: null
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };
  handleChangeTab = (event, newValue) => {
    this.setState({ tabSelect: newValue });
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Ubicaciones</h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Ubicaciones
              </p>
            </CardHeader>
            <CardBody>
              {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevoubicacion')} color="primary"><AddIcon /> Nueva Ubicacion</Button> */}
              <Paper>
                <Tabs
                  value={this.state.tabSelect}
                  onChange={this.handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Ubicaciones" />
                </Tabs>
              </Paper>

              <TabPanel value={this.state.tabSelect} index={0}>
                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListado}
                  data={this.state.ubicaciones}
                  title=""
                  localization={localization}
                  components={{
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  detailPanel={rowData => {
                    return (
                      <div>
                        {rowData.id_ocupacion == 3 && (
                          <div>
                            <h5>Datos del Pallet</h5>
                            <p>
                              número:<strong>{rowData.contenedor_id}</strong>
                              <br />
                              Producto Unidad:
                              <strong>{rowData.producto_unidad_nombre}</strong>
                              <br />
                              Producto Presentacion:
                              <strong>
                                {rowData.producto_presentacion_descripcion}
                              </strong>
                              <br />
                              cantidad de unidades:
                              <strong>
                                {rowData.contenedor_cantidad_unidades}
                              </strong>
                              <br />
                              cantidad de bultos:
                              <strong>
                                {rowData.contenedor_cantidad_bultos}
                              </strong>
                              <br />
                              cliente:
                              <strong>{rowData.cliente_razon_social}</strong>
                              <br />
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  }}
                  components={{
                    Row: props => {
                      let class_alt = null;
                      if (props.data.id_ocupacion == 3)
                        class_alt = this.props.classes.rowTable;
                      return <MTableBodyRow className={class_alt} {...props} />;
                    },
                    Container: props => <Paper elevation={0} {...props} />
                  }}
                  options={{
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName:
                      "Ubicaciones " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: lightGreen[700],
                      color: "#FFF"
                    }
                  }}
                />
              </TabPanel>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Ubicaciones);
