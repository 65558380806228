import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: "10px",
    marginTop: "10px",
    backgroundColor: "rgba(0,0,0,0)",
    background: "rgba(0,0,0,0)"
  },
  texto: {
    scale: 1,
    fontSize: "small"
  }
}));

export default function Titulo(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            elevation={props.elevation ? props.elevation : 0}
            className={classes.paper}
          >
            <Typography variant="h4" className={classes.texto}>
              {props.titulo}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
