import React from "react";
// Material
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// DB
import Database from "variables/Database.js";
import { toast } from "react-toastify";

const handleAccept = props => {
  props.setModalVisibility(false, "confirm");
  if (
    props.selectedTab === 0 &&
    props.selectedRadioButton === "nopedido" &&
    !props.sePerderanContenedoresSeleccionados
  ) {
    moverLoteACalleDeSalida(props);
  } else if (
    props.selectedTab === 0 &&
    props.selectedRadioButton === "pedido" &&
    !props.sePerderanContenedoresSeleccionados
  ) {
    moverLoteACalleDeSalida(props);
  } else if (props.selectedTab === 2) {
    darSalidaAlote(props);
  } else if (props.sePerderanContenedoresSeleccionados) {
    descartarContenedoresSeleccionados(props);
  }
};

const moverLoteACalleDeSalida = props => {
  let contenedores_ids = props.contenedoresEnLoteDeSalida.map(contenedor => {
    return contenedor.id;
  });

  let calles_de_salida = contenedores_ids.map(() => {
    return props.calle_de_salida.id;
  });

  let clientes = props.contenedoresEnLoteDeSalida.map(contenedor => {
    return contenedor.id_cliente;
  });

  let db_data = { ids: contenedores_ids, calles_de_salida: calles_de_salida };
  let datos_pedido = getDatosPedido(props, clientes);

  Database.post(
    `/mover-lote-a-salida`,
    { db_data: db_data, pedido: datos_pedido },
    this
  ).then(
    res => {
      props.resetForm(0);
      toast.success("Se movieron los contenedores a la calle de salida!");
    },
    err => {
      toast.error(err.message);
    }
  );
};

const getDatosPedido = (props, clientes) => {
  if (props.selectedRadioButton === "pedido") {
    let pedido = {};
    pedido["referencia"] = props.referenciaPedido;
    pedido["clientes"] = clientes;
    // pedido['id_cliente'] = props.idSelectedClient;
    return pedido;
  } else {
    return null;
  }
};

const darSalidaAlote = props => {
  let contenedoresEnLoteSalida = props.contenedoresEnLoteDeSalida;
  // let ids = props.contenedoresEnLoteDeSalida.map( (contenedor) => {
  //   return contenedor.id
  // })
  Database.post(
    `/contenedores-salida`,
    { contenedoresEnLoteSalida },
    this
  ).then(
    res => {
      toast.success("Salida exitosa!");
      props.resetForm(2);
    },
    err => {
      toast.error(err.message);
    }
  );
};

const descartarContenedoresSeleccionados = props => {
  props.setSePerderanContenedoresSeleccionados(false);
  props.resetForm(0);
  if (props.selectedRadioButton === "pedido") {
    props.resetFormPedido();
  } else {
    props.setSelectedClientToCero();
  }
};

const handle_NO_Descartar_contenedores_seleccionados = (event, props) => {
  // solo para el caso de no descartar pedidos seleccionados
  props.setModalVisibility(false, "confirm");
};

function ConfirmDialog(props) {
  return (
    <div>
      <Dialog
        open={props.confirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.sePerderanContenedoresSeleccionados ? (
            <Button
              onClick={event =>
                handle_NO_Descartar_contenedores_seleccionados(event, props)
              }
              color="primary"
            >
              {props.text_no ? props.text_no : `NO`}
            </Button>
          ) : (
            <Button
              onClick={() => props.setModalVisibility(false, "confirm")}
              color="primary"
            >
              {props.text_no ? props.text_no : `NO`}
            </Button>
          )}
          <Button onClick={() => handleAccept(props)} color="primary" autoFocus>
            {props.text_yes ? props.text_no : `SI`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    confirmDialog: state.salidas.confirmDialog,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    calle_de_salida: state.salidas.calle_de_salida,
    selectedTab: state.salidas.selectedTab,
    sePerderanContenedoresSeleccionados:
      state.salidas.sePerderanContenedoresSeleccionados,
    selectedCliente: state.pedidossalidas.selectedCliente,
    hasSelectedClient: state.pedidossalidas.hasSelectedClient,
    idSelectedClient: state.pedidossalidas.idSelectedClient,
    referenciaPedido: state.pedidossalidas.referenciaPedido,
    selectedRadioButton: state.pedidossalidas.selectedRadioButton,
    id_pedido: state.pedidossalidas.id_pedido
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setModalVisibility: (status, modal) =>
      dispatch(actionCreators.setModalVisibility(status, modal)),
    resetForm: selected_tab => dispatch(actionCreators.resetForm(selected_tab)),
    resetFormPedido: () => dispatch(actionCreators.resetFormPedido()),
    reloadTable: () => dispatch(actionCreators.reloadTable()),
    setSePerderanContenedoresSeleccionados: () =>
      dispatch(actionCreators.setSePerderanContenedoresSeleccionados()),
    changeRadioButtonPedido: () =>
      dispatch(actionCreators.changeRadioButtonPedido()),
    setSelectedClientToCero: () =>
      dispatch(actionCreators.setSelectedClientToCero())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
