export const StateListOrdenesTrabajo = {
  ordenestrabajo: [],
  offset: 0,
  // checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  disableAllButtons: false,
  isLoading: false
};

export const StateListOrdenesTrabajoProductos = {
  ordenestrabajoproductos: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditOrdenTrabajo = {
  EditOrdenTrabajoForm: {
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Descripción",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  ordentrabajoEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewOrdenTrabajo = {
  // fechaVencimientoLinti: null,
  orderForm: {
    descripcion: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Referencia",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    destino: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Destino",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  successSubmit: null,
  formIsValid: false,
  disableAllButtons: false,
  openDialogProducto: false,
  openDialogCliente: false,
  openDialogUnidad: false,
  openDialogPresentacion: false,
  rowProductoPresentacion: null,
  rowEditProducto: null,
  detalleordenestrabajo: [],
  infoCliente: null
};

export const StateOrdenesTrabajo = {
  orderForm: {
    referencia: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Referencia",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    id_muelle: {
      elementType: "select",
      elementConfig: {
        label: "Muelle de Entrada",
        options: [],
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },

      valid: false,
      touched: false
    },
    devolucion: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Es devolución",
        fullWidth: true
      },
      value: 0,
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    comentario: {
      elementType: "textarea",
      elementConfig: {
        label: "Comentarios",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },

  formIsValid: false,
  disableAllButtons: false,
  openDialogProducto: false,
  openDialogCliente: false,
  openDialogUnidad: false,
  openDialogPresentacion: false,
  rowProductoPresentacion: null,
  rowEditProducto: null,
  detalleordenestrabajo: [],
  infoCliente: null
};

export const ColumnsListado = [
  { title: "Numero", field: "id" },
  { title: "Fecha Creacion", field: "fecha_mostrar" },
  { title: "N° de Pedidos Procesados", field: "id_pedido" },
  { title: "Estado", field: "estado" }
];

export const ColumnsListadoPedidos = [
  { title: "Numero", field: "id" },
  { title: "Referencia", field: "descripcion" },
  { title: "Cliente", field: "razon_social" },
  { title: "Destino", field: "destino" },
  { title: "Fecha", field: "fecha_mostrar" },
  { title: "Estado", field: "estado" }
];

export const ColumnsListadoOrdenTrabajoProductos = [
  { title: "Producto Presentacion", field: "producto" },
  { title: "Cantidad Pallets", field: "cantidad_contenedores" },
  { title: "Cantidad Bultos", field: "cantidad_bultos" },
  { title: "Cantidad Displays", field: "cantidad_displays" },
  { title: "Cantidad Unidades", field: "cantidad_unidades" },
  { title: "Estado", field: "estado" }
];

export const ColumnsListadoProductos = [
  { title: "Dato Logístico", field: "presentacion.descripcion" },
  { title: "Articulo", field: "presentacion.ProductosUnidad.descripcion" },
  { title: "EAN13", field: "presentacion.ProductosUnidad.ean13" },
  { title: "Cod. Artículo", field: "presentacion.ProductosUnidad.codigo" },
  { title: "Pallets", field: "cantidad_contenedores" },
  { title: "Bultos", field: "cantidad_bultos" },
  { title: "Displays", field: "cantidad_displays" },
  { title: "Unidades", field: "cantidad_unidades" }

  // {
  //     title: "Vencimiento", field: "vencimiento_mostrar", customSort: (a, b) => parseInt(moment(a.vencimiento).format("YYYYMMDD")) - parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  // }

  //{ title: 'Cantidad', field: 'cantidad', render: rowData => <input type="text"/>}
];
