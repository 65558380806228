import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import MaterialTable from "material-table";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ButtonSmall from "@material-ui/core/Button";
import ModalConfirm from "./ModalConfirmar.js";

import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";

import { localization } from "variables/general.js";
import ModalSeleccionarTrabajador from "../../OrdenesTrabajo/components/ModalSeleccionarTrabajador.js";
import ModalSeleccionarCalleSalida from "./ModalSeleccionarCalleSalida.js";

import { toast } from "react-toastify";
import Save from "@material-ui/icons/Save";

import {
  StateListOrdenesPickingProductos,
  ColumnsListadoOrdenPickingProductos
} from "../VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

const styles = {
  cardCategoryWhite: {
    "&, & a": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px"
    },
    "& a:hover, & a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "3px",
    "& small": {
      color: "#777",
      fontSize: "65%",
      lineHeight: "1"
    }
  },
  centeredText: {
    textAlign: "center"
  },
  strongText: {
    color: "#546e7a",
    padding: "10px"
  },
  buttonFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px"
  },
  mainButtonStyle: {
    backgroundColor: "#546e7a",
    color: "#fff",
    margin: "0 3px",
    fontSize: "0.85em"
  }
};

class OrdenPickingProductos extends Component {
  state = {
    ...StateListOrdenesPickingProductos,
    currentEdit: null,
    greyedRows: [],
    previousTogglePanel: null
  };

  getCorrectKey = type => {
    switch (type) {
      case "bultos":
        return "cantidad_bultos_cumplidos";
      case "displays":
        return "cantidad_displays_cumplidos";
      case "unidades":
        return "cantidad_unidades_cumplidas";
      default:
        return "";
    }
  };

  increment = (type, rowData) => {
    const key = this.getCorrectKey(type);
    const index = this.state.ordenespickingproductos.indexOf(rowData);

    if (rowData[key] < rowData[`cantidad_${type}`]) {
      const newItem = {
        ...rowData,
        [key]: rowData[key] + 1
      };

      this.setState(prevState => ({
        ordenespickingproductos: [
          ...prevState.ordenespickingproductos.slice(0, index),
          newItem,
          ...prevState.ordenespickingproductos.slice(index + 1)
        ]
      }));
    } else {
      toast.error(
        `La cantidad de ${type} cumplidos no puede ser mayor a la cantidad de ${type} solicitados`
      );
    }
  };

  decrement = (type, rowData) => {
    const key = this.getCorrectKey(type);
    const index = this.state.ordenespickingproductos.indexOf(rowData);

    if (rowData[key] > 0) {
      const newItem = {
        ...rowData,
        [key]: rowData[key] - 1
      };

      this.setState(prevState => ({
        ordenespickingproductos: [
          ...prevState.ordenespickingproductos.slice(0, index),
          newItem,
          ...prevState.ordenespickingproductos.slice(index + 1)
        ]
      }));
    } else {
      toast.error(`La cantidad de ${type} cumplidos no puede ser menor a cero`);
    }
  };

  componentDidMount() {
    this.getOrdenesPickingProductosAdmin();
  }

  menuHandleClose = value => {
    this.setState({
      menuContext: null
    });
  };

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    });
  };

  getOrdenesPickingProductosAdmin = () => {
    this.setState({
      isLoading: true
    });

    console.log(window.location.pathname);

    var url = window.location.pathname.split("/");

    //Database.get('/list-entrada-detalle/' + this.props.idEntrada ,this).then(res => {

    Database.get(
      "/list-ordenespicking-productos/" +
        process.env.REACT_APP_ID_ALMACEN +
        "/" +
        url[4],
      this,
      null,
      true
    )
      .then(
        res => {
          let resultado = [...res.productos];

          let extra = [...res.extra];

          if (!Array.isArray(extra) || !extra.length) {
          } else {
            //si el array no esta vacio
            resultado = resultado.map(elem => {
              let index = extra.findIndex(
                v =>
                  v.id_producto_presentacion == elem.id_producto_presentacion &&
                  v.id_pedido == elem.id_pedido
              );
              return {
                ...elem,
                cantidad_unidades_cumplidas:
                  extra[index].cantidad_unidades_cumplidas,
                cantidad_displays_cumplidos:
                  extra[index].cantidad_displays_cumplidos,
                cantidad_bultos_cumplidos:
                  extra[index].cantidad_bultos_cumplidos,
                estado: extra[index].estado
              };
            });
          }

          resultado = resultado.map(elem => {
            return {
              ...elem,
              old_cantidad_unidades_cumplidas: elem.cantidad_unidades_cumplidas,
              old_cantidad_displays_cumplidos: elem.cantidad_displays_cumplidos,
              old_cantidad_bultos_cumplidos: elem.cantidad_bultos_cumplidos
            };
          });

          //añadimos las estimaciones
          resultado = resultado.map(elem => {
            var cantidad_unidades_display =
              parseFloat(elem.cantidad_unidades_display) || null;
            var cantidad_unidades_bulto =
              parseFloat(elem.cantidad_unidades_bulto) || null;
            var cantidad_displays_bulto =
              parseFloat(elem.cantidad_displays_bulto) || null;

            var elem_cantidad_unidades = parseFloat(
              elem.cantidad_unidades_picking
            );

            //relleno de mayor a menor: uso la diferencia del redondeo menor para rellenar
            if (cantidad_displays_bulto !== null) {
              //hay display

              var cantidad_bultos_calculada =
                elem_cantidad_unidades /
                cantidad_unidades_display /
                cantidad_displays_bulto;
              var cantidad_bultos = Math.floor(cantidad_bultos_calculada);
              var restan_bultos = cantidad_bultos_calculada - cantidad_bultos; //0 o el sobrante

              if (restan_bultos > 0) {
                var cantidad_displays_calculada =
                  restan_bultos * cantidad_displays_bulto;
                var cantidad_displays = Math.floor(cantidad_displays_calculada);
                var restan_displays =
                  cantidad_displays_calculada - cantidad_displays;
              } else {
                cantidad_displays = 0;
              }

              if (restan_displays > 0) {
                var cantidad_unidades_calculada =
                  restan_displays * cantidad_unidades_display;
                var cantidad_unidades = Math.round(cantidad_unidades_calculada); //redondea para arriba o para abajo
              } else {
                cantidad_unidades = 0;
              }
            } else {
              //no hay display

              var cantidad_bultos_calculada =
                elem_cantidad_unidades / cantidad_unidades_bulto;
              var cantidad_bultos = Math.floor(cantidad_bultos_calculada);
              var restan_bultos = cantidad_bultos_calculada - cantidad_bultos; //0 o el sobrante

              if (restan_bultos > 0) {
                var cantidad_unidades_calculada =
                  restan_bultos * cantidad_unidades_bulto;
                var cantidad_unidades = Math.round(cantidad_unidades_calculada); //redondea para arriba o para abajo
              } else {
                cantidad_unidades = 0;
              }

              var cantidad_displays = "-"; //no aplica
            }

            return {
              ...elem,
              cantidad_bultos_picking: cantidad_bultos,
              cantidad_displays_picking: cantidad_displays,
              cantidad_unidades_picking: elem_cantidad_unidades
            };
          });

          if (resultado[0].id_trabajador !== null) {
            this.setState({ hay_trabajador: true });
          }

          if (resultado[0].id_calle_salida !== null) {
            this.setState({ hay_calle_salida: true });
          }

          if (resultado[0].edicion_bloqueada === 1) {
            this.setState({ edicion_bloqueada: true });
          } else {
            this.setState({ edicion_bloqueada: false });
          }

          var url = window.location.pathname.split("/");
          this.setState({ id_orden_picking: url[4] });

          //  let resultado = [...res.productos];
          console.log(resultado);
          this.setState({
            isLoading: false,
            ordenespickingproductos: resultado,
            checked: [],
            menuContext: null,
            botonesAcciones: {
              nuevo: {
                enabled: true,
                texto: "Nuevo"
              },
              editar: {
                enabled: false,
                texto: "Editar"
              },
              delete: {
                enabled: false,
                texto: "Eliminar"
              }
            }
          });
        },
        err => {
          toast.error(err.message);
        }
      )
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onClickItemTrabajador = rowData => {
    this.closeDialog();
    this.setState({
      infoTrabajador: rowData
    });
  };

  closeDialog() {
    this.setState({ openDialogTrabajador: false });
  }

  onClickItemCalleSalida = rowData => {
    this.closeDialogCalleSalida();
    this.setState({
      infoCalleSalida: rowData
    });
  };

  closeDialogCalleSalida() {
    this.setState({ openDialogCalleSalida: false });
  }

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  handleConfirmButton = rowData => {
    this.setState({
      openConfirmDialog: true,
      confirmRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openConfirmDialog: false,
      confirmRowData: null
    });
  }

  fill = rowData => {
    console.log("Fill method executed", rowData);

    if (this.state.currentEdit === rowData.tableData.id) {
      const index = this.state.ordenespickingproductos.indexOf(rowData);

      const newItem = {
        ...rowData,
        cantidad_bultos_cumplidos: parseInt(rowData.cantidad_bultos, 10),
        cantidad_displays_cumplidos:
          rowData.cantidad_displays === "-"
            ? parseInt(rowData.cantidad_displays_cumplidos, 10)
            : parseInt(rowData.cantidad_displays, 10),
        cantidad_unidades_cumplidas: parseInt(rowData.cantidad_unidades, 10)
      };

      this.setState(prevState => ({
        ordenespickingproductos: [
          ...prevState.ordenespickingproductos.slice(0, index),
          newItem,
          ...prevState.ordenespickingproductos.slice(index + 1)
        ]
      }));
    }
  };

  handleSave = event => {
    const {
      ordenespickingproductos,
      infoTrabajador,
      infoCalleSalida
    } = this.state;

    const rowData = ordenespickingproductos[this.state.currentEdit];
    const rowId = rowData.tableData.id;
    if (!rowData) {
      toast.error("No row selected for editing");
      return;
    }

    if (this.state.previousTogglePanel) {
      this.state.previousTogglePanel();
    }

    this.setState(prevState => ({
      greyedRows: [...prevState.greyedRows, rowId],
      currentEdit: null,
      previousTogglePanel: null
    }));

    Database.post(
      "/actualizar-ordenpicking",
      {
        productos: [rowData],
        id_trabajador: infoTrabajador?.id || null,
        id_calle_salida: infoCalleSalida?.id || null,
        id_almacen: process.env.REACT_APP_ID_ALMACEN,
        id_orden_picking: window.location.pathname.split("/")[4]
      },
      this
    )
      .then(res => {
        this.resetForm();
        this.props.getOrdenesPickingAdmin();
        this.getOrdenesPickingProductosAdmin();
        toast.success("Orden de Picking Actualizada");
        this.setState({ habilitaFinalizar: true });
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  handleSend = () => {
    const {
      ordenespickingproductos,
      infoTrabajador,
      infoCalleSalida,
    } = this.state;

    Database.post(
      "/actualizar-ordenpicking",
      {
        productos: ordenespickingproductos,
        id_trabajador: infoTrabajador?.id || null,
        id_calle_salida: infoCalleSalida?.id || null,
        id_almacen: process.env.REACT_APP_ID_ALMACEN,
        id_orden_picking: window.location.pathname.split("/")[4]
      },
      this
    )
      .then(res => {
        this.resetForm();
        this.props.getOrdenesPickingAdmin();
        this.getOrdenesPickingProductosAdmin();
        toast.success("Orden de Picking Actualizada");
        this.setState({ habilitaFinalizar: true });
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  handleInfo = () => {
    const {
      ordenespickingproductos,
      infoTrabajador,
      infoCalleSalida,
      hay_calle_salida,
      hay_trabajador
    } = this.state;

    if (!hay_calle_salida || !hay_trabajador) {
      if (!infoTrabajador || !infoCalleSalida) {
        return toast.error(
          "Seleccione un trabajador y una calle de salida para habilitar la orden de picking"
        );
      }
    }

    Database.post(
      "/actualizar-ordenpicking",
      {
        productos: ordenespickingproductos,
        id_trabajador: infoTrabajador?.id || null,
        id_calle_salida: infoCalleSalida?.id || null,
        id_almacen: process.env.REACT_APP_ID_ALMACEN,
        id_orden_picking: window.location.pathname.split("/")[4]
      },
      this
    )
      .then(res => {
        this.resetForm();
        this.props.getOrdenesPickingAdmin();
        this.getOrdenesPickingProductosAdmin();
        toast.success("Orden de Picking Actualizada");
        window.location.reload();
      })
      .catch(err => {
        toast.error(err.message);
      });
  };


  handleFinalizarOrden = () => {
    let objeto = {};

    var url = window.location.pathname.split("/");

    objeto["id_orden_picking"] = url[4];

    console.log(objeto);

    Database.post("/finalizar-ordenpicking", { ...objeto }, this).then(
      res => {
        this.resetForm();
        this.props.getOrdenesPickingAdmin();
        this.getOrdenesPickingProductosAdmin();
        toast.success("Orden de Picking Finalizada");
        this.setState({
          openConfirmDialog: false
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  resetForm = () => {
    let state = { ...StateListOrdenesPickingProductos };
  };

  render() {
    let style = {};
    return (
      <form
        onSubmit={event => {
          event.preventDefault();
          this.handleSend(event);
        }}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={style}>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite}>
                  Productos de la Orden de Picking
                </h4>
                <p className={this.props.classes.cardCategoryWhite}>
                  Listado de Productos de la Orden de Picking
                </p>
              </CardHeader>
              <CardBody>
                {!this.state.hay_trabajador && (
                  <Button
                    variant="contained"
                    style={{ marginTop: "25px" }}
                    color="primary"
                    onClick={() =>
                      this.setState({ openDialogTrabajador: true })
                    }
                  >
                    Seleccionar Trabajador
                  </Button>
                )}

                {!this.state.hay_calle_salida && (
                  <Button
                    variant="contained"
                    style={{ marginTop: "25px", marginLeft: "25px" }}
                    color="primary"
                    onClick={() =>
                      this.setState({ openDialogCalleSalida: true })
                    }
                  >
                    Seleccionar Calle de Salida
                  </Button>
                )}
                {this.state.infoTrabajador && !this.state.hay_trabajador && (
                  <p>
                    Nombre: <strong>{this.state.infoTrabajador.nombre}</strong>{" "}
                    <br />
                    Nombre de Usuario:{" "}
                    <strong>{this.state.infoTrabajador.username}</strong> <br />
                    Tipo de Usuario:{" "}
                    <strong>
                      {this.state.infoTrabajador.descripcion_users_type}
                    </strong>
                  </p>
                )}

                <div>
                  {this.state.openDialogTrabajador && (
                    <ModalSeleccionarTrabajador
                      onClick={this.onClickItemTrabajador}
                      openDialog={this.state.openDialogTrabajador}
                      closeDialog={this.closeDialog.bind(this)}
                    />
                  )}
                </div>

                {this.state.infoCalleSalida && !this.state.hay_calle_salida && (
                  <p>
                    Nombre:{" "}
                    <strong>{this.state.infoCalleSalida.descripcion}</strong>{" "}
                    <br />
                    Capacidad:{" "}
                    <strong>{this.state.infoCalleSalida.capacidad}</strong>
                  </p>
                )}

                <div>
                  {this.state.openDialogCalleSalida && (
                    <ModalSeleccionarCalleSalida
                      onClick={this.onClickItemCalleSalida}
                      openDialog={this.state.openDialogCalleSalida}
                      closeDialog={this.closeDialogCalleSalida.bind(this)}
                    />
                  )}
                </div>

                {!this.state.hay_trabajador || !this.state.hay_calle_salida ? (
                  <h3>
                    Seleccione un trabajador y una calle de salida para
                    habilitar la orden de picking
                  </h3>
                ) : !this.state.edicion_bloqueada ? (
                  <MaterialTable
                    isLoading={this.state.isLoading}
                    columns={ColumnsListadoOrdenPickingProductos}
                    data={this.state.ordenespickingproductos}
                    title=""
                    localization={localization}
                    onRowSelected={() => console.log("selected")}
                    onRowClick={(event, rowData, togglePanel) => {
                      console.log("click");
                      this.setState(
                        prevState => {
                          if (prevState.currentEdit === rowData.tableData.id) {
                            togglePanel();
                            return {
                              currentEdit: null,
                              previousTogglePanel: null
                            };
                          } else {
                            if (prevState.previousTogglePanel) {
                              prevState.previousTogglePanel();
                            }

                            return {
                              currentEdit: rowData.tableData.id,
                              previousTogglePanel: togglePanel
                            };
                          }
                        },
                        () => {
                          if (this.state.currentEdit !== null) {
                            togglePanel();
                          }
                        }
                      );
                    }}
                    detailPanel={[
                      {
                        disabled: true,
                        render: rowData => {
                          const isGreyedOut = this.state.greyedRows.includes(
                            rowData.id
                          );
                          const textColor = isGreyedOut ? "#888" : "#37474f";
                          const unidadesTotales =
                            (rowData.cantidad_unidades_bulto || 0) *
                              (rowData.cantidad_bultos || 0) +
                            parseFloat(rowData.cantidad_unidades || 0) +
                            (rowData.cantidad_displays || 0) *
                              (parseFloat(rowData.cantidad_unidades_display) ||
                                0);
                          return (
                            <div
                              style={{
                                padding: "15px",
                                fontFamily: "Arial"
                              }}
                            >
                              <table
                                style={{
                                  marginRight: "15px",
                                  color: textColor,
                                  fontSize: "1em"
                                }}
                              >
                                <div style={{ marginBottom: "20px" }}>
                                  <table
                                    style={{
                                      borderCollapse: "collapse",
                                      width: "100%",
                                      color: textColor,
                                      fontSize: "1em"
                                    }}
                                  >
                                    <tbody>
                                      <tr
                                        style={{ backgroundColor: "#f7f7f7" }}
                                      >
                                        <td style={{ padding: "8px 12px" }}>
                                          <strong>Artículo</strong>
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px 12px",
                                            textAlign: "right"
                                          }}
                                        >
                                          {rowData.descripcion_producto_unidad}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "8px 12px" }}>
                                          <strong>En almacenamiento</strong>
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px 12px",
                                            textAlign: "right"
                                          }}
                                        >
                                          {rowData.cantidad_unidades_stock}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{ backgroundColor: "#f7f7f7" }}
                                      >
                                        <td style={{ padding: "8px 12px" }}>
                                          <strong>DUN 14</strong>
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px 12px",
                                            textAlign: "right"
                                          }}
                                        >
                                          {rowData.dun14}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "8px 12px" }}>
                                          <strong>EAN 13</strong>
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px 12px",
                                            textAlign: "right"
                                          }}
                                        >
                                          {rowData.ean13}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{ backgroundColor: "#f7f7f7" }}
                                      >
                                        <td style={{ padding: "8px 12px" }}>
                                          <strong>
                                            Total Unidades Solicitadas
                                          </strong>
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px 12px",
                                            textAlign: "right"
                                          }}
                                        >
                                          {unidadesTotales}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div style={{ marginBottom: "20px" }}>
                                  <table
                                    style={{
                                      borderCollapse: "collapse",
                                      width: "100%",
                                      color: textColor,
                                      fontSize: "0.85em"
                                    }}
                                  >
                                    <thead>
                                      <tr style={styles.strongText}>
                                        <th></th>
                                        <th style={styles.centeredText}>
                                          Solicitados
                                        </th>
                                        <th style={styles.centeredText}>
                                          Cumplidos
                                        </th>
                                        <th style={styles.centeredText}>
                                          Disponible en Picking
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={styles.centeredText}>
                                          <strong>Bultos</strong>
                                        </td>
                                        <td style={styles.centeredText}>
                                          {parseInt(rowData.cantidad_bultos)}
                                          <div>
                                            (
                                            {rowData.cantidad_unidades_bulto *
                                              rowData.cantidad_bultos}{" "}
                                            unidades)
                                          </div>
                                        </td>
                                        <td style={styles.buttonFlex}>
                                          <Button
                                            variant="contained"
                                            style={styles.mainButtonStyle}
                                            onClick={() =>
                                              this.decrement("bultos", rowData)
                                            }
                                          >
                                            -
                                          </Button>
                                          {rowData.cantidad_bultos_cumplidos}
                                          <Button
                                            variant="contained"
                                            style={styles.mainButtonStyle}
                                            onClick={() =>
                                              this.increment("bultos", rowData)
                                            }
                                          >
                                            +
                                          </Button>
                                        </td>
                                        <td style={styles.centeredText}>
                                          {rowData.cantidad_bultos_picking}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={styles.centeredText}>
                                          <strong>Displays</strong>
                                        </td>
                                        <td style={styles.centeredText}>
                                          {parseInt(rowData.cantidad_displays)}
                                        </td>
                                        <td style={styles.buttonFlex}>
                                          <Button
                                            variant="contained"
                                            style={styles.mainButtonStyle}
                                            onClick={() =>
                                              this.decrement(
                                                "displays",
                                                rowData
                                              )
                                            }
                                          >
                                            -
                                          </Button>
                                          {rowData.cantidad_displays_cumplidos}
                                          <Button
                                            variant="contained"
                                            style={styles.mainButtonStyle}
                                            onClick={() =>
                                              this.increment(
                                                "displays",
                                                rowData
                                              )
                                            }
                                          >
                                            +
                                          </Button>
                                        </td>
                                        <td style={styles.centeredText}>
                                          {rowData.cantidad_displays_picking}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={styles.centeredText}>
                                          <strong>Unidades</strong>
                                        </td>
                                        <td style={styles.centeredText}>
                                          {parseInt(rowData.cantidad_unidades)}
                                        </td>
                                        <td style={styles.buttonFlex}>
                                          <Button
                                            variant="contained"
                                            style={styles.mainButtonStyle}
                                            onClick={() =>
                                              this.decrement(
                                                "unidades",
                                                rowData
                                              )
                                            }
                                          >
                                            -
                                          </Button>
                                          {rowData.cantidad_unidades_cumplidas}
                                          <Button
                                            variant="contained"
                                            style={styles.mainButtonStyle}
                                            onClick={() =>
                                              this.increment(
                                                "unidades",
                                                rowData
                                              )
                                            }
                                          >
                                            +
                                          </Button>
                                        </td>
                                        <td style={styles.centeredText}>
                                          {rowData.cantidad_unidades_picking}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                    gap: "10px"
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#176701",
                                      color: "#fff",
                                      marginBottom: "10px",
                                      boxShadow:
                                        "0px 3px 10px rgba(0, 0, 0, 0.2)"
                                    }}
                                    onClick={() => this.fill(rowData)}
                                  >
                                    Rellenar
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#373F73",
                                      color: "#fff",
                                      boxShadow:
                                        "0px 3px 10px rgba(0, 0, 0, 0.2)"
                                    }}
                                    type="button"
                                    onClick={() => this.handleSave()}
                                  >
                                    Guardar
                                  </Button>
                                </div>
                              </table>
                            </div>
                          );
                        }
                      }
                    ]}
                    components={{
                      Container: props => <Paper elevation={0} {...props} />
                    }}
                    options={{
                      rowStyle: rowData => {
                        const isGreyed = this.state.greyedRows.includes(
                          rowData.tableData.id
                        );
                        return isGreyed ? { backgroundColor: "#CCCCCC" } : {};
                      },
                      actionsColumnIndex: -1,
                      exportButton: true,
                      exportAllData: true,
                      exportFileName:
                        "OrdenesPickingProductos " +
                        moment().format("DD-MM-YYYY"),
                      exportDelimiter: ";",
                      headerStyle: {
                        backgroundColor: lightGreen[700],
                        color: "#FFF"
                      }
                    }}
                  />
                ) : (
                  <MaterialTable
                    isLoading={this.state.isLoading}
                    columns={ColumnsListadoOrdenPickingProductos}
                    data={this.state.ordenespickingproductos}
                    title=""
                    localization={localization}
                    components={{
                      Container: props => <Paper elevation={0} {...props} />
                    }}
                    options={{
                      filtering: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      exportAllData: true,
                      exportFileName:
                        "OrdenesPickingProductos " +
                        moment().format("DD-MM-YYYY"),
                      exportDelimiter: ";",
                      headerStyle: {
                        backgroundColor: lightGreen[700],
                        color: "#FFF"
                      }
                    }}
                  />
                )}
                <ButtonSmall
                  style={{ marginTop: "25px" }}
                  color="info"
                  onClick={() =>
                    this.props.history.push("/admin/ordenespicking")
                  }
                >
                  <ArrowBack />
                  Volver
                </ButtonSmall>

                {!this.state.hay_trabajador && !this.state.hay_calle_salida && (
                  <ButtonSmall
                    style={{ marginTop: "25px" }}
                    color="primary"
                    variant="contained"
                    disabled={this.state.disableAllButtons}
                    type="submit"
                    onClick={this.handleInfo}
                    >
                    <Save /> Guardar Cambios
                  </ButtonSmall>
                )}

                {this.state.hay_trabajador &&
                  this.state.hay_calle_salida &&
                  !this.state.edicion_bloqueada && (
                    <ButtonSmall
                      style={{ marginTop: "25px" }}
                      color="primary"
                      variant="contained"
                      disabled={this.state.disableAllButtons}
                      type="submit"
                    >
                      <Save /> Enviar a calle de salida
                    </ButtonSmall>
                  )}

                {this.state.hay_trabajador &&
                  this.state.hay_calle_salida &&
                  !this.state.edicion_bloqueada &&
                  this.state.habilitaFinalizar && (
                    <ButtonSmall
                      style={{
                        marginTop: "25px",
                        marginLeft: "25px",
                        backgroundColor: "#a94442"
                      }}
                      color="primary"
                      variant="contained"
                      disabled={this.state.disableAllButtons}
                      onClick={() =>
                        this.handleConfirmButton(this.state.id_orden_picking)
                      }
                    >
                      <Save /> Finalizar Orden
                    </ButtonSmall>
                  )}
              </CardBody>
            </Card>
          </GridItem>
          <ModalConfirm
            openConfirmDialog={this.state.openConfirmDialog}
            confirmRowData={this.state.confirmRowData}
            handleClose={() => this.handleModalClose()}
            handleConfirm={() => this.handleFinalizarOrden()}
          />
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(OrdenPickingProductos);
