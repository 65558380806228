export const inputChangedHandler = function(event, inputIdentifier, orderForm) {
  let checkValid;
  const updatedOrderForm = {
    ...orderForm
  };

  const updatedFormElement = {
    ...updatedOrderForm[inputIdentifier]
  };

  if (updatedFormElement.elementType === "checkbox") {
    if (event.target.checked) updatedFormElement.value = 1;
    else updatedFormElement.value = 0;
  } else {
    updatedFormElement.value = event.target.value;
  }

  if (!updatedOrderForm[inputIdentifier].disabled) {
    checkValid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      updatedOrderForm,
      inputIdentifier
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;
  }

  let formIsValidAlt = true;
  for (let inputIdentifier in updatedOrderForm) {
    if (!updatedOrderForm[inputIdentifier].disabled) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
  }
  return { orderForm: updatedOrderForm, formIsValid: formIsValidAlt };
};

export const inputAllChangedHandler = function(orderForm) {
  let checkValid;
  const updatedOrderForm = {
    ...orderForm
  };

  for (let key in updatedOrderForm) {
    const updatedFormElement = {
      ...updatedOrderForm[key]
    };

    if (!updatedOrderForm[key].disabled) {
      checkValid = checkValidity(
        updatedFormElement.value,
        updatedFormElement.validation,
        updatedOrderForm,
        key
      );
      updatedFormElement.valid = checkValid.isValid;
      updatedFormElement.textValid = checkValid.textValid;
      updatedFormElement.touched = true;
      updatedOrderForm[key] = updatedFormElement;
    }
  }

  let formIsValidAlt = true;
  for (let inputIdentifier in updatedOrderForm) {
    if (!updatedOrderForm[inputIdentifier].disabled) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].disabled ||
        (updatedOrderForm[inputIdentifier].valid && formIsValidAlt);
    }
  }

  return { orderForm: updatedOrderForm, formIsValid: formIsValidAlt };
};

function valida_rango(value, rango, orderForm) {
  let objeto = { isValid: null, textValid: null };
  if (value < 0) {
    objeto.isValid = false;
    objeto.textValid = "Debe ingresar un número mayor a 0";
    return objeto;
  }
  // console.log("entro por aca1");
  if (
    rango.tipo === "desde" &&
    parseInt(orderForm[rango.identificadorAlt].value) > 0
  ) {
    if (value && value > parseInt(orderForm[rango.identificadorAlt].value)) {
      objeto.isValid = false;
      objeto.textValid = "El valor no puede superar al exptremo posterior";
      return objeto;
    }
  } else if (
    rango.tipo === "hasta" &&
    parseInt(orderForm[rango.identificadorAlt].value) > 0
  ) {
    if (value && value <= parseInt(orderForm[rango.identificadorAlt].value)) {
      objeto.isValid = false;
      objeto.textValid = "El valor no puede ser inferior al inicio";
      return objeto;
    }
  }
  objeto.isValid = true;
  return objeto;
}

export const checkValidity = function(value, rules, orderForm, key) {
  let isValid = true;
  let textValid = null;

  if (rules.required && isValid) {
    isValid = value.toString().trim() !== "";
    textValid = "El campo es requerido";
  }

  if (value.toString().trim().length > 0) {
    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    if (rules.exactLength && isValid) {
      isValid = value.toString().length === rules.exactLength;
      textValid = "La cantidad de caracteres es " + rules.exactLength;
    }

    if (rules.mayor0 && isValid) {
      isValid = value >= 0;
      textValid = "El valor debe ser mayor a 0";
    }

    if (rules.mayor1 && isValid) {
      isValid = value >= 1;
      textValid = "El valor debe ser mayor igual a 1";
    }
    if (rules.max1) { 
      isValid = value <= 1 && isValid;
      textValid = "El valor no debe ser mayor a 1";
    }

    if (rules.rango && isValid) {
      let objeto = valida_rango(value, rules.rango, orderForm);
      isValid = objeto.isValid;
      textValid = objeto.textValid;
    }
  }

  return { isValid: isValid, textValid: textValid };
};

export const resetForm = function(orderForm) {
  let orderFormAlt = { ...orderForm };
  // let successSubmit = this.state.successSubmit;
  for (let key in orderFormAlt) {
    orderFormAlt[key].value = "";
  }

  return orderForm;
};
