import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

// import { AddBox, ArrowUpward } from "@material-ui/icons";
// import ReactDOM from "react-dom";
//import MaterialTable from "material-table";
import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import { toast } from "react-toastify";

import { localization } from "variables/general.js";
import lightGreen from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/styles";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const ColumnsListado = [
  { title: "Pallet", field: "id" },
  { title: "Dato Logistico", field: "ProductosPresentacion.descripcion" },
  { title: "Ubicación", field: "ubicacion_desc" },
  { title: "Muelle", field: "muelle.descripcion" },
  { title: "Calle Salida", field: "CallesSalida.descripcion" },
  { title: "Cantidad Unidades", field: "cantidad_unidades" },
  { title: "Alerta", field: "alerta_desc" },

  {
    title: "Vencimiento",
    field: "vencimiento",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento, "DD/MM/YYYY").format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento, "DD/MM/YYYY").format("YYYYMMDD"))
  }
];

class AlertaVencimientos extends Component {
  state = {
    listado: [],
    isLoading: false
  };

  getItems = () => {
    this.setState({
      isLoading: true
    });
    Database.get("/list-alertas-vencimiento", this, null, true).then(
      res => {
        // console.log(res);
        let resultado = res.result.map(elem => {
          let alerta_desc = null;
          let ubicacion_desc = null;
          if (elem.alerta == 3) alerta_desc = "VENCIDO";
          if (elem.alerta == 2) alerta_desc = "2º AVISO";
          if (elem.alerta == 1) alerta_desc = "1º AVISO";

          if (elem.Ubicacion) {
            ubicacion_desc =
              (elem.Ubicacion.Calle && elem.Ubicacion.Calle.numero) +
              "-" +
              (elem.Ubicacion.Columna && elem.Ubicacion.Columna.numero) +
              "-" +
              elem.Ubicacion.nivel;
          }
          return {
            ...elem,
            alerta_desc: alerta_desc,
            ubicacion_desc: ubicacion_desc,
            vencimiento: moment.utc(elem.vencimiento).format("DD/MM/YYYY")
          };
        });

        // console.log(resultado);

        this.setState({
          isLoading: false,
          listado: resultado
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  componentDidMount() {
    this.getItems();
  }

  render() {
    let style = { maxWidth: "100%" };
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none", maxWidth: "100%" };
    }
    return [
      <div key={"pedidos-list-pedidos"} style={style}>
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              ALERTAS DE VENCIMIENTOS
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Listado de Alertas de Vencimientos
            </p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              isLoading={this.state.isLoading}
              columns={ColumnsListado}
              data={this.state.listado}
              title=""
              localization={localization}
              // actions={[{
              //   icon: 'edit',
              //   tooltip: 'Editar Pedido',
              //   onClick: (event, rowData) => this.props.history.push('/admin/costos/editarcosto/' + rowData.id)
              // }]}
              components={{
                Cell: props => {
                  let styles = null;
                  if (props.columnDef.field == "alerta_desc") {
                    //if (props.rowData.cantidad - props.rowData.minimo < 0) {
                    if (props.rowData.alerta == 3) {
                      styles = { backgroundColor: red[300], color: "white" };
                    } else {
                      styles = { backgroundColor: yellow[300], color: "white" };
                    }
                  }
                  return <MTableCell style={styles} {...props} />;
                },
                Row: props => {
                  return (
                    <MTableBodyRow
                      className={this.props.classes.rowTable}
                      {...props}
                    />
                  );
                },
                Container: props => <Paper elevation={0} {...props} />
              }}
              options={{
                exportButton: true,
                exportAllData: true,
                exportFileName:
                  "AlertaVencimientos " + moment().format("DD-MM-YYYY"),
                exportDelimiter: ";",
                headerStyle: {
                  backgroundColor: lightGreen[700],
                  color: "#FFF"
                }
              }}
              // detailPanel={rowData => {
              //   return <span></span>;
              // }}
            />
          </CardBody>
        </Card>
      </div>
    ];
  }
}

export default withStyles(styles)(AlertaVencimientos);
