import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import CSVReader from "react-csv-reader";

import Database from "variables/Database.js";

import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ModalVerProductos from "./ModalVerProductos.js";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ValidarCSV from "./ValidarCsv";
import { Grid, Typography } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const NewPedido = props => {
  const [csvData, setCsvData] = useState(""); // Inicialmente no hay datos de CSV
  const [shouldRender, setShouldRender] = useState(false); // Controla si se debe volver a renderizar
  const [infoCliente, setInfoCliente] = useState("");
  const [fileName, setFileName] = useState("");
  const renderKey = Math.random(); // Genera una clave de renderizado aleatoria
  const [openDialogProducto, setOpenDialogProducto] = useState(false);
  const [rowProductoPresentacion, setRowProductoPresentacion] = useState(null);

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true
  };

  const ObtenerInfoCliente = () => {
    Database.get("/list-mi-id-cliente/", this).then(
      res => {
        let resultado = res.result;
        setInfoCliente(resultado.id_cliente);
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  const handleOpenDialogProducto = () => {
    setOpenDialogProducto(true);
    setRowProductoPresentacion(null);
  };

  const handleCloseDialogProducto = () => {
    setOpenDialogProducto(false);
  };

  useEffect(() => {
    ObtenerInfoCliente();
  }, []);

  const handleSetCsvData = csv => {
    setCsvData(csv);
    setShouldRender(true);
  };

  const handleSetShouldRender = shouldRender => {
    setShouldRender(shouldRender);
    setCsvData(""); // Actualiza csvData a vacío o nulo al cambiar shouldRender
  };

  const handleRemoveCsv = () => {
    setCsvData("");
    setShouldRender(false);
  };

  return (
    <>
      <Card key={renderKey}>
        <CardHeader color="primary">
          <h4 className={props.classes.cardTitleWhite}>Nuevo Pedido</h4>
          <p className={props.classes.cardCategoryWhite}>
            Formulario de un pedido nuevo
          </p>
        </CardHeader>
        <CardBody>
          <Typography>
            1) Obtener el/los campos <b>"id_producto_presentacion"</b> de Datos
            Logísticos:
          </Typography>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  style={{ marginTop: "25px" }}
                  color="primary"
                  onClick={handleOpenDialogProducto}
                >
                  Ver Datos Logísticos
                </Button>
              </div>
            </Grid>
          </Grid>
          <br />
          <Typography>2) Armar el (.csv) con los siguientes campos:</Typography>
          <br />
          <Typography variant="h7">
            {" "}
            <b>
              | pedido | destino | id_producto_presentacion |
              cantidad_contenedores | cantidad_bultos | cantidad_displays |
              cantidad_unidades |
            </b>
          </Typography>
          <br /> <br />
          <Typography>3) Cargar el archivo (.csv):</Typography>
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              {!shouldRender && (
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  spacing={1}
                >
                  <CSVReader
                    cssClass="csv-reader-input"
                    label="Buscar CSV "
                    onFileLoaded={(data, fileInfo) => {
                      setCsvData(data);
                      setShouldRender(true);
                      setFileName(fileInfo.name);
                    }}
                    onError={() =>
                      console.log("Error al cargar el archivo CSV")
                    }
                    parserOptions={parseOptions}
                    inputId="cargar-csv"
                    inputStyle={{ color: "red" }}
                  />
                </Grid>
              )}
              {shouldRender && (
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  spacing={1}
                >
                  <Grid item>
                    <p>Nombre del archivo: {fileName}</p>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleRemoveCsv}>
                      Quitar archivo
                    </Button>
                  </Grid>
                </Grid>
              )}
              <ValidarCSV
                csvData={csvData}
                setShouldRender={handleSetShouldRender}
                shouldRender={shouldRender}
              />
            </Grid>
          </>
        </CardBody>
      </Card>

      <Dialog
        open={openDialogProducto}
        onClose={handleCloseDialogProducto}
        fullWidth={true}
        maxWidth="X1"
      >
        <DialogTitle>
          Ver Datos Logísticos
          <IconButton aria-label="close" onClick={handleCloseDialogProducto}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {openDialogProducto && (
            <ModalVerProductos
              infoCliente={infoCliente}
              rowEditProducto={null}
              rowProductoPresentacion={rowProductoPresentacion}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withRouter(withStyles(styles)(NewPedido));
