export const StateListado = {
  costos: [],
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const ColumnsListado = [{ title: "Alerta Costo", field: "nombre" }];

export const StateEditConfiguracion = {
  editFormIsValid: false,
  costoEdit: null,
  arrayConfiguraciones: [],
  editConfiguracionForm: {
    alertaCaducidadPrimera: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Primer Alerta Caducidad (dias previos a vencimiento)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    alertaCaducidadSegunda: {
      elementType: "input",
      elementConfig: {
        type: "number",
        label: "Segunda Alerta Caducidad (dias previos a vencimiento)",
        fullWidth: true
      },
      value: "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  }
};
