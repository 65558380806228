import React from "react";
// style
import lightGreen from "@material-ui/core/colors/lightGreen";
// material table
import MaterialTable from "material-table";
import { localization } from "variables/general.js";
// Card
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import EncabezadoCard from "components/EncabezadoCard/EncabezadoCard";

const columnasTablaDetalleMovimientos = [
  { title: "Pallet", field: "id_contenedor" },
  { title: "Tipo de movimiento", field: "tipo_movimiento.descripcion" },
  { title: "Cantidad de Unidades", field: "cantidad_unidades" },
  { title: "Lugar", field: "tipo_localizacion" },
  { title: "Nombre Lugar", field: "localizacion" },
  { title: "Fecha", field: "createdTimestamp" }
];

let localization_options = { header: { actions: "" } };
localization_options = {
  ...JSON.parse(JSON.stringify(localization)),
  ...localization_options
};

const TablaDetalleMovimiento = props => {
  return (
    <Card>
      <EncabezadoCard
        titulo_card=""
        subtitulo_card="Detalle Movimientos"
      ></EncabezadoCard>
      <CardBody>
        <MaterialTable
          id="tabla-detalle-movimiento"
          // isLoading={props.isLoading}
          columns={columnasTablaDetalleMovimientos}
          data={props.data}
          style={{ width: "100%" }}
          title=""
          localization={localization_options}
          options={{
            filtering: true,
            exportDelimiter: ";",
            headerStyle: {
              backgroundColor: lightGreen[700],
              color: "#FFF"
            },
            selection: false,
            actionsColumnIndex: -1,
            pageSize: 10
          }}
        ></MaterialTable>
      </CardBody>
    </Card>
  );
};

export default TablaDetalleMovimiento;
