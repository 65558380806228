import React from "react";
// Material
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
// moment
import moment from "moment";
// DB
// import Database from "variables/Database.js";
// import { toast } from "react-toastify";

// const darSalidaAlote = (props) => {

//   let contenedoresEnLoteSalida = props.contenedoresEnLoteDeSalida;
//   // let ids = props.contenedoresEnLoteDeSalida.map( (contenedor) => {
//   //   return contenedor.id
//   // })
//   Database.post(`/contenedores-salida`, { contenedoresEnLoteSalida }, this).then(res => {
//     toast.success("Salida exitosa!");
//     props.resetForm(2);
//   }, err => {
//     toast.error(err.message);
//   })
// }

const handleAccept = props => {
  props.setConfirmEliminarPalletPedido(true);
  props.setModalPedidoVisibility(false);
};

const getCliente = dataPedido => {
  let cliente = null;
  if (dataPedido) {
    if (dataPedido.cliente) {
      cliente = dataPedido.cliente;
    }
  }
  return cliente;
};

const getReferencia = dataPedido => {
  let referencia = null;
  if (dataPedido) {
    if (dataPedido.referencia) {
      referencia = dataPedido.referencia;
    }
  }
  return referencia;
};

const getCreatedTimestamp = dataPedido => {
  let createdTimestamp = null;
  if (dataPedido) {
    if (dataPedido.fecha_creacion_pedido) {
      createdTimestamp = dataPedido.fecha_creacion_pedido;
    }
  }
  return createdTimestamp;
};

function ModalDatosPedido(props) {
  let cliente = getCliente(props.datosPedido);
  let referencia = getReferencia(props.datosPedido);
  let createdTimestamp = getCreatedTimestamp(props.datosPedido);

  return (
    <div>
      <Dialog
        open={props.openModalPedido}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          El pallet seleccionado forma parte de un pedido
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ color: "red" }}
            id="alert-dialog-description"
          >
            Se eliminará el pallet del pedido
          </DialogContentText>
          <Card>
            <CardBody>
              <p style={{ color: "green", fontWeight: "bold" }}>
                Datos del Pedido:
              </p>
              <p style={{ fontWeight: "bold" }}>Referencia:</p>
              <p>{referencia}</p>
              <p style={{ fontWeight: "bold" }}>
                Fecha de creación del Pedido:
              </p>
              <p>
                {moment(createdTimestamp).format("DD/MMM/YYYY LT") ||
                  "No posee"}
              </p>
              <p style={{ fontWeight: "bold" }}>Cliente:</p>
              <p>{cliente}</p>
            </CardBody>
          </Card>
          <DialogContentText
            style={{ textTransform: "uppercase", color: "#303f9f" }}
            id="alert-dialog-description"
          >
            Confirma que desea sacar el pallet de la calle de salida?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAccept(props)} color="primary" autoFocus>
            SI
          </Button>
          <Button
            onClick={() => props.setModalPedidoVisibility(false)}
            color="primary"
          >
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    openModalPedido: state.movimientos.openModalPedido,
    datosPedido: state.movimientos.datosPedido,
    eliminarPallet: state.movimientos.eliminarPallet
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setModalPedidoVisibility: status =>
      dispatch(actionCreators.setModalPedidoVisibility(status)),
    setConfirmEliminarPalletPedido: status =>
      dispatch(actionCreators.setConfirmEliminarPalletPedido(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDatosPedido);
