import * as actionTypes from "./actionTypes";

export const handleOpenBuscarClienteDialog = () => {
  return {
    type: actionTypes.HANDLE_OPEN_CLIENTE_DIALOG
  };
};

export const handleCloseClienteDialog = () => {
  return {
    type: actionTypes.HANDLE_CLOSE_CLIENTE_DIALOG
  };
};

export const handleAcceptModalCliente = cliente => {
  return {
    type: actionTypes.HANDLE_ACCEPT_MODAL_CLIENTE,
    cliente: cliente
  };
};

export const onChangeHandlerInputText = event => {
  return {
    type: actionTypes.ONCHANGE_HANDLER_INPUT_TEXT,
    event: event
  };
};

export const setSelectedRadioButton = selected_radio => {
  return {
    type: actionTypes.SET_SELECTED_RADIO_BUTTON,
    selected_radio: selected_radio
  };
};

export const setSelectedClientToCero = () => {
  return {
    type: actionTypes.SET_SELECTED_CLIENT_TO_CERO
  };
};

export const resetFormPedido = () => {
  return {
    type: actionTypes.RESET_FORM_PEDIDO
  };
};

export const setPedidoSelected = pedido => {
  return {
    type: actionTypes.SET_PEDIDO_SELECTED,
    pedido: pedido
  };
};

export const changeRadioButtonPedido = () => {
  return {
    type: actionTypes.CHANGE_RADIO_BUTTON_PEDIDO
  };
};

export const setTrueNuevaReferencia = () => {
  return {
    type: actionTypes.SET_TRUE_NUEVA_REFERENCIA
  };
};

export const setFalseNuevaReferencia = () => {
  return {
    type: actionTypes.SET_FALSE_NUEVA_REFERENCIA
  };
};

export const resetNuevaReferencia = () => {
  return {
    type: actionTypes.RESET_NUEVA_REFERENCIA
  };
};

export const setDisableInputText = is_disabled => {
  return {
    type: actionTypes.SET_DISABLE_INPUT_TEXT,
    is_disabled: is_disabled
  };
};

export const setBuscarReferencia = bool_buscar => {
  return {
    type: actionTypes.SET_BUSCAR_REFERENCIA,
    bool_buscar: bool_buscar
  };
};
