import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import EncabezadoCard from "components/EncabezadoCard/EncabezadoCard";
import moment from "moment";

const detallesEntrada = props => {
  let styleDisplay = { display: "block" };
  let styleTitleDetail = {
    fontSize: "115%",
    color: "green"
  };

  return (
    <Card>
      <EncabezadoCard
        titulo_card=""
        subtitulo_card="Detalle Entrada"
      ></EncabezadoCard>
      <CardBody>
        <div style={{ marginLeft: "4em" }}>
          <div>
            <p style={styleTitleDetail}>Cantidad de Unidades</p>
            <p style={styleDisplay}>
              {props.data.entrada["AudEntradaDetalles.cantidad_unidades"] ||
                "No posee"}{" "}
              <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Fecha</p>
            <p style={styleDisplay}>
              {moment(props.data.entrada.fecha).format("DD/MMM/YYYY LT") ||
                "No posee"}{" "}
              <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Cliente</p>
            <p style={styleDisplay}>
              {props.data.entrada["cliente.nombre_fantasia"] || "No posee"}{" "}
              <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Usuario</p>
            <p style={styleDisplay}>
              {props.data.usuario_entrada.nombre || "No posee"} <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Muelle</p>
            <p style={styleDisplay}>
              {props.data.entrada["muelle.descripcion"] || "No posee"} <br />
            </p>
          </div>
          <div>
            <p style={styleTitleDetail}>Referencia</p>
            <p style={styleDisplay}>
              {props.data.entrada.referencia || "No posee"} <br />
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default detallesEntrada;
