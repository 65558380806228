export const setStyleTransformBloque = (
  dataxBloque,
  datayBloque,
  rotacion,
  ancho_bloque,
  alto_bloque
) => {
  let style = {};

  if (dataxBloque === null) {
    // cuando se carga un bloque por primera vez en el mapa, data-x y data-y son nulos
    style = {
      width: String(ancho_bloque) + "px",
      height: String(alto_bloque) + "px",
      transform:
        "translate(" + 0 + "px, " + 0 + "px) rotate(" + rotacion + "deg)"
    };
  } else {
    style = {
      width: String(ancho_bloque) + "px",
      height: String(alto_bloque) + "px",
      transform:
        "translate(" +
        dataxBloque +
        "px, " +
        datayBloque +
        "px) rotate(" +
        rotacion +
        "deg)"
    };
  }
  return style;
};

export const generarBloquesdeColumnas = (ancho, alto, tipo) => {
  let columnas = [];
  if (tipo === "PI") {
    columnas = [
      { id: "0", columna_nombre: "0", width: ancho, height: alto },
      { id: "1", columna_nombre: "1", width: ancho, height: alto }
    ];
  } else {
    columnas = [{ id: "0", columna_nombre: "0", width: ancho, height: alto }];
  }
  return columnas;
};
