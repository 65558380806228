import React from "react";
import { useState, useEffect } from "react";
import Database from "variables/Database.js";
import moment from "moment";

//import { Route, Switch } from "react-router-dom";
// core components
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
//import Button from "components/CustomButtons/Button.js";
//import AddIcon from "@material-ui/icons/Add";
import { localization } from "variables/general.js";

import { toast } from "react-toastify";

//import { StateListClienteEntradas } from "./VariablesState.js";

import lightGreen from "@material-ui/core/colors/lightGreen";
import EncabezadoCard from "components/EncabezadoCard/EncabezadoCard";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

export const ColumnsListado = [
  //{ title: "IDs", field: "id" },
  {
    title: "Fecha",
    field: "fecha_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.fecha).format("YYYYMMDDHHmmSS")) -
      parseInt(moment(b.fecha).format("YYYYMMDDHHmmSS"))
  },
  { title: "Referencia", field: "referencia" },
  { title: "Nro Entrada", field: "id_aud_entrada" },
  { title: "Codigo", field: "codigo" },
  { title: "Presentacion", field: "producto_presentacion" },
  { title: "Unidad", field: "producto_unidad" },
  { title: "Pallet", field: "id_contenedor" },
  {
    title: "Vencimiento",
    field: "vencimiento_mostrar",
    customSort: (a, b) =>
      parseInt(moment(a.vencimiento).format("YYYYMMDD")) -
      parseInt(moment(b.vencimiento).format("YYYYMMDD"))
  },
  { title: "Lote", field: "lote" },
  { title: "Cantidad Unidades", field: "cantidad_unidades" }
];

const ClienteEntradas = props => {
  const [dataColumnsContenedor, setdataColumnsContenedor] = useState([]);

  useEffect(() => {
    // Obtener los datos de entradas de los clientes cuando se monta el componente
    Database.get("/list-clienteentradas").then(
      result => {
        let dataMovimientos = [...result.result];

        dataMovimientos = dataMovimientos.map(elem => {
          return {
            ...elem,

            fecha_mostrar: moment(elem.fecha_mostrar).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          };
        });
        // Guardar datos en el state
        setdataColumnsContenedor(dataMovimientos);
        console.log(dataMovimientos);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, []);

  let style = {};

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={style}>
          {/*
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Auditoria Entradas</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Listado de Entradas
            </p>
          </CardHeader>
  */}
          <EncabezadoCard
            titulo_card="Auditoria Entradas"
            subtitulo_card="Listado de Entradas"
          ></EncabezadoCard>
          <CardBody>
            <MaterialTable
              isLoading={props.isLoading}
              columns={ColumnsListado}
              data={dataColumnsContenedor}
              title=""
              localization={localization}
              components={{
                Container: props => <Paper elevation={0} {...props} />
              }}
              options={{
                filtering: true,
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                exportFileName:
                  "ClienteEntradas " + moment().format("DD-MM-YYYY"),
                exportDelimiter: ";",
                pageSizeOptions: [50, 100, 200, 400, 800],
                pageSize: 50,
                //  maxBodyHeight: 200,  //sticky header
                emptyRowsWhenPaging: false,
                headerStyle: {
                  backgroundColor: lightGreen[700],
                  color: "#FFF"
                }
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

//export default ClienteEntradas
export default withStyles(styles)(ClienteEntradas);

/*
 
class ClienteEntradas extends Component {
state = { ...StateListClienteEntradas };

componentDidMount() {
  this.getClienteEntradasAdmin();
}
 
 
getClienteEntradasAdmin = () => {
  this.setState({
    isLoading: true
  });

  Database.get("/list-clienteentradas", this, null, true).then(
    res => {
      let resultado = [...res.result];
      resultado = resultado.map(elem => {
        return {
          ...elem,
          vencimiento_mostrar: moment(elem.vencimiento).isValid() ? moment.parseZone(elem.vencimiento).format("DD/MM/YYYY") : ""
        };
      });

      this.setState({
        isLoading: false,
        clienteentradas: resultado,
        checked: [],
        menuContext: null,
        botonesAcciones: {
          nuevo: {
            enabled: true,
            texto: "Nuevo"
          },
          editar: {
            enabled: false,
            texto: "Editar"
          },
          delete: {
            enabled: false,
            texto: "Eliminar"
          }
        }
      });
    },
    err => {
      toast.error(err.message);
    }
  );
};

handlePagination = offset => {
  this.setState({
    offset: offset
  });
};


render() {
  let style = {};
  if (this.props.match.url !== this.props.location.pathname) {
    style = { display: "none" };
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={style}>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Auditoria Entradas</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Listado de Entradas
            </p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              isLoading={this.state.isLoading}
              columns={ColumnsListado}
              data={this.state.clienteentradas}
              title=""
              localization={localization}
              components={{
                Container: props => <Paper elevation={0} {...props} />
              }}
              options={{
                filtering: true,
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                exportFileName: "ClienteEntradas " + moment().format("DD-MM-YYYY"),
                exportDelimiter: ";",
                pageSizeOptions: [50, 100, 200, 400, 800],
                pageSize: 50,
              //  maxBodyHeight: 200,  //sticky header
                emptyRowsWhenPaging: false,
                headerStyle: {
                  backgroundColor: lightGreen[700],
                  color: "#FFF"
                }
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
}

export default withStyles(styles)(ClienteEntradas);
*/
