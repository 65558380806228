/* eslint-disable */
//COMPONENTES GENERALES
import React from "react";
import Database from "variables/Database.js";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import moment from "moment";

//COMPONENTES LOCALES
import InputNew from "components/Input/InputNew";
import { localization } from "variables/general.js";
import { toast } from "react-toastify";

//ESTILOS Y COLORES
import { makeStyles } from "@material-ui/core/styles";

//CONTENEDORES
import MaterialTable, { MTableBodyRow } from "material-table";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";

//BOTONES Y VARIOS
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { RadioGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

const useStyles = makeStyles(theme => ({
  root: {
    width: "90%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const columns = [
  { title: "Dato Logístico", field: "descripcion", editable: "never" },
  {
    title: "Artículo",
    field: "ProductosUnidad.descripcion",
    editable: "never"
  },
  {
    title: "Cod. Artículo",
    field: "ProductosUnidad.codigo",
    editable: "never"
  },
  { title: "DUN 14", field: "dun14", editable: "never" },
  { title: "EAN 13", field: "ProductosUnidad.ean13", editable: "never" }
];

function getSteps() {
  return ["Seleccionar un Dato Logístico", "Agregar cantidad de Pallets"];
}

const orderFormCopy = {
  cantidad_contenedores: {
    elementType: "input",
    elementConfig: {
      type: "number",
      label: "Cantidad de Pallets",
      fullWidth: true,
      inputProps: {
        autoFocus: true
      }
    },
    value: "",
    validation: {
      required: true,
      mayor0: true
    },
    valid: false,
    touched: false
  },
  cantidad_bultos: {
    elementType: "input",
    elementConfig: {
      type: "number",
      label: "Bultos por Pallets",
      fullWidth: true,
      inputProps: {}
    },
    value: "",
    validation: {
      required: true,
      mayor0: true
    },
    valid: false,
    touched: false,
    disabled: false // Visualizar campo
  },
  carga_unidad: { // Nuevo campo para "Por Unidad"
    elementType: "input",
    elementConfig: {
      type: "number",
      label: "Por Unidad",
      fullWidth: true,
      inputProps: {}
    },
    value: "",
    validation: {
      required: true,
      mayor0: true
    },
    valid: false,
    touched: false,
    disabled: true // Ocultar campo
  },
  lote: {
    elementType: "input",
    elementConfig: {
      type: "text",
      label: "Lote",
      fullWidth: true,
      inputProps: {}
    },
    value: "",
    validation: {
      required: false
    },
    valid: true,
    touched: false
  }
};

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [fecha, setfecha] = React.useState(null);
  const [orderForm, setOrderForm] = React.useState(
    JSON.parse(JSON.stringify(orderFormCopy))
  );
  const [formIsValid, setFormIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [productos, setProductos] = React.useState([]);
  const [rowProducto, setRowProducto] = React.useState(null);
  const formElementsArray = [];
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  React.useEffect(() => {
    if (props.infoCliente) getProductos(props.infoCliente.id);

    // if(props.rowEditProducto)
    // {
    //   setRowProducto(props.rowEditProducto);
    //   handleNext();
    //   let orderFormAlt = {...orderForm};

    //   orderFormAlt.cantidad.value = props.rowEditProducto.cantidad_requerida;
    //   orderFormAlt.cantidad.validation.asignada = true;
    //   setOrderForm(orderFormAlt);

    // }

    if (props.rowProductoPresentacion) {
      setRowProducto(props.rowProductoPresentacion);
      handleNext();
    }
  }, []);

  const getProductos = id => {
    setIsLoading(true);
    Database.get("/list-productos-presentacion/" + id, this).then(
      res => {
        setIsLoading(false);

        let resultado = [...res.result];
        console.log(resultado);

        setProductos(resultado);
        /*
                $(".MuiDialog-root input").each(function (index, element) {
                    if (index == 0)
                        element.focus();
                })
                */
      },
      err => {
        setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleFinish = () => {
    let objeto = {};
    for (let key in orderForm) {
      objeto[key] = orderForm[key].value;
    }

    // Validar si cantidad_bultos está vacío
    if (orderForm.cantidad_bultos.value === "") {
      
      // Verificamos siempre si carga_unidad es mayor a cantidad_unidades_bulto
      if (orderForm.carga_unidad.value > rowProducto.cantidad_unidades_bulto){
        // Redondeamos valor para asignarle la cantidad de bulto
        objeto.cantidad_bultos = Math.round(orderForm.carga_unidad.value / rowProducto.cantidad_unidades_bulto);
      }

    }
    if (fecha && fecha != "Invalid Date") {
      let fechaString = moment(fecha).format("YYYY-MM-DD");
      let fecha_utc = moment.utc(fechaString).format();
      objeto.vencimiento = fecha_utc;
    }

    props.onClickProducto(rowProducto, objeto);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDateChange = (date, value) => {
    setfecha(date);
  };
  const getStepContent = stepIndex => {
    // Añadir un estado para el campo seleccionado
    const [selectedField, setSelectedField] = React.useState('select_cantidad_bultos'); // Inicializado con 'cantidad_bultos' por defecto
    // Definir una función para manejar el cambio del campo seleccionado
    const handleFieldChange = event => {
      const selectedValue = event.target.value;
      setSelectedField(selectedValue);

      // Modificar el estado del formulario según la selección del campo
      if (selectedValue === 'select_cantidad_bultos') {
        const updatedOrderForm = {
          ...orderFormCopy,
          carga_unidad: {
            ...orderFormCopy.carga_unidad,
            disabled: true,  // Ocultar el campo cantidad_unidad
            value: "" 
          },
          cantidad_bultos: {
            ...orderFormCopy.cantidad_bultos,
            disabled: false  // Mostrar el campo cantidad_bultos
          }

        };
        
        //console.log('Ocultar cantidad_unidad ')
        setOrderForm(updatedOrderForm);
      } else if (selectedValue === 'select_cantidad_unidad') {
        const updatedOrderForm = {
          ...orderFormCopy,
          carga_unidad: {
            ...orderFormCopy.carga_unidad,
            disabled: false  // Mostrar el campo cantidad_unidad    
          },
          cantidad_bultos: {
            ...orderFormCopy.cantidad_bultos,
            disabled: true,  // Ocultar el campo cantidad_unidad  
            value: ""  
          },
          cantidad_contenedores: {
            ...orderFormCopy.cantidad_contenedores,
            validation: {
              required: true,
              mayor0: true,
              max1: true 
            }
          }


        };
        //console.log('Ocultar cantidad_bultos ')
        setOrderForm(updatedOrderForm);
      }
    };

    switch (stepIndex) {
      case 0:
        return (
          <MaterialTable
            isLoading={isLoading}
            columns={columns}
            data={productos}
            title="Seleccionar Dato Logístico"
            localization={localization}
            onRowClick={(event, rowData) => {
              let orderFormAlt = JSON.parse(JSON.stringify(orderFormCopy));
              console.log(rowData);
              if (rowData.cantidad_bultos_capa && rowData.cantidad_capas) {
                orderFormAlt.cantidad_bultos.value =
                  parseInt(rowData.cantidad_bultos_capa) *
                  parseInt(rowData.cantidad_capas);
                orderFormAlt.cantidad_bultos.valid = true;
              }

              setOrderForm(orderFormAlt);
              setfecha(null);
              setFormIsValid(false);
              setRowProducto(rowData);

              handleNext();
            }}
            components={{
              Container: props => <Paper elevation={0} {...props} />
            }}
          />
        );

      case 1:
        console.log(rowProducto);
        return (
          <React.Fragment>
            <p>
              <span style={{ fontWeight: "300" }}>Articulo: </span>
              {rowProducto.ProductosUnidad.descripcion}
              <br />
              EAN13: {rowProducto.ProductosUnidad.ean13}
              <br />
              Cod. Artículo: {rowProducto.ProductosUnidad.codigo}
              <br />
            </p>

            <p>
              <span style={{ fontWeight: "300" }}>Dato Logístico: </span>
              {rowProducto.descripcion}
              <br />
              DUN14: {rowProducto.dun14}
              <br />
              unidades x bulto: {rowProducto.cantidad_unidades_bulto}, unidades
              x display : {rowProducto.cantidad_unidades_diplays}, display x
              bulto: {rowProducto.cantidad_displays_bulto}
            </p>
            {/* <p><span style={{ fontWeight:'300'}}>Unidad: </span>
                      {rowProducto.unidad}</p> */}

            {/* Muestra los campos correspondientes según la opción seleccionada */}
            {/* Título */}
            <Typography variant="h6" gutterBottom>
              Cargar por:
            </Typography>

            {/* Selectores de forma horizontal */}
            <RadioGroup
              row
              aria-label="field"
              name="field"
              value={selectedField}
              onChange={handleFieldChange}
            >
              <FormControlLabel
                value="select_cantidad_bultos"
                control={<Radio color="primary" />}
                label="Bultos por Pallets"
                labelPlacement="end"
              />
              <FormControlLabel
                value="select_cantidad_unidad"
                control={<Radio color="primary" />}
                label="Por Unidad"
                labelPlacement="end"
              />
            </RadioGroup>
            {selectedField === 'select_cantidad_bultos' && (
              console.log('Se selecciono : ' +  selectedField)
            )}

            {selectedField === 'select_cantidad_unidad' && (
              console.log('Se selecciono : ' + selectedField)
            )}  

            {formElementsArray.map(formElement => (
              <InputNew
                objeto={formElement.config}
                changed={event => {
                  let objeto = inputChangedHandler(
                    event,
                    formElement.id,
                    orderForm
                  );
                  setOrderForm(objeto.orderForm);
                  setFormIsValid(objeto.formIsValid);
                }}
              />
            ))}
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="vencimiento"
                label="Fecha de Vencimiento"
                format="dd/MM/yyyy"
                value={fecha}
                onChange={handleDateChange}
                autoOk={true}
                cancelLabel={"Cancelar"}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </React.Fragment>
        );
      default:
        return "Paso no seleccionado";
    }
  };

  for (let key in orderForm) {
    formElementsArray.push({
      id: key,
      config: orderForm[key]
    });
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {getStepContent(activeStep)}
      <div style={{ marginTop: "2em" }}>
        {!props.rowEditProducto && !props.rowProductoPresentacion && (
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Atras
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            disabled={!formIsValid}
            onClick={handleFinish}
          >
            Agregar
          </Button>
        ) : null}
      </div>
    </div>
  );
}
