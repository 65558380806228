import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// Iconos
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import CachedIcon from "@material-ui/icons/Cached";
import BlockIcon from "@material-ui/icons/Block";

import Tooltip from "@material-ui/core/Tooltip";
// DB
import Database from "variables/Database.js";
import { toast } from "react-toastify";
// Redux
import { connect } from "react-redux";
import * as actionCreators from "store/actions/index";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    // filter: `blur(0px)!important`
  },
  textField: {
    marginBottom: "10px"
  },
  blurry: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    filter: `blur(1px)`
  }
}));

const CamposMapeados = function(props) {
  const [editable, setEditable] = React.useState(true);
  const [label, setLabel] = React.useState(props.text_der);
  const [input, setInput] = React.useState(props.text_der);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [isDeleted, setIsDeleted] = React.useState(false);

  const classes = useStyles();

  function toggleEditable() {
    setEditable(!editable);
  }

  function saveMapping() {
    toggleEditable();
    let data = { id: props.db_id, csv: input };
    Database.post(`/set-campo-csv`, { data }, this).then(
      res => {
        toast.success("Campo CSV guardado!");
      },
      err => {
        toast.error(err.message);
      }
    );
    setLabel(input);
    props.touch_reload_table();
  }

  function handleKeyPress(e) {
    if (e.keyCode === 13) {
      saveMapping();
    }
  }

  function handleisEnabled() {
    if (isEnabled) {
      props.removeCampo(props.db_id);
    } else {
      props.addCampo(props.db_id);
    }
    setIsEnabled(!isEnabled);
  }

  function handleDeleteField() {
    // props.toggleConfirmDialog(props.db_id)
    setIsDeleted(true);
  }

  if (isDeleted) return null;

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={3} className={isEnabled ? classes.root : classes.blurry}>
          <Tooltip title={props.tabla_db}>
            {/* <TextField
              id={props.db_id}
              data-id={props.db_id}
              className={classes.textField}
              label={props.text_izq}
              disabled={false}
              fullWidth={true}
            /> */}
            <Typography>- {props.text_izq}:</Typography>
          </Tooltip>
        </Grid>
        <Grid container item spacing={1} xs={8} alignItems="flex-start">
          <Grid
            item
            xs={5}
            className={isEnabled ? classes.root : classes.blurry}
          >
            <TextField
              className={classes.textField}
              label={label}
              disabled={editable}
              onChange={event => {
                setInput(event.target.value);
              }}
              onKeyDown={e => {
                handleKeyPress(e);
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Editar el nombre campo del csv">
              <IconButton
                color="primary"
                aria-label="editar"
                component="span"
                disabled={isEnabled ? !editable : true}
                onClick={toggleEditable}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Guardar el cambio">
              <IconButton
                color="primary"
                aria-label="editar"
                component="span"
                disabled={editable}
                onClick={saveMapping}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
            {isEnabled ? (
              <Tooltip title="No utilizar el campo">
                <IconButton
                  style={{ color: "#ff867c" }}
                  aria-label="no-usar"
                  component="span"
                  disabled={!isEnabled}
                  onClick={handleisEnabled}
                >
                  <BlockIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Utilizar este campo">
                <IconButton
                  color="primary"
                  aria-label="editar"
                  component="span"
                  disabled={isEnabled}
                  onClick={handleisEnabled}
                  className={classes.root}
                >
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* <Tooltip title='Eliminar este campo'>
              <IconButton
                style={{ color: '#ff867c' }}
                aria-label='editar'
                component='span'
                disabled={false}
                onClick={handleDeleteField}
                className={classes.root}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    campos_habilitados: state.importacionmasiva.campos_habilitados,
    confirmDialog: state.importacionmasiva.confirmDialog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCampo: campo => dispatch(actionCreators.addCampo(campo)),
    removeCampo: campo => dispatch(actionCreators.removeCampo(campo)),
    toggleConfirmDialog: db_id =>
      dispatch(actionCreators.toggleConfirmDialog(db_id)),
    touch_reload_table: () => dispatch(actionCreators.touch_reload_table())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CamposMapeados);
