/* eslint-disable */
import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Route, Switch, Link } from "react-router-dom";
// core components
import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";

import NewProductoUnidad from "./components/NewProductoUnidad";
import ModalDeleteUnidad from "./components/ModalDelete";
import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import { StateListProductosUnidad, ColumnsListado } from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class ProductosUnidad extends Component {
  state = { ...StateListProductosUnidad };

  componentDidMount() {
    this.getListado();
  }

  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE CALLES
  ////////////////////////
  ////////////////////////
  getListado = () => {
    this.setState({
      isLoading: true
    });

    //alert(process.env.REACT_APP_ID_ALMACEN);

    Database.get("/list-productos-unidad/", this).then(
      res => {
        let resultado = [...res.result];
        console.log(resultado);
        this.setState({
          isLoading: false,
          listado: resultado
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  editItem = value => {
    this.props.history.push(this.props.match.url + "/editar/" + value);
  };

  handleDelete = rowData => {
    Database.post("/delete-producto-unidad", { id: rowData.id }, this).then(
      res => {
        let listado = [...this.state.listado];
        listado = listado.filter(elem => {
          if (elem.id == rowData.id) return false;

          return true;
        });

        this.setState(
          {
            listado: listado,
            openDeleteDialog: false
          },
          () => {
            toast.success("La referencia se ha eliminado con exito!");
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    });
  }

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>
                Maestro de Referencias
              </h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Referencias
              </p>
            </CardHeader>
            <CardBody>
              <Button
                style={{ marginTop: "25px" }}
                onClick={() =>
                  this.props.history.push(this.props.match.url + "/nuevo")
                }
                color="primary"
              >
                <AddIcon /> Nueva Referencia
              </Button>
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListado}
                data={this.state.listado}
                title=""
                localization={localization}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar Referencia",
                    onClick: (event, rowData) =>
                      this.props.history.push(
                        this.props.match.url + "/editar/" + rowData.id
                      )
                  },
                  {
                    icon: "delete",
                    tooltip: "Borrar Referencia",
                    onClick: (event, rowData) =>
                      this.handleDeleteButton(rowData)
                  }
                ]}
                components={{
                  Container: props => <Paper elevation={0} {...props} />
                }}
                options={{
                  //filtering: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName:
                    "Referencias " + moment().format("DD-MM-YYYY"),
                  exportDelimiter: ";",
                  headerStyle: {
                    backgroundColor: lightGreen[700],
                    color: "#FFF"
                  }
                }}
                detailPanel={rowData => {
                  return (
                    <div>
                      <p style={{ fontSize: "115%" }}>
                        Información Adicional Referencia
                      </p>
                      <p>
                        Alto: {rowData.alto} (cm), Largo: {rowData.largo} (cm),
                        Ancho: {rowData.ancho} (cm)
                      </p>
                    </div>
                  );
                }}
              />
            </CardBody>
          </Card>

          <Switch>
            <Route
              path={this.props.match.url + "/nuevo"}
              render={() => (
                <NewProductoUnidad getListado={() => this.getListado()} />
              )}
            />

            <Route
              path={this.props.match.url + "/editar/:idProductoUnidad"}
              render={() => (
                <NewProductoUnidad getListado={() => this.getListado()} />
              )}
            />
          </Switch>
        </GridItem>
        <ModalDeleteUnidad
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={rowData => this.handleDelete(rowData)}
        />
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ProductosUnidad);
