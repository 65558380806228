import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

// Redux
import { connect } from "react-redux";
import * as actionCreators from "store/actions/index";

// DB
import Database from "variables/Database.js";
import { toast } from "react-toastify";

var ALLOW_NULL_FIELDS = true;
var FILAS_CON_ERRORES = [];
var FILAS_SIN_ERRORES = 0;
var PROBLEMAS_STRING = [];
var PROBLEMAS_STRING_FILAS = [];
var CSV_DEPURADO = [];

const ValidarCSV = props => {
  // const [campos_db, setCamposDB] = React.useState([])
  const [campos_a_filtrar, setCamposAFiltrar] = React.useState([]);
  const [csv_depurado, setCsvDepurado] = React.useState([]);
  const [csv_valido, setCsvValido] = React.useState(false);
  const [tooltip_text_validar, setTooltip_text_validar] = React.useState(false);
  const [tooltip_text_importar, setTooltip_text_importar] = React.useState(
    "Debe primero validar el archivo .csv"
  );

  React.useEffect(() => {
    if (props.shouldRender) {
      setTooltip_text_validar("Validar el archivo CSV");
    } else {
      setTooltip_text_validar("Debe primero cargar un archivo .csv");
    }
  }, [props.shouldRender]);

  // let csv_cargado = props.csv.length > 0
  // const tooltip_text_validar = csv_cargado
  //   ? 'Validar el archivo CSV'
  //   : 'Debe primero cargar un archivo .csv'

  const resetLogs = () => {
    FILAS_CON_ERRORES = [];
    FILAS_SIN_ERRORES = 0;
    PROBLEMAS_STRING = [];
    PROBLEMAS_STRING_FILAS = [];
    CSV_DEPURADO = [];
    setCsvDepurado([]);
    setCsvValido(false);
  };

  React.useEffect(() => {
    //   let campos_a_filtrar_tmp = campos_db.filter( (el) => { return props.campos_habilitados.includes(`${el.id}`)})

    let campos_a_filtrar_tmp = [
      { id: 1, campo_csv: "pedido" },
      { id: 2, campo_csv: "destino" },
      { id: 3, campo_csv: "id_producto_presentacion" },
      { id: 4, campo_csv: "cantidad_contenedores" },
      { id: 5, campo_csv: "cantidad_bultos" },
      { id: 6, campo_csv: "cantidad_displays" },
      { id: 7, campo_csv: "cantidad_unidades" }
    ];

    setCamposAFiltrar(campos_a_filtrar_tmp);
    // alert(campos_a_filtrar_tmp);
    FILAS_CON_ERRORES = [];
  }, []);

  React.useEffect(() => {
    resetLogs();
  }, [props.csvData]);

  React.useEffect(() => {
    // Verificar si el CSV es válido
    const esCsvValido = csv_depurado.length > 0;
    setCsvValido(esCsvValido);
  }, [csv_depurado]);

  const importarCSV = () => {
    // Crear un objeto para almacenar los pedidos agrupados por par destino-pedido
    const pedidosPorPar = {};

    // Recorrer cada fila del CSV y agrupar los pedidos por par destino-pedido
    csv_depurado.forEach(fila => {
      const pedido = fila.pedido;
      const destino = fila.destino;
      const par = `${destino}-${pedido}`;

      if (par in pedidosPorPar) {
        pedidosPorPar[par].push(fila);
      } else {
        pedidosPorPar[par] = [fila];
      }
    });

    // Generar un nuevo CSV solo con los campos restantes para los pedidos con par destino-pedido repetido
    const nuevoCSV = [];
    for (const par in pedidosPorPar) {
      const pedidos = pedidosPorPar[par];

      if (pedidos.length > 1) {
        const camposRestantes = Object.keys(pedidos[0]).filter(
          campo => campo !== "pedido" && campo !== "destino"
        );

        const nuevosPedidos = pedidos.map(pedido => {
          const nuevoPedido = { ...pedido };
          camposRestantes.forEach(campo => {
            nuevoPedido[campo] = pedido[campo].toString();
          });
          return nuevoPedido;
        });

        nuevoCSV.push(nuevosPedidos);
      } else {
        nuevoCSV.push(pedidos[0]);
      }
    }

    // console.log('este es el nuevo CSV', nuevoCSV)

    // Inicializar el contador
    let completedIterations = 0;

    nuevoCSV.forEach((pedido, index) => {
      setTimeout(() => {
        // Verificar si el elemento es un arreglo de objetos o un objeto
        if (Array.isArray(pedido)) {
          // Crear un nuevo arreglo sin los campos "pedido" y "destino"
          const pedidosSinCampos = pedido.map(p => {
            const pedidoSinCampos = {
              id_producto_presentacion: p.id_producto_presentacion,
              cantidad_contenedores: p.cantidad_contenedores,
              cantidad_bultos: p.cantidad_bultos,
              cantidad_displays: p.cantidad_displays,
              cantidad_unidades: p.cantidad_unidades
            };
            return pedidoSinCampos;
          });

          // console.log('Estos serían los pedidos:', pedidosSinCampos)
          Database.post("/clientes-insert-pedido-csv", {
            csv: pedidosSinCampos,
            descripcion: pedido[0].pedido,
            destino: pedido[0].destino
          }).then(
            res => {
              // Éxito en la inserción
              toast.success(
                `Pedidos insertados con éxito: ${pedido[0].pedido} - ${pedido[0].destino}`
              );
              // Incrementar el contador
              completedIterations++;

              // Verificar si todas las iteraciones han sido completadas
              if (completedIterations === nuevoCSV.length) {
                toast.success("Todos los pedidos fueron creados con éxito!");
                props.setShouldRender(false);
              }
            },
            err => {
              // Error en la inserción
              console.error(err.message);
              toast.error(err.message);
            }
          );
        } else {
          // Crear un nuevo objeto sin los campos "pedido" y "destino"
          const { pedido: pedidoFilt, destino } = pedido;

          const pedidosSinCampos = [
            {
              id_producto_presentacion: pedido.id_producto_presentacion,
              cantidad_contenedores: pedido.cantidad_contenedores,
              cantidad_bultos: pedido.cantidad_bultos,
              cantidad_displays: pedido.cantidad_displays,
              cantidad_unidades: pedido.cantidad_unidades
            }
          ];

          // console.log('Estos serían los pedidos:', pedidosSinCampos)
          Database.post("/clientes-insert-pedido-csv", {
            csv: pedidosSinCampos,
            descripcion: pedidoFilt,
            destino: destino
          }).then(
            res => {
              // Éxito en la inserción
              toast.success(
                `Pedido insertado con éxito: ${pedido.pedido} - ${pedido.destino}`
              );
              // Incrementar el contador
              completedIterations++;

              // Verificar si todas las iteraciones han sido completadas
              if (completedIterations === nuevoCSV.length) {
                // Si deseas mostrar un mensaje al finalizar todas las inserciones
                toast.success("Todos los pedidos fueron creados con éxito!");
                props.setShouldRender(false);
              }
            },
            err => {
              // Error en la inserción
              console.error(err.message);
              toast.error(err.message);
            }
          );
        }
      }, index * 1000); // Ajusta el valor de 1000 según la cantidad de tiempo de demora deseada entre cada inserción
    });
  };

  const _check_is_valid_field = (csv_row, field, csv_index) => {
    try {
      if (ALLOW_NULL_FIELDS) {
        return true;
      } else {
        if (csv_row[field] !== null) {
          return true;
        } else {
          FILAS_CON_ERRORES.push(csv_index);
          let msg = `La fila ${csv_index +
            1} contiene el campo ${field} como inexistente`;
          PROBLEMAS_STRING_FILAS.push(msg);
          return false;
        }
      }
    } catch (e) {
      console.log(e);
      FILAS_CON_ERRORES.push(csv_index);
      return false;
    }
  };

  const _check_row_has_field = (csv_row, field, csv_index) => {
    try {
      if (csv_row.hasOwnProperty(field)) {
        return true;
      } else {
        let msg = `La tabla no contiene la columna: ${field}`;
        if (!PROBLEMAS_STRING.includes(msg)) {
          PROBLEMAS_STRING.push(msg);
        }
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const appendRow = csv_row => {
    CSV_DEPURADO.push(csv_row);
  };

  const validarCSV = () => {
    resetLogs();
    for (let i = 0; i < props.csvData.length; i++) {
      let fila_csv = props.csvData[i];
      var fila_con_error = false;
      for (let c of campos_a_filtrar) {
        let test_1 = _check_row_has_field(fila_csv, c.campo_csv, i + 1);
        let test_2 = _check_is_valid_field(fila_csv, c.campo_csv, i + 1);
        // console.log(`test 1: ${test_1} y test_2: ${test_2} for ${c.campo_csv}`);
        if (!test_1 || !test_2) {
          fila_con_error = true;
        }
      }
      if (!fila_con_error) {
        appendRow(fila_csv);
        FILAS_SIN_ERRORES++;
      }
    }
    if (FILAS_CON_ERRORES.length > 0) {
      toast.error(
        `Existen ${FILAS_CON_ERRORES.length} productos solicitados con problemas`,
        { autoClose: false }
      );
    }

    if (PROBLEMAS_STRING.length > 0) {
      PROBLEMAS_STRING.map(el => toast.error(el, { autoClose: false }));
    }

    if (FILAS_SIN_ERRORES > 0) {
      toast.success(`${FILAS_SIN_ERRORES} productos solicitados correctamente`);
    }

    if (CSV_DEPURADO.length > 0) {
      setCsvDepurado(CSV_DEPURADO);
      setCsvValido(true);
      setTooltip_text_importar("Cargar el archivo CSV");
    }
  };

  return (
    <>
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        direction="row"
        style={{ gap: "200px" }}
      >
        {" "}
        <Tooltip title={tooltip_text_validar}>
          <Grid item>
            <Button
              style={{ marginTop: "25px" }}
              variant="contained"
              color="primary"
              disabled={!props.shouldRender}
              onClick={validarCSV}
            >
              Validar CSV
            </Button>
          </Grid>
        </Tooltip>
        <Tooltip title={tooltip_text_importar}>
          <Grid item>
            <Button
              style={{ marginTop: "25px" }}
              variant="contained"
              color="primary"
              disabled={!csv_valido && !props.validacion}
              onClick={importarCSV}
            >
              Crear Pedido
            </Button>
          </Grid>
        </Tooltip>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    // campos_habilitados: state.importacionmasiva.campos_habilitados,
    csv: state.importacionmasiva.csvData
    // campos_cargados: state.importacionmasiva.campos_cargados,
    // reload_table: state.importacionmasiva.reload_table,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cargaCsv: csvData => dispatch(actionCreators.cargaCsv(csvData))
    // touch_campos_cargados: () => dispatch(actionCreators.touch_campos_cargados()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidarCSV);
