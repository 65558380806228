export const StateListHijos = {
  hijos: [],
  offset: 0,
  checked: [],
  menuContext: null,
  botonesAcciones: {
    nuevo: {
      enabled: true,
      texto: "Nuevo"
    },
    editar: {
      enabled: false,
      texto: "Editar"
    },
    delete: {
      enabled: false,
      texto: "Eliminar"
    }
  },
  modalOpen: false,
  openDeleteDialog: false,
  deleteRowData: null,
  isLoading: false
};

export const StateEditHijo = {
  idEmpleado: null,
  nombreEmpleado: null,
  editHijoForm: {
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    apellido: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Apellido",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    certificado_foto: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Adjuntar Certificado",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  fechaVencimiento: null,
  hijoEdit: null,
  editFormIsValid: false,
  successSubmitEdit: null,
  disableAllButtons: false,
  openChangePass: false
};

export const StateNewHijo = {
  idEmpleado: null,
  nombreEmpleado: null,
  newHijoForm: {
    nombre: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Nombre",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    apellido: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Apellido",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    },
    certificado_foto: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Adjuntar Certificado",
        fullWidth: true
      },
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  fechaVencimiento: null,
  formIsValid: false,
  successSubmit: null,
  disableAllButtons: false
};

export const ColumnsListado = [
  { title: "Legajo", field: "numero_legajo" },
  { title: "Apellido Empleado", field: "apellido_empleado" },
  { title: "Nombre Empleado", field: "nombre_empleado" },
  { title: "Apellido", field: "apellido" },
  { title: "Nombre", field: "nombre" },
  { title: "Adjuntar Foto", field: "certificado_foto" },
  { title: "Fecha Vencimiento", field: "fecha_vencimiento" }
];
