import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SelectorClientes from "./SelectorClientesNew";

export default function ModalBuscarCliente(props) {
  const [selectedCliente, setSelectedCliente] = React.useState([null]);
  const [enabledButton, setButtonStatus] = React.useState(false);

  const getSelectedCliente = cliente => {
    setSelectedCliente(cliente);
    if (cliente != null) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  };

  return (
    <Dialog
      open={props.openBuscarClienteDialog}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Buscar un Cliente {enabledButton}
      </DialogTitle>
      <DialogContent>
        <SelectorClientes
          getDataSelectedCliente={c => getSelectedCliente(c)}
        ></SelectorClientes>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => props.handleAccept(selectedCliente)}
          color="primary"
          autoFocus
          disabled={!enabledButton}
        >
          Seleccionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
