import React, { Component } from 'react'
import Database from 'variables/Database.js'
import moment from 'moment'

import { Route, Switch } from 'react-router-dom'
// core components
// import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import MaterialTable from 'material-table'
import { withStyles } from '@material-ui/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Card from 'components/Card/Card.js'
import Paper from '@material-ui/core/Paper'
import Button from 'components/CustomButtons/Button.js'
import AddIcon from '@material-ui/icons/Add'
import lightGreen from '@material-ui/core/colors/lightGreen'

import NewProductoPresentacion from './components/NewProductoPresentacion'
import ModalDelete from './components/ModalDelete'
import { localization } from 'variables/general.js'

import { toast } from 'react-toastify'

import {
  StateListProductosPresentacion,
  ColumnsListadoPresentacion,
} from './variablesState/variablesStatePresentacion'

import * as accs from './FuncionesAccesorias'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
}

class ProductosPresentacion extends Component {
  state = { ...StateListProductosPresentacion }

  readableDataListado = {}

  componentDidMount() {
    this.getListado()
  }

  getListado = () => {
    this.setState({
      isLoading: true,
    })

    Database.get('/list-productos-presentacion-productos-join/', this).then(
      (result) => {
        let resultado = [...result.result]
        // console.log('list', resultado)
        this.setState({
          isLoading: false,
          listado: resultado,
        })
      },
      (err) => {
        toast.error(err.message)
      }
    )
  }

  editItem = (value) => {
    this.props.history.push(this.props.match.url + '/editar/' + value)
  }

  handleDelete = (rowData) => {
    Database.post(
      '/delete-producto-presentacion',
      { id: rowData.id },
      this
    ).then(
      (res) => {
        let listado = [...this.state.listado]
        listado = listado.filter((elem) => {
          if (elem.id === rowData.id) return false
          return true
        })
        this.setState(
          {
            listado: listado,
            openDeleteDialog: false,
          },
          () => {
            toast.success('El Dato Logístico se ha eliminado con exito!')
          }
        )
      },
      (err) => {
        toast.error(err.message)
      }
    )
  }

  handleDeleteButton = (rowData) => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData,
    })
  }

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null,
    })
  }

  changeIntToSiNoValues() {
    const stateCopia = JSON.parse(JSON.stringify(this.state.listado))
    let siNoDataListado = accs.changeIntValueToSioNoAllDisplays(stateCopia)
    return siNoDataListado
  }

  render() {
    let style = {}

    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: 'none' }
    }

    let newDataListado = this.changeIntToSiNoValues()

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color='primary'>
              <h4 className={this.props.classes.cardTitleWhite}>
                Datos Logísticos
              </h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Datos Logísticos
              </p>
            </CardHeader>
            <CardBody>
              <Button
                style={{ marginTop: '25px' }}
                onClick={() =>
                  this.props.history.push(this.props.match.url + '/nuevo')
                }
                color='primary'
              >
                <AddIcon /> Nuevo Dato Logístico
              </Button>

              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListadoPresentacion}
                data={newDataListado}
                title=''
                localization={localization}
                actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Editar Dato Logístico',
                    onClick: (event, rowData) =>
                      this.props.history.push(
                        this.props.match.url + '/editar/' + rowData.id
                      ),
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Borrar Dato Logístico',
                    onClick: (event, rowData) =>
                      this.handleDeleteButton(rowData),
                  },
                ]}
                components={{
                  Container: (props) => <Paper elevation={0} {...props} />,
                }}
                options={{
                  //filtering: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName:
                    'Datos_Logísticos ' + moment().format('DD-MM-YYYY'),
                  exportDelimiter: ';',
                  headerStyle: {
                    backgroundColor: lightGreen[700],
                    color: '#FFF',
                  },
                }}
                detailPanel={(rowData) => {
                  let styleHide = { display: 'none' }
                  let styleShow = { display: 'block' }
                  let styleTitleDetail = {
                    fontSize: '115%',
                    color: 'green',
                  }
                  let styleDescripcion = { ...styleShow }
                  let styleDisplay = { ...styleShow }
                  let styleBulto = { ...styleShow }
                  let unidades_bulto_label = ''
                  let unidades_bulto_cantidad = ''

                  if (rowData.descripcion === null) {
                    styleDescripcion = { ...styleHide }
                  }

                  if (rowData.display === 'No') {
                    styleDisplay = { ...styleHide }
                    unidades_bulto_label = 'Unidades por bulto'
                    unidades_bulto_cantidad = rowData.cantidad_unidades_bulto
                  } else {
                    unidades_bulto_label = 'Displays por bulto'
                    unidades_bulto_cantidad = rowData.cantidad_displays_bulto
                  }

                  if (rowData.bulto === 'No') {
                    styleBulto = { ...styleHide }
                  }

                  return (
                    <div>
                      <div style={styleDescripcion}>
                        <p style={styleTitleDetail}>Descripción</p>
                        <p>{rowData.descripcion}</p>
                      </div>
                      <div style={styleDisplay}>
                        <p style={styleTitleDetail}>
                          Información sobre Display
                        </p>
                        <p>
                          cantidad de Unidades:{' '}
                          {rowData.cantidad_unidades_display} <br />
                          Alto: {rowData.alto_display} (cm), Largo:{' '}
                          {rowData.largo_display} (cm), Ancho:{' '}
                          {rowData.ancho_display} (cm)
                        </p>
                      </div>
                      <div style={styleBulto}>
                        <p style={styleTitleDetail}>Información sobre Bulto</p>
                        <p>
                          {unidades_bulto_label}: {unidades_bulto_cantidad}{' '}
                          <br />
                          Alto: {rowData.alto_bulto} (cm), Largo:{' '}
                          {rowData.largo_bulto} (cm), Ancho:{' '}
                          {rowData.ancho_bulto} (cm)
                        </p>
                      </div>
                      <div>
                        <p style={styleTitleDetail}>Información sobre Capa</p>
                        <p style={styleBulto}>
                          Cantidad de bultos por capa:{' '}
                          {rowData.cantidad_bultos_capa}{' '}
                        </p>
                        <p style={{ lineHeight: '0' }}>
                          {' '}
                          Cantidad de capas: {rowData.cantidad_capas}
                        </p>
                      </div>
                    </div>
                  )
                }}
              />
            </CardBody>
          </Card>

          <Switch>
            <Route
              path={this.props.match.url + '/nuevo'}
              render={() => (
                <NewProductoPresentacion getListado={() => this.getListado()} />
              )}
            />

            <Route
              path={this.props.match.url + '/editar/:idProductoPresentacion'}
              render={() => (
                <NewProductoPresentacion getListado={() => this.getListado()} />
              )}
            />
          </Switch>
        </GridItem>
        <ModalDelete
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={(rowData) => this.handleDelete(rowData)}
          disableAllButtons={this.state.disableAllButtons}
        />
      </GridContainer>
    )
  }
}

export default withStyles(styles)(ProductosPresentacion)
