import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import { StateMuelles } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditMuelle extends Component {
  state = JSON.parse(JSON.stringify(StateMuelles));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  getMuelleEdit = id => {
    // alert('/list-muelle/');
    Database.get("/list-muelle/" + id, this).then(
      res => {
        let resultado = res.result[0] || null;

        if (resultado.length > 0) {
          let objeto = resultado[0];
          let orderFormCopy = { ...this.state.orderForm };

          for (let key in orderFormCopy) {
            if (objeto[key]) orderFormCopy[key].value = objeto[key];
          }

          let { orderForm, formIsValid } = inputAllChangedHandler(
            orderFormCopy
          );

          this.setState({
            orderForm: orderForm,
            formIsValid: formIsValid
          });
        }

        //   }
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitForm = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }
      this.setState({
        disableAllButtons: true
      });
      Database.post(`/update-muelle`, {
        id: this.props.match.params.idmuelle,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              toast.success("El muelle se ha modificado con exito!");
              this.props.getMuellesAdmin();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
    } else {
      toast.error("verificar los campos en el formulario");
    }
  };

  // resetEditForm = () => {
  //   let editMuelleFormAlt = { ...this.state.editMuelleForm };
  //   let successSubmitEdit = this.state.successSubmitEdit;
  //   for (let key in editMuelleFormAlt) {
  //     editMuelleFormAlt[key].value = ''
  //   }

  //   this.setState({
  //     editFormIsValid: false,
  //     successSubmitEdit: successSubmitEdit
  //   })

  // }

  componentDidMount() {
    // this.getMuellesType();
    this.getMuelleEdit(this.props.match.params.idmuelle);
  }

  render() {
    const formElementsArray = [];

    //console.log(this.state.orderForm);
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitForm(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>Editar Muelle</h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del muelle
            </p>
          </CardHeader>
          <CardBody>
            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <InputNew
                  key={"input-" + formElement.id}
                  objeto={formElement.config}
                  changed={event => {
                    let { orderForm, formIsValid } = inputChangedHandler(
                      event,
                      formElement.id,
                      this.state.orderForm
                    );
                    this.setState({
                      orderForm: orderForm,
                      formIsValid: formIsValid
                    });
                  }}
                />
              ))}
            </div>
            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/muelles")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>
    ];
  }
}

export default withRouter(withStyles(styles)(EditMuelle));
