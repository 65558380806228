import React, { useEffect } from "react";
// style
import lightGreen from "@material-ui/core/colors/lightGreen";
// material table
import MaterialTable from "material-table";
import { localization } from "variables/general.js";
//redux
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import Database from "variables/Database.js";
import { toast } from "react-toastify";
import { GiForklift } from "react-icons/gi";

const isInLote = (rowData, props) => {
  let test = props.contenedoresEnLoteDeSalida.filter(c => {
    return c.id === rowData.id;
  });
  return test.length == 0;
};

// const restaBultosCantidadSeleccionada = (rowdata, cantidad) => {
//     let numero_bultos rowdata.bultos_totales
// }

const cantidadDeUnidadesASalida = (rowdata, props) => {
  if (rowdata.id) {
    props.setMaximosCantidadPicking(
      rowdata.bultos_totales,
      rowdata.displays_totales,
      rowdata.unidades_totales
    );
    props.setContenedoresUbicacionesPicking(rowdata.contenedores);
    props.setTipoPickingUbicacion(rowdata.tipo_picking);
    props.setDatosUbicacionPicking(rowdata);
  }
};

export let localization_options = { header: { actions: "" } };
localization_options = {
  ...JSON.parse(JSON.stringify(localization)),
  ...localization_options
};

export const columnasTablaPicking = [
  { title: "Ubicacion", field: "nombre_ubicacion" },
  { title: "Artículo", field: "nombre_producto_unidad" },
  { title: "Codigo", field: "codigo" },
  { title: "Producto", field: "producto" },
  { title: "EAN13", field: "ean13" },
  { title: "Tipo Picking", field: "tipo_picking" },
  { title: "Cantidad de Unidades", field: "unidades_totales" },
  { title: "Cantidad de Bultos Estimada", field: "bultos_totales" },
  { title: "Cantidad de Displays Estimada", field: "displays_totales" }
];

function TablaPickingASalida(props) {
  const [table_title, setTableTitle] = React.useState(null);

  useEffect(() => {
    Database.get("/get-tablas-ubicaciones-picking").then(
      result => {
        let ubicacionesPicking = [...result.result];
        props.setUbicacionesPicking(ubicacionesPicking);
      },
      err => {
        toast.error(err.message);
      }
    );
  }, [props.reloadPicking]);

  return (
    <MaterialTable
      id="tabla-salidas"
      isLoading={props.isLoadingPicking}
      columns={columnasTablaPicking}
      data={props.ubicacionesPicking}
      title={table_title ? `Moviendo articulos de ${table_title}` : ""}
      localization={localization_options}
      actions={[
        rowData => ({
          icon: () => <GiForklift />,
          tooltip: "Seleccionar ubicación de picking",
          onClick: (event, rowData) => {
            setTableTitle(rowData.nombre_ubicacion);
            cantidadDeUnidadesASalida(rowData, props);
            props.resetSelectorCantidadPicking();
          },
          disabled: !isInLote(rowData, props)
        })
      ]}
      options={{
        filtering: true,
        exportDelimiter: ";",
        headerStyle: {
          backgroundColor: lightGreen[700],
          color: "#FFF"
        },
        selection: false,
        actionsColumnIndex: -1
      }}
    ></MaterialTable>
  );
}

const mapStateToProps = state => {
  return {
    ubicacionesPicking: state.salidas.ubicacionesPicking,
    contenedoresEnLoteDeSalida: state.salidas.contenedoresEnLoteDeSalida,
    isLoadingPicking: state.salidas.isLoadingPicking,
    reloadPicking: state.salidas.reloadPicking,
    selectedTab: state.salidas.selectedTab,
    selectedCliente: state.pedidossalidas.selectedCliente,
    idSelectedClient: state.pedidossalidas.idSelectedClient,
    contenedores_ubicacion_picking:
      state.salidas.contenedores_ubicacion_picking,
    tipo_picking: state.salidas.tipo_picking,
    editLoteDialog: state.salidas.editLoteDialog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetSelectorCantidadPicking: () =>
      dispatch(actionCreators.resetSelectorCantidadPicking()),
    setDatosUbicacionPicking: datos =>
      dispatch(actionCreators.setDatosUbicacionPicking(datos)),
    setTipoPickingUbicacion: tipo_picking =>
      dispatch(actionCreators.setTipoPickingUbicacion(tipo_picking)),
    setUbicacionesPicking: ubicacionesPicking =>
      dispatch(actionCreators.setUbicacionesPicking(ubicacionesPicking)),
    setMaximosCantidadPicking: (total_bultos, total_displays, total_unidades) =>
      dispatch(
        actionCreators.setMaximosCantidadPicking(
          total_bultos,
          total_displays,
          total_unidades
        )
      ),
    removeContenedorDeLoteDeSalida: contenedor =>
      dispatch(actionCreators.removeContenedorDeLoteDeSalida(contenedor)),
    addContenedorALoteDeSalida: contenedor =>
      dispatch(actionCreators.addContenedorALoteDeSalida(contenedor)),
    setContenedoresUbicacionesPicking: contenedores_ubicacion_picking =>
      dispatch(
        actionCreators.setContenedoresUbicacionesPicking(
          contenedores_ubicacion_picking
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TablaPickingASalida);
