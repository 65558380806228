import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";
import Files from "react-files";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";
import BackupIcon from "@material-ui/icons/Backup";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

import { StateEditAusentismo } from "../VariablesState";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditAusentismo extends Component {
  state = JSON.parse(JSON.stringify(StateEditAusentismo));

  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  getEmpleado = id => {
    Database.get("/list-empleado/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          nombreEmpleado:
            resultado.result[0].nombre.toUpperCase() +
            " " +
            resultado.result[0].apellido.toUpperCase(),
          idEmpleado: id
        });
      }
    });
  };

  getAusentismoEdit = id => {
    Database.get("/list-ausentismos/" + id).then(resultado => {
      if (resultado.result.length > 0) {
        this.setState({
          nombreEmpleado: this.getEmpleado(resultado.result[0].id_empleado),
          ausentismoEdit: resultado.result[0]
        });

        let editAusentismoFormAlt = { ...this.state.editAusentismoForm };
        editAusentismoFormAlt.tipo.value = resultado.result[0].id_tipos;
        editAusentismoFormAlt.dolencia.value = resultado.result[0].dolencia;
        editAusentismoFormAlt.diagnostico.value =
          resultado.result[0].diagnostico;
        editAusentismoFormAlt.evolucion.value = resultado.result[0].evolucion;
        this.state.fechaBaja = resultado.result[0].fecha_baja;
        this.state.fechaProxControl = resultado.result[0].fecha_prox_control;
        this.state.fechaAltaProbable = resultado.result[0].fecha_alta_probable;
        this.state.fechaAlta = resultado.result[0].fecha_alta;
        for (let key in editAusentismoFormAlt) {
          editAusentismoFormAlt[key].touched = true;
          editAusentismoFormAlt[key].valid = true;
        }

        this.setState({
          editAusentismoForm: editAusentismoFormAlt
        });
        // this.getAusentismosType("edit", editAusentismoFormAlt);
      } else {
        this.setState({
          ausentismoEdit: null
        });
      }
    });

    Database.get("/list-ausentismos-tipo/").then(resultado => {
      console.log(resultado.result);
      if (resultado.result.length > 0) {
        console.log("entro");
        let editAusentismoFormAlt = { ...this.state.editAusentismoForm };

        let res = [...resultado.result];
        console.log(res);
        let s = [];
        res.forEach(function(entry) {
          s.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        console.log(s);

        //  console.log(this.state.orderForm);
        let formulario = { ...this.state.editAusentismoForm };
        // let id_muelle = JSON.parse(JSON.stringify(formulario.id_muelle))
        formulario.tipo.elementConfig.options = s;
        // formulario.id_muelle = id_muelle;

        for (let key in editAusentismoFormAlt) {
          editAusentismoFormAlt[key].touched = true;
          editAusentismoFormAlt[key].valid = true;
        }

        this.setState({
          editAusentismoForm: editAusentismoFormAlt
        });
      } else {
        // this.setState({
        //   bancoEdit: null
        // })
      }
    });
  };

  //   getAusentismoTipo = () => {
  //   Database.get('/list-ausentismos-tipo/')
  //   .then(res => {

  //     let resultado = [...res.result];
  //     let a = [];
  //     resultado.forEach(function (entry) {
  //       a.push({
  //         value: entry.id,
  //         displayValue: entry.descripcion
  //       });
  //     })
  //     let formulario = { ...this.state.EditAusentismoForm }
  //     formulario.tipo.elementConfig.options = [...a];
  //     this.setState({
  //       EditAusentismoForm: formulario
  //     })
  // },err => {
  //   toast.error(err.message);
  // })
  // }

  // handleChangePass = (event) => {
  //   event.preventDefault();
  //   this.setState({
  //     openChangePass:false
  //   })

  //   Database.post(`/update-pass`,{id: this.props.match.params.idausentismo,newpass:event.target.contrasenia.value})
  //     .then(res => {
  //       toast.success("La operación se ha modificado con exito!");
  //     },err =>{
  //       toast.error(err.message);

  //   })
  // }

  handleSubmitEditAusentismo = event => {
    event.preventDefault();
    Database.post(
      `/update-ausentismo`,
      {
        id: this.props.match.params.idausentismo,
        id_tipos: this.state.editAusentismoForm.tipo.value,
        dolencia: this.state.editAusentismoForm.dolencia.value,
        diagnostico: this.state.editAusentismoForm.diagnostico.value,
        evolucion: this.state.editAusentismoForm.evolucion.value,
        fecha_prox_control: this.state.fechaProxControl,
        fecha_alta_probable: this.state.fechaAltaProbable,
        fecha_alta: this.state.fechaAlta,
        fecha_baja: this.state.fechaBaja
      },
      this
    ).then(
      res => {
        this.setState(
          {
            successSubmitEdit: true,
            editFormIsValid: false,
            disableAllButtons: false
          },
          () => {
            toast.success("El ausentismo se ha modificado con exito!");

            //this.props.getAusentismosAdmin();
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  onFilesChange = files => {
    console.log(files);
    this.setState({
      files: files
    });
    console.log(this.state.files);

    const formData = new FormData();
    formData.append("certificado", files[0]);
    // if (props.thumbs)
    //     formData.append('thumbs', JSON.stringify(props.thumbs));

    var id = this.props.match.params.idausentismo; //buscar id

    Database.post(
      "/insert-ausentismo-certificado/" + id,
      formData,
      this,
      false,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then(
      res => {
        // setIsLoading(false);
        toast.success(
          "El archivo " + files[0].name + " se ha subido con exito!"
        );
        // callback.bind(this)(file_name);
        console.log(res);
      },
      err => {
        //    setIsLoading(false);
        toast.error(err.message);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editAusentismoForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    this.setState({
      editAusentismoForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  resetEditForm = () => {
    let editAusentismoFormAlt = { ...this.state.editAusentismoForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in editAusentismoFormAlt) {
      editAusentismoFormAlt[key].value = "";
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    });
  };

  componentDidMount() {
    // this.getAusentismosType();
    console.log(this.props.match.params);
    this.getAusentismoEdit(this.props.match.params.idausentismo);
    //this.getAusentismoTipo();
  }

  handleFechaBaja = date => {
    this.setState({
      fechaBaja: date
    });
  };
  handleFechaProxControl = date => {
    this.setState({
      fechaProxControl: date
    });
  };
  handleFechaAltaProbable = date => {
    this.setState({
      fechaAltaProbable: date
    });
  };
  handleFechaAlta = date => {
    this.setState({
      fechaAlta: date
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.editAusentismoForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editAusentismoForm[key]
      });
    }

    return [
      <form
        onSubmit={event => {
          this.handleSubmitEditAusentismo(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              {this.state.nombreEmpleado}
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del ausentismo
            </p>
          </CardHeader>
          <CardBody>
            {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
        Cambiar Contraseña
      </Button> */}

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <Input
                  key={"editausentismo-" + formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  textValid={formElement.config.textValid}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={event =>
                    this.inputEditChangedHandler(event, formElement.id)
                  }
                />
              ))}
            </div>

            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechabaja"
                  label="Fecha Baja"
                  format="dd/MM/yyyy"
                  value={this.state.fechaBaja}
                  onChange={this.handleFechaBaja}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechaproxcontrol"
                  label="Fecha Proximo Control"
                  format="dd/MM/yyyy"
                  value={this.state.fechaProxControl}
                  onChange={this.handleFechaProxControl}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechaaltaprobable"
                  label="Fecha Alta Probable"
                  format="dd/MM/yyyy"
                  value={this.state.fechaAltaProbable}
                  onChange={this.handleFechaAltaProbable}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
              <div>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechaalta"
                  label="Fecha Alta"
                  format="dd/MM/yyyy"
                  value={this.state.fechaAlta}
                  onChange={this.handleFechaAlta}
                  autoOk={true}
                  cancelLabel={"Cancelar"}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>

            <div className="files">
              <Files
                className="files-dropzone"
                onChange={this.onFilesChange}
                onError={this.onFilesError}
                accepts={[
                  "image/png",
                  ".pdf",
                  "audio/*",
                  ".docx",
                  ".doc",
                  ".jpg"
                ]}
                multiple
                maxFiles={3}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <Button
                  style={{ marginTop: "25px" }}
                  color="primary"
                  variant="contained"
                >
                  <BackupIcon />
                  &nbsp; Adjuntar Certificados de Ausentismo
                </Button>
              </Files>
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="info"
              onClick={() => this.props.history.push("/admin/ausentismos")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,

      <Dialog
        open={this.state.openChangePass}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
        <form
          onSubmit={event => {
            this.handleChangePass(event);
          }}
        >
          {this.state.openChangePass && (
            <DialogContent>
              <DialogContentText>
                Ingrese una nueva contraseña para el Ausentismo
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="contrasenia"
                name="contrasenia"
                label="nueva contraseña"
                type="password"
                fullWidth
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(EditAusentismo));
