import React from "react";
import "./Regla.css";
import interact from "interactjs";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import * as calc from "../CalculosAccesorios/CalculosAccesorios";

const regla = props => {
  let currentScale = 1;
  try {
    currentScale = Number(calc.getCurrentScale());
  } catch {
    //pass
  }

  let anchoRegla = props.width;

  // const currentScale = 2;
  let intervaloLineas = 0;
  let posicionesLineas = [];
  let labelMetros = [];

  if (props.iconType === "forward") {
    intervaloLineas = 0;
    posicionesLineas = [];
    labelMetros = [];
  } else {
    intervaloLineas = (props.width - 30) / 3;
    posicionesLineas = [
      intervaloLineas,
      intervaloLineas * 2,
      intervaloLineas * 3
    ];
    labelMetros = ["10 m", "20 m", ""];
  }

  // const styleRegla = {
  //   width: String(anchoRegla) + 'px',
  // }

  let styleRegla = {};
  if (currentScale <= 1) {
    styleRegla = {
      width: String(anchoRegla) + "px",
      display: "block",
      transform: "scale(" + currentScale + ")"
    };
  } else {
    styleRegla = {
      display: "none"
    };
  }

  // transform: 'scale(' + currentScale + ')'
  const styleIcon = {
    fontSize: "12px"
  };

  let arrowIcon = null;
  if (props.iconType === "back") {
    arrowIcon = <ArrowBackIosIcon style={styleIcon} />;
  } else {
    arrowIcon = <ArrowForwardIosIcon style={styleIcon} />;
  }

  return (
    <div className="regla" style={styleRegla} iconType={props.iconType}>
      {posicionesLineas.map((x, index) => {
        let styleLine = {
          left: String(x) + "px"
        };
        let label = labelMetros[index];
        return (
          <div>
            <div className="rulerLabel" style={styleLine}>
              {label}
            </div>
            <div className="line" style={styleLine}></div>
          </div>
        );
      })}
      <button onClick={props.click_hide} className="arrowButton">
        {arrowIcon}
      </button>
    </div>
  );
};

// target elements with the "draggable" class
interact(".draggable").draggable({
  // enable inertial throwing
  inertia: true,
  // keep the element within the area of it's parent
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: "parent",
      endOnly: true
    })
  ],
  // enable autoScroll
  autoScroll: true,

  listeners: {
    // call this function on every dragmove event
    move: dragMoveListener,

    // call this function on every dragend event
    end(event) {
      var textEl = event.target.querySelector("p");

      textEl &&
        (textEl.textContent =
          "moved a distance of " +
          Math.sqrt(
            (Math.pow(event.pageX - event.x0, 2) +
              Math.pow(event.pageY - event.y0, 2)) |
              0
          ).toFixed(2) +
          "px");
    }
  }
});

function dragMoveListener(event) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  //var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

  // translate the element
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + 0 + "px)";

  // update the posiion attributes
  target.setAttribute("data-x", x);
  //target.setAttribute('data-y', y)
}

export default regla;
