import React from "react";

//COMPONENTES LOCALES
// import Input from "components/Input/Input";
// import { localization } from "variables/general.js";
// import { toast } from "react-toastify";

//ESTILOS Y COLORES
import { makeStyles } from "@material-ui/core/styles";

//CONTENEDORES
// import MaterialTable, { MTableBodyRow } from "material-table";
// import Paper from "@material-ui/core/Paper";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import NewProductoUnidad from "../../Productos/components/NewProductoUnidad";

const useStyles = makeStyles(theme => ({
  root: {
    width: "90%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  closeButton: {
    position: "absolute",
    right: "0.5em",
    top: "0.5em",
    color: "grey"
  }
}));

export default function ModalNuevaUnidad(props) {
  const classes = useStyles();

  React.useEffect(() => {}, []);

  return (
    <Dialog
      open={props.openDialog}
      onClose={props.closeDialog}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>
        Nueva Referencia
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.closeDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <NewProductoUnidad
          modal={true}
          rowCliente={props.rowCliente}
          onSave={last_insert => {
            props.onSave(last_insert);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
